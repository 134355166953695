import { Box, Typography } from "@mui/material";
import Italics from "../../../common/Italics";

export default function TermsFPAgreement() {
  return (
    <Box style={{ height: "100vh", overflow: "auto" }}>
      <Typography variant="h3" mb={3}>
        Agreement terms
      </Typography>
      <Typography variant="h3" mb={3}>
        RECITALS
      </Typography>

      <Typography variant="body1">
        <ol>
          <li>The Principal conducts the business known as WeFlex.</li>
          <li>WeFlex provides health and fitness services to people living with a disability.</li>
          <li>
            The Principal has agreed to use the Services of the Contractor and the Contractor has agreed to provide
            those Services on the terms and conditions set out in this agreement.
          </li>
        </ol>
      </Typography>

      <Typography variant="h3" mb={3}>
        OPERATIVE PART
      </Typography>
      <Typography variant="h3" mb={3}>
        1. Interpretation
      </Typography>

      <Typography variant="body1">
        <p>
          This agreement is governed by the laws of New South Wales and the parties submit to the non-exclusive
          jurisdiction of the courts of that State. In the interpretation of this agreement:
        </p>
        <ol>
          <li>
            References to legislation or provisions of legislation include changes or re-enactments of the legislation
            and statutory instruments and regulations issued under the legislation;
          </li>
          <li>
            Words denoting the singular include the plural and vice versa, words denoting individuals or persons include
            bodies corporate and vice versa, words denoting one gender include all genders and references to documents
            or agreements also mean those documents or agreements as changed, novated or replaced;
          </li>
          <li>Grammatical forms of defined words or phrases have corresponding meanings;</li>
          <li>
            Parties must perform their obligations on the dates and times fixed by reference to the capital city of New
            South Wales;
          </li>
          <li>
            Reference to an amount of money is a reference to the amount in the lawful currency of the Commonwealth of
            Australia;
          </li>
          <li>
            If the day on or by which anything is to be done is a Saturday, a Sunday or a public holiday in the place in
            which it is to be done, then it must be done on the next business day;
          </li>
          <li>
            References to a party are intended to bind their heirs, executors, administrators, successors and assigns;
            and
          </li>
          <li>
            Obligations under this agreement affecting more than one party bind them jointly and each of them severally.
          </li>
        </ol>
      </Typography>

      <Typography variant="h3">2. Services</Typography>

      <Typography variant="body1">
        <ol>
          <li>
            The engagement of the Contractor commences on the date of your first session with a WeFlex client and
            continues until terminated in accordance with this agreement.
          </li>
          <li>
            The Principal and the Contractor enter into the arrangements on the terms set out herein for the provision
            by the Contractor of services to the Principal as described in the schedule. (“Services”){" "}
          </li>
          <li>
            The Contractor warrants the provision of the Services will not breach the terms of any other binding
            arrangement.
          </li>
          <li>
            The Contractor will undertake the performance of the Services at such times, locations and regularity as is
            agreed between it and the Client. Client means those clients of WeFlex who are living with a disability or
            chronic illness and who are the beneficiary of the Services.
          </li>
          <li>
            The Contractor will provide the Services in a professional and diligent manner to the standards normally
            expected of someone performing the Services.
          </li>
          <li>
            The Contractor will not be required to perform the Services for more than 10 hours per week on average over
            any given 4-week period.
          </li>
          <li>
            The Contractor may sub contract the provision of the Services to those suitably qualified to undertake the
            Services provided they have first undertaken initial training and on boarding with the Principal.
          </li>
        </ol>
      </Typography>
      <Typography variant="h3" mb={3}>
        3. Scope of the Services
      </Typography>

      <Typography variant="body1">
        <ol>
          <li>
            The Contractor is responsible for completion of the Services described in the schedule, which may be varied
            by agreement of the Parties as circumstances dictate.
          </li>
          <li>
            The Contractor must undergo initial training and an onboarding process from WeFlex and comply with all
            policies and procedures adopted by the Principal in the conduct of its business.
          </li>
          <li>
            The Contractor will act with good faith in all of the Contractor’s dealings with the Principal and, whilst
            performing the Services, the Contractor will not intentionally do anything which is harmful to the
            Principal.
          </li>
          <li>
            During the term of this agreement the Contractor may engage in any other employment, occupation, consulting
            or other business activity directly or indirectly competitive with the business of the Principal other than
            for the provision of Services to those Clients who have already been provided Services by the Principal, its
            agents or its contractors.
          </li>
          <li>
            During the term of this agreement the Contractor must not engage in any other employment, occupation,
            consulting or other business activity directly or indirectly in conflict with the Contractor&apos;s
            obligations to the Principal under this agreement.
          </li>
        </ol>
      </Typography>

      <Typography variant="h3" mb={3}>
        4. Equipment
      </Typography>

      <Typography variant="body1" mb={3}>
        The Contractor must provide their own equipment in order to carry out the provision of the Services fully and
        comprehensively under this agreement.
      </Typography>

      <Typography variant="h3" mb={3}>
        5. Fees and expenses
      </Typography>

      <Typography variant="body1">
        <ol>
          <li>
            At the end of each fortnight the Contractor will submit a tax invoice, in approved format, for the Services
            completed by the Contractor in the amount agreed, or calculated at the rate agreed, from time to time and
            payment will be made within 14 days thereof.
          </li>
          <li>
            The Contractor will be responsible for all their expenses incurred in the performance of the Services.
          </li>
          <li>
            The Contractor must maintain a basic accounting system that provides for the issue of compliant tax
            invoices, receipts and appropriate financial records necessary to conduct its business efficiently and
            professionally.{" "}
          </li>
        </ol>
      </Typography>

      <Typography variant="h3" mb={3}>
        6. Statutory obligations, insurances and registrations
      </Typography>

      <Typography variant="body1">
        <ol>
          <li>
            The Contractor will comply with their statutory obligations in respect of the Services, including but not
            limited to, compliance with:
          </li>
          <ol>
            <li>Any applicable industrial awards and agreements;</li>
            <li>
              Minimum terms and conditions of employment including those in respect of long service leave, annual leave,
              parental leave and sick leave;
            </li>
            <li>Applicable industrial relations and anti-discrimination legislation;</li>
            <li>Occupational health and safety and workers compensation legislation; and</li>
            <li>Superannuation Guarantee; </li>
            <li>Workers Compensation.</li>
          </ol>
          <li>
            Prior to the date of this agreement the Contractor must obtain, and thereafter must maintain during the
            period of this agreement, current policies of insurance, in respect of the Services, against:
          </li>
          <li>All third-party risks in relation to persons and property including public liability insurance;</li>
          <li>Workers compensation claims; and</li>
          <li>Negligence by the Contractor or an employee of the Contractor.</li>
          <li>
            The Contractor acknowledges they are solely responsible for all remuneration, expenses, taxes, leave
            entitlements, superannuation, workers compensation and other insurances in respect of them or their
            employees.
          </li>
          <li>
            The Contractor will obtain and maintain until the termination of this agreement any licences or
            registrations required for the Contractor or their employees to perform the Services.
          </li>
          <li>
            Prior to the date of this agreement the Contractor must obtain, and thereafter must maintain during the
            period of this agreement an Australian Business Number.{" "}
          </li>
        </ol>
      </Typography>

      <Typography variant="h3" mb={3}>
        7. Confidential information
      </Typography>

      <Typography variant="body1">
        <ol>
          <li>
            The Contractor shall not at any time or for any reason, whether during the term of this agreement or after
            its termination, divulge any Confidential Information to any third party and they hereby indemnify the
            Principal and any related entity against any loss or damage they may suffer as a result of the breach of
            confidentiality.
          </li>
          <li>
            The Contractor shall cause any person assisting them to sign an acknowledgement of their obligations to
            observe the confidentiality to the same extent as the Contractor.
          </li>
          <li>
            For the purposes of this Agreement Confidential Information means but is not limited to processes and
            procedures regarding onboarding, training, names and contact details of the Client’s and fees for Services.{" "}
          </li>
        </ol>
      </Typography>

      <Typography variant="h3" mb={3}>
        8. Intellectual property
      </Typography>

      <Typography variant="body1">
        <ol>
          <li>
            The Parties acknowledges that the intellectual property in all works of the Contractor or their servants or
            agents in the course of the fulfilment of the Contractor’s obligations hereunder are the absolute property
            of the Contractor;
          </li>
          <li>
            The Parties acknowledges that the intellectual property in all works of the Principal or their servants or
            agents in the course of the fulfilment of the Principal’s obligations hereunder including initial training,
            on boarding and various policies are the absolute property of the Principal and that the Contractor shall do
            all things and sign all documents that may be necessary to vest such intellectual property in the Principal.{" "}
          </li>
        </ol>
      </Typography>

      <Typography variant="h3" mb={3}>
        9. Termination by notice
      </Typography>

      <Typography variant="body1" mb={3}>
        Either party may terminate this agreement by one month written notice to the other.
      </Typography>

      <Typography variant="h3" mb={3}>
        10. Termination without notice
      </Typography>

      <Typography variant="body1">
        <ol>
          <li> The Principal may immediately terminate this agreement if the Contractor:</li>
          <ol>
            <li>
              Commits any serious or persistent breach of this agreement which is in the reasonable opinion of the
              Principal incapable of rectification; or
            </li>
            <li>
              Fails to remedy, to the Principal&apos;s reasonable satisfaction, a breach of any provision of this
              agreement within one week of receiving a notice from the Principal identifying the breach and requiring
              the breach to be remedied; or
            </li>
            <li>
              Becomes bankrupt or goes into liquidation or makes any assignment arrangement or composition with
              creditors of the Contractor on the happening of the event; or
            </li>
            <li>
              Is subject to a finding of guilt for a criminal or civil offence, other than an offence which, in the
              reasonable opinion of the Principal, does not affect the Contractor&apos;s ability to perform their
              duties; or
            </li>
            <li>
              A nominated employee of the Contractor, in the performance of Services, commits any act of misconduct,
              fraud or dishonesty; or
            </li>
            <li>Ceases business.</li>
          </ol>
          <li>
            The Contractor may immediately terminate this agreement by giving written notice to the Principal if the
            Principal:
          </li>
          <ol>
            <li>
              Commits any serious or persistent breach of this agreement, which is in the reasonable opinion of the
              Contractor incapable of rectification; or
            </li>
            <li>
              Commits any serious or persistent breach of this agreement which the Principal fails to remedy for one
              week after the Principal receives written notice from the Contractor of that breach; or
            </li>
            <li>Is placed under some form of official management or insolvency administration.</li>
          </ol>
          <li>
            On termination of this agreement, and after receipt of an appropriate invoice from the Contractor, the
            Principal will pay to the Contractor the amount of any fee and reimbursement of approved expenses owing
            pursuant to this agreement, up to and including the date of termination. The Principal may set-off and
            deduct from any amount owed to the Contractor including payments made in advance against unfinished works
            that the Principal will require to be completed by others.
          </li>
          <li>
            The termination of this agreement will not prejudice any rights or remedies already accrued to any party
            under, or in respect of any breach of, this agreement.
          </li>
        </ol>
      </Typography>

      <Typography variant="h3" mb={3}>
        11. Delivery up on termination
      </Typography>

      <Typography variant="body1">Upon termination of this agreement, the Contractor must:</Typography>

      <Typography variant="body1">
        <ol>
          <li>
            Immediately deliver to the Principal any documents, plans, lists, inventions, and intellectual property
            arising out of the Services undertaken by the Contractor or any other person working for the Contractor for
            the Principal; and
          </li>
          <li>Disclaim any association with the business of the Principal.</li>
        </ol>
      </Typography>

      <Typography variant="h3" mb={3}>
        12. Delivery up on termination
      </Typography>

      <Typography variant="body1" mb={3}>
        The Contractor agrees that for a period of 12 months from the date of termination of this agreement it will not
        directly or indirectly endeavour to entice away from the Principal or any of their related entities any person
        or organisation who is a Client, employee, customer or contractor of the Principal or any of its related
        entities.
      </Typography>

      <Typography variant="h3" mb={3}>
        13. Dispute resolution
      </Typography>

      <Typography variant="body1" mb={3}>
        <ol>
          <li>
            General If a dispute arises out of or relates to this agreement, including any dispute as to breach or
            termination of the agreement or as to any claim in tort, in equity or pursuant to any statute, neither party
            may commence any court or arbitration proceedings relating to the dispute unless they have complied with
            this clause except where they seek urgent interlocutory relief.
          </li>
          <li>
            Notice specifying the nature of the dispute The party to this agreement claiming that a dispute has arisen
            under or in relation to this agreement must give written notice to the other party to this agreement
            specifying the nature of the dispute. On receipt of the notice referred to in this clause by that party,
            both parties must endeavour to resolve the dispute expeditiously using informal dispute resolution
            techniques such as mediation, expert evaluation, determination or similar techniques agreed by them.
          </li>
          <li>
            Mediation If the Contractor and the Principal do not agree within 14 days of receipt of the notice, or such
            further period as agreed in writing by them, as to: The dispute resolution technique and procedures to be
            adopted; The timetable for all steps in those procedures; and The selection and compensation of the
            independent person required for such technique, then they must mediate the dispute in accordance with the
            mediation rules of the recognised professional association for solicitors in the State of New South Wales.
            The president of this professional association or the president&apos;s nominee will select the mediator and
            determine the mediator&apos;s remuneration.
          </li>
          <li>
            Proceedings If the mediation referred to above is not completed within four weeks of reference to a
            mediator, then either party may commence any court or arbitration proceedings relating to the dispute as
            they see fit.
          </li>
          <li>Associated costs The costs of the mediation will be borne equally by the parties.</li>
        </ol>
      </Typography>

      <Typography variant="h3" mb={3}>
        14. GST
      </Typography>

      <Typography variant="h4" mb={3}>
        Application of this clause
      </Typography>

      <Typography variant="body1" mb={3}>
        <ol>
          <li>
            Words and phrases set out in italics in this agreement have the same meaning as those words and phrases in
            the <Italics>A New Tax System (Goods and Services Tax) Act 1999</Italics> (Cth) <strong>(GST Law).</strong>
          </li>
          <li>This clause applies if the Contractor is registered for GST.</li>
        </ol>
      </Typography>

      <Typography variant="h4" mb={3}>
        Application of this clause
      </Typography>
      <Typography variant="body1" mb={3}>
        <ol>
          <li>
            Where the Services constitute a <Italics>taxable supply</Italics> under <Italics>GST Law</Italics>, the
            Contractor must issue a <Italics>tax invoice</Italics> to the Principal for the purposes of GST Law.
          </li>
          <li>
            If GST is imposed on any <Italics>taxable supply</Italics> in connection with this agreement, then the
            Principal must also pay the GST payable in respect of that <Italics>taxable supply</Italics>:
            <ol>
              <li>
                on delivery of the <Italics>tax invoice</Italics> by the Contractor to the Principal, being the{" "}
                <Italics>supplier</Italics> of that <Italics>taxable supply</Italics> to the{" "}
                <Italics>recipient</Italics>; and
              </li>
              <li>
                in the same manner as the <Italics>value</Italics> is payable.
              </li>
            </ol>
          </li>
          <li>
            Where the Services or any part of it constitutes a <Italics>taxable supply</Italics> under GST Law, the
            Contractor undertakes to comply in every respect with GST Law, and to provide every possible assistance to
            the Principal for the purpose of ensuring that it is able to comply with GST Law.
          </li>
          <li>
            The Contractor agrees to immediately notify the Principal in writing it if ceases to be registered for{" "}
            <Italics>GST</Italics>.
          </li>
        </ol>
      </Typography>

      <Typography variant="h3" mb={3}>
        15. Relationship of the parties
      </Typography>

      <Typography variant="body1" mb={3}>
        The parties acknowledge that this agreement is intended as an agreement for the provision of Services and
        creates the relationship of Principal and Contractor and not any other relationship and, in particular, not the
        relationship of employer and employee, Principal and agent or the relationship of partnership.
      </Typography>

      <Typography variant="h3" mb={3}>
        16. Assignment
      </Typography>

      <Typography variant="body1" mb={3}>
        The Parties acknowledge and agree that the Principal may assign all of its right tittle and interest in this
        Agreement to a third party. The Parties further acknowledge and agreed that the Contractor may assign all of its
        right tittle and interest in this Agreement to a third party provided that the assignee complies with all
        requirements and obligations required of the Contractor under this Agreement, undergoes initial training from
        WeFlex and has the requisite qualifications to carry out the Services.
      </Typography>

      <Typography variant="h3" mb={3}>
        SCHEDULE
      </Typography>
      <table>
        <tr>
          <td>Service fees:</td>
          <td>
            <Typography variant="body1">
              One on One personal training: $60.00 per session (plus GST - $66.00 total if applicable)
            </Typography>
            <Typography variant="body1">
              One on One personal training: $60.00 per session (plus GST - $66.00 total if applicable)
            </Typography>
            <Typography variant="body1">
              Group Class (5 or less clients): $70.00 per session (plus GST - $77.00 total if applicable)
            </Typography>
            <Typography variant="body1">
              Group Class (6-9 clients): $90.00 per session (plus GST - $99.00 total if applicable) Group Class (10 +
              clients): $110.00 per session (plus GST - $121.00 total if applicable)
            </Typography>
          </td>
        </tr>
        <tr>
          <td>
            <Typography variant="body1">Description of Services:</Typography>
          </td>
          <td>
            <Typography variant="body1">
              Provide personal and exercise related activies in accordance with WeFlex inital training and on boarding
              techniques to people living with a disability.
            </Typography>
          </td>
        </tr>
        <tr>
          <td>
            <Typography variant="body1">Scope of Services:</Typography>
          </td>
          <td>
            <Typography variant="body1">
              Provide tailored personal and exercise releated activites that is agreed to between the Client and the
              Contractor from time to time.
            </Typography>
          </td>
        </tr>
      </table>
    </Box>
  );
}
