const ENV_VARIABLES_RAW = {
  ENVIRONMENT: process.env.NODE_ENV,
  API_HTTP_URL: process.env.REACT_APP_API_HTTP_URL,
  GOOGLE_MAPS_API_KEY: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  CC_EMAIL_ADDRESS: process.env.REACT_APP_CC_EMAIL_ADDRESS,
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
  SENTRY_ENVIRONMENT: process.env.REACT_APP_HOST_ENVIRONMENT,
  GA_MEASUREMENT_ID: process.env.REACT_APP_GA_MEASUREMENT_ID,
  SUPPORTING_DOCS_URI: process.env.REACT_APP_SUPPORTING_DOCS_URI,
  FP_BOOK_INTERVIEW_URI: process.env.REACT_APP_FP_BOOK_INTERVIEW_URI,
};

const ENV = {
  ...ENV_VARIABLES_RAW,

  IS_PRODUCTION: process.env.NODE_ENV === "production",
  IS_DEVELOPMENT: process.env.NODE_ENV === "development",
  IS_TEST: process.env.NODE_ENV === "test",
  WEFLEX_ACADEMY_URL: "https://weflex.myabsorb.com.au/#/login",
  WEFLEX_TERMS_AND_CONDITIONS_URL:
    "https://weflex-production-assets-bucket-prod.s3.ap-southeast-2.amazonaws.com/static/WeFlex+Terms+and+Conditions.pdf",
  WEFLEX_CONTACT_US_URL: "https://weflex.com.au/contact/",
  WEFLEX_TRUST_SAFETY_URL:
    "https://weflex-production-assets-bucket-prod.s3.ap-southeast-2.amazonaws.com/static/Trust+and+Safety.pdf",
  WEFLEX_WELCOME_FP_VIDEO_URL: "https://vimeo.com/724367977",
  WEFLEX_WELCOME_PWAD_VIDEO_URL: "https://vimeo.com/768809288",
  WEFLEX_INCIDENT_REPORT_PDF_URL:
    "https://weflex-production-assets-bucket-prod.s3.ap-southeast-2.amazonaws.com/static/WeFlex+Incident+Report.pdf",
  WEFLEX_PHONE_NUMBER: "1300 933 539",
};

if (ENV.IS_DEVELOPMENT) {
  console.log(ENV);
}

export default ENV;
