import { Dispatch, SetStateAction } from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import SchoolIcon from "@mui/icons-material/School";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { isNull } from "lodash";
import { useQuery } from "react-query";
import { RouteComponentProps, useParams } from "react-router-dom";
import CurrentStepStatusAlert from "./CurrentStepStatusAlert";
import TrainingForm from "./edit/TrainingForm";
import { ErrorResponse } from "../../../api/Generic";
import { useGetFPProfileAboutMe } from "../../../api/queries/FPProfile";
import { trainingStepNumber } from "../../../constants/fpProfileConstants";
import { FPProfileTrainingModel } from "../../../models/FPProfile";
import { FPProfileStatus } from "../../../models/ProfileBase";
import { getFPProfileIsLive, getFPProfileStatus } from "../../../utils/person";
import ColumnBox from "../../common/ColumnBox";
import ErrorAlert from "../../common/ErrorAlert";
import PrivateFileLink from "../../common/PrivateFileLink";
import ProfileCard from "../../common/ProfileCard";
import ProfileCardTitleWithContent from "../../common/ProfileCardTitleWithContent";

interface TrainingViewProps {
  status: FPProfileStatus;
  isProfileLive: boolean;
  profileId: string;
}

function TrainingView({ status, isProfileLive, profileId }: TrainingViewProps) {
  const {
    data: trainingData,
    isLoading,
    error,
  } = useQuery<FPProfileTrainingModel, ErrorResponse>(`/fpprofile/training/?fpprofile=${profileId}`);

  if (isLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <ErrorAlert message="Profile details failed to load" />;
  }

  return (
    <Grid container spacing={2} py={2}>
      <Grid item xs={12}>
        <CurrentStepStatusAlert stepNumber={trainingStepNumber} status={status} isLive={isProfileLive} />
        <Typography variant="h1" mb={3}>
          My{" "}
          <Typography display="inline" variant="h1" component="span" color="link.main">
            Training.
          </Typography>
        </Typography>

        <Typography variant="body1" mb={3}>
          Before you are eligible for an interview you must complete the below training modules. Once you have completed
          these please update the fields below.
        </Typography>
      </Grid>

      <Grid item lg={6} xs={12}>
        <ColumnBox gap={3}>
          <ProfileCard title="WeFlex Academy" Icon={SchoolIcon}>
            <Typography variant="body1" mb={3}>
              <ol>
                <li>
                  To create your Academy account and get started go to:{" "}
                  <a target="_blank" href="https://weflex.myabsorb.com.au/?KeyName=Personal_Trainers" rel="noreferrer">
                    https://weflex.myabsorb.com.au/?KeyName=Personal_Trainers
                  </a>
                </li>
                <Typography variant="body1" mb={3} mt={3}>
                  OR
                </Typography>
                <li>
                  Already setup your account? Just login here:
                  <a target="_blank" href="https://weflex.myabsorb.com.au/#/login" rel="noreferrer">
                    https://weflex.myabsorb.com.au/#/login{" "}
                  </a>
                </li>
              </ol>
            </Typography>
          </ProfileCard>

          <ProfileCard title="NDIS Worker Screen Number" Icon={CheckCircleOutlineIcon}>
            <Typography variant="body1" mb={3}>
              For instructions on how to apply for a NDIS worker screen number visit:{" "}
              <a
                target="_blank"
                href="https://www.ndiscommission.gov.au/workers/worker-screening/where-apply-worker-screening"
                rel="noreferrer"
              >
                this page.
              </a>
            </Typography>

            <ProfileCardTitleWithContent
              title="NDIS Worker Screen Number"
              content={trainingData?.ndis_worker_screen_number}
            />
          </ProfileCard>
        </ColumnBox>
      </Grid>

      <Grid item lg={6} xs={12}>
        <ProfileCard title="NDIS Worker Orientation Module" Icon={CheckCircleOutlineIcon}>
          <Typography variant="body1" mb={3}>
            To complete this module visit:{" "}
            <a
              target="_blank"
              href="https://training.ndiscommission.gov.au//course/index.php?categoryid=2"
              rel="noreferrer"
            >
              https://training.ndiscommission.gov.au//course/index.php?categoryid=2
            </a>
          </Typography>

          {!!trainingData?.ndis_orientation_module_file && (
            <PrivateFileLink
              privateFileId={trainingData?.ndis_orientation_module_file}
              viewFileText="View Primary qualification"
            />
          )}
        </ProfileCard>
      </Grid>
    </Grid>
  );
}

interface Props extends RouteComponentProps {
  setSavedSnackbarMessage: Dispatch<SetStateAction<string>>;
}

export default function Training({ setSavedSnackbarMessage, history, location, match }: Props): JSX.Element {
  const { profileId } = useParams<{ profileId: string }>();
  const { data: aboutMeData, isLoading: aboutMeLoading, error: aboutMeError } = useGetFPProfileAboutMe(profileId);
  // Note: this does not user the current user because the current user could be an admin looking at an FP's profile.
  const isProfileLive = getFPProfileIsLive(aboutMeData);
  const status = getFPProfileStatus(aboutMeData);

  const isTrainingEditable = status === FPProfileStatus.PROFILE_SUBMITTED;

  if (aboutMeLoading || !aboutMeData || isNull(isProfileLive) || isNull(status)) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (aboutMeError) {
    return <ErrorAlert message="Profile details failed to load" />;
  }

  if (!isTrainingEditable) {
    return <TrainingView isProfileLive={isProfileLive} status={status} profileId={profileId} />;
  }

  return (
    <TrainingForm
      setSavedSnackbarMessage={setSavedSnackbarMessage}
      history={history}
      location={location}
      match={match}
    />
  );
}
