import React from "react";
import DescriptionIcon from "@mui/icons-material/Description";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import { Link, Typography } from "@mui/material";
import { startsWith } from "lodash";
// @ts-ignore
import mime from "mime";
import ProfileCardIconWithContent from "./ProfileCardIconWithContent";

interface Props {
  publicFileUrl: string;
  viewFileText?: string;
}

export default function PublicFileLink({ publicFileUrl, viewFileText }: Props) {
  const publicFileMimeType = mime.getType(publicFileUrl);

  let Icon = DescriptionIcon;

  if (publicFileMimeType === "application/pdf") {
    Icon = PictureAsPdfIcon;
  } else if (startsWith(publicFileMimeType, "image/")) {
    Icon = PhotoLibraryIcon;
  } else if (startsWith(publicFileMimeType, "video/")) {
    Icon = VideoLibraryIcon;
  }

  return (
    <ProfileCardIconWithContent
      Icon={Icon}
      iconColor="grey.600"
      content={
        <Typography>
          <Link
            href={publicFileUrl}
            target="_blank"
            color="link.main"
            sx={{
              cursor: "pointer",
              textDecoration: "none",
              maxWidth: "100%",
              wordBreak: "break-all",
              overflowWrap: "break-word",
              whiteSpace: "normal",
            }}
          >
            {viewFileText ? viewFileText : "View file"}
          </Link>
        </Typography>
      }
    />
  );
}
