import { Typography } from "@mui/material";
import { SessionLocationModel, SessionLocationTypeEnum } from "../../../models/Session";
import CheckIconList from "../../common/CheckIconList";

interface Props {
  locations: SessionLocationModel[];
}

interface FPSessionLocationProps {
  location: SessionLocationModel;
}

function FPSessionLocation({ location }: FPSessionLocationProps) {
  const { location_type: locationType, place, mobile_location: mobileLocation, id } = location;

  if (locationType === SessionLocationTypeEnum.FIXED) {
    return (
      <>
        <Typography key={id}>{place}</Typography>
      </>
    );
  }

  if (locationType === SessionLocationTypeEnum.MOBILE) {
    return (
      <>
        <Typography key={id}>Mobile: {mobileLocation}</Typography>
      </>
    );
  }

  if (locationType === SessionLocationTypeEnum.VIRTUAL) {
    return (
      <>
        <Typography key={id}>Online: link provided by fitness professional on booking confirmation</Typography>
      </>
    );
  }

  return null;
}

function FPSessionLocations({ locations }: Props) {
  return (
    <CheckIconList
      list={locations
        // We actually want to have these in the order Fixed, Mobile, Online. It just happens that this corresponds to alpha sorting.
        .sort((a, b) => a.location_type.localeCompare(b.location_type))
        .map((location) => ({
          id: location.id,
          name: <FPSessionLocation location={location} />,
        }))}
    />
  );
}

export default FPSessionLocations;
