import { ReactNode } from "react";
import { Avatar, Box, Grid, Paper, Typography, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { PersonModel } from "../../models/Person";
import { SessionLocationTypeEnum } from "../../models/Session";
import { formatDateTimeStr } from "../../utils/formatDateTime";
import getAvatarUrl from "../../utils/getAvatarUrl";
import { getFullName } from "../../utils/person";
import SessionLocation from "../pwad/request-session/SessionLocation";

const AVATAR_SIZE = 70;

type SessionLocationModel = {
  place: string;
  location_type: SessionLocationTypeEnum;
  mobile_location: string;
};

interface Props {
  date: string;
  startTime: string;
  person: PersonModel;
  profileLinkUrl: string | null;
  personTitle?: string;
  emailPersonLink?: string;
  location: SessionLocationModel;
  showDirectionsLink?: boolean;
  renderDateStatus?(): ReactNode;
  renderActions?(): ReactNode;
}

export default function SessionRow({
  date,
  person,
  startTime,
  renderDateStatus,
  profileLinkUrl,
  personTitle,
  emailPersonLink,
  location,
  showDirectionsLink = false,
  renderActions,
}: Props): JSX.Element {
  const { place, location_type: locationType, mobile_location: mobileLocation } = location;

  return (
    <Grid container>
      <Grid item xs={12} lg={1}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          sx={{ backgroundColor: "grey.200" }}
          height={{ xs: 100, lg: 133 }}
        >
          <Typography variant="h3">{formatDateTimeStr(date, "eee MMM d")}</Typography>

          <Typography variant="body2">{formatDateTimeStr(`${date} ${startTime}`, "hh:mmaaa")}</Typography>

          {!!renderDateStatus && renderDateStatus()}
        </Box>
      </Grid>

      <Grid item xs={12} lg={3}>
        <Paper
          sx={{
            display: "flex",
            flexDirection: "row",
            minHeight: { xs: 100, lg: "100%" },
            px: 2,
            alignItems: "center",
            borderRadius: 0,
          }}
          elevation={0}
        >
          <Avatar sx={{ width: AVATAR_SIZE, height: AVATAR_SIZE }} src={getAvatarUrl(person.avatar)} />

          <Box ml={2}>
            {profileLinkUrl ? (
              <Typography variant="body1">
                <Link component={RouterLink} to={profileLinkUrl} color="link.main" variant="link1">
                  {getFullName(person)}
                </Link>
              </Typography>
            ) : (
              <Typography variant="h3" lineHeight="24px">
                {getFullName(person)}
              </Typography>
            )}

            {!!personTitle && (
              <Typography variant="body2" lineHeight="24px">
                {personTitle}
              </Typography>
            )}

            {!!emailPersonLink && (
              <Typography variant="body2">
                <Link href={emailPersonLink} target="_blank" variant="body2" color="link.main" fontWeight="bold">
                  Email
                </Link>
              </Typography>
            )}
          </Box>
        </Paper>
      </Grid>

      <Grid item xs={12} lg={4}>
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            borderRadius: 0,
            minHeight: { xs: 80, lg: "100%" },
            px: 2,
          }}
          elevation={0}
        >
          <SessionLocation
            place={place}
            locationType={locationType}
            mobileLocation={mobileLocation}
            showDirectionsLink={showDirectionsLink}
          />
        </Paper>
      </Grid>
      <Grid item xs={12} lg={4}>
        <Paper
          sx={{
            display: "flex",
            minHeight: { xs: 80, lg: "100%" },
            justifyContent: { xs: "center", lg: "flex-end" },
            alignItems: "center",
            px: 2,
            borderRadius: 0,
          }}
          elevation={0}
        >
          {!!renderActions && renderActions()}
        </Paper>
      </Grid>
    </Grid>
  );
}
