import React from "react";
import { LoadingButton } from "@mui/lab";
import { Box, Paper, Typography } from "@mui/material";
import { keys } from "lodash";

interface Props {
  buttonValues: Record<string, string>;
  onSubmit: () => void;
  loading: boolean;
}

const BulkActions = ({ buttonValues, onSubmit, loading }: Props) => {
  if (keys(buttonValues).length === 0) {
    return null;
  }

  return (
    <Paper sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }} elevation={3}>
      <Box sx={{ textAlign: "center", backgroundColor: "#464646", paddingLeft: { xs: 3, lg: "250px" } }} p={3}>
        <LoadingButton
          variant="contained"
          onClick={onSubmit}
          loading={loading}
          sx={{
            "&:disabled": {
              backgroundColor: "#167B60",
            },
          }}
        >
          Submit
        </LoadingButton>
        <Box mt={2}>
          <Typography variant="body1" color="#fff">
            You can accept/decline multiple bookings before submitting.
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};
export default BulkActions;
