import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Alert, Box, Container, Link, Stack, Typography } from "@mui/material";
import * as Sentry from "@sentry/browser";
import { SubmitHandler, useForm, FormProvider } from "react-hook-form";
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom";
import * as yup from "yup";
import { useLogIn } from "../../api/mutations/User";
import * as ROUTES from "../../constants/routes";
import FormPasswordField from "../../form/FormPasswordField";
import FormTextField from "../../form/FormTextField";
import { useAuth } from "../../utils/AuthProvider";
import useDocumentTitles from "../../utils/useDocumentTitles";
import ErrorDialog from "../common/ErrorDialog";

type LoginFormInputs = {
  email: string;
  password: string;
};

const LoginFormSchema: yup.SchemaOf<LoginFormInputs> = yup.object({
  email: yup.string().email("Please enter a valid email address.").required("Your email address is required."),
  password: yup.string().required("Your password is required."),
});

interface Props {
  redirectTo?: string;
}

interface LocationState {
  resetPasswordSuccess?: boolean;
}

const Login = ({ redirectTo }: Props): JSX.Element => {
  useDocumentTitles("Log In | WeFlex Portal");

  const methods = useForm<LoginFormInputs>({
    resolver: yupResolver(LoginFormSchema),
    criteriaMode: "firstError",
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const history = useHistory();
  const location = useLocation<LocationState>();

  console.log({ state: location.state });
  const { refetchCurrentUser, clearCurrentUser, isRefetching: isRefetchingCurrentUser } = useAuth();

  const { mutate: logInMutate, isLoading: logInLoading } = useLogIn();

  const [submitErrorMessage, setSubmitErrorMessage] = useState("");

  const onSubmit: SubmitHandler<LoginFormInputs> = async (values) => {
    clearCurrentUser();

    logInMutate(
      { username: values.email, password: values.password },
      {
        onError: (error) => {
          if (error.response.status === 400) {
            setSubmitErrorMessage(
              "No user was found matching the email address and password provided. Please check these values and try again.",
            );
          } else {
            Sentry.captureException(error);

            setSubmitErrorMessage(
              "An unknown error occurred while logging in. Please try again, and if the problem persists, contact WeFlex.",
            );
          }
        },
        onSuccess: async (response) => {
          try {
            await refetchCurrentUser?.(response.data.key);

            history.replace(redirectTo ?? ROUTES.HOME);
          } catch (error) {
            Sentry.captureException(error);

            setSubmitErrorMessage(
              "An unknown error occurred while logging in. Please try again, and if the problem persists, contact WeFlex.",
            );
          }
        },
      },
    );
  };

  return (
    <Container maxWidth="md">
      <Stack sx={{ alignItems: "center" }} spacing={3} mt={15}>
        <Typography variant="h1">Log In</Typography>
        <Typography variant="body1">Log in to manage your WeFlex account.</Typography>

        {location.state?.resetPasswordSuccess && (
          <Alert severity="success" variant="filled" sx={{ mt: 3 }}>
            The password for your account has been successfully updated. You can now log in with your new password.
          </Alert>
        )}

        <FormProvider {...methods}>
          <Stack
            sx={{ alignItems: "center", width: { xs: "100%", md: "50%" } }}
            spacing={2}
            component="form"
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <FormTextField required name="email" id="email-text-field" label="Email" variant="outlined" fullWidth />

            <FormPasswordField
              required
              name="password"
              id="password-text-field"
              label="Password"
              variant="outlined"
              fullWidth
            />

            <LoadingButton
              loading={logInLoading || isRefetchingCurrentUser}
              type="submit"
              variant="contained"
              fullWidth
              sx={{ width: "60%" }}
            >
              Log In
            </LoadingButton>
          </Stack>
        </FormProvider>

        <Box>
          <Typography variant="body2" component="span">
            <Link variant="link1" color="link.main" component={RouterLink} to={ROUTES.FORGOT_PASSWORD}>
              Forgot password?
            </Link>
          </Typography>
        </Box>
      </Stack>

      {!!submitErrorMessage && (
        <ErrorDialog title="Log in failed" messages={[submitErrorMessage]} onClose={() => setSubmitErrorMessage("")} />
      )}
    </Container>
  );
};

export default Login;
