import React, { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import DownloadIcon from "@mui/icons-material/Download";
import {
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Paper,
  Radio,
  RadioGroup,
  Typography,
  Link,
} from "@mui/material";
import * as Sentry from "@sentry/browser";
import { isEmpty, isNil } from "lodash";
import { Controller, useForm, FormProvider } from "react-hook-form";
import { useQuery } from "react-query";
import * as yup from "yup";
import { ErrorResponse } from "../../api/Generic";
import { useUpdateSessionReport } from "../../api/mutations/SessionReport";
import ENV from "../../constants/envConstants";
import { SessionReportModel } from "../../models/Booking";
import ColumnBox from "../common/ColumnBox";
import ErrorAlert from "../common/ErrorAlert";
import PrivateFileLink from "../common/PrivateFileLink";

type IncidentReportAnswers = "Yes" | "No" | "";

interface IncidentReportFormInputs {
  didIncidentOccur: IncidentReportAnswers;
}

const IncidentReportFormSchema = yup.object({
  didIncidentOccur: yup.string().oneOf(["Yes", "No"]).required("Please select an option."),
});

interface Props {
  sessionReportId: string;
  isEditMode: boolean;
  onFormLoading(isLoading: boolean): void;
  onFormSubmitting(isSubmitting: boolean): void;
  onSuccess(showSavedConfirmation: boolean): void;
  onError(error: unknown): void;
}

export default function SessionReportIncidentReport({
  sessionReportId,
  isEditMode,
  onFormLoading,
  onFormSubmitting,
  onSuccess,
  onError,
}: Props) {
  const formMethods = useForm<IncidentReportFormInputs>({ resolver: yupResolver(IncidentReportFormSchema) });

  const {
    control,
    watch,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = formMethods;

  const {
    data: sessionReportData,
    isLoading: isSessionReportLoading,
    error: sessionReportError,
    refetch: refetchSessionReport,
  } = useQuery<SessionReportModel, ErrorResponse>(`/session-report/${sessionReportId}/`);
  const { mutateAsync: updateSessionReport } = useUpdateSessionReport();

  useEffect(() => {
    onFormLoading(isSessionReportLoading);
  }, [isSessionReportLoading]);

  useEffect(() => {
    if (!isEmpty(sessionReportData)) {
      let didIncidentOccurStr: IncidentReportAnswers;
      if (isNil(sessionReportData!.did_incident_occur)) {
        didIncidentOccurStr = "";
      } else {
        didIncidentOccurStr = sessionReportData!.did_incident_occur ? "Yes" : "No";
      }
      reset({ didIncidentOccur: didIncidentOccurStr });
    }
  }, [sessionReportData, reset]);

  const didIncidentOccurValue = watch("didIncidentOccur");

  const onSubmit = async (values: IncidentReportFormInputs) => {
    try {
      if (isDirty) {
        onFormSubmitting(true);
        await updateSessionReport({
          sessionReportId,
          did_incident_occur: values.didIncidentOccur === "Yes",
        });
        await refetchSessionReport();
      }
      onSuccess(isDirty);
    } catch (error) {
      Sentry.captureException(error);
      onError(error);
    } finally {
      onFormSubmitting(false);
    }
  };

  if (isSessionReportLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (sessionReportError) {
    return <ErrorAlert message="Session report details failed to load" />;
  }

  if (!sessionReportData) {
    return null;
  }

  const showCompleteIncidentReportContent =
    didIncidentOccurValue === "Yes" && !sessionReportData.incident_report_private_file;

  const showIncidentReportFile = didIncidentOccurValue === "Yes" && !!sessionReportData.incident_report_private_file;

  return (
    <FormProvider {...formMethods}>
      <form id="session-report-detail-form" onSubmit={handleSubmit(onSubmit)}>
        <ColumnBox>
          <Typography variant="h2">Incident Report</Typography>

          <ColumnBox mt={2}>
            <Typography>Did an incident occur during this session?</Typography>

            <Controller
              name="didIncidentOccur"
              defaultValue=""
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error, invalid } }) => (
                <FormControl error={invalid}>
                  {isEditMode ? (
                    <RadioGroup
                      aria-labelledby="incident-report-radio-buttons-group-label"
                      name="incident-report-radio-buttons-group"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                    >
                      <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                      <FormControlLabel value="No" control={<Radio />} label="No" />
                    </RadioGroup>
                  ) : (
                    <Typography>{value}</Typography>
                  )}
                  <FormHelperText>{error?.message}</FormHelperText>
                </FormControl>
              )}
            />

            {showCompleteIncidentReportContent && (
              <ColumnBox mt={4} gap={2}>
                <Typography>Please download the WeFlex Incident Report template:</Typography>

                <Link href={ENV.WEFLEX_INCIDENT_REPORT_PDF_URL} target="_blank" download display="flex">
                  <Box
                    component={Paper}
                    display="flex"
                    justifyContent="space-between"
                    p={2}
                    border="1px dashed"
                    borderColor="grey.300"
                    elevation={0}
                    borderRadius="4px"
                    sx={{
                      "flexShrink": 1,
                      ":hover": {
                        borderColor: "link.main",
                      },
                    }}
                  >
                    <Typography variant="link1" color="link.main" mr={2}>
                      Download WeFlex Incident Report template
                    </Typography>

                    <DownloadIcon sx={{ color: "grey.600" }} />
                  </Box>
                </Link>

                <Typography>
                  Please complete the WeFlex Incident Report and email it to{" "}
                  <Link
                    variant="link1"
                    href={`mailto:${encodeURIComponent(ENV.CC_EMAIL_ADDRESS!)}`}
                    target="_blank"
                    color="link.main"
                  >
                    {ENV.CC_EMAIL_ADDRESS}
                  </Link>
                  .
                </Typography>
              </ColumnBox>
            )}

            {showIncidentReportFile && (
              <Box my={2}>
                {!!sessionReportData.incident_report_private_file && (
                  <PrivateFileLink
                    privateFileId={sessionReportData.incident_report_private_file}
                    viewFileText="View Incident Report"
                  />
                )}
              </Box>
            )}
          </ColumnBox>
        </ColumnBox>
      </form>
    </FormProvider>
  );
}
