import { ReactNode } from "react";
import { Box, SvgIconTypeMap, Typography } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { format, parseISO } from "date-fns";
import { isEmpty, isNil, isNull, isString } from "lodash";
import OptionalValue from "./OptionalValue";

export enum IconContentType {
  TEXT,
  COMBO,
  MULTI,
  CHECKBOX,
  DATE,
}

interface Props {
  content: ReactNode | string | null | undefined;
  contentType?: IconContentType;
  Icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  iconColor?: string;
  iconSize?: "small" | "inherit" | "large" | "medium" | undefined;
}

function renderContent(content: ReactNode | string | null | undefined, contentType: IconContentType) {
  if (isNil(content) || isEmpty(content) || content === "") {
    return null;
  }

  if ((contentType === IconContentType.DATE && isString(content)) || isNull(content)) {
    const dateOfBirth = content ? format(parseISO(content), "dd MMM yyyy") : "";

    return <Typography variant="body1">{dateOfBirth}</Typography>;
  }

  if (isString(content)) {
    if (content === "true") {
      return <Typography variant="body1">Yes</Typography>;
    }

    if (content === "false") {
      return <Typography variant="body1">No</Typography>;
    }

    return <Typography variant="body1">{content}</Typography>;
  }

  // TODO figure out what to do for editing other types of content
  return content;
}

export default function ProfileCardIconWithContent({
  content,
  contentType,
  Icon,
  iconColor,
  iconSize,
}: Props): JSX.Element {
  const renderedContent = renderContent(content, contentType ? contentType : IconContentType.TEXT);

  return (
    <Box display="flex" alignItems="center">
      {!!Icon && <Icon sx={{ color: iconColor, mt: 0, mr: 1 }} fontSize={iconSize} />}

      <OptionalValue>{renderedContent}</OptionalValue>
    </Box>
  );
}
