import React from "react";
import { Box, CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import ProfileLive from "./ProfileLive";
import ProfileNotLive from "./ProfileNotLive";
import { useGetPWADProfileAboutMe } from "../../../api/queries/PWADProfile";
import { getPWADProfileIsLive } from "../../../utils/person";
import ErrorAlert from "../../common/ErrorAlert";

function Profile(): JSX.Element {
  const { profileId } = useParams<{ profileId?: string }>();

  const { data: aboutMeData, isLoading: aboutMeLoading, error: aboutMeError } = useGetPWADProfileAboutMe(profileId);

  const isProfileLive = getPWADProfileIsLive(aboutMeData);

  if (aboutMeLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (aboutMeError) {
    return <ErrorAlert message="Profile details failed to load" />;
  }

  if (isProfileLive === false) {
    return <ProfileNotLive />;
  }

  return <ProfileLive />;
}

export default Profile;
