import { Fragment, ReactNode } from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import OptionalValue from "./OptionalValue";
import ProfileCardDivider from "./ProfileCardDivider";
import ProfileCardIconWithContent from "./ProfileCardIconWithContent";

export interface CheckIconListItem {
  id: string;
  name: ReactNode | string;
  secondary?: string;
}

interface Props {
  showDivider?: boolean;
  list: CheckIconListItem[] | undefined;
  dividerVerticalMargin?: number;
  contentFn?: any;
}

export default function CheckIconList({
  list = [],
  showDivider = false,
  dividerVerticalMargin = 2,
  contentFn,
}: Props): JSX.Element {
  return (
    <OptionalValue>
      {list.map((item, index) => (
        <Fragment key={item.id}>
          <ProfileCardIconWithContent
            Icon={CheckCircleOutlineIcon}
            iconColor="primary.main"
            iconSize="small"
            content={contentFn ? contentFn(item) : item.name}
          />

          {showDivider && index < list.length - 1 ? <ProfileCardDivider sx={{ my: dividerVerticalMargin }} /> : null}
        </Fragment>
      ))}
    </OptionalValue>
  );
}
