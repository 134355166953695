import React, { ReactNode, useCallback } from "react";
import { Box, Button, Divider } from "@mui/material";
import { generatePath, Link } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import { BookingWithSessionReportModel } from "../../models/Booking";
import { useAuth } from "../../utils/AuthProvider";
import SessionRow from "../common/SessionRow";
import { SessionReportReturnToEnum, SessionReportStepsEnum } from "../session-report-details/SessionReportConstants";

interface Props {
  bookingsWithSessionReport: BookingWithSessionReportModel[];
}

export default function PWADSessionReportList({ bookingsWithSessionReport }: Props): JSX.Element {
  const { currentUser } = useAuth();

  const getProfileLinkUrl = useCallback(
    (bookingWithSessionReport: BookingWithSessionReportModel) => {
      if (currentUser?.is_admin) {
        return generatePath(ROUTES.FP_PROFILE, { profileId: bookingWithSessionReport.session.fp_profile.id });
      }

      if (currentUser?.is_fp) {
        return null;
      }

      return generatePath(ROUTES.PWAD_VIEW_FP_PROFILE, { fpId: bookingWithSessionReport.session.fp_profile.id });
    },
    [currentUser],
  );

  function renderActions(bookingWithSessionReport: BookingWithSessionReportModel): ReactNode {
    return (
      <Button
        variant="contained"
        component={Link}
        to={generatePath(ROUTES.SESSION_REPORT_DETAILS, {
          bookingId: bookingWithSessionReport.id,
          activeStep: SessionReportStepsEnum.GOALS,
          returnTo: SessionReportReturnToEnum.PWAD_PROFILE,
        })}
      >
        View
      </Button>
    );
  }

  return (
    <Box mt={2}>
      {bookingsWithSessionReport.map((bookingWithSessionReport, index, array) => (
        <React.Fragment key={bookingWithSessionReport.id}>
          <SessionRow
            person={bookingWithSessionReport.session.fp_profile.person!}
            date={bookingWithSessionReport.session.date}
            startTime={bookingWithSessionReport.session.start_time}
            location={bookingWithSessionReport.session.session_location}
            profileLinkUrl={getProfileLinkUrl(bookingWithSessionReport)}
            renderActions={() => renderActions(bookingWithSessionReport)}
            personTitle="Fitness professional"
          />
          {index < array.length - 1 && <Divider />}
        </React.Fragment>
      ))}
    </Box>
  );
}
