import React from "react";
import ImageIcon from "@mui/icons-material/Image";
import MediaMarketingConsentContent from "./PWADMediaMarketingConsentContent";
import { PWADProfileConsentModel } from "../../models/PWADProfile";
import BooleanValueDisplayBox from "../common/BooleanValueDisplayBox";
import ProfileCard from "../common/ProfileCard";

interface Props {
  consentData: PWADProfileConsentModel;
}

export default function PWADMediaMarketingConsent({ consentData }: Props): JSX.Element {
  return (
    <ProfileCard title="Media and Marketing" Icon={ImageIcon}>
      <MediaMarketingConsentContent />

      <BooleanValueDisplayBox
        value={consentData.waivers_consent_media_marketing_waiver}
        trueLabel="Yes, I give my consent"
        falseLabel="No, I do not give my consent"
        nullLabel="I have not yet indicated whether I give consent"
        mt={4}
      />
    </ProfileCard>
  );
}
