import { Typography } from "@mui/material";

const PasswordRequirements = () => {
  return (
    <Typography variant="body2" component="p">
      Your password:
      <ul>
        <li>Can&apos;t be too similar to your other personal information</li>
        <li>Must contain at least 8 characters</li>
        <li>Can&apos;t be a commonly used password</li>
        <li>Can&apos;t be entirely numeric</li>
      </ul>
    </Typography>
  );
};

export default PasswordRequirements;
