export const PHONE_VALIDATION_REGEX = /^\+?\d{10,30}$/;

export const GENERIC_REQUIRED_VALIDATION_ERROR_MESSAGE = "A value is required for this field.";

export const GENERIC_MINIMUM_VALIDATION_ERROR_MESSAGE = "At least one option must be selected for this field.";

export const GENERIC_SUBMIT_VALIDATION_ERROR_MESSAGE =
  "There are errors with one or more of the values provided. Please check these values and try again.";

export const GENERIC_REQUEST_ERROR_MESSAGE =
  "There was an issue with your request. Please try again, and if the problem persists, contact WeFlex.";

export const GENERIC_ADDRESS_REQUIRED_VALIDATION_ERROR_MESSAGE =
  "Please enter an address and select the matching option from the dropdown.";
