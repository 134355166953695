import { ReactNode, useEffect } from "react";
import { Checkbox, CheckboxProps, FormControl, FormControlLabel, FormHelperText, Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import { omit } from "lodash";
import { Controller, useFormContext } from "react-hook-form";

export type FormCheckboxProps = Omit<CheckboxProps, "name"> & {
  name: string;
  label?: string | ReactNode;
  labelStyle?: SxProps<Theme>;
};

function FormCheckboxField(props: FormCheckboxProps) {
  const { control, getValues, setValue } = useFormContext();
  const { name, label, labelStyle } = props;

  const defaultValue = getValues(name);

  useEffect(() => {
    if (defaultValue === undefined) {
      setValue(name, false);
    }
  }, [defaultValue]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => {
        let checkboxField = (
          <Checkbox
            {...omit(props, ["name", "label", "labelStyle"])}
            onChange={onChange}
            onBlur={onBlur}
            checked={value}
            inputRef={ref}
          />
        );

        if (label !== undefined) {
          checkboxField = (
            <FormControlLabel control={checkboxField} label={label} labelPlacement="end" sx={labelStyle} />
          );
        }

        return (
          <FormControl error={invalid}>
            {checkboxField}
            <FormHelperText>{error?.message}</FormHelperText>
          </FormControl>
        );
      }}
    />
  );
}

export default FormCheckboxField;
