import { createTheme } from "@mui/material/styles";
import { drawerWidth } from "../components/layout/ResponsiveDrawer";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    link1: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    link1?: React.CSSProperties;
  }

  interface Palette {
    link: Palette["primary"];
  }
  interface PaletteOptions {
    link: PaletteOptions["primary"];
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    link1: true;
  }
}

const TEXT_PRIMARY = "#000000";
const CONTRAST_TEXT = "#000000";
const TEXT_DISABLED = "#8D8D8D";
const LINK_COLOR = "#008330";
const MAIN_COLOR = "#00CF7D";
const MAIN_DARK_COLOR = "#167B60";

const theme = createTheme({
  palette: {
    primary: {
      light: "#7986CB",
      main: MAIN_COLOR,
      dark: MAIN_DARK_COLOR,
      contrastText: CONTRAST_TEXT,
    },
    secondary: {
      light: "#FF4081",
      main: "#F50057",
      dark: "#C51162",
      contrastText: CONTRAST_TEXT,
    },
    error: {
      light: "#F88078",
      main: "#F44336",
      dark: "#E31B0C",
      contrastText: CONTRAST_TEXT,
    },
    warning: {
      light: "#FFB547",
      main: "#ED6C02",
      dark: "#C77700",
      contrastText: CONTRAST_TEXT,
    },
    info: {
      light: "#64B6F7",
      main: "#2196F3",
      dark: "#0B79D0",
      contrastText: CONTRAST_TEXT,
    },
    success: {
      light: "#7BC67E",
      main: "#4CAF50",
      dark: "#3B873E",
      contrastText: CONTRAST_TEXT,
    },
    text: {
      primary: TEXT_PRIMARY,
      secondary: "#464646",
      disabled: TEXT_DISABLED,
    },
    action: {
      active: "rgba(0, 0, 0, 0.54)",
    },
    link: {
      main: LINK_COLOR,
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          "borderRadius": "50px",
          "padding": "12px 32px",
          "&:hover": {
            backgroundColor: "#006230",
            color: "#FFFFFF",
          },
          "&:disabled": {
            backgroundColor: "rgba(0, 0, 0, 0.26)",
            color: CONTRAST_TEXT,
          },
        },
        outlined: {
          "color": CONTRAST_TEXT,
          "border": `2px solid ${MAIN_COLOR}`,
          "&:hover": {
            backgroundColor: "#FFFFFF",
            color: CONTRAST_TEXT,
            border: `2px solid #006230`,
          },
          "&:disabled": {
            backgroundColor: "#FFFFFF",
            color: TEXT_DISABLED,
            border: `2px solid ${TEXT_DISABLED}`,
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          "borderRadius": "30px",
          "padding": "14px 24px",
          "&.Mui-selected": {
            "backgroundColor": MAIN_COLOR,
            "color": "#000",
            "&:hover": {
              backgroundColor: MAIN_DARK_COLOR,
              color: "#fff",
            },
          },
          ".MuiToggleButton-secondary": {
            backgroundColor: "#f00",
            color: "#f00",
          },
        },
      },
    },
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
  },
  typography: {
    fontFamily: "Archivo, sans-serif",
    h1: {
      fontSize: "30px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "36px",
      letterSpacing: "0.15px",
    },
    h2: {
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "36px",
      letterSpacing: "0.15px",
    },
    h3: {
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "18.67px",
    },
    h4: {
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "21px",
    },
    body1: {
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 300,
      lineHeight: "24px",
    },
    body2: {
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 300,
      lineHeight: "21px",
    },
    link1: {
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "16px",
      letterSpacing: "0.15px",
      lineHeight: "24px",
      textDecoration: "underline",
    },
    button: {
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "26px",
      letterSpacing: "0.46px",
      padding: "8px 22px",
      textTransform: "none",
    },
  },
});

// IPhone 12 width
theme.breakpoints.values.sm = 390;
theme.breakpoints.values.lg = 1190 + drawerWidth; // Correspond to the main content div width + drawer width, when a user is connected

export default theme;
