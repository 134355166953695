import { Paper, Typography } from "@mui/material";
import { FPProfileAboutMeModel, FPProfileAboutMeModelAsPWAD } from "../../models/FPProfile";
import ColumnBox from "../common/ColumnBox";
import OptionalValue from "../common/OptionalValue";
import VideoPlayer from "../common/VideoPlayer";

interface Props {
  aboutMeData?: FPProfileAboutMeModel | FPProfileAboutMeModelAsPWAD;
}

export default function FPAboutMe({ aboutMeData }: Props) {
  const content = aboutMeData?.about_me ? aboutMeData?.about_me : "";

  return (
    <ColumnBox component={Paper} p={4}>
      <Typography variant="body1">
        <OptionalValue>{content}</OptionalValue>
      </Typography>

      <VideoPlayer containerProps={{ alignSelf: "center", mt: 2 }} videoUrl={aboutMeData?.about_me_video} />
    </ColumnBox>
  );
}
