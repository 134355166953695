import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { isNil } from "lodash";
import { useQueryClient } from "react-query";
import { RouteComponentProps, generatePath, useParams } from "react-router-dom";
import { useSubmitFPProfileStatus } from "../../../../api/mutations/FPProfile";
import * as ROUTES from "../../../../constants/routes";
import { FPProfileStatus } from "../../../../models/ProfileBase";
import { useAuth } from "../../../../utils/AuthProvider";
import getErrorMessages from "../../../../utils/getErrorMessages";
import { getUserFPProfileIsCompleted, getUserFPProfileStatus } from "../../../../utils/person";
import ColumnBox from "../../../common/ColumnBox";
import ErrorDialog from "../../../common/ErrorDialog";

interface Props extends RouteComponentProps {
  setSavedSnackbarMessage: Dispatch<SetStateAction<string>>;
}

const SubmitForm = ({ setSavedSnackbarMessage, history }: Props) => {
  const { currentUser, refetchCurrentUser } = useAuth();
  const fpProfileIsCompleted = getUserFPProfileIsCompleted(currentUser);
  const fpProfileStatus = getUserFPProfileStatus(currentUser);
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const { profileId } = useParams<{ profileId: string }>();
  const { mutateAsync: submitFPProfileStatus } = useSubmitFPProfileStatus(profileId);
  const [submitErrors, setSubmitErrors] = useState<string[] | null>(null);
  const queryClient = useQueryClient();
  const [showSubmitModal, setShowSubmitModal] = useState(false);

  const theme: Theme = useTheme();
  const breakpointSM = useMediaQuery(theme.breakpoints.up("sm"));
  const breakpointMD = useMediaQuery(theme.breakpoints.up("md"));

  const handleSubmitProfile = useCallback(async () => {
    setIsSubmitLoading(true);

    try {
      await submitFPProfileStatus();
      await queryClient.invalidateQueries("/user/me");
      await refetchCurrentUser();

      setSavedSnackbarMessage("Profile submitted successfully.");
      history.push(generatePath(ROUTES.FP_PROFILE_TRAINING, { profileId }));
    } catch (error: any) {
      console.error(error);
      const errorMessage = getErrorMessages(error.response?.data?.message);
      setSubmitErrors(errorMessage);
    }

    setShowSubmitModal(false);
    setIsSubmitLoading(false);
  }, [profileId, refetchCurrentUser, queryClient]);

  if (isNil(fpProfileStatus)) {
    return null;
  }

  if (fpProfileStatus !== FPProfileStatus.PROFILE_CREATED) {
    return null;
  }

  return (
    <Grid container spacing={2} py={2}>
      <Grid item xs={12}>
        <Typography variant="h1" mb={3}>
          Submit{" "}
          <Typography display="inline" variant="h1" component="span" color="link.main">
            Profile.
          </Typography>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ColumnBox component={Paper} p={4} mb={2}>
          <Grid item mb={3}>
            {fpProfileIsCompleted ? (
              <Typography variant="body1">
                Your profile is ready to be submitted! If you need to make any final changes please go back and review
                your profile before submitting.
              </Typography>
            ) : (
              <Typography variant="body1">
                There are some sections of your profile that are still incomplete. Please review each section and ensure
                all required fields have been filled in and saved. Once that is done you can return here and submit your
                profile.
              </Typography>
            )}
          </Grid>
          <Grid item>
            <LoadingButton
              variant={fpProfileIsCompleted ? "contained" : "outlined"}
              size={breakpointMD ? "medium" : "small"}
              sx={breakpointSM ? undefined : { marginTop: 2 }}
              disabled={isSubmitLoading || !fpProfileIsCompleted}
              onClick={() => setShowSubmitModal(true)}
            >
              Submit Profile
            </LoadingButton>
          </Grid>
        </ColumnBox>
      </Grid>
      <Dialog
        open={showSubmitModal}
        onClose={() => {
          setShowSubmitModal(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Submit profile</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please note submitting cannot be undone and you will no longer be able to update your profile. Are you sure
            you want to submit?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowSubmitModal(false);
            }}
          >
            Cancel
          </Button>
          <LoadingButton loading={isSubmitLoading} onClick={handleSubmitProfile} autoFocus>
            Submit
          </LoadingButton>
        </DialogActions>
      </Dialog>

      {submitErrors && (
        <ErrorDialog title="Unable to submit profile" messages={submitErrors} onClose={() => setSubmitErrors(null)} />
      )}
    </Grid>
  );
};

export default SubmitForm;
