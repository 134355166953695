import Status from "./Status";
import { BookingOutcomeEnum, BookingOutcomeLabel } from "../../models/Booking";

interface Props {
  outcome: BookingOutcomeEnum | "";
}

export default function BookingItemOutcome({ outcome }: Props) {
  if (!outcome) {
    return <Status color="error" label="No Outcome Set" />;
  }

  if (outcome === BookingOutcomeEnum.COMPLETED) {
    return <Status color="success" label={BookingOutcomeLabel.COMPLETED} />;
  }

  if (outcome === BookingOutcomeEnum.RESCHEDULED) {
    return <Status color="info" label={BookingOutcomeLabel.RESCHEDULED} />;
  }

  if (outcome === BookingOutcomeEnum.CANCELLED_WITH_NOTICE) {
    return <Status color="warning" label={BookingOutcomeLabel.CANCELLED_WITH_NOTICE} />;
  }

  if (outcome === BookingOutcomeEnum.CANCELLED_SHORT_NOTICE) {
    return <Status color="error" label={BookingOutcomeLabel.CANCELLED_SHORT_NOTICE} />;
  }

  if (outcome === BookingOutcomeEnum.CANCELLED_BY_FP) {
    return <Status color="warning" label={BookingOutcomeLabel.CANCELLED_BY_FP} />;
  }

  return null;
}
