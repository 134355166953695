import { useMutation, useQuery } from "react-query";
import { PrivateFileModel } from "../../models/PrivateFile";
import Axios from "../Axios";
import { ErrorResponse, ServerResponse } from "../Generic";

interface GetPrivateFileUrlParameters {
  fileId: string;
}

interface GetPrivateFileUrlResponse {
  url: string;
  expiry_seconds: number;
}

export function useGetPrivateFile(fileId: string | null) {
  return useQuery<PrivateFileModel, ErrorResponse>(`/files/private-file/${fileId}/`, { enabled: !!fileId });
}

export function useGetPrivateFileUrl() {
  return useMutation<ServerResponse<GetPrivateFileUrlResponse>, ErrorResponse, GetPrivateFileUrlParameters>(
    ({ fileId }: GetPrivateFileUrlParameters) =>
      Axios.post<ServerResponse, any>(`/files/private-file/${fileId}/generate-url/`, {}),
  );
}
