// Public routes
export const JOIN_NOW_FP = "/join-now-fitness-professional";
export const JOIN_NOW_PWAD = "/join-now-client";
export const LOGIN = "/login";
export const FORGOT_PASSWORD = "/forgot-password";
export const RESET_PASSWORD = "/reset-password";

// FP
const FP_PREFIX = "/fitness-professional";
export const FP_ALL = `${FP_PREFIX}/*`;
export const FP_MY_BOOKINGS = `${FP_PREFIX}/my-bookings/:year?/:month?`;
export const FP_MY_BOOKINGS_LIST = `${FP_PREFIX}/my-bookings/:year/:month/:status`;

export const FP_PROFILE = `${FP_PREFIX}/my-profile/:profileId`;
export const FP_PROFILE_ROOT = `${FP_PROFILE}/profile`;
export const FP_PROFILE_TRAINING = `${FP_PROFILE}/training`;
export const FP_PROFILE_INTERVIEW = `${FP_PROFILE}/interview`;

export const FP_PROFILE_ABOUT_ME = `${FP_PROFILE_ROOT}/me`;
export const FP_PROFILE_MY_DOCUMENTS = `${FP_PROFILE_ROOT}/documents`;
export const FP_PROFILE_MY_BUSINESS = `${FP_PROFILE_ROOT}/business`;
export const FP_PROFILE_MY_SERVICES = `${FP_PROFILE_ROOT}/services`;
export const FP_PROFILE_PAYOUT_DETAILS = `${FP_PROFILE_ROOT}/payout`;
export const FP_PROFILE_TERMS = `${FP_PROFILE_ROOT}/terms`;
export const FP_PROFILE_SUBMIT = `${FP_PROFILE_ROOT}/submit`;
export const FP_PROFILE_STATISTICS = `${FP_PROFILE_ROOT}/statistics`;

export const FP_VIEW_PWAD_PROFILE = `${FP_PREFIX}/client-profile/:pwadProfileId`;
export const FP_VIEW_PWAD_PROFILE_ABOUT_ME = `${FP_VIEW_PWAD_PROFILE}/me`;
export const FP_VIEW_PWAD_PROFILE_MY_UNIQUE_NEEDS = `${FP_VIEW_PWAD_PROFILE}/my-unique-needs`;
export const FP_VIEW_PWAD_PROFILE_MY_GOALS = `${FP_VIEW_PWAD_PROFILE}/my-goals`;
export const FP_VIEW_PWAD_PROFILE_MY_PREFERENCES = `${FP_VIEW_PWAD_PROFILE}/my-preferences`;
export const FP_VIEW_PWAD_PROFILE_MY_HEALTH = `${FP_VIEW_PWAD_PROFILE}/my-health`;
export const FP_VIEW_PWAD_PROFILE_CONSENT = `${FP_VIEW_PWAD_PROFILE}/consent`;
export const FP_VIEW_PWAD_PROFILE_SESSION_REPORTS = `${FP_VIEW_PWAD_PROFILE}/session-reports`;

export const FP_MY_SESSION_REPORTS = `${FP_PREFIX}/my-session-reports`;
export const FP_MY_SESSION_REPORTS_TO_DO = `${FP_MY_SESSION_REPORTS}/list/to-do`;
export const FP_MY_SESSION_REPORTS_COMPLETED = `${FP_MY_SESSION_REPORTS}/list/completed`;

export const FP_SET_BOOKING_OUTCOME = `${FP_PREFIX}/booking/:bookingId/set-outcome`;
export const FP_INVOICE_DETAIL = `${FP_PREFIX}/invoice/:invoiceId`;
export const FP_MY_INVOICES = `${FP_PREFIX}/my-invoices`;

// PWAD
const PWAD_PREFIX = "/client";
export const PWAD_ALL = `${PWAD_PREFIX}/*`;
export const PWAD_MY_BOOKINGS = `${PWAD_PREFIX}/my-bookings/:year?/:month?`;
export const PWAD_PROFILE = `${PWAD_PREFIX}/my-profile/:profileId`;
export const PWAD_PROFILE_ABOUT_ME = `${PWAD_PROFILE}/me`;
export const PWAD_PROFILE_MY_UNIQUE_NEEDS = `${PWAD_PROFILE}/my-unique-needs`;
export const PWAD_PROFILE_MY_GOALS = `${PWAD_PROFILE}/my-goals`;
export const PWAD_PROFILE_MY_PREFERENCES = `${PWAD_PROFILE}/my-preferences`;
export const PWAD_PROFILE_MY_HEALTH = `${PWAD_PROFILE}/my-health`;
export const PWAD_PROFILE_CONSENT = `${PWAD_PROFILE}/consent`;
export const PWAD_PROFILE_SESSION_REPORTS = `${PWAD_PROFILE}/session-reports`;
export const PWAD_PROFILE_STATISTICS = `${PWAD_PROFILE}/statistics`;

export const PWAD_VIEW_FP_PROFILE = `${PWAD_PREFIX}/fitness-professional-profile/:fpId`;
export const PWAD_VIEW_FP_PROFILE_ABOUT_ME = `${PWAD_VIEW_FP_PROFILE}/me`;
export const PWAD_VIEW_FP_PROFILE_MY_SERVICES = `${PWAD_VIEW_FP_PROFILE}/services`;
export const PWAD_VIEW_FP_PROFILE_MY_QUALIFICATIONS = `${PWAD_VIEW_FP_PROFILE}/qualifications`;

export const PWAD_MAKE_A_BOOKING = `${PWAD_PREFIX}/make-a-booking`;
export const PWAD_REQUEST_SESSION = `${PWAD_MAKE_A_BOOKING}/request-a-session/:fpId`;

// Common
export const HOME = "/";
export const DOCUMENT_VIEWER = "/private-document-viewer/:fileId";
export const SESSION_REPORT_DETAILS = "/session-report/details/:bookingId/:activeStep/:returnTo";
