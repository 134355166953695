import { isEmpty, isNil, sortBy, uniqBy } from "lodash";
import { useAuth, UserViewModel } from "./AuthProvider";
import isBlank from "./string";
import { FPProfileAboutMeModel, FPProfileModel } from "../models/FPProfile";
import { PersonBasicModel } from "../models/Person";
import { FPProfileStatus, PWADProfileStatus } from "../models/ProfileBase";
import { PWADProfileAboutMeModel } from "../models/PWADProfile";

export const getFirstName = (person: PersonBasicModel | undefined): string => {
  if (isNil(person)) {
    return "";
  }

  if (!isBlank(person.preferred_name)) {
    return person.preferred_name;
  }

  return person.first_name;
};

export const getFullName = (person: PersonBasicModel | undefined) => {
  if (isEmpty(person)) {
    return "";
  }

  const firstName = getFirstName(person);

  return `${firstName} ${person?.last_name}`;
};

export const getPWADProfileIsLive = (pwad?: PWADProfileAboutMeModel) => {
  if (!pwad) {
    return null;
  }

  return pwad.is_live;
};

export const getFPProfileIsLive = (fp?: FPProfileAboutMeModel) => {
  if (!fp) {
    return null;
  }

  return fp.is_live;
};

export const getFPProfileStatus = (fp?: FPProfileAboutMeModel) => {
  if (!fp) {
    return null;
  }
  return fp.status;
};

export const getUserIsPWAD = (user: UserViewModel | null) => {
  return !!user?.person?.pwad_profile;
};

export const getUserIsFP = (user: UserViewModel | null) => {
  return !!user?.person?.fp_profile;
};

export const getUserPWADProfileStatus = (user: UserViewModel | null) => {
  return user?.person?.pwad_profile?.status;
};

export const getUserPWADProfileIsCompleted = (user: UserViewModel | null) => {
  return user?.person?.pwad_profile?.is_profile_completed;
};

export const getUserFPProfileIsCompleted = (user: UserViewModel | null) => {
  return user?.person?.fp_profile?.is_profile_completed;
};

export const getUserFPProfileStatus = (user: UserViewModel | null) => {
  return user?.person?.fp_profile?.status;
};

export const useCurrentUserProfileIsEditable = () => {
  const { currentUser } = useAuth();

  if (currentUser?.is_admin) {
    return false;
  }

  if (currentUser?.is_live) {
    return false;
  }

  if (getUserIsPWAD(currentUser)) {
    const pwadProfileStatus = getUserPWADProfileStatus(currentUser);

    if (!pwadProfileStatus) {
      return false;
    }

    return pwadProfileStatus === PWADProfileStatus.CREATED;
  }

  if (getUserIsFP(currentUser)) {
    const fpProfileStatus = getUserFPProfileStatus(currentUser);

    if (!fpProfileStatus) {
      return false;
    }

    return fpProfileStatus === FPProfileStatus.PROFILE_CREATED;
  }

  return false;
};

export const useCurrentUserProfileIsLive = () => {
  const { currentUser } = useAuth();

  return !!currentUser?.is_live;
};

export const getServicesFromServicesData = (myServicesData: FPProfileModel | undefined) => {
  if (isEmpty(myServicesData?.services)) {
    return [];
  }

  return sortBy(uniqBy(myServicesData!.services, "id"), "name");
};
