import GroupIcon from "@mui/icons-material/Group";
import MailIcon from "@mui/icons-material/Mail";
import PeopleIcon from "@mui/icons-material/People";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/PhoneAndroid";
import { PWADProfileAboutMeModel, RepresentativeRelationshipLabel } from "../../models/PWADProfile";
import { getFullName } from "../../utils/person";
import ColumnBox from "../common/ColumnBox";
import ProfileCard from "../common/ProfileCard";
import ProfileCardIconWithContent from "../common/ProfileCardIconWithContent";

interface Props {
  aboutMeData: PWADProfileAboutMeModel;
}

export default function PWADRepresentativeInformation({ aboutMeData }: Props) {
  const representedByPerson = aboutMeData.person.represented_by;
  const representativeRelationship = aboutMeData.representative_relationship;

  if (!representedByPerson) {
    return null;
  }

  const iconColor = "grey.600";

  return (
    <ProfileCard title="Representative Information" Icon={GroupIcon}>
      <ColumnBox gap={3}>
        <ProfileCardIconWithContent
          content={getFullName(representedByPerson)}
          Icon={PersonIcon}
          iconColor={iconColor}
        />

        <ProfileCardIconWithContent
          content={representativeRelationship ? RepresentativeRelationshipLabel[representativeRelationship] : ""}
          Icon={PeopleIcon}
          iconColor={iconColor}
        />

        <ProfileCardIconWithContent content={representedByPerson.email} Icon={MailIcon} iconColor={iconColor} />

        <ProfileCardIconWithContent content={representedByPerson.phone} Icon={PhoneIcon} iconColor={iconColor} />
      </ColumnBox>
    </ProfileCard>
  );
}
