import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useGetPWADProfileAboutMe } from "../../../api/queries/PWADProfile";
import ColumnBox from "../../common/ColumnBox";
import ErrorAlert from "../../common/ErrorAlert";
import PWADBasicInformation from "../../profile-cards/PWADBasicInformation";
import PWADCommunicationStyle from "../../profile-cards/PWADCommunicationStyle";
import PWADEmergencyContactInformation from "../../profile-cards/PWADEmergencyContactInformation";
import PWADGymExperience from "../../profile-cards/PWADGymExperience";
import PWADLikes from "../../profile-cards/PWADLikes";
import PWADMotivations from "../../profile-cards/PWADMotivations";
import PWADPrimaryContactInformation from "../../profile-cards/PWADPrimaryContactInformation";
import PWADRelatedPersonEmergencyContact from "../../profile-cards/PWADRelatedPersonEmergencyContact";
import PWADRepresentativeInformation from "../../profile-cards/PWADRepresentativeInformation";

export default function AboutMe(): JSX.Element | null {
  const { pwadProfileId } = useParams<{ pwadProfileId: string }>();

  const { data: aboutMeData, isLoading: aboutMeLoading, error: aboutMeError } = useGetPWADProfileAboutMe(pwadProfileId);

  if (aboutMeLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (aboutMeError) {
    return <ErrorAlert message="Profile details failed to load" />;
  }

  if (!aboutMeData) {
    return null;
  }

  return (
    <Grid container spacing={2} py={2}>
      <Grid item xs={12}>
        <Typography variant="h1" mb={3}>
          About{" "}
          <Typography display="inline" variant="h1" component="span" color="link.main">
            Me.
          </Typography>
        </Typography>
      </Grid>

      <Grid item lg={6} xs={12}>
        <ColumnBox gap={2}>
          <PWADBasicInformation aboutMeData={aboutMeData} />
          <PWADRepresentativeInformation aboutMeData={aboutMeData} />
          <PWADPrimaryContactInformation aboutMeData={aboutMeData} />
          <PWADEmergencyContactInformation aboutMeData={aboutMeData} />
          <PWADRelatedPersonEmergencyContact aboutMeData={aboutMeData} />
        </ColumnBox>
      </Grid>

      <Grid item lg={6} xs={12}>
        <ColumnBox gap={2}>
          <PWADLikes aboutMeData={aboutMeData} />
          <PWADMotivations aboutMeData={aboutMeData} />
          <PWADCommunicationStyle aboutMeData={aboutMeData} />
          <PWADGymExperience aboutMeData={aboutMeData} />
        </ColumnBox>
      </Grid>
    </Grid>
  );
}
