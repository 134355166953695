import { useCallback } from "react";
import { Typography, Grid, Box, CircularProgress } from "@mui/material";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { ErrorResponse } from "../../../api/Generic";
import { FPProfileModel } from "../../../models/FPProfile";
import { useCurrentUserProfileIsLive } from "../../../utils/person";
import ColumnBox from "../../common/ColumnBox";
import ErrorAlert from "../../common/ErrorAlert";
import FPServices from "../../profile-cards/FPServices";
import FPSessionLocations from "../../profile-cards/FPSessionLocations";
import FPSpecialties from "../../profile-cards/FPSpecialties";

export default function Services(): JSX.Element {
  const { profileId } = useParams<{ profileId: string }>();
  const isProfileLive = useCurrentUserProfileIsLive();

  const {
    data: myServicesData,
    isLoading,
    error,
    refetch,
  } = useQuery<FPProfileModel, ErrorResponse>(`/fpprofile/my-services/?fpprofile=${profileId}`);

  const handleServiceUpdate = useCallback(async () => {
    await refetch();
  }, [refetch]);

  if (isLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <ErrorAlert message="Profile details failed to load" />;
  }

  return (
    <Grid container spacing={2} py={2}>
      <Grid item xs={12}>
        <Typography variant="h1" mb={3}>
          My{" "}
          <Typography display="inline" variant="h1" component="span" color="link.main">
            Services.
          </Typography>
        </Typography>
      </Grid>

      <Grid item lg={6} xs={12}>
        <ColumnBox gap={2}>
          <FPServices myServicesData={myServicesData} onUpdate={handleServiceUpdate} />
          <FPSpecialties myServicesData={myServicesData} />
        </ColumnBox>
      </Grid>

      {isProfileLive && (
        <Grid item lg={6} xs={12}>
          <FPSessionLocations myServicesData={myServicesData} />
        </Grid>
      )}
    </Grid>
  );
}
