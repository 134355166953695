import StarRateIcon from "@mui/icons-material/StarRate";
import { PWADProfileMyUniqueNeedsModel } from "../../models/PWADProfile";
import ProfileCard from "../common/ProfileCard";
import ProfileCardDivider from "../common/ProfileCardDivider";
import ProfileCardTitleWithContent from "../common/ProfileCardTitleWithContent";

interface Props {
  myUniqueNeedsData: PWADProfileMyUniqueNeedsModel;
}

export default function PWADNeeds({ myUniqueNeedsData }: Props) {
  return (
    <ProfileCard title="Needs" Icon={StarRateIcon}>
      <ProfileCardTitleWithContent title="My diagnosis" content={myUniqueNeedsData.unique_needs_diagnosis} />

      <ProfileCardDivider />

      <ProfileCardTitleWithContent title="My needs" content={myUniqueNeedsData.unique_needs_my_needs} />

      <ProfileCardDivider />

      <ProfileCardTitleWithContent
        title="My accessibility needs"
        content={myUniqueNeedsData.unique_needs_accessibility}
      />
    </ProfileCard>
  );
}
