import BadgeIcon from "@mui/icons-material/Badge";
import SchoolIcon from "@mui/icons-material/School";
import { Grid } from "@mui/material";
import { FPProfileMyDocumentsQueryModel } from "../../models/FPProfile";
import ColumnBox from "../common/ColumnBox";
import PrivateFileLink from "../common/PrivateFileLink";
import ProfileCard from "../common/ProfileCard";
import ProfileCardTitleWithContent, { TitleContentType } from "../common/ProfileCardTitleWithContent";

interface Props {
  myDocumentsData?: FPProfileMyDocumentsQueryModel;
}

export default function FPMyDocuments({ myDocumentsData }: Props) {
  return (
    <>
      <Grid item lg={6} xs={12}>
        <ProfileCard title="Qualifications" Icon={SchoolIcon}>
          <ColumnBox gap={2}>
            {!!myDocumentsData?.primary_qualification_file && (
              <PrivateFileLink
                privateFileId={myDocumentsData.primary_qualification_file}
                viewFileText="View Primary qualification"
              />
            )}

            {!!myDocumentsData?.additional_qualification_file1 && (
              <PrivateFileLink
                privateFileId={myDocumentsData.additional_qualification_file1}
                viewFileText="View Additional qualification 1"
              />
            )}

            {!!myDocumentsData?.additional_qualification_file2 && (
              <PrivateFileLink
                privateFileId={myDocumentsData.additional_qualification_file2}
                viewFileText="View Additional qualification 2"
              />
            )}

            {!!myDocumentsData?.fitness_body_membership_file && (
              <PrivateFileLink
                privateFileId={myDocumentsData.fitness_body_membership_file}
                viewFileText="View Fitness body membership certificate"
              />
            )}
          </ColumnBox>
        </ProfileCard>
      </Grid>
      <Grid item lg={6} xs={12}>
        <ProfileCard title="Supporting Documents" Icon={BadgeIcon}>
          <ColumnBox gap={2}>
            {!!myDocumentsData?.professional_and_liability_insurance_file && (
              <PrivateFileLink
                privateFileId={myDocumentsData.professional_and_liability_insurance_file}
                viewFileText="View Professional and liability insurance expiry"
              />
            )}

            {!!myDocumentsData?.professional_and_liability_insurance_file &&
              !!myDocumentsData.professional_and_liability_insurance_file_expires_at && (
                <ProfileCardTitleWithContent
                  title="Professional and liability insurance expiry"
                  contentType={TitleContentType.DATE}
                  content={myDocumentsData?.professional_and_liability_insurance_file_expires_at}
                />
              )}

            {!!myDocumentsData?.first_aid_cpr_file && (
              <PrivateFileLink
                privateFileId={myDocumentsData.first_aid_cpr_file}
                viewFileText="View First aid and CPR certificate"
              />
            )}

            {!!myDocumentsData?.first_aid_cpr_file && !!myDocumentsData.first_aid_cpr_expires_at && (
              <ProfileCardTitleWithContent
                title="First aid and CPR certificate expiry"
                contentType={TitleContentType.DATE}
                content={myDocumentsData?.first_aid_cpr_expires_at}
              />
            )}

            {!!myDocumentsData?.cpr_file && (
              <PrivateFileLink privateFileId={myDocumentsData.cpr_file} viewFileText="CPR Certificate" />
            )}

            {!!myDocumentsData?.covid_vaccination_file && (
              <PrivateFileLink
                privateFileId={myDocumentsData.covid_vaccination_file}
                viewFileText="View Covid Vaccination Certificate"
              />
            )}

            {!!myDocumentsData?.working_with_children_file && (
              <PrivateFileLink
                privateFileId={myDocumentsData.working_with_children_file}
                viewFileText="View Working with Children Check"
              />
            )}

            {!!myDocumentsData?.working_with_children_file && !!myDocumentsData.working_with_children_expires_at && (
              <ProfileCardTitleWithContent
                title="Working with children check expiry"
                contentType={TitleContentType.DATE}
                content={myDocumentsData?.working_with_children_expires_at}
              />
            )}
          </ColumnBox>
        </ProfileCard>
      </Grid>
    </>
  );
}
