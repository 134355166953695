import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import MailIcon from "@mui/icons-material/Mail";
import PeopleIcon from "@mui/icons-material/People";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/PhoneAndroid";
import { trim } from "lodash";
import { FPProfileAboutMeModel } from "../../models/FPProfile";
import ColumnBox from "../common/ColumnBox";
import OptionalValue from "../common/OptionalValue";
import ProfileCard from "../common/ProfileCard";
import ProfileCardIconWithContent from "../common/ProfileCardIconWithContent";

interface Props {
  aboutMeData?: FPProfileAboutMeModel;
}

export default function FPEmergencyContact({ aboutMeData }: Props) {
  const iconColor = "grey.600";

  const emergencyFullName = trim(`${aboutMeData?.emergency_first_name} ${aboutMeData?.emergency_last_name}`);

  return (
    <ProfileCard title="Emergency Contact" Icon={ContactPhoneIcon}>
      <ColumnBox gap={3}>
        <ProfileCardIconWithContent
          content={<OptionalValue>{emergencyFullName}</OptionalValue>}
          Icon={PersonIcon}
          iconColor={iconColor}
        />

        <ProfileCardIconWithContent
          content={aboutMeData?.emergency_relationship}
          Icon={PeopleIcon}
          iconColor={iconColor}
        />

        <ProfileCardIconWithContent content={aboutMeData?.emergency_email} Icon={MailIcon} iconColor={iconColor} />

        <ProfileCardIconWithContent content={aboutMeData?.emergency_phone} Icon={PhoneIcon} iconColor={iconColor} />
      </ColumnBox>
    </ProfileCard>
  );
}
