import { useMemo } from "react";
import { SessionReportAchievementModel } from "../models/Booking";
import { PWADProfileMyGoalsModel } from "../models/PWADProfile";

export const useGetGoalsFromGoalAchievements = (
  goalAchievementsData: Array<SessionReportAchievementModel> | undefined,
) => {
  return useMemo(() => {
    const goalAchievements = goalAchievementsData ?? [];
    const goals = goalAchievements.map((goalAchievement) => goalAchievement.goal);

    return {
      fitnessGoals: goals.filter((goal) => !goal.ndis),
      ndisGoals: goals.filter((goal) => goal.ndis),
    };
  }, [goalAchievementsData]);
};

export const useGetGoalsFromPWADProfile = (pwadGoalsData: PWADProfileMyGoalsModel | undefined) => {
  return useMemo(() => {
    const goals = pwadGoalsData?.goals ?? [];

    return {
      fitnessGoals: goals.filter((goal) => !goal.ndis),
      ndisGoals: goals.filter((goal) => goal.ndis),
    };
  }, [pwadGoalsData]);
};
