import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LanguageIcon from "@mui/icons-material/Language";
import PersonIcon from "@mui/icons-material/Person";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as Sentry from "@sentry/browser";
import { parseISO } from "date-fns";
import { omit } from "lodash";
import { Controller, FormProvider, SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { generatePath, RouteComponentProps, useParams } from "react-router-dom";
import * as yup from "yup";
import { isErrorResponse } from "../../../../api/Generic";
import { useEditFPProfileAboutMe } from "../../../../api/mutations/FPProfile";
import { useGetFPProfileAboutMe } from "../../../../api/queries/FPProfile";
import isoCountries from "../../../../constants/isoCountries";
import isoLanguages from "../../../../constants/isoLanguages";
import { IMAGE_MIME_TYPES } from "../../../../constants/mimeTypes";
import * as ROUTES from "../../../../constants/routes";
import {
  GENERIC_REQUEST_ERROR_MESSAGE,
  GENERIC_REQUIRED_VALIDATION_ERROR_MESSAGE,
  GENERIC_SUBMIT_VALIDATION_ERROR_MESSAGE,
  PHONE_VALIDATION_REGEX,
} from "../../../../constants/validation";
import PublicFileUploadControl from "../../../../form/PublicFileUploadControl";
import SelectControl from "../../../../form/SelectControl";
import { FPProfileAboutMeEditModel } from "../../../../models/FPProfile";
import { FPPersonEditModel, PersonGenderEnum, PersonGenderLabel, PersonModel } from "../../../../models/Person";
import { useAuth } from "../../../../utils/AuthProvider";
import { formatISODate } from "../../../../utils/formatDateTime";
import getErrorMessages from "../../../../utils/getErrorMessages";
import { getUserFPProfileIsCompleted } from "../../../../utils/person";
import ColumnBox from "../../../common/ColumnBox";
import ErrorAlert from "../../../common/ErrorAlert";
import ErrorDialog from "../../../common/ErrorDialog";
import ProfileCard from "../../../common/ProfileCard";
import UnsavedChangesPrompt from "../../../common/UnsavedChangesPrompt";

type AboutMeFormInput = {
  person: FPPersonEditModel;
  fp_profile: FPProfileAboutMeEditModel;
};

const MIN_DOB_VALUE = new Date("1900-01-01");
const MAX_DOB_VALUE = new Date();

// @ts-ignore
const AboutMeFormSchema: yup.SchemaOf<AboutMeFormInput> = yup.object({
  person: yup.object({
    first_name: yup.string().trim().required("First name is required."),
    last_name: yup.string().trim().required("Last name is required."),
    preferred_name: yup.string().trim().defined(),
    phone: yup
      .string()
      .trim()
      .defined()
      .test("phoneWithMinDigits", "Please enter a valid mobile phone number.", (value) => {
        if (!value) {
          return true;
        }

        return PHONE_VALIDATION_REGEX.test(value);
      }),
    dob: yup
      .date()
      .nullable()
      .required("Date of birth is required.")
      .typeError("Please enter a valid date in the format dd/mm/yyyy.")
      .min(MIN_DOB_VALUE, "Date of birth must not be before 1900.")
      .max(MAX_DOB_VALUE, "Date of birth must not be after today."),
    gender: yup.mixed().defined().oneOf(Object.values(PersonGenderEnum), "Gender is required."),
    avatar: yup.string().defined(),
    nationality: yup.mixed().defined().oneOf(Object.keys(isoCountries)),
    spoken_languages: yup
      .array()
      .ensure()
      .min(1, "Please select at least 1 language.")
      .of(yup.string().oneOf(Object.values(isoLanguages).map((language) => language.name)))
      .test("valuesAreUnique", "Please ensure you select a language only once.", (values) => {
        const s = new Set(values);
        return s.size === values?.length;
      }),
  }),
  fp_profile: yup.object({
    about_me: yup.string().trim().required(GENERIC_REQUIRED_VALIDATION_ERROR_MESSAGE),
    about_me_social_fb: yup.string().url("Please enter a valid URL").trim().max(200),
    about_me_social_insta: yup.string().url("Please enter a valid URL").trim().max(200),
    about_me_things_you_love: yup.string().trim().required(GENERIC_REQUIRED_VALIDATION_ERROR_MESSAGE),
    emergency_first_name: yup.string().trim().required("Emergency contact first name is required."),
    emergency_last_name: yup.string().trim().required("Emergency contact last name is required."),
    emergency_relationship: yup.string().trim().required("Emergency contact relationship is required."),
    emergency_email: yup
      .string()
      .email("Please enter a valid email address.")
      .required("Emergency contact email address is required."),
    emergency_phone: yup
      .string()
      .trim()
      .test("phoneWithMinDigits", "Please enter a valid mobile phone number.", (value) => {
        if (!value) {
          return true;
        }

        return PHONE_VALIDATION_REGEX.test(value);
      })
      .required("Emergency contact mobile phone number is required."),
  }),
});

interface AboutMeReactHookFormProps {
  person: PersonModel;
  defaultValues: AboutMeFormInput;
  onSubmit: SubmitHandler<AboutMeFormInput>;
  onError: SubmitErrorHandler<AboutMeFormInput>;
}

function AboutMeReactHookForm({ person, defaultValues, onSubmit, onError }: AboutMeReactHookFormProps): JSX.Element {
  const { currentUser } = useAuth();
  const fpProfileIsCompleted = getUserFPProfileIsCompleted(currentUser);

  const formMethods = useForm<AboutMeFormInput>({
    resolver: yupResolver(AboutMeFormSchema),
    mode: "onTouched",
    criteriaMode: "firstError",
    defaultValues: defaultValues,
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting, isDirty },
  } = formMethods;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const theme: Theme = useTheme();
  const breakpointMD = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <Grid container spacing={2} py={2}>
            <Grid item xs={12}>
              <Box mb={3}>
                <Grid
                  item
                  display="flex"
                  flexDirection={{ xs: "column", md: "row" }}
                  alignItems={{ xs: "flex-start", md: "center" }}
                  justifyContent={{ xs: "flex-start", md: "space-between" }}
                >
                  <Grid item>
                    <Typography variant="h1">
                      About{" "}
                      <Typography display="inline" variant="h1" component="span" color="link.main">
                        Me.
                      </Typography>
                    </Typography>
                  </Grid>

                  <Grid item>
                    <LoadingButton
                      type="submit"
                      variant={fpProfileIsCompleted ? "outlined" : "contained"}
                      size={breakpointMD ? "medium" : "small"}
                      sx={breakpointMD ? undefined : { marginTop: 2 }}
                      loading={isSubmitting}
                      disabled={isSubmitting}
                    >
                      Save About Me
                    </LoadingButton>
                  </Grid>
                </Grid>

                <Alert severity="info" variant="filled" sx={{ mt: 3 }}>
                  After completing a profile tab, use the &quot;Save&quot; button to save the information you&apos;ve
                  provided. You must complete all of the required fields on each of the profile tabs before you can
                  submit your profile.
                </Alert>
              </Box>
            </Grid>

            <Grid item lg={6} xs={12}>
              <ColumnBox component={Paper} p={4} mb={2}>
                <Controller
                  control={control}
                  name="fp_profile.about_me"
                  render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                    <TextField
                      label="About me"
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      inputRef={ref}
                      error={invalid}
                      placeholder="Tell us a bit about you as a trainer"
                      helperText={error?.message}
                      required
                    />
                  )}
                />
              </ColumnBox>

              <ColumnBox gap={2}>
                <ProfileCard title="Basic Information" Icon={PersonIcon}>
                  <ColumnBox gap={3}>
                    <Controller
                      control={control}
                      name="person.first_name"
                      render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                        <TextField
                          label="First name"
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          inputRef={ref}
                          error={invalid}
                          helperText={error?.message}
                          required
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name="person.last_name"
                      render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                        <TextField
                          label="Last name"
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          inputRef={ref}
                          error={invalid}
                          helperText={error?.message}
                          required
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name="person.preferred_name"
                      render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                        <TextField
                          label="Preferred name"
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          inputRef={ref}
                          error={invalid}
                          helperText={error?.message}
                        />
                      )}
                    />

                    <TextField
                      value={person.email ?? ""}
                      label="Email"
                      helperText="Contact WeFlex if you need to update this email address."
                      required
                      disabled
                    />

                    <Controller
                      control={control}
                      name="person.phone"
                      render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                        <TextField
                          label="Mobile"
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          inputRef={ref}
                          error={invalid}
                          helperText={error?.message}
                          required
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name="person.dob"
                      render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                        <DesktopDatePicker
                          label="Date of birth"
                          inputFormat="dd/MM/yyyy"
                          value={value}
                          onChange={onChange}
                          inputRef={ref}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              onBlur={onBlur}
                              error={invalid}
                              helperText={error?.message}
                              required
                            />
                          )}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name="fp_profile.about_me_social_insta"
                      render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                        <TextField
                          label="Instagram"
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          inputRef={ref}
                          error={invalid}
                          helperText={error?.message}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name="fp_profile.about_me_social_fb"
                      render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                        <TextField
                          label="Facebook"
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          inputRef={ref}
                          error={invalid}
                          helperText={error?.message}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name="person.gender"
                      render={({ field, fieldState }) => (
                        <SelectControl
                          label="Gender"
                          options={Object.values(PersonGenderEnum).map((option) => ({
                            value: option,
                            label: PersonGenderLabel[option],
                          }))}
                          required
                          field={field}
                          fieldState={fieldState}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name="person.avatar"
                      render={({ field, fieldState }) => (
                        <PublicFileUploadControl
                          label="Profile picture / avatar"
                          allowedMimeTypes={IMAGE_MIME_TYPES}
                          allowedMimeTypesMessage="Allowed file types: images"
                          viewFileText="View current profile picture / avatar"
                          field={field}
                          fieldState={fieldState}
                        />
                      )}
                    />
                  </ColumnBox>
                </ProfileCard>
              </ColumnBox>
            </Grid>

            <Grid item lg={6} xs={12}>
              <ColumnBox gap={2} mb={2}>
                <ProfileCard title="Culture" Icon={LanguageIcon}>
                  <ColumnBox gap={3}>
                    <Controller
                      control={control}
                      name="person.nationality"
                      render={({ field, fieldState }) => (
                        <SelectControl
                          label="Country of origin"
                          options={Object.keys(isoCountries).map((key) => ({
                            value: key,
                            label: isoCountries[key].name,
                          }))}
                          required
                          field={field}
                          fieldState={fieldState}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name="person.spoken_languages"
                      render={({ field, fieldState }) => (
                        <SelectControl
                          label="Languages I speak"
                          options={Object.values(isoLanguages).map((value) => ({
                            value: value.name,
                            label: value.name,
                          }))}
                          required
                          multiple
                          field={field}
                          fieldState={fieldState}
                        />
                      )}
                    />
                  </ColumnBox>
                </ProfileCard>
              </ColumnBox>

              <ColumnBox gap={2}>
                <ProfileCard title="Likes" Icon={FavoriteIcon}>
                  <ColumnBox gap={3}>
                    <Controller
                      control={control}
                      name="fp_profile.about_me_things_you_love"
                      render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                        <TextField
                          label="Things I love"
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          inputRef={ref}
                          error={invalid}
                          placeholder="Tell us what you enjoy doing in your spare time. Do you have any hobbies or enjoy certain sports or online games?"
                          helperText={error?.message}
                          required
                          multiline
                          rows={3}
                        />
                      )}
                    />
                  </ColumnBox>
                </ProfileCard>

                <ProfileCard title="Emergency Contact" Icon={ContactPhoneIcon}>
                  <ColumnBox gap={3}>
                    <Controller
                      control={control}
                      name="fp_profile.emergency_first_name"
                      render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                        <TextField
                          label="First name"
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          inputRef={ref}
                          error={invalid}
                          helperText={error?.message}
                          required
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name="fp_profile.emergency_last_name"
                      render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                        <TextField
                          label="Last name"
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          inputRef={ref}
                          error={invalid}
                          helperText={error?.message}
                          required
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name="fp_profile.emergency_relationship"
                      render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                        <TextField
                          label="Relationship"
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          inputRef={ref}
                          error={invalid}
                          helperText={error?.message}
                          required
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name="fp_profile.emergency_email"
                      render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                        <TextField
                          label="Email"
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          inputRef={ref}
                          error={invalid}
                          helperText={error?.message}
                          required
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name="fp_profile.emergency_phone"
                      render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                        <TextField
                          label="Mobile"
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          inputRef={ref}
                          error={invalid}
                          helperText={error?.message}
                          required
                        />
                      )}
                    />
                  </ColumnBox>
                </ProfileCard>
              </ColumnBox>
            </Grid>
          </Grid>
        </form>
      </FormProvider>

      <UnsavedChangesPrompt showPrompt={isDirty} />
    </LocalizationProvider>
  );
}

interface Props extends RouteComponentProps {
  setSavedSnackbarMessage: Dispatch<SetStateAction<string>>;
}

export default function AboutMeForm({ setSavedSnackbarMessage, history }: Props): JSX.Element {
  const { profileId } = useParams<{ profileId: string }>();

  const { refetchCurrentUser } = useAuth();

  const [errorDialogMessages, setErrorDialogMessages] = useState<Array<string>>([]);

  const {
    data: aboutMeData,
    isLoading: aboutMeLoading,
    error: aboutMeError,
    refetch: aboutMeRefetch,
  } = useGetFPProfileAboutMe(profileId);

  const { mutateAsync: editFPProfileAboutMe } = useEditFPProfileAboutMe(profileId);

  const defaultValues = useMemo((): AboutMeFormInput | null => {
    if (!aboutMeData) {
      return null;
    }

    const { person } = aboutMeData;

    return {
      person: {
        ...person,
        dob: person.dob ? parseISO(person.dob) : null,
        gender: person.gender ?? "",
        avatar: person.avatar?.full_size ?? "",
      },
      fp_profile: omit(aboutMeData, "person"),
    };
  }, [aboutMeData]);

  const onSubmit: SubmitHandler<AboutMeFormInput> = async (values) => {
    try {
      await editFPProfileAboutMe({
        person: {
          ...values.person,
          dob: formatISODate(values.person.dob as Date),
          gender: values.person.gender as PersonGenderEnum,
        },
        fp_profile: values.fp_profile,
      });

      await aboutMeRefetch();
      await refetchCurrentUser();

      setSavedSnackbarMessage("About Me saved successfully.");

      history.push(generatePath(ROUTES.FP_PROFILE_MY_BUSINESS, { profileId }));
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);

      if (isErrorResponse(error)) {
        setErrorDialogMessages(getErrorMessages(error.response.data?.message));
      } else {
        setErrorDialogMessages([GENERIC_REQUEST_ERROR_MESSAGE]);
      }
    }
  };

  const onError: SubmitErrorHandler<AboutMeFormInput> = (error) => {
    console.error({ error });
    setErrorDialogMessages([GENERIC_SUBMIT_VALIDATION_ERROR_MESSAGE]);
  };

  if (aboutMeLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (aboutMeError) {
    return <ErrorAlert message="Profile details failed to load" />;
  }

  if (!defaultValues || !aboutMeData) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <AboutMeReactHookForm
        person={aboutMeData.person}
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        onError={onError}
      />

      {!!errorDialogMessages.length && (
        <ErrorDialog title="Unable to save" messages={errorDialogMessages} onClose={() => setErrorDialogMessages([])} />
      )}
    </>
  );
}
