import { useMemo } from "react";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { PWADProfileMyGoalsModel } from "../../models/PWADProfile";
import { useGetGoalsFromPWADProfile } from "../../utils/goal";
import CheckIconList from "../common/CheckIconList";
import ColumnBox from "../common/ColumnBox";
import ProfileCard from "../common/ProfileCard";
import ProfileCardTitleWithContent from "../common/ProfileCardTitleWithContent";

interface Props {
  myGoalsData: PWADProfileMyGoalsModel;
}

export default function PWADGoals({ myGoalsData }: Props) {
  const { fitnessGoals, ndisGoals } = useGetGoalsFromPWADProfile(myGoalsData);

  const { fitnessGoalsList, ndisGoalsList } = useMemo(
    () => ({
      fitnessGoalsList: fitnessGoals.map((goal) => ({ id: goal.id, name: goal.description })),
      ndisGoalsList: ndisGoals.map((goal) => ({ id: goal.id, name: goal.description })),
    }),
    [fitnessGoals, ndisGoals],
  );

  return (
    <ProfileCard title="Goals" Icon={EmojiEventsIcon}>
      <ColumnBox gap={8}>
        <ProfileCardTitleWithContent
          title="Fitness goals"
          content={
            <ColumnBox pt={fitnessGoals.length ? 1 : 0}>
              <CheckIconList showDivider list={fitnessGoalsList} dividerVerticalMargin={2} />
            </ColumnBox>
          }
        />

        <ProfileCardTitleWithContent
          title="NDIS goals"
          content={
            <ColumnBox pt={ndisGoals.length ? 1 : 0}>
              <CheckIconList showDivider list={ndisGoalsList} dividerVerticalMargin={2} />
            </ColumnBox>
          }
        />
      </ColumnBox>
    </ProfileCard>
  );
}
