import { Grid, Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import { Route, Switch } from "react-router-dom";
import CreateAccount from "./onboarding/CreateAccount";
import ForgotPassword from "./onboarding/ForgotPassword";
import OnboardingFP from "./onboarding/fp/OnboardingFP";
import Footer, { FOOTER_HEIGHT } from "./onboarding/layout/Footer";
import Header, { HEADER_HEIGHT, HEADER_MARGIN_HEIGHT } from "./onboarding/layout/Header";
import Login from "./onboarding/Login";
import OnboardingPWAD from "./onboarding/pwad/OnboardingPWAD";
import ResetPassword from "./onboarding/ResetPassword";
import * as ROUTES from "../constants/routes";

const publicRoutesSxProps: SxProps<Theme> = {
  height: "100%",
  width: "100%",
  bgcolor: "white",
  display: "grid",
  gridTemplateAreas: `
  "header"
  "main"
  "footer"`,
};

const mainAreaSxProps: SxProps<Theme> = {
  display: "flex",
  justifyContent: "center",
  minHeight: `calc(100vh - (${HEADER_HEIGHT}px + ${FOOTER_HEIGHT}px + ${HEADER_MARGIN_HEIGHT}px))`,
  gridArea: "main",
};

function PublicRoutes(): JSX.Element {
  return (
    <Grid container sx={publicRoutesSxProps}>
      <Grid item sx={{ gridArea: "header" }} component={Header} />

      <Grid item sx={mainAreaSxProps}>
        <Switch>
          <Route exact path={ROUTES.HOME} render={() => <CreateAccount />} />
          <Route path={ROUTES.JOIN_NOW_FP} render={() => <OnboardingFP />} />
          <Route path={ROUTES.JOIN_NOW_PWAD} render={() => <OnboardingPWAD />} />
          <Route exact path={ROUTES.LOGIN} render={() => <Login />} />
          <Route exact path={ROUTES.FORGOT_PASSWORD} render={() => <ForgotPassword />} />
          <Route exact path={ROUTES.RESET_PASSWORD} render={() => <ResetPassword />} />
          <Route render={({ location }) => <Login redirectTo={`${location.pathname}${location.search}`} />} />
        </Switch>
      </Grid>

      <Grid item sx={{ gridArea: "footer" }} component={Footer} />
    </Grid>
  );
}

export default PublicRoutes;
