import { ReactNode } from "react";
import AddIcon from "@mui/icons-material/Add";
import LinearScaleIcon from "@mui/icons-material/LinearScale";
import ModeCommentIcon from "@mui/icons-material/ModeComment";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import StackedLineChartIcon from "@mui/icons-material/StackedLineChart";
import { Grid, Avatar, Typography } from "@mui/material";
import {
  PWADProfileAboutMeModel,
  CommunicationStyleReceiveInformationEnum,
  CommunicationStyleCommunicateLabel,
  CommunicationStyleReceiveInformationLabel,
} from "../../models/PWADProfile";
import BorderedBox from "../common/BorderedBox";
import CheckIconList from "../common/CheckIconList";
import OptionalValue from "../common/OptionalValue";
import ProfileCard from "../common/ProfileCard";
import ProfileCardDivider from "../common/ProfileCardDivider";
import ProfileCardTitleWithContent from "../common/ProfileCardTitleWithContent";

interface Props {
  aboutMeData: PWADProfileAboutMeModel;
}

const CommunicationStyleDetails: {
  [key in CommunicationStyleReceiveInformationEnum]: { label: string; icon: ReactNode };
} = {
  FOLLOW_MULTI_STEP_DIRECTIONS: {
    label:
      CommunicationStyleReceiveInformationLabel[CommunicationStyleReceiveInformationEnum.FOLLOW_MULTI_STEP_DIRECTIONS],
    icon: <StackedLineChartIcon sx={{ color: "primary.main" }} fontSize="small" />,
  },
  FOLLOW_SIMPLE_DIRECTIONS: {
    label: CommunicationStyleReceiveInformationLabel[CommunicationStyleReceiveInformationEnum.FOLLOW_SIMPLE_DIRECTIONS],
    icon: <LinearScaleIcon sx={{ color: "primary.main" }} fontSize="small" />,
  },
  PREFER_VISUAL_INFORMATION: {
    label:
      CommunicationStyleReceiveInformationLabel[CommunicationStyleReceiveInformationEnum.PREFER_VISUAL_INFORMATION],
    icon: <RemoveRedEyeIcon sx={{ color: "primary.main" }} fontSize="small" />,
  },
  REPEAT_INFORMATION: {
    label: CommunicationStyleReceiveInformationLabel[CommunicationStyleReceiveInformationEnum.REPEAT_INFORMATION],
    icon: <AddIcon sx={{ color: "primary.main" }} fontSize="small" />,
  },
};

export default function PWADCommunicationStyle({ aboutMeData }: Props) {
  const communicationStyles = aboutMeData.communication_style_communicate.map((value) => ({
    id: value,
    name: CommunicationStyleCommunicateLabel[value],
  }));

  return (
    <ProfileCard title="Communication Style" Icon={ModeCommentIcon}>
      <ProfileCardTitleWithContent
        title="How I like to communicate"
        content={<CheckIconList list={communicationStyles} />}
      />

      <ProfileCardDivider />

      <ProfileCardTitleWithContent
        title="How I best receive information"
        content={
          <Grid container spacing={2}>
            <OptionalValue>
              {aboutMeData.communication_style_receive_information.map((item) => (
                <Grid item xs={12} lg={6} key={item}>
                  <BorderedBox>
                    <Avatar sx={{ bgcolor: "#E6FBF2" }}>{CommunicationStyleDetails[item].icon}</Avatar>
                    <Typography variant="body1">{CommunicationStyleDetails[item].label}</Typography>
                  </BorderedBox>
                </Grid>
              ))}
            </OptionalValue>
          </Grid>
        }
      />

      <ProfileCardDivider />

      <ProfileCardTitleWithContent
        title="Other notes about my communication style"
        content={<OptionalValue>{aboutMeData.communication_other_preferences}</OptionalValue>}
      />
    </ProfileCard>
  );
}
