import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import GroupIcon from "@mui/icons-material/Group";
import PersonIcon from "@mui/icons-material/Person";
import { Avatar, Box, Button, Divider, Grid, Paper, Typography } from "@mui/material";
import { generatePath } from "react-router-dom";
import ENV from "../../../constants/envConstants";
import * as ROUTES from "../../../constants/routes";
import { useAuth } from "../../../utils/AuthProvider";
import { getFirstName } from "../../../utils/person";
import HomeInfoCard from "../../common/HomeInfoCard";
import VideoPlayer from "../../common/VideoPlayer";

export default function Incomplete(): JSX.Element {
  const { currentUser } = useAuth();

  return (
    <Grid container padding={{ lg: 4, xs: 2 }} spacing={2}>
      <Grid item xs={12} textAlign={{ xs: "center", lg: "start" }} mb={{ xs: 2, lg: 4 }}>
        <Typography variant="h1">
          Welcome,{" "}
          <Typography display="inline" variant="h1" component="span" color="link.main">
            {getFirstName(currentUser?.person)}.
          </Typography>
        </Typography>
      </Grid>
      <Grid item xs={12} lg={6} display="flex" flexDirection="column" textAlign={{ xs: "center", lg: "start" }}>
        <Typography variant="body1" fontSize={20} lineHeight={1.5}>
          We are pumped you&apos;ve decided to become a WeFlex fitness professional, not long now until you start
          training your first client. There are some important steps you now need to work through for you to become
          WeFlex accredited. Move quick so we can get you matched ASAP.
        </Typography>

        <Box mt={4}>
          <Button
            component="a"
            href={generatePath(ROUTES.FP_PROFILE_ROOT, { profileId: currentUser?.person?.fp_profile?.id })}
            variant="contained"
          >
            Complete Profile
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Box display="flex" justifyContent={{ xs: "center", lg: "flex-end" }}>
          <VideoPlayer videoUrl={ENV.WEFLEX_WELCOME_FP_VIDEO_URL} containerProps={{ component: Paper, p: 2 }} />
        </Box>
      </Grid>
      <Grid item xs={12} my={4}>
        <Divider />
      </Grid>
      <Grid item xs={12} display="flex" flexDirection="column" alignItems="center">
        <Typography variant="h2">How it works</Typography>
        <Box maxWidth={722} mt={1} mb={2}>
          <Typography variant="body1" align="center">
            WeFlex connects people with disabilities to WeFlex fitness professionals, and that can be you next if you
            prioritise these steps:
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={12} lg={3} display="flex">
        <HomeInfoCard>
          <Avatar sx={{ bgcolor: "primary.main", height: 35, width: 35, color: "black" }}>
            <Typography variant="body1">1</Typography>
          </Avatar>
          <Typography variant="h3">Complete your profile</Typography>
          <PersonIcon sx={{ fontSize: "60px" }} />
          <Typography variant="body2">
            Information about you to share with prospective clients and basic compliances to ensure all our WeFlex
            trainers have the relevant qualifications and insurances.
          </Typography>
        </HomeInfoCard>
      </Grid>

      <Grid item xs={12} lg={3} display="flex">
        <HomeInfoCard>
          <Avatar sx={{ bgcolor: "primary.main", height: 35, width: 35, color: "black" }}>
            <Typography variant="body1">2</Typography>
          </Avatar>
          <Typography variant="h3">Training and compliance</Typography>
          <FitnessCenterIcon sx={{ fontSize: "60px" }} />
          <Typography variant="body2">
            WeFlex Academy training that will educate you on the foundations of working with clients with a disability,
            NDIS basic training and your NDIS Worker screen.
          </Typography>
        </HomeInfoCard>
      </Grid>

      <Grid item xs={12} lg={3} display="flex">
        <HomeInfoCard>
          <Avatar sx={{ bgcolor: "primary.main", height: 35, width: 35, color: "black" }}>
            <Typography variant="body1">3</Typography>
          </Avatar>
          <Typography variant="h3">Complete your interview</Typography>
          <CalendarTodayIcon sx={{ fontSize: "60px" }} />
          <Typography variant="body2">
            Last step before matching is an interview to answer any final questions and for us to review your profile to
            start matching you with the clients that best fit you.
          </Typography>
        </HomeInfoCard>
      </Grid>

      <Grid item xs={12} lg={3} display="flex">
        <HomeInfoCard>
          <Avatar sx={{ bgcolor: "primary.main", height: 35, width: 35, color: "black" }}>
            <Typography variant="body1">4</Typography>
          </Avatar>
          <Typography variant="h3">Build your client base</Typography>
          <GroupIcon sx={{ fontSize: "60px" }} />
          <Typography variant="body2">
            Enjoy the session with your first WeFlex client, then grow your client base as you become an inclusive
            champion!{" "}
          </Typography>
        </HomeInfoCard>
      </Grid>
    </Grid>
  );
}
