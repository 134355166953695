import { Alert, AlertTitle } from "@mui/material";

interface Props {
  message: string;
  description?: string;
}

const GENERIC_DESCRIPTION =
  "There was an issue loading the data for this page. Please try again, and if the problem persists, contact WeFlex.";

export default function ErrorAlert({ message, description = GENERIC_DESCRIPTION }: Props) {
  return (
    <Alert severity="error" sx={{ border: "1px solid", borderColor: "error.main", my: 2 }}>
      <AlertTitle>{message}</AlertTitle>
      {description}
    </Alert>
  );
}
