import { useEffect, useState } from "react";
import { Box, Card, CircularProgress, Container, useMediaQuery } from "@mui/material";
import { Route, Switch, generatePath, matchPath, useHistory, useLocation, useParams } from "react-router-dom";
import FPProfileInterview from "./Interview";
import FPProfile from "./Profile";
import FPProfileTraining from "./Training";
import { useGetFPProfileAboutMe } from "../../../api/queries/FPProfile";
import {
  interviewStepNumber,
  isCurrentStep,
  isStepCompleted,
  profileStepNumber,
  trainingStepNumber,
} from "../../../constants/fpProfileConstants";
import * as ROUTES from "../../../constants/routes";
import { FPProfileStatus } from "../../../models/ProfileBase";
import theme from "../../../styles/_theme";
import { getFPProfileIsLive, getFPProfileStatus, getFullName } from "../../../utils/person";
import ErrorAlert from "../../common/ErrorAlert";
import NavTabs from "../../common/NavTabs";
import ProfileBanner from "../../common/ProfileBanner";
import SnackbarAlert from "../../common/SnackbarAlert";

const getNavTabItems = (isLive: boolean, status: FPProfileStatus | null, profileId: string) => {
  if (!status) {
    return [];
  }

  const tabs = [
    {
      label: "Profile",
      path: generatePath(ROUTES.FP_PROFILE_ABOUT_ME, { profileId }),
      stepNumber: 1,
      isCurrentStep: isCurrentStep(status, profileStepNumber),
      isStepCompleted: isStepCompleted(status, profileStepNumber),
      hideIcon: isLive,
    },
    {
      label: "Training",
      path: generatePath(ROUTES.FP_PROFILE_TRAINING, { profileId }),
      stepNumber: 2,
      isCurrentStep: isCurrentStep(status, trainingStepNumber),
      isStepCompleted: isStepCompleted(status, trainingStepNumber),
      hideIcon: isLive,
    },
  ];

  if (!isLive) {
    tabs.push({
      label: "Interview",
      path: generatePath(ROUTES.FP_PROFILE_INTERVIEW, { profileId }),
      stepNumber: 3,
      isCurrentStep: isCurrentStep(status, interviewStepNumber),
      isStepCompleted: isStepCompleted(status, interviewStepNumber),
      hideIcon: isLive,
    });
  }
  return tabs;
};

export default function ProfileRoot(): JSX.Element {
  const [savedSnackbarMessage, setSavedSnackbarMessage] = useState("");
  const mobileMode = useMediaQuery(theme.breakpoints.down("lg"));
  const { profileId } = useParams<{ profileId: string }>();
  const { data: aboutMeData, isLoading: aboutMeLoading, error: aboutMeError } = useGetFPProfileAboutMe(profileId);
  // Note: this does not user the current user because the current user could be an admin looking at an FP's profile.
  const isProfileLive = getFPProfileIsLive(aboutMeData);
  const status = getFPProfileStatus(aboutMeData);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const match = matchPath(location.pathname, { path: ROUTES.FP_PROFILE, exact: true });
    if (!match) {
      return;
    }

    if (!aboutMeData) {
      return;
    }

    if (isProfileLive) {
      history.push(generatePath(ROUTES.FP_PROFILE_ROOT, { profileId }));
    }

    if (status === FPProfileStatus.PROFILE_CREATED) {
      history.push(generatePath(ROUTES.FP_PROFILE_ROOT, { profileId }));
    } else if (status === FPProfileStatus.PROFILE_SUBMITTED) {
      history.push(generatePath(ROUTES.FP_PROFILE_TRAINING, { profileId }));
    } else {
      history.push(generatePath(ROUTES.FP_PROFILE_INTERVIEW, { profileId }));
    }
  }, [aboutMeData, isProfileLive, location, status, profileId]);

  if (aboutMeLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (aboutMeError) {
    return <ErrorAlert message="Profile details failed to load" />;
  }

  return (
    <Container maxWidth="xl" sx={{ px: 2 }}>
      <Card sx={{ width: "100%", mb: { xs: 2, lg: 2 } }}>
        <ProfileBanner
          fullName={getFullName(aboutMeData?.person)}
          title="WeFlex fitness professional"
          avatar={aboutMeData?.person?.avatar}
        />
        <NavTabs
          scrollButtons="auto"
          allowScrollButtonsMobile
          variant={mobileMode ? "scrollable" : "standard"}
          sx={{ ".MuiTabs-flexContainer": { justifyContent: { lg: "flex-end" }, py: { lg: 1 } } }}
          items={getNavTabItems(!!isProfileLive, status, profileId)}
        />
      </Card>

      <Switch>
        <Route
          path={ROUTES.FP_PROFILE_ROOT}
          render={(props) => <FPProfile {...props} setProfileRootSnackbarMessage={setSavedSnackbarMessage} />}
        />
        <Route
          path={ROUTES.FP_PROFILE_TRAINING}
          render={(props) => <FPProfileTraining {...props} setSavedSnackbarMessage={setSavedSnackbarMessage} />}
        />
        <Route
          path={ROUTES.FP_PROFILE_INTERVIEW}
          render={(props) => <FPProfileInterview {...props} setSavedSnackbarMessage={setSavedSnackbarMessage} />}
        />
        <Route component={FPProfile} />
      </Switch>
      <SnackbarAlert
        title="Saved"
        content={savedSnackbarMessage}
        severity="success"
        open={!!savedSnackbarMessage}
        onClose={() => setSavedSnackbarMessage("")}
      />
    </Container>
  );
}
