import React, { useEffect, useState } from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  Alert,
  AlertTitle,
  Avatar,
  Box,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import * as Sentry from "@sentry/browser";
import { isUndefined } from "lodash";
import { useQuery } from "react-query";
import { useParams, Link as RouterLink, generatePath } from "react-router-dom";
import { ErrorResponse, isErrorResponse } from "../../../api/Generic";
import { useSetBookingOutcome } from "../../../api/mutations/Booking";
import * as ROUTES from "../../../constants/routes";
import { GENERIC_REQUEST_ERROR_MESSAGE } from "../../../constants/validation";
import { BookingOutcomeEnum, BookingOutcomeLabel, BookingWithSessionReportModel } from "../../../models/Booking";
import { formatDateTimeStr } from "../../../utils/formatDateTime";
import getAvatarUrl from "../../../utils/getAvatarUrl";
import getErrorMessages from "../../../utils/getErrorMessages";
import { getFullName } from "../../../utils/person";
import useQueryParams from "../../../utils/useQueryParams";
import ErrorAlert from "../../common/ErrorAlert";
import ErrorDialog from "../../common/ErrorDialog";
import SessionLocation from "../../pwad/request-session/SessionLocation";

function SetBookingOutcome(): JSX.Element | null {
  const { bookingId } = useParams<{ bookingId: string }>();
  const queryParams = useQueryParams();

  const specifiedOutcome = queryParams.get("outcome");

  const [errorDialogMessages, setErrorDialogMessages] = useState<Array<string>>([]);

  const {
    data: bookingWithSessionReportData,
    isLoading: isBookingWithSessionReportLoading,
    error: bookingWithSessionReportError,
    refetch: refetchBookingWithSessionReport,
  } = useQuery<BookingWithSessionReportModel, ErrorResponse>(`/booking/session-report-detail/${bookingId}/`);

  const { mutateAsync: setBookingOutcome } = useSetBookingOutcome();

  const isSpecifiedOutcomeValid =
    !!specifiedOutcome && Object.values(BookingOutcomeEnum).includes(specifiedOutcome as BookingOutcomeEnum);

  const savedOutcome = bookingWithSessionReportData?.outcome;

  useEffect(() => {
    async function autoSetBookingOutcome() {
      if (!isSpecifiedOutcomeValid) {
        return;
      }

      if (isUndefined(savedOutcome) || specifiedOutcome === savedOutcome) {
        return;
      }

      try {
        await setBookingOutcome({ booking: bookingId, outcome: specifiedOutcome as BookingOutcomeEnum });

        await refetchBookingWithSessionReport();
      } catch (error) {
        console.error(error);
        Sentry.captureException(error);

        if (isErrorResponse(error)) {
          setErrorDialogMessages(getErrorMessages(error.response.data?.message));
        } else {
          setErrorDialogMessages([GENERIC_REQUEST_ERROR_MESSAGE]);
        }
      }
    }

    autoSetBookingOutcome();
  }, [specifiedOutcome, isSpecifiedOutcomeValid, savedOutcome]);

  if (isBookingWithSessionReportLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (bookingWithSessionReportError) {
    return <ErrorAlert message="Booking details failed to load" />;
  }

  if (!isSpecifiedOutcomeValid) {
    return (
      <ErrorAlert
        message="Invalid booking outcome"
        description="The specified booking outcome is not valid. Please check the URL and try again, and if the problem persists, contact WeFlex."
      />
    );
  }

  if (!bookingWithSessionReportData) {
    return null;
  }

  const { person } = bookingWithSessionReportData.pwad_profile;
  const { session } = bookingWithSessionReportData;
  const sessionLocation = session.session_location;
  const pwadProfileId = bookingWithSessionReportData.pwad_profile.id;
  const pwadProfileUrl = generatePath(ROUTES.FP_VIEW_PWAD_PROFILE, { pwadProfileId });

  return (
    <>
      <Container maxWidth="xl">
        <Grid container p={2}>
          <Grid item xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Link
                  component={RouterLink}
                  to={ROUTES.HOME}
                  color="link.main"
                  display="flex"
                  alignItems="center"
                  underline="none"
                  width="min-content"
                >
                  <ChevronLeftIcon sx={{ width: 30, height: 30 }} />
                  <Typography sx={{ fontSize: "18px", fontWeight: 600, whiteSpace: "nowrap" }}>My Bookings</Typography>
                </Link>
              </Grid>

              <Grid item xs={12} display="flex" justifyContent={{ xs: "center", lg: "flex-start" }}>
                <Avatar src={getAvatarUrl(person.avatar)} sx={{ width: 142, height: 142 }} />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h1">
                  Booking{" "}
                  <Typography display="inline" variant="h1" component="span" color="link.main">
                    Outcome.
                  </Typography>
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Box display="flex" alignItems="center" gap={1}>
                  <Typography variant="h3">Client: </Typography>
                  <Typography>
                    <Link component={RouterLink} to={pwadProfileUrl} target="_blank" color="link.main" variant="link1">
                      {getFullName(person)}
                    </Link>
                  </Typography>
                </Box>

                <Box display="flex" alignItems="center" gap={1}>
                  <Typography variant="h3">Session: </Typography>
                  <Typography>
                    {formatDateTimeStr(`${session.date} ${session.start_time}`, "eeee MMMM dd, yyyy, h:mmaaa")}
                  </Typography>
                </Box>

                <Box display="flex" alignItems="center" gap={1}>
                  <Typography variant="h3">Location: </Typography>
                  <SessionLocation
                    place={sessionLocation.place}
                    locationType={sessionLocation.location_type}
                    mobileLocation={sessionLocation.mobile_location}
                    showFixedLocationTitle={false}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} my={4}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            {savedOutcome === "" && (
              <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                <CircularProgress />
              </Box>
            )}

            {savedOutcome === BookingOutcomeEnum.COMPLETED && (
              <Alert severity="success" variant="filled">
                <AlertTitle>{BookingOutcomeLabel.COMPLETED}</AlertTitle>
                This booking has been marked as completed.
              </Alert>
            )}

            {savedOutcome === BookingOutcomeEnum.RESCHEDULED && (
              <Alert severity="info" variant="filled">
                <AlertTitle>{BookingOutcomeLabel.RESCHEDULED}</AlertTitle>
                This booking has been marked as rescheduled.
              </Alert>
            )}

            {savedOutcome === BookingOutcomeEnum.CANCELLED_WITH_NOTICE && (
              <Alert severity="warning" variant="filled">
                <AlertTitle>{BookingOutcomeLabel.CANCELLED_WITH_NOTICE}</AlertTitle>
                This booking has been marked as cancelled - with notice.
              </Alert>
            )}

            {savedOutcome === BookingOutcomeEnum.CANCELLED_SHORT_NOTICE && (
              <Alert severity="error" variant="filled">
                <AlertTitle>{BookingOutcomeLabel.CANCELLED_SHORT_NOTICE}</AlertTitle>
                This booking has been marked as cancelled - short notice.
              </Alert>
            )}

            {savedOutcome === BookingOutcomeEnum.CANCELLED_BY_FP && (
              <Alert severity="warning" variant="filled">
                <AlertTitle>{BookingOutcomeLabel.CANCELLED_BY_FP}</AlertTitle>
                This booking has been marked as cancelled - by the fitness professional.
              </Alert>
            )}

            {savedOutcome !== "" && (
              <Typography variant="body2" mt={4}>
                If you need to update the outcome of this booking, please contact WeFlex.
              </Typography>
            )}
          </Grid>
        </Grid>
      </Container>

      {!!errorDialogMessages.length && (
        <ErrorDialog
          title="Unable to set booking outcome"
          messages={errorDialogMessages}
          onClose={() => setErrorDialogMessages([])}
        />
      )}
    </>
  );
}

export default SetBookingOutcome;
