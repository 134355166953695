import { Alert } from "@mui/material";
import { isFutureStep, isStepCompleted } from "../../../constants/fpProfileConstants";
import { FPProfileStatus } from "../../../models/ProfileBase";

interface Props {
  isLive: boolean;
  stepNumber: number;
  status: FPProfileStatus | undefined;
}

export default function CurrentStepStatusAlert({ isLive, stepNumber, status }: Props): JSX.Element {
  if (!status || isLive) {
    return <></>;
  }

  if (isStepCompleted(status, stepNumber)) {
    return (
      <Alert severity="info" sx={{ mb: 3 }}>
        Your data has been submitted and is being reviewed by WeFlex.
      </Alert>
    );
  }

  if (isFutureStep(status, stepNumber)) {
    return (
      <Alert severity="info" sx={{ mb: 3 }}>
        Please complete earlier step before attempting to complete this step.
      </Alert>
    );
  }

  return <></>;
}
