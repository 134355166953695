import { Box, Theme } from "@mui/material";
import { SxProps } from "@mui/system";

export type DotColors = "success" | "info" | "warning" | "error";

interface Props {
  color: DotColors;
  sx?: SxProps<Theme> | undefined;
}

export default function Dot({ color, sx }: Props) {
  return <Box component="span" sx={{ ...sx, width: 10, height: 10, borderRadius: 50, bgcolor: `${color}.main` }} />;
}
