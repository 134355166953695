import { useMutation } from "react-query";
import Axios from "../Axios";
import { ErrorResponse, ServerResponse } from "../Generic";

type EditInvoiceParameters = {
  status: string;
};

export function useEditInvoiceStatus(invoice_id: string) {
  return useMutation<ServerResponse, ErrorResponse, EditInvoiceParameters>(({ status }: EditInvoiceParameters) =>
    Axios.post<ServerResponse, any>(`/invoice/${invoice_id}/update-status/`, {
      status,
    }),
  );
}

export default useEditInvoiceStatus;
