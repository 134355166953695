import React from "react";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { ErrorResponse } from "../../../../api/Generic";
import { PWADProfileConsentModel } from "../../../../models/PWADProfile";
import ErrorAlert from "../../../common/ErrorAlert";
import PWADMediaMarketingConsent from "../../../profile-cards/PWADMediaMarketingConsent";
import PWADServicesConsent from "../../../profile-cards/PWADServicesConsent";

export default function Consent(): JSX.Element | null {
  const { profileId } = useParams<{ profileId: string }>();

  const {
    data: consentData,
    error: consentError,
    isLoading: consentLoading,
  } = useQuery<PWADProfileConsentModel, ErrorResponse>(`/pwadprofile/consent/?pwad_profile=${profileId}`);

  if (consentLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (consentError) {
    return <ErrorAlert message="Profile details failed to load" />;
  }

  if (!consentData) {
    return null;
  }

  return (
    <Grid container spacing={2} py={2}>
      <Grid item xs={12}>
        <Typography variant="h1" mb={3}>
          <Typography display="inline" variant="h1" component="span" color="link.main">
            Consent.
          </Typography>
        </Typography>
      </Grid>

      <Grid item lg={6} xs={12}>
        <PWADServicesConsent consentData={consentData} />
      </Grid>

      <Grid item lg={6} xs={12}>
        <PWADMediaMarketingConsent consentData={consentData} />
      </Grid>
    </Grid>
  );
}
