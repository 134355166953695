import { Link } from "@mui/material";

interface Props {
  location: string;
  text?: string;
}

export default function MapLink({ location, text = "Directions" }: Props) {
  return (
    <Link
      href={`https://www.google.com/maps/?q=${encodeURIComponent(location)}}`}
      target="_blank"
      variant="body2"
      color="link.main"
      fontWeight="bold"
    >
      {text}
    </Link>
  );
}
