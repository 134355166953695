import { Box, Paper } from "@mui/material";

interface Props {
  children: React.ReactNode;
}
export default function HomeInfoCard({ children }: Props): JSX.Element {
  return (
    <Box
      component={Paper}
      display="flex"
      flexDirection="column"
      alignItems="center"
      p={[2, 4]}
      gap={4}
      textAlign="center"
      width="100%"
    >
      {children}
    </Box>
  );
}
