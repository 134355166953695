import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import StarRateIcon from "@mui/icons-material/StarRate";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import * as Sentry from "@sentry/browser";
import { Controller, FormProvider, SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { RouteComponentProps, generatePath, useParams } from "react-router-dom";
import * as yup from "yup";
import { ErrorResponse, isErrorResponse } from "../../../../api/Generic";
import { useEditPWADProfileMyUniqueNeeds } from "../../../../api/mutations/PWADProfile";
import { IMAGE_MIME_TYPES, PDF_MIME_TYPES, WORD_DOCUMENT_MIME_TYPES } from "../../../../constants/mimeTypes";
import * as ROUTES from "../../../../constants/routes";
import {
  GENERIC_REQUEST_ERROR_MESSAGE,
  GENERIC_REQUIRED_VALIDATION_ERROR_MESSAGE,
  GENERIC_SUBMIT_VALIDATION_ERROR_MESSAGE,
} from "../../../../constants/validation";
import PrivateFileUploadControl from "../../../../form/PrivateFileUploadControl";
import { PWADProfileMyUniqueNeedsEditModel, PWADProfileMyUniqueNeedsModel } from "../../../../models/PWADProfile";
import { useAuth } from "../../../../utils/AuthProvider";
import getErrorMessages from "../../../../utils/getErrorMessages";
import { getUserPWADProfileIsCompleted } from "../../../../utils/person";
import ColumnBox from "../../../common/ColumnBox";
import ErrorAlert from "../../../common/ErrorAlert";
import ErrorDialog from "../../../common/ErrorDialog";
import ProfileCard from "../../../common/ProfileCard";
import UnsavedChangesPrompt from "../../../common/UnsavedChangesPrompt";

type MyUniqueNeedsFormInput = PWADProfileMyUniqueNeedsEditModel;

const MyUniqueNeedsFormSchema: yup.SchemaOf<MyUniqueNeedsFormInput> = yup.object({
  unique_needs_diagnosis: yup.string().trim().required(GENERIC_REQUIRED_VALIDATION_ERROR_MESSAGE),
  unique_needs_my_needs: yup.string().trim().required(GENERIC_REQUIRED_VALIDATION_ERROR_MESSAGE),
  unique_needs_accessibility: yup.string().trim().defined(),
  unique_needs_behaviour_support: yup.string().trim().defined(),
  unique_needs_sensory_activity: yup.string().trim().defined(),
  unique_needs_seizure_activity: yup.string().trim().defined(),
  unique_needs_behaviour_support_private_file: yup.string().defined().nullable(),
  unique_needs_sensory_private_file: yup.string().defined().nullable(),
  unique_needs_seizure_private_file: yup.string().defined().nullable(),
});

interface MyUniqueNeedsReactHookFormProps {
  defaultValues: MyUniqueNeedsFormInput;
  onSubmit: SubmitHandler<MyUniqueNeedsFormInput>;
  onError: SubmitErrorHandler<MyUniqueNeedsFormInput>;
}

function MyUniqueNeedsReactHookForm({
  defaultValues,
  onSubmit,
  onError,
}: MyUniqueNeedsReactHookFormProps): JSX.Element {
  const { currentUser } = useAuth();
  const pwadProfileIsCompleted = getUserPWADProfileIsCompleted(currentUser);

  const formMethods = useForm<MyUniqueNeedsFormInput>({
    resolver: yupResolver(MyUniqueNeedsFormSchema),
    mode: "onTouched",
    criteriaMode: "firstError",
    defaultValues: defaultValues,
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting, isDirty },
  } = formMethods;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const theme: Theme = useTheme();
  const breakpointMD = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <Grid container spacing={2} py={2}>
          <Grid item xs={12}>
            <Box mb={3}>
              <Grid
                item
                display="flex"
                flexDirection={{ xs: "column", md: "row" }}
                alignItems={{ xs: "flex-start", md: "center" }}
                justifyContent={{ xs: "flex-start", md: "space-between" }}
              >
                <Grid item>
                  <Typography variant="h1">
                    My{" "}
                    <Typography display="inline" variant="h1" component="span" color="link.main">
                      Unique Needs.
                    </Typography>
                  </Typography>
                </Grid>

                <Grid item>
                  <LoadingButton
                    type="submit"
                    variant={pwadProfileIsCompleted ? "outlined" : "contained"}
                    size={breakpointMD ? "medium" : "small"}
                    sx={breakpointMD ? undefined : { marginTop: 2 }}
                    loading={isSubmitting}
                    disabled={isSubmitting}
                  >
                    Save My Unique Needs
                  </LoadingButton>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid item lg={6} xs={12}>
            <ProfileCard title="Needs" Icon={StarRateIcon}>
              <ColumnBox gap={3}>
                <Controller
                  control={control}
                  name="unique_needs_diagnosis"
                  render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                    <TextField
                      label="My diagnosis"
                      placeholder="Tell us about your disability."
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      inputRef={ref}
                      error={invalid}
                      helperText={error?.message}
                      multiline
                      rows={3}
                      required
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="unique_needs_my_needs"
                  render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                    <TextField
                      label="My needs"
                      placeholder="What do you need your fitness professional to know about your needs that will help them support you and deliver the best sessions for you."
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      inputRef={ref}
                      error={invalid}
                      helperText={error?.message}
                      multiline
                      rows={3}
                      required
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="unique_needs_accessibility"
                  render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                    <TextField
                      label="My accessibility needs"
                      placeholder="Tell us about your accessibility needs."
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      inputRef={ref}
                      error={invalid}
                      helperText={error?.message}
                      multiline
                      rows={3}
                    />
                  )}
                />
              </ColumnBox>
            </ProfileCard>
          </Grid>

          <Grid item lg={6} xs={12}>
            <ProfileCard title="Support Considerations" Icon={VolunteerActivismIcon}>
              <ColumnBox gap={3}>
                <Controller
                  control={control}
                  name="unique_needs_behaviour_support"
                  render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                    <TextField
                      label="Behaviour support notes"
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      inputRef={ref}
                      error={invalid}
                      helperText={error?.message}
                      multiline
                      rows={3}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="unique_needs_behaviour_support_private_file"
                  render={({ field, fieldState }) => (
                    <PrivateFileUploadControl
                      label="Behaviour Support Plan file"
                      allowedMimeTypes={[...IMAGE_MIME_TYPES, ...WORD_DOCUMENT_MIME_TYPES, ...PDF_MIME_TYPES]}
                      allowedMimeTypesMessage="Allowed file types: images, documents, and PDFs"
                      field={field}
                      fieldState={fieldState}
                    />
                  )}
                />

                <Divider sx={{ my: 2 }} />

                <Controller
                  control={control}
                  name="unique_needs_sensory_activity"
                  render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                    <TextField
                      label="Sensory support notes"
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      inputRef={ref}
                      error={invalid}
                      helperText={error?.message}
                      multiline
                      rows={3}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="unique_needs_sensory_private_file"
                  render={({ field, fieldState }) => (
                    <PrivateFileUploadControl
                      label="Sensory Needs Profile file"
                      allowedMimeTypes={[...IMAGE_MIME_TYPES, ...WORD_DOCUMENT_MIME_TYPES, ...PDF_MIME_TYPES]}
                      allowedMimeTypesMessage="Allowed file types: images, documents, and PDFs"
                      field={field}
                      fieldState={fieldState}
                    />
                  )}
                />

                <Divider sx={{ my: 2 }} />

                <Controller
                  control={control}
                  name="unique_needs_seizure_activity"
                  render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                    <TextField
                      label="Seizure support notes"
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      inputRef={ref}
                      error={invalid}
                      helperText={error?.message}
                      multiline
                      rows={3}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="unique_needs_seizure_private_file"
                  render={({ field, fieldState }) => (
                    <PrivateFileUploadControl
                      label="Epilepsy Management Plan file"
                      allowedMimeTypes={[...IMAGE_MIME_TYPES, ...WORD_DOCUMENT_MIME_TYPES, ...PDF_MIME_TYPES]}
                      allowedMimeTypesMessage="Allowed file types: images, documents, and PDFs"
                      field={field}
                      fieldState={fieldState}
                    />
                  )}
                />
              </ColumnBox>
            </ProfileCard>
          </Grid>
        </Grid>
      </form>

      <UnsavedChangesPrompt showPrompt={isDirty} />
    </FormProvider>
  );
}

interface Props extends RouteComponentProps {
  setSavedSnackbarMessage: Dispatch<SetStateAction<string>>;
}

export default function MyUniqueNeedsForm({ setSavedSnackbarMessage, history }: Props): JSX.Element {
  const { profileId } = useParams<{ profileId: string }>();

  const { refetchCurrentUser } = useAuth();

  const [errorDialogMessages, setErrorDialogMessages] = useState<Array<string>>([]);

  const {
    data: myUniqueNeedsData,
    error: myUniqueNeedsError,
    isLoading: myUniqueNeedsLoading,
    refetch: myUniqueNeedsRefetch,
  } = useQuery<PWADProfileMyUniqueNeedsModel, ErrorResponse>(`/pwadprofile/my-unique-needs/?pwad_profile=${profileId}`);

  const { mutateAsync: editPWADProfileMyUniqueNeeds } = useEditPWADProfileMyUniqueNeeds(profileId);

  const defaultValues = useMemo((): MyUniqueNeedsFormInput | null => {
    if (!myUniqueNeedsData) {
      return null;
    }

    return myUniqueNeedsData;
  }, [myUniqueNeedsData]);

  const onSubmit: SubmitHandler<MyUniqueNeedsFormInput> = async (values) => {
    try {
      await editPWADProfileMyUniqueNeeds({
        pwad_profile: values,
      });

      await myUniqueNeedsRefetch();
      await refetchCurrentUser();

      setSavedSnackbarMessage("My Unique Needs saved successfully.");

      history.push(generatePath(ROUTES.PWAD_PROFILE_MY_GOALS, { profileId }));
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);

      if (isErrorResponse(error)) {
        setErrorDialogMessages(getErrorMessages(error.response.data?.message));
      } else {
        setErrorDialogMessages([GENERIC_REQUEST_ERROR_MESSAGE]);
      }
    }
  };

  const onError: SubmitErrorHandler<MyUniqueNeedsFormInput> = () => {
    setErrorDialogMessages([GENERIC_SUBMIT_VALIDATION_ERROR_MESSAGE]);
  };

  if (myUniqueNeedsLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (myUniqueNeedsError) {
    return <ErrorAlert message="Profile details failed to load" />;
  }

  if (!defaultValues) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <MyUniqueNeedsReactHookForm defaultValues={defaultValues} onSubmit={onSubmit} onError={onError} />

      {!!errorDialogMessages.length && (
        <ErrorDialog title="Unable to save" messages={errorDialogMessages} onClose={() => setErrorDialogMessages([])} />
      )}
    </>
  );
}
