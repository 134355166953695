import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MailIcon from "@mui/icons-material/Mail";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/PhoneAndroid";
import TransgenderIcon from "@mui/icons-material/Transgender";
import { format, parseISO } from "date-fns";
import { PersonGenderLabel } from "../../models/Person";
import { PWADProfileAboutMeModel } from "../../models/PWADProfile";
import ColumnBox from "../common/ColumnBox";
import ProfileCard from "../common/ProfileCard";
import ProfileCardIconWithContent from "../common/ProfileCardIconWithContent";

interface Props {
  aboutMeData: PWADProfileAboutMeModel;
}

export default function PWADBasicInformation({ aboutMeData }: Props) {
  const iconColor = "grey.600";

  const { person } = aboutMeData;

  if (!person) {
    return null;
  }

  const gender = person.gender ? PersonGenderLabel[person.gender] : "";
  const dateOfBirth = person.dob ? format(parseISO(person.dob), "dd MMM yyyy") : "";

  return (
    <ProfileCard title="Basic Information" Icon={PersonIcon}>
      <ColumnBox gap={3}>
        {person.email && <ProfileCardIconWithContent content={person.email} Icon={MailIcon} iconColor={iconColor} />}

        {person.phone && <ProfileCardIconWithContent content={person.phone} Icon={PhoneIcon} iconColor={iconColor} />}

        <ProfileCardIconWithContent content={person.address?.place} Icon={LocationOnIcon} iconColor={iconColor} />

        <ProfileCardIconWithContent content={dateOfBirth} Icon={CalendarTodayIcon} iconColor={iconColor} />

        <ProfileCardIconWithContent content={gender} Icon={TransgenderIcon} iconColor={iconColor} />
      </ColumnBox>
    </ProfileCard>
  );
}
