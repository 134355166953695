import React from "react";
import { Prompt } from "react-router-dom";

interface Props {
  showPrompt: boolean;
}

export default function UnsavedChangesPrompt({ showPrompt }: Props) {
  return (
    <Prompt
      when={showPrompt}
      message="You have made changes to values that have not yet been saved. Are you sure you want to leave this page and discard your changes?"
    />
  );
}
