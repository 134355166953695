import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Alert, Container, Stack, Typography } from "@mui/material";
import * as Sentry from "@sentry/browser";
import { SubmitHandler, useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { useForgotPassword } from "../../api/mutations/User";
import FormTextField from "../../form/FormTextField";
import ErrorDialog from "../common/ErrorDialog";

type ForgotPasswordParameters = {
  email: string;
};

const ForgotPasswordFormSchema: yup.SchemaOf<ForgotPasswordParameters> = yup.object({
  email: yup.string().email("Please enter a valid email address.").required("Email address is required."),
});

const ForgotPassword = () => {
  const methods = useForm<ForgotPasswordParameters>({
    resolver: yupResolver(ForgotPasswordFormSchema),
    criteriaMode: "firstError",
    defaultValues: {
      email: "",
    },
  });

  const { mutate: forgotPassword, isLoading: forgotPasswordLoading } = useForgotPassword();
  const [submitMessage, setSubmitMessage] = useState("");

  const [submitErrorMessage, setSubmitErrorMessage] = useState("");

  const onSubmit: SubmitHandler<ForgotPasswordParameters> = async (values) => {
    forgotPassword(
      { email: values.email },
      {
        onError: (error) => {
          Sentry.captureException(error);

          setSubmitErrorMessage(
            "An unknown error occurred. Please try again, and if the problem persists, contact WeFlex.",
          );
        },
        onSuccess: async () => {
          setSubmitMessage(
            `If the email address '${values.email}' is associated with a WeFlex account, an email has been sent with a link to reset your password.`,
          );
        },
      },
    );
  };

  return (
    <Container maxWidth="md">
      <Stack sx={{ alignItems: "center" }} spacing={3} mt={15}>
        <Typography variant="h1">Reset your password</Typography>
        <Typography variant="body1">
          Enter your email address below to receive an email with a link to reset your password.
        </Typography>

        {submitMessage && (
          <Alert severity="success" variant="filled" sx={{ mt: 3 }}>
            {submitMessage}
          </Alert>
        )}

        <FormProvider {...methods}>
          <Stack
            sx={{ alignItems: "center", width: { xs: "100%", md: "50%" } }}
            spacing={2}
            component="form"
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <FormTextField required name="email" id="email-text-field" label="Email" variant="outlined" fullWidth />

            <LoadingButton
              loading={forgotPasswordLoading}
              type="submit"
              variant="contained"
              fullWidth
              sx={{ width: "60%" }}
            >
              Send Reset Link
            </LoadingButton>
          </Stack>
        </FormProvider>
      </Stack>

      {!!submitErrorMessage && (
        <ErrorDialog
          title="Password reset failed"
          messages={[submitErrorMessage]}
          onClose={() => setSubmitErrorMessage("")}
        />
      )}
    </Container>
  );
};

export default ForgotPassword;
