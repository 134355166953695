import PaidIcon from "@mui/icons-material/Paid";
import {
  BookingFundingMethodEnum,
  BookingFundingMethodLabel,
  PaymentMethodLabel,
  PWADProfileMyPreferencesModel,
} from "../../models/PWADProfile";
import ProfileCard from "../common/ProfileCard";
import ProfileCardDivider from "../common/ProfileCardDivider";
import ProfileCardTitleWithContent from "../common/ProfileCardTitleWithContent";

interface Props {
  myPreferencesData: PWADProfileMyPreferencesModel;
}

export default function PWADPaymentPreferences({ myPreferencesData }: Props): JSX.Element {
  const showPreferredPaymentMethodField =
    myPreferencesData.typical_funding_method === BookingFundingMethodEnum.CLIENT_FUNDED ||
    myPreferencesData.typical_funding_method === BookingFundingMethodEnum.OTHER;

  return (
    <ProfileCard title="Payment" Icon={PaidIcon}>
      <ProfileCardTitleWithContent
        title="Typical funding method"
        content={
          myPreferencesData.typical_funding_method &&
          BookingFundingMethodLabel[myPreferencesData.typical_funding_method]
        }
      />

      <ProfileCardDivider />

      <ProfileCardTitleWithContent title="Invoicing email" content={myPreferencesData.invoicing_email} />

      {showPreferredPaymentMethodField && (
        <>
          <ProfileCardDivider />

          <ProfileCardTitleWithContent
            title="Preferred payment method"
            content={
              myPreferencesData.preferred_payment_method &&
              PaymentMethodLabel[myPreferencesData.preferred_payment_method]
            }
          />
        </>
      )}
    </ProfileCard>
  );
}
