import * as Sentry from "@sentry/browser";
import ENV from "../constants/envConstants";

export default (() => {
  if (!ENV.SENTRY_DSN) {
    return;
  }

  Sentry.init({
    dsn: ENV.SENTRY_DSN,
    environment: ENV.SENTRY_ENVIRONMENT,

    // Ignore events that are caused when a rejection is raised with a non-error object, from which Sentry cannot
    // extract much information.
    //
    // See https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/14.
    //
    // There is a list of other errors which can potentially be ignored, as well as blacklist URLs, available at
    // https://github.com/getsentry/sentry-javascript/issues/2210#issuecomment-630337981. This may be useful if we
    // identify other errors coming through which can be safely ignored.
    ignoreErrors: ["Non-Error exception captured", "Non-Error promise rejection captured"],
  });

  console.log("Sentry is initialised", ENV.SENTRY_DSN);
})();
