import React, { useEffect, useMemo } from "react";
import { Box, CircularProgress, FormHelperText, Link, Paper, Typography } from "@mui/material";
import { isEmpty, isNil, sortBy } from "lodash";
import { Controller, useFormContext } from "react-hook-form";
import { useQuery } from "react-query";
import RequestSessionFormRepeat from "./RequestSessionFormRepeat";
import RequestSessionFormSessions from "./RequestSessionFormSessions";
import RequestSessionFormSpecialties from "./RequestSessionFormSpecialties";
import { ErrorResponse } from "../../../api/Generic";
import ENV from "../../../constants/envConstants";
import FormCheckboxField from "../../../form/FormCheckboxField";
import SelectControl from "../../../form/SelectControl";
import { AvailableSessionsModel } from "../../../models/Session";

interface Props {
  fpId: string;
  date: string;
}

export default function RequestSessionForm({ fpId, date }: Props) {
  const { reset, watch, setValue, control } = useFormContext();

  const { data: availableSessionsData, isLoading: isAvailableSessionsLoading } = useQuery<
    AvailableSessionsModel,
    ErrorResponse
  >(`/fpprofile/get-sessions-available/?fpprofile=${fpId}&date=${date}`);

  useEffect(() => {
    reset();
  }, [availableSessionsData]);

  const serviceOptions = useMemo(
    () => availableSessionsData?.services.map((service) => ({ value: service.id, label: service.name })) ?? [],
    [availableSessionsData],
  );

  const selectedServiceId = watch("service");
  const selectedSpecialtyId = watch("specialty");
  const selectedSessionId = watch("session");
  const isRepeated = watch("isRepeated");
  const selectedRepeatFrequency = watch("repeatFrequency");
  const selectedRepeatCount = watch("repeatCount");

  useEffect(() => {
    // When date is changed, reset selected service since the options for it may have changed.
    setValue("service", "");
  }, [date]);

  useEffect(() => {
    // When service is changed, reset selected specialty and session since the options for these may have changed.
    setValue("specialty", "");
    setValue("session", "");
  }, [selectedServiceId]);

  const selectedService = useMemo(
    () => availableSessionsData?.services.find(({ id }) => id === selectedServiceId),
    [availableSessionsData, selectedServiceId],
  );

  const specialties = useMemo(() => {
    if (isNil(selectedService)) {
      return [];
    }

    return selectedService.specialties;
  }, [selectedService]);

  const sessions = useMemo(() => {
    if (isNil(selectedService)) {
      return [];
    }

    return selectedService.locations.flatMap((location) =>
      sortBy(
        location.session.map((session) => ({
          ...session,
          place: location.place,
          locationType: location.location_type,
          mobileLocation: location.mobile_location,
        })),
        "start_time",
      ),
    );
  }, [selectedService]);

  const handleSpecialtyClick = (e: any) => {
    if (selectedSpecialtyId !== e.target.value) {
      setValue("specialty", e.target.value);
    }
  };

  const handleSessionClick = (e: any) => {
    if (selectedSessionId !== e.target.value) {
      setValue("session", e.target.value);
    }
  };

  const handleRepeatChange = (e: any) => {
    setValue("isRepeated", e.target.checked);
  };

  const handleRepeatFrequencyChange = (e: any) => {
    setValue("repeatFrequency", e.target.value);
  };

  const handleRepeatCountChange = (e: any) => {
    setValue("repeatCount", e.target.value);
  };

  if (isAvailableSessionsLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (isEmpty(serviceOptions)) {
    return (
      <Paper sx={{ my: 4, p: 4, display: "flex", justifyContent: "center" }} elevation={0}>
        <Typography>There are no available services for this date</Typography>
      </Paper>
    );
  }

  if (!isNil(selectedServiceId) && selectedServiceId !== "" && isNil(selectedService)) {
    return null;
  }

  return (
    <Box mt={4}>
      <Typography variant="body1" fontWeight="bold" mb={2}>
        Choose the service you require
      </Typography>

      <Controller
        control={control}
        name="service"
        render={({ field, fieldState }) => (
          <SelectControl
            label="Service"
            options={serviceOptions}
            required
            fullWidth
            field={field}
            fieldState={fieldState}
          />
        )}
      />

      <FormHelperText>Only services available for the chosen date can be selected</FormHelperText>

      {!!selectedServiceId && (
        <RequestSessionFormSpecialties
          specialties={specialties}
          selectedSpecialtyId={selectedSpecialtyId}
          onSpecialtyClick={handleSpecialtyClick}
        />
      )}

      {!!selectedSpecialtyId && (
        <RequestSessionFormSessions
          date={date}
          sessions={sessions}
          selectedSessionId={selectedSessionId}
          onSessionClick={handleSessionClick}
        />
      )}

      <RequestSessionFormRepeat
        date={date}
        sessions={sessions}
        selectedSessionId={selectedSessionId}
        selectedSpecialtyId={selectedSpecialtyId}
        onRepeatChange={handleRepeatChange}
        onFrequencyChange={handleRepeatFrequencyChange}
        onCountChange={handleRepeatCountChange}
        isRepeated={isRepeated}
        selectedRepeatFrequency={selectedRepeatFrequency}
        selectedRepeatCount={selectedRepeatCount}
      />

      {!isEmpty(selectedSessionId) && (
        <Paper sx={{ p: 2, mt: 2 }}>
          <FormCheckboxField
            name="tandc"
            label={
              <Typography>
                I have read and agree to the WeFlex{" "}
                <Link href={ENV.WEFLEX_TERMS_AND_CONDITIONS_URL} color="link.main" target="_blank">
                  Terms and Conditions
                </Link>
                .
              </Typography>
            }
          />
        </Paper>
      )}
    </Box>
  );
}
