import React from "react";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import {
  FPGenderPreferenceLabel,
  PWADProfileMyPreferencesModel,
  TrainingEnvironmentLabel,
  TrainingFrequencyLabel,
} from "../../models/PWADProfile";
import CheckIconList from "../common/CheckIconList";
import ProfileCard from "../common/ProfileCard";
import ProfileCardDivider from "../common/ProfileCardDivider";
import ProfileCardTitleWithContent from "../common/ProfileCardTitleWithContent";

interface Props {
  myPreferencesData: PWADProfileMyPreferencesModel;
}

export default function PWADTrainingPreferences({ myPreferencesData }: Props): JSX.Element {
  const preferredTrainingEnvironments = myPreferencesData.preferred_training_environments.map((value) => ({
    id: value,
    name: TrainingEnvironmentLabel[value],
  }));

  return (
    <ProfileCard title="Training" Icon={FitnessCenterIcon}>
      <ProfileCardTitleWithContent
        title="Gender preference for fitness professionals"
        content={
          myPreferencesData.fp_gender_preference && FPGenderPreferenceLabel[myPreferencesData.fp_gender_preference]
        }
      />

      <ProfileCardDivider />

      <ProfileCardTitleWithContent
        title="Preferred training frequency"
        content={
          myPreferencesData.preferred_training_frequency &&
          TrainingFrequencyLabel[myPreferencesData.preferred_training_frequency]
        }
      />

      <ProfileCardDivider />

      <ProfileCardTitleWithContent
        title="Preferred training environments"
        content={<CheckIconList list={preferredTrainingEnvironments} />}
      />

      <ProfileCardDivider />

      <ProfileCardTitleWithContent
        title="Preferred training days/times"
        content={myPreferencesData.preferred_training_days_times}
      />
    </ProfileCard>
  );
}
