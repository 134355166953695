import { ReactNode } from "react";
import { TextField, TextFieldProps, Typography } from "@mui/material";
import { omit } from "lodash";
import { Controller, useFormContext } from "react-hook-form";
import OptionalValue from "../components/common/OptionalValue";

export type FormTextFieldProps = Omit<TextFieldProps, "name"> & {
  name: string;
  label?: string;
  readOnly?: boolean;
};

function FormTextField(props: FormTextFieldProps) {
  const { name, helperText } = props;

  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => {
        if (props.readOnly) {
          return (
            <Typography>
              <OptionalValue>{value}</OptionalValue>
            </Typography>
          );
        }

        // Need empty space by default for the helper text to reserve space for at least 1 line. Most of the helper
        // texts will be 1 line height, so this will avoid the form expanding / shrinking as errors are displayed.
        let helperTextValue: string | ReactNode | undefined = " ";

        if (error?.message) {
          helperTextValue = error.message;
        } else if (helperText) {
          helperTextValue = helperText;
        }

        return (
          <TextField
            value={value}
            onChange={onChange}
            error={invalid}
            onBlur={onBlur}
            inputRef={ref}
            helperText={helperTextValue}
            {...omit(props, ["defaultValue", "value", "error", "helperText", "onChange", "readOnly"])}
          />
        );
      }}
    />
  );
}

export default FormTextField;
