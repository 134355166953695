import React from "react";
import { isEmpty } from "lodash";
import ReactDOM from "react-dom";
import ReactGA from "react-ga4";
import App from "./App";
import "./config/sentry";
import ENV from "./constants/envConstants";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

if (!isEmpty(ENV.GA_MEASUREMENT_ID)) {
  ReactGA.initialize(ENV.GA_MEASUREMENT_ID!);
  ReactGA.send("pageview");
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
