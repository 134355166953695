import { useMemo } from "react";
import FacebookIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import { CardMedia, Avatar, Typography, Button, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import { isEmpty } from "lodash";
import ColumnBox from "./ColumnBox";
import fpBannerImage from "../../assets/img/fpBanner.jpeg";
import ENV from "../../constants/envConstants";
import { AvatarModel } from "../../models/Person";
import getAvatarUrl from "../../utils/getAvatarUrl";

interface Props {
  fullName: string;
  avatar: AvatarModel | undefined;
  title?: string;
  email?: string;
  facebookUrl?: string | null | undefined;
  instagramUrl?: string | null | undefined;
}
export default function ProfileBanner({
  fullName,
  avatar,
  title,
  email,
  facebookUrl,
  instagramUrl,
}: Props): JSX.Element {
  const showEmail = !isEmpty(email);
  const showSocial = !isEmpty(facebookUrl) || !isEmpty(instagramUrl);
  const showTitle = !isEmpty(title);

  const xsHeight = useMemo(() => {
    let height = 230;

    if (showTitle) {
      height += 30;
    }

    if (showEmail) {
      height += 75;
    }

    if (showSocial) {
      height += 50;
    }

    return height;
  }, [showTitle, showEmail, showSocial]);

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: { xs: `${xsHeight}px`, lg: "173px" },
      }}
    >
      <CardMedia component="img" height="100%" image={fpBannerImage} alt="Profile banner" />
      <Box
        position="absolute"
        bottom="0"
        left="0"
        bgcolor="rgba(0, 0, 0, 0.4)"
        height="100%"
        display="flex"
        flexDirection={{ xs: "column", lg: "row" }}
        width="100%"
        justifyContent={{ xs: "flex-start", lg: "space-between" }}
      >
        <Box
          display="flex"
          flexDirection={{ xs: "column", lg: "row" }}
          alignContent="center"
          alignItems="center"
          mt={{ xs: 3, lg: 10 }}
          gap={2}
          px={3}
        >
          <Avatar
            alt={fullName}
            src={getAvatarUrl(avatar)}
            sx={{
              height: "142px",
              width: "142px",
              border: "2px solid",
              borderColor: { lg: "primary.main", xs: "white" },
            }}
          />
          <ColumnBox alignItems={{ xs: "center", lg: "flex-start" }}>
            <Typography variant="h2" color="white">
              {fullName}
            </Typography>
            {showTitle && (
              <Typography variant="body1" color="white">
                {title}
              </Typography>
            )}
          </ColumnBox>
        </Box>
        <ColumnBox gap={2} p={2} justifyContent={{ xs: "flex-start", lg: "flex-end" }}>
          {showSocial && (
            <Box display="flex" justifyContent="center" gap={2} flex={1}>
              {instagramUrl && (
                <IconButton
                  href={instagramUrl}
                  target="_blank"
                  aria-label="Twitter"
                  sx={{ bgcolor: "white", height: "35px", width: "35px" }}
                >
                  <InstagramIcon sx={{ color: "text.secondary", fontSize: "22px" }} />
                </IconButton>
              )}
              {facebookUrl && (
                <IconButton
                  href={facebookUrl}
                  target="_blank"
                  aria-label="Facebook"
                  sx={{ bgcolor: "white", height: "35px", width: "35px" }}
                >
                  <FacebookIcon fontSize="large" sx={{ color: "text.secondary", fontSize: "22px" }} />
                </IconButton>
              )}
            </Box>
          )}

          {showEmail && (
            <Box display="flex" justifyContent="center">
              <Button
                fullWidth={false}
                variant="contained"
                size="large"
                href={`mailto:${encodeURIComponent(email!)}?cc=${encodeURIComponent(ENV.CC_EMAIL_ADDRESS!)}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Email
              </Button>
            </Box>
          )}
        </ColumnBox>
      </Box>
    </Box>
  );
}
