import { SyntheticEvent, useState } from "react";
import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import { isString } from "lodash";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { ControllerFieldState, useFormContext } from "react-hook-form";
import { ControllerRenderProps } from "react-hook-form/dist/types/controller";
import ENV from "../constants/envConstants";

export type GooglePlace = {
  place_id: string;
  description: string;
  reference: string;
};

interface Props {
  label?: string;
  required?: boolean;
  helperText?: string;
  preciseAddressResultsOnly?: boolean;
  latitudeFieldName: string;
  longitudeFieldName: string;
  field: ControllerRenderProps;
  fieldState: ControllerFieldState;
}

function GooglePlacesAutocompleteControl({
  label,
  required = false,
  preciseAddressResultsOnly = true,
  latitudeFieldName,
  longitudeFieldName,
  field,
  fieldState: { error, invalid },
}: Props) {
  const [inputValue, setInputValue] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  const { setValue } = useFormContext();

  const { placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading } = usePlacesService({
    apiKey: ENV.GOOGLE_MAPS_API_KEY,
    debounce: 1000,
    options: {
      // See https://developers.google.com/maps/documentation/javascript/supported_types#table3
      types: preciseAddressResultsOnly ? ["address"] : ["geocode"],
      // See https://developers.google.com/maps/documentation/javascript/reference/places-service#PlaceResult
      fields: ["place_id", "types"],
      componentRestrictions: { country: ["aus", "nz"] },
    },
  });

  const handleInputChange = (event: SyntheticEvent, value: string) => {
    setInputValue(value);

    if (!value) {
      return;
    }

    getPlacePredictions({ input: value });
  };

  const handleChange = (event: SyntheticEvent, value: string | GooglePlace | null) => {
    if (!value || isString(value) || !value.place_id) {
      setValue(field.name, "");
      setValue(latitudeFieldName, null);
      setValue(longitudeFieldName, null);

      return;
    }

    setValue(field.name, value.description, { shouldValidate: true, shouldDirty: true, shouldTouch: true });

    placesService?.getDetails({ placeId: value.place_id, fields: ["geometry.location"] }, (placeResult: any) => {
      setValue(latitudeFieldName, placeResult.geometry.location.lat());
      setValue(longitudeFieldName, placeResult.geometry.location.lng());
    });
  };

  const handleClickEdit = () => {
    setIsEditing(true);

    setValue(field.name, "");
    setValue(latitudeFieldName, null);
    setValue(longitudeFieldName, null);
  };

  if (!isEditing) {
    return (
      <TextField
        value={field.value}
        label={label}
        required={required}
        error={invalid}
        helperText={error?.message}
        onClick={handleClickEdit}
      />
    );
  }

  return (
    <Autocomplete
      inputValue={inputValue}
      freeSolo
      getOptionLabel={(option: GooglePlace) => option.description ?? ""}
      options={placePredictions}
      loading={isPlacePredictionsLoading}
      onInputChange={handleInputChange}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          required={required}
          onBlur={field.onBlur}
          inputRef={field.ref}
          error={invalid}
          helperText={error?.message}
          fullWidth
          autoFocus
          placeholder="For example: 500 Kent Street, Sydney NSW, Australia."
        />
      )}
      renderOption={(renderOptionProps, option: GooglePlace) => (
        <li {...renderOptionProps}>
          <Grid container alignItems="center">
            <Grid item xs>
              <Typography variant="body2" color="text.secondary">
                {option.description}
              </Typography>
            </Grid>
          </Grid>
        </li>
      )}
    />
  );
}

export default GooglePlacesAutocompleteControl;
