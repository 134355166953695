const isoLanguages: { [key in string]: { name: string } } = {
  ALBANIAN: { name: "Albanian" },
  AMHARIC: { name: "Amharic" },
  ARABIC: { name: "Arabic" },
  ARMENIAN: { name: "Armenian" },
  AUSLAN: { name: "Auslan" },
  AZERI: { name: "Azeri (Latin)" },
  BELARUSIAN: { name: "Belarusian" },
  BENGALI: { name: "Bengali" },
  BULGARIAN: { name: "Bulgarian" },
  CHINESE: { name: "Chinese (Traditional) legacy" },
  CROATIA: { name: "Croatian" },
  CZECH: { name: "Czech" },
  DANISH: { name: "Danish" },
  DIVEHI: { name: "Divehi" },
  ENGLISH: { name: "English" },
  ESTONIAN: { name: "Estonian" },
  FAROESE: { name: "Faroese" },
  FRENCH: { name: "French" },
  FRISIAN: { name: "Frisian" },
  GEAROGIAN: { name: "Georgian" },
  GERMAN: { name: "German" },
  GREEK: { name: "Greek" },
  GREENLANDIC: { name: "Greenlandic" },
  HEBREW: { name: "Hebrew" },
  HUNGARIAN: { name: "Hungarian" },
  ICELANDIC: { name: "Icelandic" },
  INDONESIAN: { name: "Indonesian" },
  IRISH: { name: "Irish" },
  ITALIAN: { name: "Italian" },
  JAPANESE: { name: "Japanese" },
  KAZAKH: { name: "Kazakh" },
  KHMER: { name: "Khmer" },
  KINYARWANDA: { name: "Kinyarwanda" },
  KISWAHILI: { name: "Kiswahili" },
  KOREAN: { name: "Korean" },
  KYRGYZ: { name: "Kyrgyz" },
  LAO: { name: "Lao" },
  LATVIAN: { name: "Latvian" },
  LITHUANIAN: { name: "Lithuanian" },
  LUXEMBOURGISH: { name: "Luxembourgish" },
  MACEDONIAN: { name: "Macedonian (fyrom)" },
  MALAY: { name: "Malay" },
  MALTESE: { name: "Maltese" },
  MAORI: { name: "Maori" },
  MOHAWK: { name: "Mohawk" },
  MONGOLIAN: { name: "Mongolian (Cyrillic)" },
  NEPALI: { name: "Nepali" },
  OCCITAN: { name: "Occitan" },
  PASHTO: { name: "Pashto" },
  PERSIAN: { name: "Persian" },
  POLISH: { name: "Polish" },
  PORTUGUESE: { name: "Portuguese" },
  ROMANIAN: { name: "Romanian" },
  ROMANSH: { name: "Romansh" },
  SAMI: { name: "Sami (Southern)" },
  SERBIAN: { name: "Serbian (Latin)" },
  SETSWANA: { name: "Setswana" },
  SINHALA: { name: "Sinhala" },
  SLOVAK: { name: "Slovak" },
  SLOVANIAN: { name: "Slovenian" },
  SORBIAN: { name: "Upper sorbian" },
  SPANISH: { name: "Spanish" },
  SWEDISH: { name: "Swedish" },
  SYRIAC: { name: "Syriac" },
  TAJIK: { name: "Tajik (Cyrillic)" },
  TAMAZIGHT: { name: "Tamazight (Latin)" },
  TELUGU: { name: "Telugu" },
  THAI: { name: "Thai" },
  TURKISH: { name: "Turkish" },
  TURKMEN: { name: "Turkmen" },
  UKRAINIAN: { name: "Ukrainian" },
  URDU: { name: "Urdu" },
  UZBEK: { name: "Uzbek (Latin)" },
  VIETNAMESE: { name: "Vietnamese" },
  WELSH: { name: "Welsh" },
  WOLOF: { name: "Wolof" },
  YAKUT: { name: "Yakut" },
  YI: { name: "Yi" },
  YORUBA: { name: "Yoruba" },
};

export default isoLanguages;
