import React from "react";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import { PWADProfileMyUniqueNeedsModel } from "../../models/PWADProfile";
import ColumnBox from "../common/ColumnBox";
import PrivateFileLink from "../common/PrivateFileLink";
import ProfileCard from "../common/ProfileCard";
import ProfileCardDivider from "../common/ProfileCardDivider";
import ProfileCardTitleWithContent from "../common/ProfileCardTitleWithContent";

interface Props {
  myUniqueNeedsData: PWADProfileMyUniqueNeedsModel;
}

export default function PWADSupportConsiderations({ myUniqueNeedsData }: Props) {
  return (
    <ProfileCard title="Support Considerations" Icon={VolunteerActivismIcon}>
      <ColumnBox gap={2}>
        <ProfileCardTitleWithContent
          title="Behaviour support notes"
          content={myUniqueNeedsData.unique_needs_behaviour_support}
        />

        {!!myUniqueNeedsData.unique_needs_behaviour_support_private_file && (
          <PrivateFileLink
            privateFileId={myUniqueNeedsData.unique_needs_behaviour_support_private_file}
            viewFileText="View Behaviour Support Plan"
          />
        )}
      </ColumnBox>

      <ProfileCardDivider />

      <ColumnBox gap={2}>
        <ProfileCardTitleWithContent
          title="Sensory support notes"
          content={myUniqueNeedsData.unique_needs_sensory_activity}
        />

        {!!myUniqueNeedsData.unique_needs_sensory_private_file && (
          <PrivateFileLink
            privateFileId={myUniqueNeedsData.unique_needs_sensory_private_file}
            viewFileText="View Sensory Needs Profile"
          />
        )}
      </ColumnBox>

      <ProfileCardDivider />

      <ColumnBox gap={2}>
        <ProfileCardTitleWithContent
          title="Seizure support notes"
          content={myUniqueNeedsData.unique_needs_seizure_activity}
        />

        {!!myUniqueNeedsData.unique_needs_seizure_private_file && (
          <PrivateFileLink
            privateFileId={myUniqueNeedsData.unique_needs_seizure_private_file}
            viewFileText="View Epilepsy Management Plan"
          />
        )}
      </ColumnBox>
    </ProfileCard>
  );
}
