import { Dispatch, SetStateAction, useEffect } from "react";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box, Button, CircularProgress, Grid, Paper, Typography } from "@mui/material";
import { RouteComponentProps, generatePath, useHistory, useParams } from "react-router-dom";
import CurrentStepStatusAlert from "./CurrentStepStatusAlert";
import { useGetFPProfileAboutMe } from "../../../api/queries/FPProfile";
import ENV from "../../../constants/envConstants";
import { interviewStepNumber } from "../../../constants/fpProfileConstants";
import * as ROUTES from "../../../constants/routes";
import { FPProfileStatus } from "../../../models/ProfileBase";
import { useAuth } from "../../../utils/AuthProvider";
import { getFPProfileIsLive, getUserFPProfileStatus } from "../../../utils/person";
import ColumnBox from "../../common/ColumnBox";
import ErrorAlert from "../../common/ErrorAlert";

interface Props extends RouteComponentProps {
  setSavedSnackbarMessage: Dispatch<SetStateAction<string>>;
}
export default function Interview({ setSavedSnackbarMessage }: Props): JSX.Element {
  const { currentUser } = useAuth();
  const { profileId } = useParams<{ profileId: string }>();
  const history = useHistory();
  const status = getUserFPProfileStatus(currentUser);
  const { data: aboutMeData, isLoading: aboutMeLoading, error: aboutMeError } = useGetFPProfileAboutMe(profileId);
  const isProfileLive = getFPProfileIsLive(aboutMeData);
  const isReadyForInterview = status === FPProfileStatus.TRAINING_COMPLETED;

  useEffect(() => {
    if (!aboutMeData) {
      return;
    }

    if (isProfileLive) {
      history.push(generatePath(ROUTES.FP_PROFILE_ROOT, { profileId }));
    }
  }, [aboutMeData, isProfileLive]);

  if (aboutMeLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (aboutMeError) {
    return <ErrorAlert message="Profile details failed to load" />;
  }

  return (
    <Grid container spacing={2} py={2}>
      <Grid item xs={12}>
        <CurrentStepStatusAlert stepNumber={interviewStepNumber} status={status} isLive={!!isProfileLive} />

        <Typography variant="h1" mb={3}>
          My{" "}
          <Typography display="inline" variant="h1" component="span" color="link.main">
            Interview.
          </Typography>
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <ColumnBox component={Paper} p={4} mb={2}>
          <Grid item mb={3}>
            <Typography variant="body1" mb={3}>
              The final step is to book an interview with our fitness relationship manager so we can confirm you&apos;re
              a suitable WeFlex trainer and start discussing your first client opportunities. Once you have booked an
              interview there is no need to book one again.
            </Typography>
          </Grid>
          <Grid item>
            <Button
              component="a"
              href={ENV.FP_BOOK_INTERVIEW_URI}
              variant="contained"
              target="_blank"
              disabled={!isReadyForInterview}
              endIcon={<OpenInNewIcon fontSize="small" />}
            >
              Book Interview
            </Button>
          </Grid>
        </ColumnBox>
      </Grid>
    </Grid>
  );
}
