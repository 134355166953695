import { useEffect, useState } from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DownloadIcon from "@mui/icons-material/Download";
import { Box, CircularProgress, Grid, Typography, IconButton } from "@mui/material";
import * as Sentry from "@sentry/browser";
import { isEmpty } from "lodash";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { useHistory, useParams } from "react-router-dom";
import ColumnBox from "./ColumnBox";
import ErrorAlert from "./ErrorAlert";
import { useGetPrivateFileUrl } from "../../api/mutations/PrivateFile";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function PrivateDocumentViewer() {
  const history = useHistory();

  const { fileId } = useParams<{ fileId: string }>();

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const {
    mutate: getPrivateFileUrl,
    mutateAsync: getPrivateFileUrlAsync,
    data: privateFileUrlData,
    isLoading: privateFileUrlLoading,
    error: privateFileUrlError,
  } = useGetPrivateFileUrl();

  useEffect(() => {
    if (!isEmpty(fileId)) {
      getPrivateFileUrl(
        { fileId },
        {
          onError: (error) => {
            Sentry.captureException(error);
          },
        },
      );
      setNumPages(null);
    }
  }, [fileId]);

  const handleDocumentLoadSuccess = ({ numPages: documentNumPages }: any) => {
    setNumPages(documentNumPages);
  };

  const handleNextPage = () => {
    setPageNumber((prevPageNumber) => prevPageNumber + 1);
  };

  const handlePrevPage = () => {
    setPageNumber((prevPageNumber) => prevPageNumber - 1);
  };

  const handleGoBack = () => {
    history.goBack();
  };

  const handlePressDownload = async () => {
    try {
      const privateFileUrlResponse = await getPrivateFileUrlAsync({ fileId });

      window.open(privateFileUrlResponse.data.url, "_blank");
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
    }
  };

  const fullFileUrl = privateFileUrlData?.data.url;

  if (privateFileUrlLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (privateFileUrlError) {
    return <ErrorAlert message="Document failed to load" />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" px={1}>
          <Box
            component="span"
            display="flex"
            alignItems="center"
            onClick={handleGoBack}
            color="link.main"
            width="min-content"
            sx={{
              cursor: "pointer",
            }}
          >
            <ChevronLeftIcon sx={{ width: 30, height: 30 }} />
            <Typography sx={{ fontSize: "18px", fontWeight: 600 }}>Back</Typography>
          </Box>

          <Box
            component="span"
            display="flex"
            alignItems="center"
            onClick={handlePressDownload}
            color="link.main"
            width="min-content"
            sx={{
              cursor: "pointer",
            }}
          >
            <DownloadIcon sx={{ width: 22, height: 22, marginRight: "6px" }} />
            <Typography sx={{ fontSize: "18px", fontWeight: 600 }}>Download</Typography>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} display="flex" alignItems="center" flexDirection="column">
        <Document file={fullFileUrl} onLoadSuccess={handleDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} />
        </Document>

        {!!numPages && (
          <ColumnBox p={2} alignItems="center">
            <Typography variant="body2">
              Page {pageNumber} of {numPages}
            </Typography>
            <Box display="flex" p={2} gap={1}>
              <IconButton color="primary" onClick={handlePrevPage} disabled={pageNumber === 1}>
                <ChevronLeftIcon sx={{ width: 24, height: 24 }} />
              </IconButton>
              <IconButton color="primary" onClick={handleNextPage} disabled={pageNumber === numPages}>
                <ChevronRightIcon sx={{ width: 24, height: 24 }} />
              </IconButton>
            </Box>
          </ColumnBox>
        )}
      </Grid>
    </Grid>
  );
}
