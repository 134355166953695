import { useMemo } from "react";
import { Tabs, useMediaQuery, TabsProps } from "@mui/material";
import { useLocation } from "react-router-dom";
import LinkTab from "./LinkTab";
import theme from "../../styles/_theme";

interface Props extends TabsProps {
  items: { label: string; path: string; disabled?: boolean }[];
}

export default function NavTabs({ items, ...restProps }: Props): JSX.Element {
  const location = useLocation();

  const value = useMemo(
    () =>
      Math.max(
        items.findIndex((item) => item.path === location.pathname),
        0,
      ),
    [location.pathname, items],
  );

  const mobileMode = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Tabs value={value} aria-label="Navigation tabs" variant={mobileMode ? "fullWidth" : "standard"} {...restProps}>
      {items.map(({ label, path, disabled, ...restTabItemProps }) => (
        <LinkTab {...restTabItemProps} key={path} label={label} to={{ pathname: path }} disabled={disabled} />
      ))}
    </Tabs>
  );
}
