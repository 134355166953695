import { ErrorOutline } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Link,
} from "@mui/material";
import ENV from "../../../../constants/envConstants";

interface Props {
  open: boolean;
  onOk(): void;
  onClose(): void;
}

export default function BookingCancelDialog({ open, onOk, onClose }: Props) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
    >
      <DialogTitle id="alert-dialog-title">
        <Box display="flex" alignItems="center">
          <ErrorOutline sx={{ color: "error.main", mr: 1 }} /> Cancel this booking?
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography mb={2}>Cancelling this booking will remove it permanently from your calendar.</Typography>
          <Typography>
            <Link href={ENV.WEFLEX_TERMS_AND_CONDITIONS_URL} target="_blank" variant="link1" color="link.main">
              Fees may occur if cancellation period is less than 48 hours.
            </Link>
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Go Back</Button>
        <Button onClick={onOk} autoFocus>
          Yes, Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
