import { useMemo } from "react";
import { Box, CircularProgress, Container, Paper, Typography } from "@mui/material";
import { isEmpty, isNil } from "lodash";
import { useQuery } from "react-query";
import MyNetworkFPProfile from "./MyNetworkFPProfile";
import { ErrorResponse } from "../../../api/Generic";
import { FPNetworkProfileModel } from "../../../models/FPProfile";
import ErrorAlert from "../../common/ErrorAlert";

function MyNetwork() {
  const {
    data: myNetworkData,
    isLoading: isMyNetworkDataLoading,
    error,
  } = useQuery<{ fp_profiles: (FPNetworkProfileModel | null)[] }, ErrorResponse>("/pwadprofile/matches/");

  const myNetworkFPs = useMemo(
    () => (myNetworkData?.fp_profiles.filter((profile) => !isNil(profile)) as FPNetworkProfileModel[]) ?? [],
    [myNetworkData],
  );

  if (isMyNetworkDataLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  function renderNetworkFPs() {
    if (error) {
      return <ErrorAlert message="Matched fitness professionals failed to load" />;
    }

    if (isEmpty(myNetworkFPs)) {
      return (
        <Paper sx={{ p: 4, display: "flex", justifyContent: "center" }} elevation={0}>
          <Typography>No matches yet. Come back here once a person from WeFlex has contacted you.</Typography>
        </Paper>
      );
    }

    return myNetworkFPs.map((fpProfile) => <MyNetworkFPProfile key={fpProfile.id} fpProfile={fpProfile} />);
  }

  return (
    <Container maxWidth="lg">
      <Box py={4}>
        <Typography variant="h1">
          Make a{" "}
          <Typography display="inline" variant="h1" component="span" color="link.main">
            Booking.
          </Typography>
        </Typography>

        <Typography variant="h3" pt={3}>
          View and book a session with a WeFlex fitness professional that best suits your needs.
        </Typography>
      </Box>
      {renderNetworkFPs()}
    </Container>
  );
}

export default MyNetwork;
