import React from "react";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MailIcon from "@mui/icons-material/Mail";
import PeopleIcon from "@mui/icons-material/People";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/PhoneAndroid";
import { Box } from "@mui/material";
import { format, parseISO } from "date-fns";
import { trim } from "lodash";
import {
  NDISFundingCategoryLabel,
  NDISSupportPersonTypeLabel,
  PWADProfileMyPreferencesModel,
} from "../../models/PWADProfile";
import BooleanValueDisplayBox from "../common/BooleanValueDisplayBox";
import ColumnBox from "../common/ColumnBox";
import ProfileCard from "../common/ProfileCard";
import ProfileCardDivider from "../common/ProfileCardDivider";
import ProfileCardIconWithContent from "../common/ProfileCardIconWithContent";
import ProfileCardTitleWithContent from "../common/ProfileCardTitleWithContent";

interface Props {
  myPreferencesData: PWADProfileMyPreferencesModel;
}

export default function PWADNDISPreferences({ myPreferencesData }: Props): JSX.Element {
  const showNdisFields = myPreferencesData.ndis_participant === true;

  const ndisPlanStartDate = myPreferencesData.ndis_plan_start_date
    ? format(parseISO(myPreferencesData.ndis_plan_start_date), "dd MMM yyyy")
    : "";

  const ndisPlanEndDate = myPreferencesData.ndis_plan_end_date
    ? format(parseISO(myPreferencesData.ndis_plan_end_date), "dd MMM yyyy")
    : "";

  const ndisSupportPersonFullName = trim(
    `${myPreferencesData.ndis_support_person_first_name} ${myPreferencesData.ndis_support_person_last_name}`,
  );

  const showNdisSupportPersonCard =
    showNdisFields &&
    !!(
      ndisSupportPersonFullName ||
      myPreferencesData.ndis_support_person_type ||
      myPreferencesData.ndis_support_person_email ||
      myPreferencesData.ndis_support_person_phone ||
      myPreferencesData.ndis_support_person_address
    );

  const iconColor = "grey.600";

  return (
    <>
      <ProfileCard title="NDIS" Icon={CorporateFareIcon}>
        <ProfileCardTitleWithContent
          title="Are you an NDIS participant?"
          content={
            <BooleanValueDisplayBox
              value={myPreferencesData.ndis_participant ?? null}
              trueLabel="Yes, I am an NDIS participant"
              falseLabel="No, I am not an NDIS participant"
              nullLabel="I have not yet indicated whether I am an NDIS participant"
            />
          }
        />

        {showNdisFields && (
          <Box mt={4}>
            <ProfileCardTitleWithContent
              title="NDIS participant number"
              content={myPreferencesData.ndis_participant_number}
            />

            <ProfileCardDivider />

            <ProfileCardTitleWithContent title="NDIS plan start date" content={ndisPlanStartDate} />

            <ProfileCardDivider />

            <ProfileCardTitleWithContent title="NDIS plan end date" content={ndisPlanEndDate} />

            <ProfileCardDivider />

            <ProfileCardTitleWithContent
              title="NDIS plan nominee and/or guardian"
              content={myPreferencesData.ndis_plan_nominee_guardian}
            />

            <ProfileCardDivider />

            <ProfileCardTitleWithContent
              title="NDIS funding category"
              content={
                myPreferencesData.ndis_funding_category
                  ? NDISFundingCategoryLabel[myPreferencesData.ndis_funding_category]
                  : ""
              }
            />
          </Box>
        )}
      </ProfileCard>

      {showNdisSupportPersonCard && (
        <ProfileCard title="NDIS Support Person" Icon={ContactPhoneIcon}>
          <ColumnBox gap={3}>
            {!!myPreferencesData.ndis_support_person_type && (
              <ProfileCardIconWithContent
                content={
                  myPreferencesData.ndis_support_person_type
                    ? NDISSupportPersonTypeLabel[myPreferencesData.ndis_support_person_type]
                    : ""
                }
                Icon={PeopleIcon}
                iconColor={iconColor}
              />
            )}

            {!!ndisSupportPersonFullName && (
              <ProfileCardIconWithContent content={ndisSupportPersonFullName} Icon={PersonIcon} iconColor={iconColor} />
            )}

            {!!myPreferencesData.ndis_support_person_email && (
              <ProfileCardIconWithContent
                content={myPreferencesData.ndis_support_person_email}
                Icon={MailIcon}
                iconColor={iconColor}
              />
            )}

            {!!myPreferencesData.ndis_support_person_phone && (
              <ProfileCardIconWithContent
                content={myPreferencesData.ndis_support_person_phone}
                Icon={PhoneIcon}
                iconColor={iconColor}
              />
            )}

            {!!myPreferencesData.ndis_support_person_address && (
              <ProfileCardIconWithContent
                content={myPreferencesData.ndis_support_person_address?.place}
                Icon={LocationOnIcon}
                iconColor={iconColor}
              />
            )}
          </ColumnBox>
        </ProfileCard>
      )}
    </>
  );
}
