/* eslint-disable @typescript-eslint/naming-convention */
import { Box, Grid, Paper, Typography } from "@mui/material";
import LineItemsTable from "./LineItemsTable";
import { InvoiceModel } from "../../../models/Invoice";

interface Props {
  invoice: InvoiceModel;
}

function InvoiceDetail({ invoice }: Props): JSX.Element {
  const {
    invoice_date,
    invoice_number,
    biller_name,
    biller_address1,
    biller_address2,
    biller_abn,
    weflex_company_name,
    weflex_address1,
    weflex_address2,
    line_items,
    biller_account_name,
    biller_account_bsb,
    biller_account_number,
    gst_amount,
    items_amount,
    total_amount,
  } = invoice;

  return (
    <Grid>
      <Grid item>
        <Box component={Paper} p={4}>
          <Grid item>
            <Typography variant="h3" display="inline">
              Date:{" "}
            </Typography>
            <Typography variant="body2" display="inline">
              {invoice_date}
            </Typography>
          </Grid>

          <Grid item mb={3}>
            <Typography variant="h3" display="inline">
              Invoice No:{" "}
            </Typography>
            <Typography variant="body2" display="inline">
              {invoice_number}
            </Typography>
          </Grid>

          <Grid item mb={3}>
            <Typography variant="body2">{biller_name}</Typography>
            <Typography variant="body2">{biller_address1}</Typography>
            <Typography variant="body2">{biller_address2}</Typography>
            <Typography variant="body2">ABN: {biller_abn}</Typography>
          </Grid>

          <Grid item mb={3}>
            <Typography variant="h3">BILL TO</Typography>
            <Typography variant="body2">{weflex_company_name}</Typography>
            <Typography variant="body2">{weflex_address1}</Typography>
            <Typography variant="body2">{weflex_address2}</Typography>
          </Grid>

          <LineItemsTable
            lineItems={line_items}
            gstAmount={gst_amount}
            itemsAmount={items_amount}
            totalAmount={total_amount}
          />

          <Grid item my={3}>
            <Typography variant="h3">TERMS: 14 Days</Typography>
          </Grid>

          <Grid item mb={3}>
            <Typography variant="h3">Payment Details</Typography>
            <Typography variant="body2">Account Name: {biller_account_name}</Typography>
            <Typography variant="body2">BSB: {biller_account_bsb}</Typography>
            <Typography variant="body2">Account Number: {biller_account_number}</Typography>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}

export default InvoiceDetail;
