import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import * as Sentry from "@sentry/browser";
import { Controller, FormProvider, SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { useQuery } from "react-query";
import * as yup from "yup";
import InvoiceDetail from "./InvoiceDetail";
import { ErrorResponse, isErrorResponse } from "../../../api/Generic";
import { useEditInvoiceStatus } from "../../../api/mutations/Invoice";
import {
  GENERIC_REQUEST_ERROR_MESSAGE,
  GENERIC_REQUIRED_VALIDATION_ERROR_MESSAGE,
  GENERIC_SUBMIT_VALIDATION_ERROR_MESSAGE,
} from "../../../constants/validation";
import { InvoiceEditModel, InvoiceModel, InvoiceStatusEnum } from "../../../models/Invoice";
import { useAuth } from "../../../utils/AuthProvider";
import getErrorMessages from "../../../utils/getErrorMessages";
import ErrorAlert from "../../common/ErrorAlert";
import ErrorDialog from "../../common/ErrorDialog";
import UnsavedChangesPrompt from "../../common/UnsavedChangesPrompt";

type InvoiceFormInput = InvoiceEditModel;

interface InvoiceReactHookFormProps {
  defaultValues: InvoiceEditModel;
  onSubmit: SubmitHandler<InvoiceFormInput>;
  onError: SubmitErrorHandler<InvoiceFormInput>;
}

const InvoiceFormSchema: yup.SchemaOf<InvoiceFormInput> = yup.object({
  status: yup
    .mixed()
    .oneOf(Object.values(InvoiceStatusEnum), "Please select one of the options.")
    .required(GENERIC_REQUIRED_VALIDATION_ERROR_MESSAGE),
});

function InvoiceReactHookForm({ defaultValues, onSubmit, onError }: InvoiceReactHookFormProps): JSX.Element {
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const formMethods = useForm<InvoiceFormInput>({
    resolver: yupResolver(InvoiceFormSchema),
    mode: "onTouched",
    criteriaMode: "firstError",
    defaultValues: defaultValues,
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting, isDirty },
    getValues,
  } = formMethods;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const theme: Theme = useTheme();
  const breakpointMD = useMediaQuery(theme.breakpoints.up("md"));

  const [showSubmitModal, setShowSubmitModal] = useState(false);

  const onFormSubmit = () => {
    setShowSubmitModal(true);
  };

  const onConfirmSubmit = async () => {
    setIsFormSubmitting(true);
    await onSubmit(getValues());
    setIsFormSubmitting(false);
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onFormSubmit, onError)}>
        <Grid container spacing={2} py={2}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="status"
              render={({ field: { onChange, onBlur, value }, fieldState: { error, invalid } }) => (
                <FormControl error={invalid} sx={{ display: "flex" }}>
                  <RadioGroup aria-labelledby="status" value={value} onChange={onChange} onBlur={onBlur}>
                    <Grid container gap={3} justifyContent="flex-end">
                      <Grid item>
                        <FormControlLabel
                          value={InvoiceStatusEnum.APPROVED}
                          control={<Radio />}
                          label="Approve, and send for processing"
                        />
                      </Grid>

                      <Grid item>
                        <FormControlLabel value={InvoiceStatusEnum.DECLINED} control={<Radio />} label="Decline" />
                      </Grid>
                    </Grid>
                  </RadioGroup>

                  {!!error?.message && <FormHelperText error>{error.message}</FormHelperText>}
                </FormControl>
              )}
            />
            <Grid item container justifyContent="flex-end">
              <Grid item mt={3}>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  size={breakpointMD ? "medium" : "small"}
                  sx={breakpointMD ? undefined : { marginTop: 2 }}
                  loading={isSubmitting}
                  disabled={isSubmitting || !!defaultValues.status}
                >
                  Submit
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>

      <Dialog
        open={showSubmitModal}
        onClose={() => {
          setShowSubmitModal(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Update Invoice</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please note this action cannot be undone. Are you sure you want to submit?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowSubmitModal(false);
            }}
          >
            Cancel
          </Button>
          <LoadingButton loading={isFormSubmitting} onClick={onConfirmSubmit} autoFocus>
            Submit
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <UnsavedChangesPrompt showPrompt={isDirty} />
    </FormProvider>
  );
}

interface EditProps {
  invoiceId: string;
  setSavedSnackbarMessage: Dispatch<SetStateAction<string>>;
}

function EditInvoice({ invoiceId, setSavedSnackbarMessage }: EditProps): JSX.Element {
  const { mutateAsync: editInvoiceStatus } = useEditInvoiceStatus(invoiceId);
  const {
    data: invoiceData,
    isLoading: invoiceLoading,
    error: invoiceError,
    refetch: invoiceRefetch,
  } = useQuery<InvoiceModel, ErrorResponse>(`/invoice/${invoiceId}`);

  const { refetchCurrentUser } = useAuth();

  const [errorDialogMessages, setErrorDialogMessages] = useState<Array<string>>([]);

  const defaultValues = useMemo((): InvoiceFormInput | null => {
    if (!invoiceData) {
      return null;
    }

    return invoiceData;
  }, [invoiceData]);

  const onSubmit: SubmitHandler<InvoiceFormInput> = async (values) => {
    try {
      await editInvoiceStatus({ status: values.status });
      await invoiceRefetch();
      await refetchCurrentUser();

      setSavedSnackbarMessage("Invoice updated successfully.");
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);

      if (isErrorResponse(error)) {
        setErrorDialogMessages(getErrorMessages(error.response.data?.message));
      } else {
        setErrorDialogMessages([GENERIC_REQUEST_ERROR_MESSAGE]);
      }
    }
  };

  const onError: SubmitErrorHandler<InvoiceFormInput> = (error) => {
    console.error({ error });
    setErrorDialogMessages([GENERIC_SUBMIT_VALIDATION_ERROR_MESSAGE]);
  };

  if (invoiceLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (invoiceError) {
    return <ErrorAlert message="Invoice failed to load" />;
  }

  if (!invoiceData || !defaultValues) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Grid item xs={12} mb={3}>
        <Typography variant="body1">
          Below is a <strong>draft</strong> invoice which has been generated for your conveience. Please review and
          approve the invoice to have it sent to WeFlex for processing, otherwise you can decline and create your own
          invoice.
        </Typography>
        <Grid item xs={12}>
          <InvoiceReactHookForm defaultValues={defaultValues} onSubmit={onSubmit} onError={onError} />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <InvoiceDetail invoice={invoiceData} />
      </Grid>

      {!!errorDialogMessages.length && (
        <ErrorDialog
          title="Unable to update invoice"
          messages={errorDialogMessages}
          onClose={() => setErrorDialogMessages([])}
        />
      )}
    </>
  );
}

export default EditInvoice;
