import React, { ReactNode } from "react";
import { Typography, Box, Divider, Paper, CircularProgress, Button } from "@mui/material";
import { isEmpty, isNil } from "lodash";
import { useQuery } from "react-query";
import { generatePath, Link } from "react-router-dom";
import SessionReportItemStatus from "./SessionReportItemStatus";
import { ErrorResponse } from "../../../api/Generic";
import * as ROUTES from "../../../constants/routes";
import { BookingWithSessionReportModel } from "../../../models/Booking";
import ErrorAlert from "../../common/ErrorAlert";
import SessionRow from "../../common/SessionRow";
import { SessionReportReturnToEnum, SessionReportStepsEnum } from "../../session-report-details/SessionReportConstants";

interface Props {
  showCompletedReports: boolean;
}

export default function SessionList({ showCompletedReports }: Props): JSX.Element {
  const {
    data: bookingWithSessionReportData,
    isLoading,
    error,
  } = useQuery<{ bookings: BookingWithSessionReportModel[] }, ErrorResponse>(
    `/booking/sessions/?is_session_report_completed=${showCompletedReports}`,
  );

  if (isLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <ErrorAlert message="Session reports failed to load" />;
  }

  if (isEmpty(bookingWithSessionReportData?.bookings)) {
    const emptyMessage = showCompletedReports
      ? "You have no session reports that have been completed"
      : "You have no session reports to complete";

    return (
      <Paper sx={{ p: 4, display: "flex", justifyContent: "center", mt: 2 }} elevation={0}>
        <Typography>{emptyMessage}</Typography>
      </Paper>
    );
  }

  function renderActions(bookingWithSessionReport: BookingWithSessionReportModel): ReactNode {
    const sessionReportDetailsPath = generatePath(ROUTES.SESSION_REPORT_DETAILS, {
      bookingId: bookingWithSessionReport.id,
      activeStep: SessionReportStepsEnum.GOALS,
      returnTo: showCompletedReports
        ? SessionReportReturnToEnum.MY_SESSION_REPORTS_COMPLETED
        : SessionReportReturnToEnum.MY_SESSION_REPORTS_TO_DO,
    });

    let actionButtonLabel;

    if (isNil(bookingWithSessionReport.session_report)) {
      actionButtonLabel = "Start";
    } else if (bookingWithSessionReport.session_report!.status === "IN_PROGRESS") {
      actionButtonLabel = "Continue";
    } else {
      actionButtonLabel = "View";
    }

    return (
      <Box display="flex" flex={{ lg: 0.8, xs: 1 }} justifyContent="space-between" alignItems="center">
        <SessionReportItemStatus bookingWithSessionReport={bookingWithSessionReport} />

        <Button variant="contained" component={Link} to={sessionReportDetailsPath} sx={{ minWidth: 140 }}>
          {actionButtonLabel}
        </Button>
      </Box>
    );
  }

  return (
    <Box mt={2}>
      {bookingWithSessionReportData?.bookings.map((bookingWithSessionReport, index, array) => (
        <React.Fragment key={bookingWithSessionReport.id}>
          <SessionRow
            person={bookingWithSessionReport.pwad_profile.person}
            date={bookingWithSessionReport.session.date}
            startTime={bookingWithSessionReport.session.start_time}
            location={bookingWithSessionReport.session.session_location}
            profileLinkUrl={generatePath(ROUTES.FP_VIEW_PWAD_PROFILE, {
              pwadProfileId: bookingWithSessionReport.pwad_profile.id,
            })}
            renderActions={() => renderActions(bookingWithSessionReport)}
          />
          {index < array.length - 1 && <Divider />}
        </React.Fragment>
      ))}
    </Box>
  );
}
