import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import { PWADProfileAboutMeModel } from "../../models/PWADProfile";
import ProfileCard from "../common/ProfileCard";
import ProfileCardDivider from "../common/ProfileCardDivider";
import ProfileCardTitleWithContent from "../common/ProfileCardTitleWithContent";

interface Props {
  aboutMeData: PWADProfileAboutMeModel;
}

export default function PWADMotivations({ aboutMeData }: Props) {
  return (
    <ProfileCard title="Motivations" Icon={EmojiEmotionsIcon}>
      <ProfileCardTitleWithContent title="What motivates me" content={aboutMeData.about_me_motivations_me} />

      <ProfileCardDivider />

      <ProfileCardTitleWithContent
        title="What makes me feel confident"
        content={aboutMeData.about_me_motivations_feel_confident}
      />
    </ProfileCard>
  );
}
