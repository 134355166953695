import { useState } from "react";
import { Typography } from "@mui/material";
import * as Sentry from "@sentry/browser";
import { useForm, FormProvider } from "react-hook-form";
import SessionReportSubmitConfirmation from "./SessionReportSubmitConfirmation";
import { ErrorResponse } from "../../api/Generic";
import { useSubmitSessionReport } from "../../api/mutations/SessionReport";
import ColumnBox from "../common/ColumnBox";

interface Props {
  sessionReportId: string;
  onFormSubmitting(isSubmitting: boolean): void;
  onSuccess(showSavedConfirmation: boolean): void;
  onError(error: unknown): void;
}

export default function SessionReportSubmit({ sessionReportId, onFormSubmitting, onSuccess, onError }: Props) {
  const formMethods = useForm();
  const { handleSubmit } = formMethods;

  const { mutateAsync: submitSessionReport } = useSubmitSessionReport();
  const [openSubmitConfirmation, setOpenSubmitConfirmation] = useState(false);

  const handleSubmitConfirmationClose = () => {
    setOpenSubmitConfirmation(false);
  };

  const handleSubmitConfirmationOk = async () => {
    setOpenSubmitConfirmation(false);

    try {
      onFormSubmitting(true);
      await submitSessionReport({
        sessionReportId,
      });
      onSuccess(false);
    } catch (error) {
      Sentry.captureException(error);
      onError(error);
    } finally {
      onFormSubmitting(false);
    }
  };

  const onSubmit = () => {
    setOpenSubmitConfirmation(true);
  };

  return (
    <FormProvider {...formMethods}>
      <form id="session-report-detail-form" onSubmit={handleSubmit(onSubmit)}>
        <ColumnBox gap={4}>
          <ColumnBox gap={2}>
            <Typography variant="h2">Submit Report</Typography>
            <Typography>
              Submitting your session report will add it your client&apos;s profile and email the report to WeFlex. You
              can edit the report if you need to.
            </Typography>
          </ColumnBox>
        </ColumnBox>
      </form>
      <SessionReportSubmitConfirmation
        open={openSubmitConfirmation}
        onOk={handleSubmitConfirmationOk}
        onClose={handleSubmitConfirmationClose}
      />
    </FormProvider>
  );
}
