import { ErrorOutline } from "@mui/icons-material";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

interface Props {
  open: boolean;
  onOk(): void;
  onClose(): void;
}

export default function RequestSessionCancelDialog({ open, onOk, onClose }: Props) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
    >
      <DialogTitle id="alert-dialog-title">
        <Box display="flex" alignItems="center">
          <ErrorOutline sx={{ color: "error.main", mr: 1 }} /> Cancel your request to have a session?
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          This action will close this form and any information you have entered will be lost.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Go Back</Button>
        <Button onClick={onOk} autoFocus>
          Yes, Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
