import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import ImageIcon from "@mui/icons-material/Image";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import * as Sentry from "@sentry/browser";
import { Controller, FormProvider, SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { RouteComponentProps, useParams } from "react-router-dom";
import * as yup from "yup";
import { ErrorResponse, isErrorResponse } from "../../../../api/Generic";
import { useEditPWADProfileConsent } from "../../../../api/mutations/PWADProfile";
import {
  GENERIC_REQUEST_ERROR_MESSAGE,
  GENERIC_REQUIRED_VALIDATION_ERROR_MESSAGE,
  GENERIC_SUBMIT_VALIDATION_ERROR_MESSAGE,
} from "../../../../constants/validation";
import { PWADProfileConsentModel } from "../../../../models/PWADProfile";
import { useAuth } from "../../../../utils/AuthProvider";
import getErrorMessages from "../../../../utils/getErrorMessages";
import { getUserPWADProfileIsCompleted } from "../../../../utils/person";
import ErrorAlert from "../../../common/ErrorAlert";
import ErrorDialog from "../../../common/ErrorDialog";
import ProfileCard from "../../../common/ProfileCard";
import ProfileCardDivider from "../../../common/ProfileCardDivider";
import UnsavedChangesPrompt from "../../../common/UnsavedChangesPrompt";
import MediaMarketingConsentContent from "../../../profile-cards/PWADMediaMarketingConsentContent";
import PWADServicesConsentContent from "../../../profile-cards/PWADServicesConsentContent";

type ConsentFormInput = PWADProfileConsentModel;

const ConsentFormSchema: yup.SchemaOf<ConsentFormInput> = yup.object({
  waivers_agree_term_and_conditions: yup
    .bool()
    .defined()
    .nullable()
    .required(GENERIC_REQUIRED_VALIDATION_ERROR_MESSAGE)
    .oneOf([true], "You must consent to receive services from WeFlex."),
  waivers_consent_media_marketing_waiver: yup
    .bool()
    .defined()
    .nullable()
    .required(GENERIC_REQUIRED_VALIDATION_ERROR_MESSAGE),
});

interface ConsentReactHookFormProps {
  defaultValues: ConsentFormInput;
  onSubmit: SubmitHandler<ConsentFormInput>;
  onError: SubmitErrorHandler<ConsentFormInput>;
}

function ConsentReactHookForm({ defaultValues, onSubmit, onError }: ConsentReactHookFormProps): JSX.Element {
  const { currentUser } = useAuth();
  const pwadProfileIsCompleted = getUserPWADProfileIsCompleted(currentUser);

  const formMethods = useForm<ConsentFormInput>({
    resolver: yupResolver(ConsentFormSchema),
    mode: "onTouched",
    criteriaMode: "firstError",
    defaultValues: defaultValues,
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting, isDirty },
  } = formMethods;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const theme: Theme = useTheme();
  const breakpointMD = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <Grid container spacing={2} py={2}>
          <Grid item xs={12}>
            <Box mb={3}>
              <Grid
                item
                display="flex"
                flexDirection={{ xs: "column", md: "row" }}
                alignItems={{ xs: "flex-start", md: "center" }}
                justifyContent={{ xs: "flex-start", md: "space-between" }}
              >
                <Grid item>
                  <Typography variant="h1">
                    <Typography display="inline" variant="h1" component="span" color="link.main">
                      Consent.
                    </Typography>
                  </Typography>
                </Grid>

                <Grid item>
                  <LoadingButton
                    type="submit"
                    variant={pwadProfileIsCompleted ? "outlined" : "contained"}
                    size={breakpointMD ? "medium" : "small"}
                    sx={breakpointMD ? undefined : { marginTop: 2 }}
                    loading={isSubmitting}
                    disabled={isSubmitting}
                  >
                    Save Consent
                  </LoadingButton>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid item lg={6} xs={12}>
            <ProfileCard title="Services" Icon={FitnessCenterIcon}>
              <PWADServicesConsentContent />

              <ProfileCardDivider />

              <Controller
                control={control}
                name="waivers_agree_term_and_conditions"
                render={({ field: { onChange, onBlur, value }, fieldState: { error, invalid } }) => (
                  <FormControl error={invalid}>
                    <FormLabel id="waivers_agree_term_and_conditions" required>
                      Services consent
                    </FormLabel>

                    <RadioGroup
                      aria-labelledby="waivers_agree_term_and_conditions"
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                    >
                      <FormControlLabel
                        value
                        control={<Radio />}
                        label="Yes, I consent to receive services from WeFlex as described above"
                      />

                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No, I do not consent to receive services from WeFlex as described above"
                      />
                    </RadioGroup>

                    {!!error?.message && <FormHelperText error>{error.message}</FormHelperText>}
                  </FormControl>
                )}
              />
            </ProfileCard>
          </Grid>

          <Grid item lg={6} xs={12}>
            <ProfileCard title="Media and Marketing" Icon={ImageIcon}>
              <MediaMarketingConsentContent />

              <ProfileCardDivider />

              <Controller
                control={control}
                name="waivers_consent_media_marketing_waiver"
                render={({ field: { onChange, onBlur, value }, fieldState: { error, invalid } }) => (
                  <FormControl error={invalid}>
                    <FormLabel id="waivers_consent_media_marketing_waiver" required>
                      Media and marketing consent
                    </FormLabel>

                    <RadioGroup
                      aria-labelledby="waivers_consent_media_marketing_waiver"
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                    >
                      <FormControlLabel value control={<Radio />} label="Yes, I give my consent" />

                      <FormControlLabel value={false} control={<Radio />} label="No, I do not give my consent" />
                    </RadioGroup>

                    {!!error?.message && <FormHelperText error>{error.message}</FormHelperText>}
                  </FormControl>
                )}
              />
            </ProfileCard>
          </Grid>
        </Grid>
      </form>

      <UnsavedChangesPrompt showPrompt={isDirty} />
    </FormProvider>
  );
}

interface Props extends RouteComponentProps {
  setSavedSnackbarMessage: Dispatch<SetStateAction<string>>;
}

export default function ConsentForm({ setSavedSnackbarMessage }: Props): JSX.Element {
  const { profileId } = useParams<{ profileId: string }>();

  const { refetchCurrentUser } = useAuth();

  const [errorDialogMessages, setErrorDialogMessages] = useState<Array<string>>([]);

  const {
    data: consentData,
    error: consentError,
    isLoading: consentLoading,
    refetch: consentRefetch,
  } = useQuery<PWADProfileConsentModel, ErrorResponse>(`/pwadprofile/consent/?pwad_profile=${profileId}`);

  const { mutateAsync: editPWADProfileConsent } = useEditPWADProfileConsent(profileId);

  const defaultValues = useMemo((): ConsentFormInput | null => {
    if (!consentData) {
      return null;
    }

    return consentData;
  }, [consentData]);

  const onSubmit: SubmitHandler<ConsentFormInput> = async (values) => {
    try {
      await editPWADProfileConsent({
        pwad_profile: values,
      });

      await consentRefetch();
      await refetchCurrentUser();

      setSavedSnackbarMessage("Consent saved successfully.");

      // Scroll to the top so that the user can see that the "Submit Profile" button is now enabled, if this is the last
      // tab that needed to be saved before the profile was completed (which will typically be the case).
      window.scrollTo(0, 0);
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);

      if (isErrorResponse(error)) {
        setErrorDialogMessages(getErrorMessages(error.response.data?.message));
      } else {
        setErrorDialogMessages([GENERIC_REQUEST_ERROR_MESSAGE]);
      }
    }
  };

  const onError: SubmitErrorHandler<ConsentFormInput> = () => {
    setErrorDialogMessages([GENERIC_SUBMIT_VALIDATION_ERROR_MESSAGE]);
  };

  if (consentLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (consentError) {
    return <ErrorAlert message="Profile details failed to load" />;
  }

  if (!defaultValues) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <ConsentReactHookForm defaultValues={defaultValues} onSubmit={onSubmit} onError={onError} />

      {!!errorDialogMessages.length && (
        <ErrorDialog title="Unable to save" messages={errorDialogMessages} onClose={() => setErrorDialogMessages([])} />
      )}
    </>
  );
}
