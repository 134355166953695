import { useMutation } from "react-query";
import { CustomerTypeEnum } from "../../components/onboarding/common/CustomerType";
import {
  PersonGenderEnum,
  PersonModel,
  PersonPrimaryContactTypeEnum,
  RepresentedByPersonEditModel,
} from "../../models/Person";
import {
  PWADProfileAboutMeEditModel,
  PWADProfileConsentModel,
  PWADProfileGoalEditModel,
  PWADProfileMyHealthModel,
  PWADProfileMyPreferencesEditMutationModel,
  PWADProfileMyUniqueNeedsEditModel,
  RepresentativeRelationshipEnum,
} from "../../models/PWADProfile";
import Axios from "../Axios";
import { ErrorResponse, ServerResponse } from "../Generic";

type InsertPWADProfileParameters = {
  person: PersonModel;
  represented_by: RepresentedByPersonEditModel | null;
  customer_type: CustomerTypeEnum;
  representative_relationship: RepresentativeRelationshipEnum | "";
  password: string;
};

type EditPWADProfileAboutMeParameters = {
  person: {
    first_name: string;
    last_name: string;
    preferred_name: string;
    phone: string;
    dob: string;
    gender: PersonGenderEnum;
    avatar: string;
    address_place: string;
    address_latitude: number;
    address_longitude: number;
    primary_contact_type: PersonPrimaryContactTypeEnum;
    alternate_email: string;
    alternate_phone: string;
  };
  represented_by: RepresentedByPersonEditModel | null;
  pwad_profile: PWADProfileAboutMeEditModel;
};

type EditPWADProfileMyUniqueNeedsParameters = {
  pwad_profile: PWADProfileMyUniqueNeedsEditModel;
};

type EditPWADProfileMyGoalsParameters = {
  goals: Array<PWADProfileGoalEditModel>;
};

type EditPWADProfileMyPreferencesParameters = {
  pwad_profile: PWADProfileMyPreferencesEditMutationModel;
};

type EditPWADProfileMyHealthParameters = {
  pwad_profile: PWADProfileMyHealthModel;
};

type EditPWADProfileConsentParameters = {
  pwad_profile: PWADProfileConsentModel;
};

export function useInsertPWADProfile() {
  return useMutation<ServerResponse, ErrorResponse, InsertPWADProfileParameters>(
    ({ person, represented_by, customer_type, representative_relationship, password }: InsertPWADProfileParameters) =>
      Axios.post<ServerResponse, any>("/pwadprofile/insert/", {
        input: { person, represented_by, customer_type, representative_relationship, password },
      }),
  );
}

export function useEditPWADProfileAboutMe(pwad_profile_id: string) {
  return useMutation<ServerResponse, ErrorResponse, EditPWADProfileAboutMeParameters>(
    ({ person, represented_by, pwad_profile }: EditPWADProfileAboutMeParameters) =>
      Axios.post<ServerResponse, any>(`/pwadprofile/edit/${pwad_profile_id}/about-me/`, {
        input: { person_data: person, represented_by_person_data: represented_by, pwad_profile_data: pwad_profile },
      }),
  );
}

export function useEditPWADProfileMyUniqueNeeds(pwad_profile_id: string) {
  return useMutation<ServerResponse, ErrorResponse, EditPWADProfileMyUniqueNeedsParameters>(
    ({ pwad_profile }: EditPWADProfileMyUniqueNeedsParameters) =>
      Axios.post<ServerResponse, any>(`/pwadprofile/edit/${pwad_profile_id}/my-unique-needs/`, {
        input: { pwad_profile_data: pwad_profile },
      }),
  );
}

export function useEditPWADProfileMyGoals(pwad_profile_id: string) {
  return useMutation<ServerResponse, ErrorResponse, EditPWADProfileMyGoalsParameters>(
    ({ goals }: EditPWADProfileMyGoalsParameters) =>
      Axios.post<ServerResponse, any>(`/pwadprofile/edit/${pwad_profile_id}/my-goals/`, {
        input: { goals_data: goals },
      }),
  );
}

export function useEditPWADProfileMyPreferences(pwad_profile_id: string) {
  return useMutation<ServerResponse, ErrorResponse, EditPWADProfileMyPreferencesParameters>(
    ({ pwad_profile }: EditPWADProfileMyPreferencesParameters) =>
      Axios.post<ServerResponse, any>(`/pwadprofile/edit/${pwad_profile_id}/my-preferences/`, {
        input: { pwad_profile_data: pwad_profile },
      }),
  );
}

export function useEditPWADProfileMyHealth(pwad_profile_id: string) {
  return useMutation<ServerResponse, ErrorResponse, EditPWADProfileMyHealthParameters>(
    ({ pwad_profile }: EditPWADProfileMyHealthParameters) =>
      Axios.post<ServerResponse, any>(`/pwadprofile/edit/${pwad_profile_id}/my-health/`, {
        input: { pwad_profile_data: pwad_profile },
      }),
  );
}

export function useEditPWADProfileConsent(pwad_profile_id: string) {
  return useMutation<ServerResponse, ErrorResponse, EditPWADProfileConsentParameters>(
    ({ pwad_profile }: EditPWADProfileConsentParameters) =>
      Axios.post<ServerResponse, any>(`/pwadprofile/edit/${pwad_profile_id}/consent/`, {
        input: { pwad_profile_data: pwad_profile },
      }),
  );
}

export function useSubmitPWADProfile(pwad_profile_id: string) {
  return useMutation<ServerResponse, ErrorResponse>(() =>
    Axios.post<ServerResponse, any>(`/pwadprofile/edit/${pwad_profile_id}/submit-profile/`),
  );
}
