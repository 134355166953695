import { ErrorOutline } from "@mui/icons-material";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

interface Props {
  title: string;
  messages: string[];
  onClose(): void;
}

export default function ErrorDialog({ title, messages, onClose }: Props) {
  return (
    <Dialog open onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        <Box display="flex" alignItems="center">
          <ErrorOutline sx={{ color: "error.main", mr: 1 }} />
          {title}
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{messages.join(", ")}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
