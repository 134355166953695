import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useGetFPProfileAboutMe } from "../../../api/queries/FPProfile";
import ColumnBox from "../../common/ColumnBox";
import ErrorAlert from "../../common/ErrorAlert";
import FPAboutMe from "../../profile-cards/FPAboutMe";
import FPBasicInformation from "../../profile-cards/FPBasicInformation";
import FPCulture from "../../profile-cards/FPCulture";
import FPEmergencyContact from "../../profile-cards/FPEmergencyContact";
import FPLikes from "../../profile-cards/FPLikes";

export default function AboutMe(): JSX.Element {
  const { profileId } = useParams<{ profileId: string }>();

  const { data: aboutMeData, isLoading: aboutMeLoading, error: aboutMeError } = useGetFPProfileAboutMe(profileId);

  if (aboutMeLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (aboutMeError) {
    return <ErrorAlert message="Profile details failed to load" />;
  }

  return (
    <>
      <Grid container spacing={2} py={2}>
        <Grid item xs={12}>
          <Box mb={3}>
            <Typography variant="h1">
              About{" "}
              <Typography display="inline" variant="h1" component="span" color="link.main">
                Me.
              </Typography>
            </Typography>
          </Box>
        </Grid>

        <Grid item lg={6} xs={12}>
          <ColumnBox gap={2}>
            <FPAboutMe aboutMeData={aboutMeData} />
            <FPBasicInformation aboutMeData={aboutMeData} />
          </ColumnBox>
        </Grid>

        <Grid item lg={6} xs={12}>
          <ColumnBox gap={2}>
            <FPCulture aboutMeData={aboutMeData} />
            <FPLikes aboutMeData={aboutMeData} />
            <FPEmergencyContact aboutMeData={aboutMeData} />
          </ColumnBox>
        </Grid>
      </Grid>
    </>
  );
}
