import { isNil } from "lodash";

const isBlank = (s: string | undefined | null) => {
  if (isNil(s)) {
    return true;
  }

  return s.trim().length === 0;
};

export default isBlank;
