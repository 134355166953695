import FavoriteIcon from "@mui/icons-material/Favorite";
import { FPProfileAboutMeModel, FPProfileAboutMeModelAsPWAD } from "../../models/FPProfile";
import ProfileCard from "../common/ProfileCard";
import ProfileCardTitleWithContent from "../common/ProfileCardTitleWithContent";

interface Props {
  aboutMeData?: FPProfileAboutMeModel | FPProfileAboutMeModelAsPWAD;
}

export default function FPLikes({ aboutMeData }: Props) {
  return (
    <ProfileCard title="Likes" Icon={FavoriteIcon}>
      <ProfileCardTitleWithContent title="Things I love" content={aboutMeData?.about_me_things_you_love} />
    </ProfileCard>
  );
}
