import { useMemo } from "react";
import LanguageIcon from "@mui/icons-material/Language";
import { Typography } from "@mui/material";
import { isNil } from "lodash";
import isoCountries from "../../constants/isoCountries";
import isoLanguages from "../../constants/isoLanguages";
import { FPProfileAboutMeModel, FPProfileAboutMeModelAsPWAD } from "../../models/FPProfile";
import CheckIconList from "../common/CheckIconList";
import ColumnBox from "../common/ColumnBox";
import OptionalValue from "../common/OptionalValue";
import ProfileCard from "../common/ProfileCard";
import ProfileCardTitleWithContent from "../common/ProfileCardTitleWithContent";

interface Props {
  aboutMeData?: FPProfileAboutMeModel | FPProfileAboutMeModelAsPWAD;
}

const selectableLanguageValues: { [key in string]: { name: string } } = {};

Object.keys(isoLanguages)?.forEach((key) => {
  selectableLanguageValues[isoLanguages[key].name] = { name: isoLanguages[key].name };
});

const textFormat = (language: string) => {
  return language[0].toUpperCase() + language.slice(1).toLowerCase();
};

export default function FPCulture({ aboutMeData }: Props) {
  const nationality = !isNil(aboutMeData?.person.nationality)
    ? isoCountries[aboutMeData?.person.nationality!]?.name ?? aboutMeData?.person.nationality!
    : null;

  const selectedLanguages = useMemo(
    () =>
      aboutMeData?.person.spoken_languages?.reduce(
        (o, language) => Object.assign(o, { [language]: { name: textFormat(language) } }),
        {},
      ),

    [aboutMeData],
  );

  const selectedLanguageList = useMemo(() => {
    if (!selectedLanguages) {
      return [];
    }

    // @ts-ignore
    return Object.keys(selectedLanguages).map((lang: string) => ({ id: lang, name: selectedLanguages[lang].name }));
  }, [selectedLanguages]);

  return (
    <ProfileCard title="Culture" Icon={LanguageIcon}>
      <ColumnBox gap={3}>
        <ProfileCardTitleWithContent
          title="Country of origin"
          content={
            <Typography variant="body1">
              <OptionalValue>{nationality}</OptionalValue>
            </Typography>
          }
        />

        <ProfileCardTitleWithContent
          title="Languages I speak"
          content={<CheckIconList list={selectedLanguageList} />}
        />
      </ColumnBox>
    </ProfileCard>
  );
}
