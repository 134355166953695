import { ReactEventHandler } from "react";
import { Radio, Tooltip } from "@material-ui/core";
import { InfoRounded } from "@mui/icons-material";
import { Box, Card, FormControlLabel, Grid, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import { SpecialtyBaseModel } from "../../../models/Session";

interface Props {
  specialties: SpecialtyBaseModel[];
  selectedSpecialtyId: string;
  onSpecialtyClick: ReactEventHandler<HTMLButtonElement>;
}

export default function RequestSessionFormSpecialties({ specialties, selectedSpecialtyId, onSpecialtyClick }: Props) {
  return (
    <Box mt={4}>
      <Typography variant="body1" fontWeight="bold" mb={2}>
        Choose the type of training you require
      </Typography>

      {isEmpty(specialties) && (
        <Typography>Sorry, no training options are available for the chosen service.</Typography>
      )}

      {!isEmpty(specialties) && (
        <Card>
          <Grid container spacing={2} padding={2} justifyContent="space-between">
            {specialties.map(({ id, name, description }) => (
              <Grid key={id} item xs={12} display="flex" alignItems="center">
                <Box display="flex" flexDirection="row" alignItems="center">
                  <FormControlLabel
                    name="specialty"
                    value={id}
                    checked={selectedSpecialtyId === id}
                    control={<Radio onClick={onSpecialtyClick} />}
                    label={name}
                    sx={{ mr: 1 }}
                  />
                  <Tooltip title={<Typography>{description}</Typography>} placement="right" arrow>
                    <InfoRounded sx={{ width: 20, height: 20, color: "green" }} />
                  </Tooltip>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Card>
      )}
    </Box>
  );
}
