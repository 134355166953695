import React from "react";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { Chip } from "@mui/material";
import { PWADHealthFieldEnum, PWADHealthFieldLabel, PWADProfileMyHealthModel } from "../../models/PWADProfile";
import { useAuth } from "../../utils/AuthProvider";
import { getUserIsPWAD } from "../../utils/person";
import ProfileCard from "../common/ProfileCard";
import ProfileCardDivider from "../common/ProfileCardDivider";
import ProfileCardTitleWithContent from "../common/ProfileCardTitleWithContent";

const getHealthContent = (healthValue: boolean | null, currentUserIsPWAD: boolean) => {
  if (healthValue === true) {
    if (currentUserIsPWAD) {
      return "Yes";
    }

    return (
      <Chip
        icon={<PriorityHighIcon fontSize="small" />}
        label="Yes"
        color="warning"
        sx={{ paddingLeft: 1, paddingRight: 1 }}
      />
    );
  }

  if (healthValue === false) {
    return "No";
  }

  return undefined;
};

interface Props {
  myHealthData: PWADProfileMyHealthModel;
}

export default function PWADHealth({ myHealthData }: Props): JSX.Element {
  const { currentUser } = useAuth();
  const currentUserIsPWAD = getUserIsPWAD(currentUser);

  const showHealthBloodSugarField = myHealthData[PWADHealthFieldEnum.health_diabetes] === true;

  return (
    <ProfileCard title="Health" Icon={HealthAndSafetyIcon}>
      <ProfileCardTitleWithContent
        title={PWADHealthFieldLabel[PWADHealthFieldEnum.health_heart_condition_stroke]}
        content={getHealthContent(myHealthData[PWADHealthFieldEnum.health_heart_condition_stroke], currentUserIsPWAD)}
      />

      <ProfileCardDivider />

      <ProfileCardTitleWithContent
        title={PWADHealthFieldLabel[PWADHealthFieldEnum.health_chest_pain]}
        content={getHealthContent(myHealthData[PWADHealthFieldEnum.health_chest_pain], currentUserIsPWAD)}
      />

      <ProfileCardDivider />

      <ProfileCardTitleWithContent
        title={PWADHealthFieldLabel[PWADHealthFieldEnum.health_faint_dizzy_exercise]}
        content={getHealthContent(myHealthData[PWADHealthFieldEnum.health_faint_dizzy_exercise], currentUserIsPWAD)}
      />

      <ProfileCardDivider />

      <ProfileCardTitleWithContent
        title={PWADHealthFieldLabel[PWADHealthFieldEnum.health_asthma_attack_last_12_months]}
        content={getHealthContent(
          myHealthData[PWADHealthFieldEnum.health_asthma_attack_last_12_months],
          currentUserIsPWAD,
        )}
      />

      <ProfileCardDivider />

      <ProfileCardTitleWithContent
        title={PWADHealthFieldLabel[PWADHealthFieldEnum.health_diabetes]}
        content={getHealthContent(myHealthData[PWADHealthFieldEnum.health_diabetes], currentUserIsPWAD)}
      />

      {showHealthBloodSugarField && (
        <>
          <ProfileCardDivider />

          <ProfileCardTitleWithContent
            title={PWADHealthFieldLabel[PWADHealthFieldEnum.health_blood_sugar]}
            content={getHealthContent(myHealthData[PWADHealthFieldEnum.health_blood_sugar], currentUserIsPWAD)}
          />
        </>
      )}

      <ProfileCardDivider />

      <ProfileCardTitleWithContent
        title={PWADHealthFieldLabel[PWADHealthFieldEnum.health_other_conditions]}
        content={getHealthContent(myHealthData[PWADHealthFieldEnum.health_other_conditions], currentUserIsPWAD)}
      />
    </ProfileCard>
  );
}
