import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import ConstructionIcon from "@mui/icons-material/Construction";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import StarIcon from "@mui/icons-material/Star";
import { LoadingButton } from "@mui/lab";
import { Box, CircularProgress, Grid, TextField, Theme, Typography, useMediaQuery, useTheme } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as Sentry from "@sentry/browser";
import { Controller, FormProvider, SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { RouteComponentProps, generatePath, useParams } from "react-router-dom";
import * as yup from "yup";
import { ErrorResponse, isErrorResponse } from "../../../../api/Generic";
import { useEditFPProfileMyServices } from "../../../../api/mutations/FPProfile";
import * as ROUTES from "../../../../constants/routes";
import {
  GENERIC_REQUEST_ERROR_MESSAGE,
  GENERIC_SUBMIT_VALIDATION_ERROR_MESSAGE,
} from "../../../../constants/validation";
import SelectControl from "../../../../form/SelectControl";
import { FPProfileMyServicesEditModel } from "../../../../models/FPProfile";
import { ServiceModel, SpecialtyModel } from "../../../../models/Session";
import { useAuth } from "../../../../utils/AuthProvider";
import getErrorMessages from "../../../../utils/getErrorMessages";
import { getUserFPProfileIsCompleted } from "../../../../utils/person";
import ColumnBox from "../../../common/ColumnBox";
import ErrorAlert from "../../../common/ErrorAlert";
import ErrorDialog from "../../../common/ErrorDialog";
import ProfileCard from "../../../common/ProfileCard";
import UnsavedChangesPrompt from "../../../common/UnsavedChangesPrompt";

const availabilityGuideHelperText =
  "List days, times and locations you would be available for WeFlex clients, e.g. Mondays 11am - 3pm, Wednesday 8am - 12pm at Chatswood Gym.";

type MyServicesFormInput = {
  services: string[];
  specialties: string[];
  availability_guide: string;
};

// @ts-ignore
const MyServicesFormSchema: yup.SchemaOf<MyServicesFormInput> = yup.object({
  services: yup.array().of(yup.string()).min(1, "Please select at least one service."),
  specialties: yup.array().of(yup.string()).min(1, "Please select at least one specialty."),
  availability_guide: yup.string().defined().required("Please enter your availability."),
});

interface MyServicesReactHookFormProps {
  defaultValues: MyServicesFormInput;
  allServices: ServiceModel[];
  allSpecialties: SpecialtyModel[];
  onSubmit: SubmitHandler<MyServicesFormInput>;
  onError: SubmitErrorHandler<MyServicesFormInput>;
}

function MyServicesReactHookForm({
  defaultValues,
  allServices,
  allSpecialties,
  onSubmit,
  onError,
}: MyServicesReactHookFormProps): JSX.Element {
  const { currentUser } = useAuth();
  const fpProfileIsCompleted = getUserFPProfileIsCompleted(currentUser);

  const formMethods = useForm<MyServicesFormInput>({
    resolver: yupResolver(MyServicesFormSchema),
    mode: "onTouched",
    criteriaMode: "firstError",
    defaultValues: defaultValues,
  });

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { isSubmitting, isDirty },
  } = formMethods;

  const [servicesValue, specialtiesValue] = watch(["services", "specialties"]);

  const specialtyOptions = useMemo(() => {
    // Specialties are filtered by the selected services
    return allSpecialties
      .filter((specialty) => servicesValue.includes(specialty.service.id))
      .map((specialty) => ({
        value: specialty.id,
        label: specialty.name,
        secondaryLabel: specialty.service.name,
      }));
  }, [servicesValue]);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  useEffect(() => {
    // When services change, ensure that the selected specialties are still valid for the selected services.
    const filteredSpecialties = specialtiesValue.filter((specialtyId) => {
      const specialty = allSpecialties.find((s) => s.id === specialtyId);
      return servicesValue.includes(specialty?.service.id ?? "");
    });
    setValue("specialties", filteredSpecialties);
  }, [servicesValue]);

  const theme: Theme = useTheme();
  const breakpointMD = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <Grid container spacing={2} py={2}>
            <Grid item xs={12}>
              <Box mb={3}>
                <Grid
                  item
                  display="flex"
                  flexDirection={{ xs: "column", md: "row" }}
                  alignItems={{ xs: "flex-start", md: "center" }}
                  justifyContent={{ xs: "flex-start", md: "space-between" }}
                >
                  <Grid item>
                    <Typography variant="h1">
                      My{" "}
                      <Typography display="inline" variant="h1" component="span" color="link.main">
                        Services.
                      </Typography>
                    </Typography>
                  </Grid>

                  <Grid item>
                    <LoadingButton
                      type="submit"
                      variant={fpProfileIsCompleted ? "outlined" : "contained"}
                      size={breakpointMD ? "medium" : "small"}
                      sx={breakpointMD ? undefined : { marginTop: 2 }}
                      loading={isSubmitting}
                      disabled={isSubmitting}
                    >
                      Save My Services
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid item lg={6} xs={12}>
              <ColumnBox gap={3}>
                <ProfileCard title="Services" Icon={ConstructionIcon}>
                  <Controller
                    control={control}
                    name="services"
                    render={({ field, fieldState }) => (
                      <SelectControl
                        label="Services I offer"
                        options={allServices.map((service) => ({
                          value: service.id,
                          label: service.name,
                        }))}
                        required
                        multiple
                        field={field}
                        fieldState={fieldState}
                      />
                    )}
                  />
                </ProfileCard>

                <ProfileCard title="Specialties" Icon={StarIcon}>
                  <Controller
                    control={control}
                    name="specialties"
                    render={({ field, fieldState }) => (
                      <SelectControl
                        label="Specialties I offer"
                        options={specialtyOptions}
                        required
                        multiple
                        field={field}
                        fieldState={fieldState}
                      />
                    )}
                  />
                </ProfileCard>
              </ColumnBox>
            </Grid>

            <Grid item lg={6} xs={12}>
              <ProfileCard title="Availability" Icon={EventAvailableIcon}>
                <Controller
                  control={control}
                  name="availability_guide"
                  render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                    <TextField
                      label="Availability guide"
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      inputRef={ref}
                      error={invalid}
                      multiline
                      rows={2}
                      helperText={
                        error?.message
                          ? `${error?.message} E.g. ${availabilityGuideHelperText}`
                          : availabilityGuideHelperText
                      }
                      required
                    />
                  )}
                />
              </ProfileCard>
            </Grid>
          </Grid>
        </form>
      </FormProvider>

      <UnsavedChangesPrompt showPrompt={isDirty} />
    </LocalizationProvider>
  );
}

interface Props extends RouteComponentProps {
  setSavedSnackbarMessage: Dispatch<SetStateAction<string>>;
}

export default function MyServicesForm({ setSavedSnackbarMessage, history }: Props): JSX.Element {
  const { profileId } = useParams<{ profileId: string }>();

  const { refetchCurrentUser } = useAuth();

  const [errorDialogMessages, setErrorDialogMessages] = useState<Array<string>>([]);

  const {
    data: myServicesData,
    isLoading: myServicesLoading,
    error: myServicesError,
    refetch: myServicesRefetch,
  } = useQuery<FPProfileMyServicesEditModel, ErrorResponse>(`/fpprofile/my-services/?fpprofile=${profileId}`);

  const { mutateAsync: editFPProfileMyServices } = useEditFPProfileMyServices(profileId);

  const defaultValues = useMemo((): MyServicesFormInput | null => {
    if (!myServicesData) {
      return null;
    }

    return {
      services: myServicesData.services.map((service) => service.id),
      specialties: myServicesData.specialties.map((specialty) => specialty.id),
      availability_guide: myServicesData.availability_guide,
    };
  }, [myServicesData]);

  const allServices = useMemo(() => {
    if (!myServicesData) {
      return [];
    }
    return myServicesData.all_services;
  }, [myServicesData]);

  const allSpecialties = useMemo(() => {
    if (!myServicesData) {
      return [];
    }
    return myServicesData.all_specialties;
  }, [myServicesData]);

  const onSubmit: SubmitHandler<MyServicesFormInput> = async (values) => {
    try {
      await editFPProfileMyServices({ fp_profile: values });
      await myServicesRefetch();
      await refetchCurrentUser();

      setSavedSnackbarMessage("My services saved successfully.");

      history.push(generatePath(ROUTES.FP_PROFILE_MY_DOCUMENTS, { profileId }));
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);

      if (isErrorResponse(error)) {
        setErrorDialogMessages(getErrorMessages(error.response.data?.message));
      } else {
        setErrorDialogMessages([GENERIC_REQUEST_ERROR_MESSAGE]);
      }
    }
  };

  const onError: SubmitErrorHandler<MyServicesFormInput> = (error) => {
    console.error({ error });
    setErrorDialogMessages([GENERIC_SUBMIT_VALIDATION_ERROR_MESSAGE]);
  };

  if (myServicesLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (myServicesError) {
    return <ErrorAlert message="Profile details failed to load" />;
  }

  if (!defaultValues || !myServicesData) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <MyServicesReactHookForm
        defaultValues={defaultValues}
        allServices={allServices}
        allSpecialties={allSpecialties}
        onSubmit={onSubmit}
        onError={onError}
      />

      {!!errorDialogMessages.length && (
        <ErrorDialog title="Unable to save" messages={errorDialogMessages} onClose={() => setErrorDialogMessages([])} />
      )}
    </>
  );
}
