import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Typography, Box, CircularProgress, Paper, Link } from "@mui/material";
import * as Sentry from "@sentry/browser";
import { isEmpty, uniq } from "lodash";
import { useQuery } from "react-query";
import { Link as RouterLink } from "react-router-dom";
import BookingCancelDialog from "./BookingCancelDialog";
import BookingList from "./BookingList";
import { ErrorResponse, isErrorResponse } from "../../../../api/Generic";
import { useCancelBooking } from "../../../../api/mutations/Booking";
import * as ROUTES from "../../../../constants/routes";
import { GENERIC_REQUEST_ERROR_MESSAGE } from "../../../../constants/validation";
import { BookingModel, BookingStatusEnum } from "../../../../models/Booking";
import getErrorMessages from "../../../../utils/getErrorMessages";
import ErrorAlert from "../../../common/ErrorAlert";
import ErrorDialog from "../../../common/ErrorDialog";

interface Props {
  year: string;
  month: string;
  setSelectedDates: Dispatch<SetStateAction<string[]>>;
}

export default function Bookings({ year, month, setSelectedDates }: Props): JSX.Element {
  const { mutate: cancelBooking } = useCancelBooking();
  const [loadingSessionDetails, setLoadingSessionDetails] = useState<{
    id: string;
  } | null>(null);
  const [submitErrors, setSubmitErrors] = useState<string[] | null>(null);
  const [openCancelWarning, setOpenCancelWarning] = useState(false);

  const {
    data: bookingsData,
    isLoading: bookingsLoading,
    error: bookingsError,
    refetch: refetchBookings,
  } = useQuery<{ bookings: BookingModel[] }, ErrorResponse>(`/booking/list/?year=${year}&month=${month}`);

  useEffect(() => {
    if (!isEmpty(bookingsData?.bookings)) {
      const selectedDates = bookingsData!.bookings
        .filter((booking) => booking.status === BookingStatusEnum.ACCEPTED)
        .map((booking) => booking.session.date);
      setSelectedDates(uniq(selectedDates));
    }
  }, [bookingsData, setSelectedDates]);

  useEffect(() => {
    if (loadingSessionDetails && loadingSessionDetails.id) {
      setOpenCancelWarning(true);
    }
  }, [loadingSessionDetails]);

  const handleCancelBooking = (sessionId: string) => {
    setLoadingSessionDetails({ id: sessionId });
  };

  const handleCancelOk = () => {
    if (!loadingSessionDetails) {
      return;
    }

    setOpenCancelWarning(false);

    cancelBooking(
      { booking: loadingSessionDetails.id },
      {
        onError: (error) => {
          Sentry.captureException(error);
          setLoadingSessionDetails(null);

          if (isErrorResponse(error)) {
            setSubmitErrors(getErrorMessages(error.response.data?.message));
          } else {
            setSubmitErrors([GENERIC_REQUEST_ERROR_MESSAGE]);
          }
        },
        onSuccess: async () => {
          await refetchBookings();
          setLoadingSessionDetails(null);
        },
      },
    );
  };

  const handleDeclineClose = () => {
    setOpenCancelWarning(false);
    setLoadingSessionDetails(null);
  };

  if (bookingsLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (bookingsError) {
    return <ErrorAlert message="Bookings failed to load" />;
  }

  if (!bookingsData?.bookings) {
    return (
      <Paper
        sx={{ p: 4, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}
        elevation={0}
      >
        <Typography>You have no sessions booked this month.</Typography>

        <Typography>
          <Link component={RouterLink} to={ROUTES.PWAD_MAKE_A_BOOKING} variant="link1" color="link.main">
            Find a WeFlex fitness professional and book a session today.
          </Link>
        </Typography>
      </Paper>
    );
  }

  return (
    <>
      <BookingList
        bookings={bookingsData?.bookings}
        onCancelBooking={handleCancelBooking}
        loading={loadingSessionDetails}
      />

      <BookingCancelDialog open={openCancelWarning} onOk={handleCancelOk} onClose={handleDeclineClose} />

      {!isEmpty(submitErrors) && (
        <ErrorDialog
          title="Failed to cancel the booking"
          messages={submitErrors!}
          onClose={() => setSubmitErrors(null)}
        />
      )}
    </>
  );
}
