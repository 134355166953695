import React from "react";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { ErrorResponse } from "../../../../api/Generic";
import { PWADProfileMyHealthModel } from "../../../../models/PWADProfile";
import ErrorAlert from "../../../common/ErrorAlert";
import PWADHealth from "../../../profile-cards/PWADHealth";

export default function MyHealth(): JSX.Element | null {
  const { profileId } = useParams<{ profileId: string }>();

  const {
    data: myHealthData,
    error: myHealthError,
    isLoading: myHealthLoading,
  } = useQuery<PWADProfileMyHealthModel, ErrorResponse>(`/pwadprofile/my-health/?pwad_profile=${profileId}`);

  if (myHealthLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (myHealthError) {
    return <ErrorAlert message="Profile details failed to load" />;
  }

  if (!myHealthData) {
    return null;
  }

  return (
    <Grid container spacing={2} py={2}>
      <Grid item xs={12}>
        <Typography variant="h1" mb={3}>
          My{" "}
          <Typography display="inline" variant="h1" component="span" color="link.main">
            Health.
          </Typography>
        </Typography>
      </Grid>

      <Grid item lg={6} xs={12}>
        <PWADHealth myHealthData={myHealthData} />
      </Grid>
    </Grid>
  );
}
