import React, { useMemo } from "react";
import { Box, Card, CircularProgress, Container, useMediaQuery } from "@mui/material";
import { generatePath, Redirect, Route, Switch, useParams } from "react-router-dom";
import AboutMe from "./read/AboutMe";
import Consent from "./read/Consent";
import MyGoals from "./read/MyGoals";
import MyHealth from "./read/MyHealth";
import MyPreferences from "./read/MyPreferences";
import MyUniqueNeeds from "./read/MyUniqueNeeds";
import SessionReports from "./read/SessionReports";
import Statistics from "./read/Statistics";
import { useGetPWADProfileAboutMe } from "../../../api/queries/PWADProfile";
import * as ROUTES from "../../../constants/routes";
import theme from "../../../styles/_theme";
import { useAuth } from "../../../utils/AuthProvider";
import { getFullName } from "../../../utils/person";
import ErrorAlert from "../../common/ErrorAlert";
import NavTabs from "../../common/NavTabs";
import ProfileBanner from "../../common/ProfileBanner";

function ProfileLive(): JSX.Element {
  const { profileId } = useParams<{ profileId?: string }>();
  const { currentUser } = useAuth();

  const { data: aboutMeData, isLoading: aboutMeLoading, error: aboutMeError } = useGetPWADProfileAboutMe(profileId);

  const navTabItems = useMemo(() => {
    const items = [
      { label: "About Me", path: generatePath(ROUTES.PWAD_PROFILE_ABOUT_ME, { profileId }) },
      { label: "My Unique Needs", path: generatePath(ROUTES.PWAD_PROFILE_MY_UNIQUE_NEEDS, { profileId }) },
      { label: "My Goals", path: generatePath(ROUTES.PWAD_PROFILE_MY_GOALS, { profileId }) },
      { label: "My Preferences", path: generatePath(ROUTES.PWAD_PROFILE_MY_PREFERENCES, { profileId }) },
      { label: "My Health", path: generatePath(ROUTES.PWAD_PROFILE_MY_HEALTH, { profileId }) },
      { label: "Consent", path: generatePath(ROUTES.PWAD_PROFILE_CONSENT, { profileId }) },
      { label: "Session Reports", path: generatePath(ROUTES.PWAD_PROFILE_SESSION_REPORTS, { profileId }) },
    ];

    if (currentUser?.is_admin) {
      items.push({ label: "Statistics", path: generatePath(ROUTES.PWAD_PROFILE_STATISTICS, { profileId }) });
    }

    return items;
  }, [profileId]);

  const mobileMode = useMediaQuery(theme.breakpoints.down("lg"));

  if (aboutMeLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (aboutMeError) {
    return <ErrorAlert message="Profile details failed to load" />;
  }

  return (
    <Container maxWidth="xl" sx={{ p: 2 }}>
      <Card sx={{ width: "100%", mb: { xs: 2, lg: 4 }, borderRadius: "4px 4px 0px 0px" }}>
        <ProfileBanner fullName={getFullName(aboutMeData?.person)} avatar={aboutMeData?.person?.avatar} />

        <NavTabs
          scrollButtons="auto"
          allowScrollButtonsMobile
          variant={mobileMode ? "scrollable" : "standard"}
          sx={{ ".MuiTabs-flexContainer": { justifyContent: { lg: "flex-end" }, py: { lg: 1 } } }}
          items={navTabItems}
        />
      </Card>

      <Switch>
        <Route path={ROUTES.PWAD_PROFILE_ABOUT_ME} component={AboutMe} />
        <Route path={ROUTES.PWAD_PROFILE_MY_UNIQUE_NEEDS} component={MyUniqueNeeds} />
        <Route path={ROUTES.PWAD_PROFILE_MY_GOALS} component={MyGoals} />
        <Route path={ROUTES.PWAD_PROFILE_MY_PREFERENCES} component={MyPreferences} />
        <Route path={ROUTES.PWAD_PROFILE_MY_HEALTH} component={MyHealth} />
        <Route path={ROUTES.PWAD_PROFILE_CONSENT} component={Consent} />
        <Route path={ROUTES.PWAD_PROFILE_SESSION_REPORTS} component={SessionReports} />
        <Route path={ROUTES.PWAD_PROFILE_STATISTICS} component={Statistics} />
        <Redirect to={ROUTES.PWAD_PROFILE_ABOUT_ME} />
      </Switch>
    </Container>
  );
}

export default ProfileLive;
