import React, { useContext, useEffect, useState } from "react";
import * as Sentry from "@sentry/browser";
import { isEmpty, isNil, isNull } from "lodash";
import { useQuery } from "react-query";
import { clearToken, getToken, setToken } from "./store";
import { ErrorResponse } from "../api/Generic";
import { UserModel } from "../models/Person";

export interface UserViewModel extends UserModel {
  is_fp: boolean;
  is_pwad: boolean;
  is_live: boolean;
  is_admin: boolean;
}

type AuthContext = {
  currentUser: UserViewModel | null;
  isLoggedIn: boolean;
  isLoading: boolean;
  isRefetching: boolean;
  refetchCurrentUser: (newToken?: string) => Promise<unknown>;
  clearCurrentUser: () => void;
};

const AuthProviderContext = React.createContext<AuthContext>({
  currentUser: null,
  isLoggedIn: false,
  isLoading: false,
  isRefetching: false,
  refetchCurrentUser: () => Promise.resolve(),
  clearCurrentUser: () => {},
});

export const useAuth = () => useContext(AuthProviderContext);

type AuthProviderProps = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: AuthProviderProps): JSX.Element {
  const [currentUser, setCurrentUser] = useState<UserViewModel | null>(null);

  const { isLoading, isRefetching, refetch } = useQuery<UserModel, ErrorResponse>("/user/me/", {
    retry: false,
    refetchOnWindowFocus: false,
    enabled: !isEmpty(getToken()),
    onSuccess: (data: UserModel) => {
      try {
        let isLive = false;

        if (!isNil(data.person?.pwad_profile?.is_live)) {
          isLive = !!data.person?.pwad_profile?.is_live;
        } else if (!isNil(data.person?.fp_profile?.is_live)) {
          isLive = !!data.person?.fp_profile?.is_live;
        }

        const userViewModel: UserViewModel = {
          ...data,
          is_admin: data.is_superuser || data.is_staff,
          is_fp: !isNull(data.person?.fp_profile),
          is_pwad: !isNull(data.person?.pwad_profile),
          is_live: isLive,
        };

        setCurrentUser(userViewModel);

        Sentry.setUser({ id: data.id, username: data.username, email: data.email });
      } catch {
        setCurrentUser(null);

        Sentry.setUser(null);
      }
    },
    onError: () => {
      setCurrentUser(null);

      Sentry.setUser(null);
    },
  });

  const clearCurrentUser = () => {
    clearToken();
    setCurrentUser(null);

    Sentry.setUser(null);
  };

  const refetchCurrentUser = (newToken?: string) => {
    if (!isEmpty(newToken)) {
      setToken(newToken!);
    }

    return refetch();
  };

  useEffect(() => {
    const onStorageChange = () => {
      if (isEmpty(getToken())) {
        setCurrentUser(null);
      }
    };

    window.addEventListener("storage", onStorageChange);

    return () => {
      window.removeEventListener("storage", onStorageChange);
    };
  }, []);

  return (
    <AuthProviderContext.Provider
      value={{ isLoggedIn: !!currentUser, currentUser, isLoading, isRefetching, refetchCurrentUser, clearCurrentUser }}
    >
      {children}
    </AuthProviderContext.Provider>
  );
}
