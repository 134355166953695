import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import MailIcon from "@mui/icons-material/Mail";
import PeopleIcon from "@mui/icons-material/People";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/PhoneAndroid";
import { PersonRelationshipTypeLabel } from "../../models/Person";
import { PWADProfileAboutMeModel } from "../../models/PWADProfile";
import { getFullName } from "../../utils/person";
import ColumnBox from "../common/ColumnBox";
import ProfileCard from "../common/ProfileCard";
import ProfileCardIconWithContent from "../common/ProfileCardIconWithContent";

interface Props {
  aboutMeData: PWADProfileAboutMeModel;
}

export default function PWADRelatedPersonEmergencyContact({ aboutMeData }: Props) {
  const emergencyContactRelationship = aboutMeData.person.emergency_contact;

  if (!emergencyContactRelationship) {
    return null;
  }

  const iconColor = "grey.600";

  return (
    <ProfileCard title="Emergency Contact" Icon={ContactPhoneIcon}>
      <ColumnBox gap={3}>
        <ProfileCardIconWithContent
          content={getFullName(emergencyContactRelationship.person)}
          Icon={PersonIcon}
          iconColor={iconColor}
        />

        <ProfileCardIconWithContent
          content={PersonRelationshipTypeLabel[emergencyContactRelationship.type]}
          Icon={PeopleIcon}
          iconColor={iconColor}
        />

        <ProfileCardIconWithContent
          content={emergencyContactRelationship.person.email}
          Icon={MailIcon}
          iconColor={iconColor}
        />

        <ProfileCardIconWithContent
          content={emergencyContactRelationship.person.phone}
          Icon={PhoneIcon}
          iconColor={iconColor}
        />
      </ColumnBox>
    </ProfileCard>
  );
}
