import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import MailIcon from "@mui/icons-material/Mail";
import PeopleIcon from "@mui/icons-material/People";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/PhoneAndroid";
import { trim } from "lodash";
import { PersonPrimaryContactTypeEnum, PersonPrimaryContactTypeLabel } from "../../models/Person";
import { PWADProfileAboutMeModel } from "../../models/PWADProfile";
import ColumnBox from "../common/ColumnBox";
import ProfileCard from "../common/ProfileCard";
import ProfileCardIconWithContent from "../common/ProfileCardIconWithContent";

interface Props {
  aboutMeData: PWADProfileAboutMeModel;
}

function getIsPrimaryContactTypeDetailsVisible(primaryContactTypeValue: PersonPrimaryContactTypeEnum | "") {
  return (
    !!primaryContactTypeValue &&
    primaryContactTypeValue !== PersonPrimaryContactTypeEnum.MYSELF &&
    primaryContactTypeValue !== PersonPrimaryContactTypeEnum.REPRESENTATIVE
  );
}

export default function PWADPrimaryContactInformation({ aboutMeData }: Props) {
  const emergencyFullName = trim(`${aboutMeData.emergency_first_name} ${aboutMeData.emergency_last_name}`);

  if (!emergencyFullName) {
    return null;
  }

  const iconColor = "grey.600";

  const primaryContactType = aboutMeData.person.primary_contact_type ?? "";
  const isPrimaryContactTypeMyself = primaryContactType === PersonPrimaryContactTypeEnum.MYSELF;
  const primaryContactTypeLabel = primaryContactType ? PersonPrimaryContactTypeLabel[primaryContactType] : "";
  const isPrimaryContactTypeDetailsVisible = getIsPrimaryContactTypeDetailsVisible(primaryContactType);

  return (
    <ProfileCard title="Primary Contact" Icon={ContactPhoneIcon}>
      <ColumnBox gap={3}>
        <ProfileCardIconWithContent
          content={primaryContactTypeLabel}
          Icon={isPrimaryContactTypeMyself ? PersonIcon : PeopleIcon}
          iconColor={iconColor}
        />

        {isPrimaryContactTypeDetailsVisible && (
          <>
            <ProfileCardIconWithContent
              content={aboutMeData.person.alternate_email}
              Icon={MailIcon}
              iconColor={iconColor}
            />

            <ProfileCardIconWithContent
              content={aboutMeData.person.alternate_phone}
              Icon={PhoneIcon}
              iconColor={iconColor}
            />
          </>
        )}
      </ColumnBox>
    </ProfileCard>
  );
}
