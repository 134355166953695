import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import { LoadingButton } from "@mui/lab";
import { Alert, Box, CircularProgress, Grid, Theme, Typography, useMediaQuery, useTheme } from "@mui/material";
import * as Sentry from "@sentry/browser";
import { Controller, FormProvider, SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { generatePath, RouteComponentProps, useParams } from "react-router-dom";
import * as yup from "yup";
import { ErrorResponse, isErrorResponse } from "../../../../api/Generic";
import { useEditPWADProfileMyHealth } from "../../../../api/mutations/PWADProfile";
import * as ROUTES from "../../../../constants/routes";
import {
  GENERIC_REQUEST_ERROR_MESSAGE,
  GENERIC_REQUIRED_VALIDATION_ERROR_MESSAGE,
  GENERIC_SUBMIT_VALIDATION_ERROR_MESSAGE,
} from "../../../../constants/validation";
import SelectControl, { SELECT_BOOLEAN_OPTIONS } from "../../../../form/SelectControl";
import { PWADHealthFieldEnum, PWADHealthFieldLabel, PWADProfileMyHealthModel } from "../../../../models/PWADProfile";
import { useAuth } from "../../../../utils/AuthProvider";
import getErrorMessages from "../../../../utils/getErrorMessages";
import { getUserPWADProfileIsCompleted } from "../../../../utils/person";
import ColumnBox from "../../../common/ColumnBox";
import ErrorAlert from "../../../common/ErrorAlert";
import ErrorDialog from "../../../common/ErrorDialog";
import ProfileCard from "../../../common/ProfileCard";
import UnsavedChangesPrompt from "../../../common/UnsavedChangesPrompt";

type MyHealthFormInput = PWADProfileMyHealthModel;

const MyHealthFormSchema: yup.SchemaOf<MyHealthFormInput> = yup.object({
  health_heart_condition_stroke: yup.bool().defined().nullable().required(GENERIC_REQUIRED_VALIDATION_ERROR_MESSAGE),
  health_chest_pain: yup.bool().defined().nullable().required(GENERIC_REQUIRED_VALIDATION_ERROR_MESSAGE),
  health_faint_dizzy_exercise: yup.bool().defined().nullable().required(GENERIC_REQUIRED_VALIDATION_ERROR_MESSAGE),
  health_asthma_attack_last_12_months: yup
    .bool()
    .defined()
    .nullable()
    .required(GENERIC_REQUIRED_VALIDATION_ERROR_MESSAGE),
  health_diabetes: yup.bool().defined().nullable().required(GENERIC_REQUIRED_VALIDATION_ERROR_MESSAGE),
  health_blood_sugar: yup
    .bool()
    .defined()
    .nullable()
    .when("health_diabetes", {
      is: (health_diabetes: boolean | null) => health_diabetes === true,
      then: (schema) => schema.required(GENERIC_REQUIRED_VALIDATION_ERROR_MESSAGE),
      otherwise: (schema) => schema,
    }),
  health_other_conditions: yup.bool().defined().nullable().required(GENERIC_REQUIRED_VALIDATION_ERROR_MESSAGE),
});

interface MyHealthReactHookFormProps {
  defaultValues: MyHealthFormInput;
  onSubmit: SubmitHandler<MyHealthFormInput>;
  onError: SubmitErrorHandler<MyHealthFormInput>;
}

function MyHealthReactHookForm({ defaultValues, onSubmit, onError }: MyHealthReactHookFormProps): JSX.Element {
  const { currentUser } = useAuth();
  const pwadProfileIsCompleted = getUserPWADProfileIsCompleted(currentUser);

  const formMethods = useForm<MyHealthFormInput>({
    resolver: yupResolver(MyHealthFormSchema),
    mode: "onTouched",
    criteriaMode: "firstError",
    defaultValues: defaultValues,
  });

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { isSubmitting, isDirty },
  } = formMethods;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const healthDiabetesValue: boolean | null = watch("health_diabetes");
  const showHealthBloodSugarField = healthDiabetesValue === true;

  useEffect(() => {
    if (!showHealthBloodSugarField) {
      setValue("health_blood_sugar", null, { shouldValidate: true, shouldDirty: true, shouldTouch: true });
    }
  }, [showHealthBloodSugarField]);

  const theme: Theme = useTheme();
  const breakpointMD = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <Grid container spacing={2} py={2}>
          <Grid item xs={12}>
            <Box mb={3}>
              <Grid
                item
                display="flex"
                flexDirection={{ xs: "column", md: "row" }}
                alignItems={{ xs: "flex-start", md: "center" }}
                justifyContent={{ xs: "flex-start", md: "space-between" }}
              >
                <Grid item>
                  <Typography variant="h1">
                    My{" "}
                    <Typography display="inline" variant="h1" component="span" color="link.main">
                      Health.
                    </Typography>
                  </Typography>
                </Grid>

                <Grid item>
                  <LoadingButton
                    type="submit"
                    variant={pwadProfileIsCompleted ? "outlined" : "contained"}
                    size={breakpointMD ? "medium" : "small"}
                    sx={breakpointMD ? undefined : { marginTop: 2 }}
                    loading={isSubmitting}
                    disabled={isSubmitting}
                  >
                    Save My Health
                  </LoadingButton>
                </Grid>
              </Grid>

              <Alert severity="info" sx={{ mt: 3 }}>
                In line with the fitness industry standard practice used by AUSactive, Exercise and Sports Science
                Australia (ESSA), and Sports Medicine Australia (SMA), WeFlex requires all participants to complete this
                standardised pre-exercise screening to ensure we understand any risk factors that may need to be
                considered prior to a participant commencing any physical activity with WeFlex.
              </Alert>
            </Box>
          </Grid>

          <Grid item lg={6} xs={12}>
            <ProfileCard title="Health" Icon={HealthAndSafetyIcon}>
              <ColumnBox gap={3}>
                <Controller
                  control={control}
                  name={PWADHealthFieldEnum.health_heart_condition_stroke}
                  render={({ field, fieldState }) => (
                    <SelectControl
                      options={SELECT_BOOLEAN_OPTIONS}
                      label={PWADHealthFieldLabel[PWADHealthFieldEnum.health_heart_condition_stroke]}
                      required
                      labelSeparate
                      field={field}
                      fieldState={fieldState}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name={PWADHealthFieldEnum.health_chest_pain}
                  render={({ field, fieldState }) => (
                    <SelectControl
                      options={SELECT_BOOLEAN_OPTIONS}
                      label={PWADHealthFieldLabel[PWADHealthFieldEnum.health_chest_pain]}
                      required
                      labelSeparate
                      field={field}
                      fieldState={fieldState}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name={PWADHealthFieldEnum.health_faint_dizzy_exercise}
                  render={({ field, fieldState }) => (
                    <SelectControl
                      options={SELECT_BOOLEAN_OPTIONS}
                      label={PWADHealthFieldLabel[PWADHealthFieldEnum.health_faint_dizzy_exercise]}
                      required
                      labelSeparate
                      field={field}
                      fieldState={fieldState}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name={PWADHealthFieldEnum.health_asthma_attack_last_12_months}
                  render={({ field, fieldState }) => (
                    <SelectControl
                      options={SELECT_BOOLEAN_OPTIONS}
                      label={PWADHealthFieldLabel[PWADHealthFieldEnum.health_asthma_attack_last_12_months]}
                      required
                      labelSeparate
                      field={field}
                      fieldState={fieldState}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name={PWADHealthFieldEnum.health_diabetes}
                  render={({ field, fieldState }) => (
                    <SelectControl
                      options={SELECT_BOOLEAN_OPTIONS}
                      label={PWADHealthFieldLabel[PWADHealthFieldEnum.health_diabetes]}
                      required
                      labelSeparate
                      field={field}
                      fieldState={fieldState}
                    />
                  )}
                />

                {showHealthBloodSugarField && (
                  <Controller
                    control={control}
                    name={PWADHealthFieldEnum.health_blood_sugar}
                    render={({ field, fieldState }) => (
                      <SelectControl
                        options={SELECT_BOOLEAN_OPTIONS}
                        label={PWADHealthFieldLabel[PWADHealthFieldEnum.health_blood_sugar]}
                        required
                        labelSeparate
                        field={field}
                        fieldState={fieldState}
                      />
                    )}
                  />
                )}

                <Controller
                  control={control}
                  name={PWADHealthFieldEnum.health_other_conditions}
                  render={({ field, fieldState }) => (
                    <SelectControl
                      options={SELECT_BOOLEAN_OPTIONS}
                      label={PWADHealthFieldLabel[PWADHealthFieldEnum.health_other_conditions]}
                      required
                      labelSeparate
                      field={field}
                      fieldState={fieldState}
                    />
                  )}
                />
              </ColumnBox>
            </ProfileCard>
          </Grid>
        </Grid>
      </form>

      <UnsavedChangesPrompt showPrompt={isDirty} />
    </FormProvider>
  );
}

interface Props extends RouteComponentProps {
  setSavedSnackbarMessage: Dispatch<SetStateAction<string>>;
}

export default function MyHealthForm({ setSavedSnackbarMessage, history }: Props): JSX.Element {
  const { profileId } = useParams<{ profileId: string }>();

  const { refetchCurrentUser } = useAuth();

  const [errorDialogMessages, setErrorDialogMessages] = useState<Array<string>>([]);

  const {
    data: myHealthData,
    error: myHealthError,
    isLoading: myHealthLoading,
    refetch: myHealthRefetch,
  } = useQuery<PWADProfileMyHealthModel, ErrorResponse>(`/pwadprofile/my-health/?pwad_profile=${profileId}`);

  const { mutateAsync: editPWADProfileMyHealth } = useEditPWADProfileMyHealth(profileId);

  const defaultValues = useMemo((): MyHealthFormInput | null => {
    if (!myHealthData) {
      return null;
    }

    return myHealthData;
  }, [myHealthData]);

  const onSubmit: SubmitHandler<MyHealthFormInput> = async (values) => {
    try {
      await editPWADProfileMyHealth({
        pwad_profile: values,
      });

      await myHealthRefetch();
      await refetchCurrentUser();

      setSavedSnackbarMessage("My Health saved successfully.");

      history.push(generatePath(ROUTES.PWAD_PROFILE_CONSENT, { profileId }));
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);

      if (isErrorResponse(error)) {
        setErrorDialogMessages(getErrorMessages(error.response.data?.message));
      } else {
        setErrorDialogMessages([GENERIC_REQUEST_ERROR_MESSAGE]);
      }
    }
  };

  const onError: SubmitErrorHandler<MyHealthFormInput> = () => {
    setErrorDialogMessages([GENERIC_SUBMIT_VALIDATION_ERROR_MESSAGE]);
  };

  if (myHealthLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (myHealthError) {
    return <ErrorAlert message="Profile details failed to load" />;
  }

  if (!defaultValues) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <MyHealthReactHookForm defaultValues={defaultValues} onSubmit={onSubmit} onError={onError} />

      {!!errorDialogMessages.length && (
        <ErrorDialog title="Unable to save" messages={errorDialogMessages} onClose={() => setErrorDialogMessages([])} />
      )}
    </>
  );
}
