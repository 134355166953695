import { Fragment } from "react";
import CheckIcon from "@mui/icons-material/Check";
import { Paper, Avatar, Typography, Divider, Box, Link } from "@mui/material";
import { generatePath, Link as RouterLink } from "react-router-dom";
import { SessionReportReturnToEnum, SessionReportStepsItemModel, SESSION_REPORT_STEPS } from "./SessionReportConstants";
import * as ROUTES from "../../constants/routes";
import ColumnBox from "../common/ColumnBox";

interface Props {
  isCompleted: boolean;
  bookingId: string;
  activeStepIndex: number;
  returnTo: SessionReportReturnToEnum;
}

export default function SessionReportStepsDesktop({ bookingId, isCompleted, activeStepIndex, returnTo }: Props) {
  function renderIncompleteStep({ key, label }: SessionReportStepsItemModel, index: number) {
    let avatarBg: string;
    if (activeStepIndex > index) {
      avatarBg = "link.main";
    } else if (activeStepIndex === index) {
      avatarBg = "black";
    } else {
      avatarBg = "grey.300";
    }

    return (
      <Fragment key={key}>
        <Box display="flex" gap={2} alignItems="center">
          <Avatar sx={{ width: 24, height: 24, bgcolor: avatarBg }}>
            {index < activeStepIndex ? (
              <CheckIcon fontSize="small" />
            ) : (
              <Typography variant="body2" color={index === activeStepIndex ? "white" : "black"}>
                {index + 1}
              </Typography>
            )}
          </Avatar>
          <Typography variant="h4">{label}</Typography>
        </Box>
        {index < SESSION_REPORT_STEPS.length - 1 && <Divider />}
      </Fragment>
    );
  }

  function renderCompleteStep({ key, label }: SessionReportStepsItemModel, index: number) {
    let avatarBg: string;

    if (activeStepIndex === index) {
      avatarBg = "black";
    } else {
      avatarBg = "link.main";
    }

    return (
      <Fragment key={key}>
        <Box display="flex" gap={2} alignItems="center">
          <Avatar sx={{ width: 24, height: 24, bgcolor: avatarBg }}>
            <CheckIcon fontSize="small" />
          </Avatar>

          {index !== activeStepIndex ? (
            <Typography>
              <Link
                component={RouterLink}
                to={generatePath(ROUTES.SESSION_REPORT_DETAILS, { bookingId, activeStep: key, returnTo })}
                sx={{ fontSize: "14px" }}
                variant="link1"
                color="link.main"
              >
                {label}
              </Link>
            </Typography>
          ) : (
            <Typography variant="h4">{label}</Typography>
          )}
        </Box>

        {index < SESSION_REPORT_STEPS.length - 1 && <Divider />}
      </Fragment>
    );
  }

  function renderStep(step: SessionReportStepsItemModel, index: number) {
    if (isCompleted) {
      return renderCompleteStep(step, index);
    }

    return renderIncompleteStep(step, index);
  }

  return (
    <ColumnBox component={Paper} p={4} flex={1} maxWidth={349} gap={3}>
      {SESSION_REPORT_STEPS.map((step, index) => renderStep(step, index))}
    </ColumnBox>
  );
}
