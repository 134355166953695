import React from "react";
import { LoadingButton } from "@mui/lab";
import { Typography, Divider, Paper, Box } from "@mui/material";
import { isEmpty } from "lodash";
import { generatePath } from "react-router-dom";
import ENV from "../../../../constants/envConstants";
import * as ROUTES from "../../../../constants/routes";
import { BookingModel, BookingStatusEnum } from "../../../../models/Booking";
import BookingItemOutcome from "../../../common/BookingItemOutcome";
import Dot from "../../../common/Dot";
import SessionRow from "../../../common/SessionRow";
import Status from "../../../common/Status";

interface Props {
  bookings: BookingModel[];
  onCancelBooking(sessionId: string): void;
  loading: { id: string } | null;
}

function renderStatus(bookingSession: BookingModel) {
  switch (bookingSession.status) {
    case BookingStatusEnum.ACCEPTED:
      return <Dot color="success" sx={{ mt: 1 }} />;
    case BookingStatusEnum.PENDING:
      return <Dot color="warning" sx={{ mt: 1 }} />;
    case BookingStatusEnum.CANCELED:
      return <Dot color="error" sx={{ mt: 1 }} />;
    case BookingStatusEnum.DECLINED:
      return <Dot color="error" sx={{ mt: 1 }} />;
    default:
      return null;
  }
}

export default function BookingList({ bookings, onCancelBooking, loading }: Props): JSX.Element {
  if (isEmpty(bookings)) {
    return (
      <Paper sx={{ p: 4, display: "flex", justifyContent: "center" }} elevation={0}>
        <Typography>You have no sessions booked this month.</Typography>
      </Paper>
    );
  }

  function renderActions(booking: BookingModel) {
    switch (booking.status) {
      case BookingStatusEnum.ACCEPTED:
        return (
          <Box display="flex" flex={{ lg: 0.8, xs: 1 }} justifyContent="space-between" alignItems="center">
            {!!booking.outcome && <BookingItemOutcome outcome={booking.outcome} />}

            {!booking.outcome && <div />}

            <LoadingButton
              loading={loading?.id === booking.id}
              disabled={loading?.id === booking.id}
              variant="outlined"
              onClick={() => {
                onCancelBooking(booking.id);
              }}
            >
              Cancel
            </LoadingButton>
          </Box>
        );

      case BookingStatusEnum.PENDING:
        return (
          <Box display="flex" flex={{ lg: 0.8, xs: 1 }} justifyContent="space-between" alignItems="center">
            <Status color="warning" label="Pending" />

            <LoadingButton
              loading={loading?.id === booking.id}
              disabled={loading?.id === booking.id}
              variant="outlined"
              onClick={() => {
                onCancelBooking(booking.id);
              }}
            >
              Cancel
            </LoadingButton>
          </Box>
        );
      case BookingStatusEnum.CANCELED:
        return (
          <Box display="flex" flex={{ lg: 0.8, xs: 1 }} justifyContent="flex-start" alignItems="center">
            <Status color="error" label="Cancelled" />
          </Box>
        );
      case BookingStatusEnum.DECLINED:
        return (
          <Box display="flex" flex={{ lg: 0.8, xs: 1 }} justifyContent="flex-start" alignItems="center">
            <Status color="error" label="Unavailable" />
          </Box>
        );
      default:
        return null;
    }
  }

  return (
    <Box>
      {bookings.map((booking, index, array) => (
        <React.Fragment key={booking.id}>
          <SessionRow
            date={booking.session.date}
            startTime={booking.session.start_time}
            person={booking.session.fp_profile.person}
            profileLinkUrl={generatePath(ROUTES.PWAD_VIEW_FP_PROFILE, { fpId: booking.session.fp_profile.id })}
            personTitle="Fitness professional"
            emailPersonLink={`mailto:${encodeURIComponent(
              booking.session.fp_profile.person.email,
            )}?cc=${encodeURIComponent(ENV.CC_EMAIL_ADDRESS!)}`}
            location={booking.session.session_location}
            showDirectionsLink
            renderDateStatus={() => renderStatus(booking)}
            renderActions={() => renderActions(booking)}
          />

          {index < array.length - 1 && <Divider />}
        </React.Fragment>
      ))}
    </Box>
  );
}
