import React from "react";
import { Box, Link, Theme, Typography } from "@mui/material";
import { SxProps } from "@mui/system";
import { useFormContext } from "react-hook-form";
import * as yup from "yup";
import { CustomerTypeEnum } from "./CustomerType";
import { StepProps } from "./Step";
import ENV from "../../../constants/envConstants";
import FormCheckboxField from "../../../form/FormCheckboxField";
import { RepresentativeRelationshipEnum, RepresentativeRelationshipLabel } from "../../../models/PWADProfile";
import ProfileCardTitleWithContent from "../../common/ProfileCardTitleWithContent";

export type ConfirmInput = {
  tandc?: boolean;
};

export const ConfirmStepValidationSchema: yup.SchemaOf<ConfirmInput> = yup.object({
  tandc: yup.bool().oneOf([true], "You must acknowledge that you have read and agree to the Terms and Conditions."),
});

const YOU_ARE_SEEKING_VALUES: { [type: string]: string } = {
  [CustomerTypeEnum.FP]: "To become a WeFlex fitness professional",
  [CustomerTypeEnum.PWAD_MYSELF]: "WeFlex services for myself",
  [CustomerTypeEnum.PWAD_REPRESENTATIVE]: "WeFlex services for another person",
};

const valueStyle: SxProps<Theme> = { paddingBottom: 2, borderBottom: 1, borderColor: "grey.300" };

const ConfirmStep = ({ name }: StepProps): JSX.Element => {
  const { getValues } = useFormContext();

  const customerType: CustomerTypeEnum = getValues("customer.type");
  const isRepresentative = customerType === CustomerTypeEnum.PWAD_REPRESENTATIVE;

  const pwadEmailValue = getValues("details.email") as string;
  const pwadMobileValue = getValues("details.mobile") as string;
  const repRelationshipValue = getValues("details.representedBy.relationship") as RepresentativeRelationshipEnum | "";

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="space-between">
      <Box maxWidth="md">
        <Typography variant="h1" align="center">
          Confirm details
        </Typography>
      </Box>

      <Box maxWidth="sm" mt={1}>
        <Typography variant="body1" align="center">
          To update these details, click the &quot;Back&quot; button.
        </Typography>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        alignItems="stretch"
        justifyContent="space-between"
        width="100%"
        maxWidth="sm"
        mt={5}
        gap={2}
      >
        {isRepresentative && (
          <Typography variant="h2" align="center">
            Who I&apos;m representing
          </Typography>
        )}

        <Box sx={valueStyle}>
          <ProfileCardTitleWithContent
            title="Name"
            content={`${getValues("details.firstName")} ${getValues("details.lastName")}`}
          />
        </Box>

        {!isRepresentative && (
          <Box sx={valueStyle}>
            <ProfileCardTitleWithContent
              title="I'm seeking"
              content={YOU_ARE_SEEKING_VALUES[getValues("customer.type")]}
            />
          </Box>
        )}

        {!!pwadEmailValue && (
          <Box sx={valueStyle}>
            <ProfileCardTitleWithContent title="Email" content={pwadEmailValue} />
          </Box>
        )}

        {!!pwadMobileValue && (
          <Box sx={valueStyle}>
            <ProfileCardTitleWithContent title="Mobile" content={pwadMobileValue} />
          </Box>
        )}

        {isRepresentative && (
          <>
            <Typography variant="h2" align="center" mt={4}>
              My details
            </Typography>

            <Box sx={valueStyle}>
              <ProfileCardTitleWithContent
                title="Name"
                content={`${getValues("details.representedBy.firstName")} ${getValues(
                  "details.representedBy.lastName",
                )}`}
              />
            </Box>

            <Box sx={valueStyle}>
              <ProfileCardTitleWithContent
                title="I'm seeking"
                content={YOU_ARE_SEEKING_VALUES[getValues("customer.type")]}
              />
            </Box>

            {!!repRelationshipValue && (
              <Box sx={valueStyle}>
                <ProfileCardTitleWithContent
                  title="Relationship"
                  content={RepresentativeRelationshipLabel[repRelationshipValue]}
                />
              </Box>
            )}

            <Box sx={valueStyle}>
              <ProfileCardTitleWithContent title="Email" content={getValues("details.representedBy.email")} />
            </Box>

            <Box sx={valueStyle}>
              <ProfileCardTitleWithContent title="Mobile" content={getValues("details.representedBy.mobile")} />
            </Box>
          </>
        )}

        <FormCheckboxField
          name={`${name}.tandc`}
          label={
            <Typography variant="body2">
              I have read and agree to the WeFlex{" "}
              <Link href={ENV.WEFLEX_TERMS_AND_CONDITIONS_URL} variant="body2" target="_blank">
                Terms and Conditions
              </Link>
              .
            </Typography>
          }
          labelStyle={{ mr: 0 }}
        />
      </Box>
    </Box>
  );
};

export default ConfirmStep;
