import React from "react";
import { Paper, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import { useParams } from "react-router-dom";
import BookingList from "./BookingList";
import BulkBookingList from "./BulkBookingList";
import { BookingModel, BookingStatusEnum } from "../../../../models/Booking";

interface Props {
  groupedBookings: Record<BookingStatusEnum, BookingModel[]>;
  onAcceptBooking(sessionId: string): void;
  onDeclineBooking(sessionId: string): void;
  loading: { id: string; action: "accept" | "decline" } | null;
  refetchBookings: any;
}

const GenericBookingList = ({
  groupedBookings,
  onAcceptBooking,
  onDeclineBooking,
  loading,
  refetchBookings,
}: Props) => {
  const { status } = useParams<{ status: BookingStatusEnum }>();
  const bookings = groupedBookings[status];

  if (isEmpty(bookings)) {
    return (
      <Paper sx={{ p: 4, display: "flex", justifyContent: "center", mt: 2 }} elevation={0}>
        <Typography>You have no sessions booked this month.</Typography>
      </Paper>
    );
  }

  if (status === BookingStatusEnum.PENDING) {
    return <BulkBookingList bookings={bookings} refetchBookings={refetchBookings} />;
  }

  return (
    <BookingList
      groupedBookings={groupedBookings}
      onAcceptBooking={onAcceptBooking}
      onDeclineBooking={onDeclineBooking}
      loading={loading}
    />
  );
};
export default GenericBookingList;
