import React from "react";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import MailIcon from "@mui/icons-material/Mail";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/PhoneAndroid";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { FPProfileAboutMeModel, FPProfileAboutMeModelAsPWAD } from "../../models/FPProfile";
import { useAuth } from "../../utils/AuthProvider";
import ColumnBox from "../common/ColumnBox";
import ProfileCard from "../common/ProfileCard";
import ProfileCardIconWithContent, { IconContentType } from "../common/ProfileCardIconWithContent";

interface Props {
  aboutMeData?: FPProfileAboutMeModel | FPProfileAboutMeModelAsPWAD;
}

export default function FPBasicInformation({ aboutMeData }: Props) {
  const iconColor = "grey.600";
  const { currentUser } = useAuth();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ProfileCard title="Basic Information" Icon={PersonIcon}>
        <ColumnBox gap={3}>
          {!currentUser?.is_pwad && (
            <ProfileCardIconWithContent content={aboutMeData?.person.email} Icon={MailIcon} iconColor={iconColor} />
          )}

          <ProfileCardIconWithContent content={aboutMeData?.person.phone} Icon={PhoneIcon} iconColor={iconColor} />

          <ProfileCardIconWithContent
            content={aboutMeData?.person.dob}
            contentType={IconContentType.DATE}
            Icon={CalendarTodayIcon}
            iconColor={iconColor}
          />

          <ProfileCardIconWithContent
            content={aboutMeData?.about_me_social_insta}
            Icon={InstagramIcon}
            iconColor={iconColor}
          />

          <ProfileCardIconWithContent
            content={aboutMeData?.about_me_social_fb}
            Icon={FacebookIcon}
            iconColor={iconColor}
          />
        </ColumnBox>
      </ProfileCard>
    </LocalizationProvider>
  );
}
