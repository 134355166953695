import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import MailIcon from "@mui/icons-material/Mail";
import PeopleIcon from "@mui/icons-material/People";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/PhoneAndroid";
import { trim } from "lodash";
import { PWADProfileAboutMeModel } from "../../models/PWADProfile";
import ColumnBox from "../common/ColumnBox";
import ProfileCard from "../common/ProfileCard";
import ProfileCardIconWithContent from "../common/ProfileCardIconWithContent";

interface Props {
  aboutMeData: PWADProfileAboutMeModel;
}

export default function PWADEmergencyContactInformation({ aboutMeData }: Props) {
  const emergencyFullName = trim(`${aboutMeData.emergency_first_name} ${aboutMeData.emergency_last_name}`);

  if (!emergencyFullName) {
    return null;
  }

  const iconColor = "grey.600";

  return (
    <ProfileCard title="Emergency Contact" Icon={ContactPhoneIcon}>
      <ColumnBox gap={3}>
        <ProfileCardIconWithContent content={emergencyFullName} Icon={PersonIcon} iconColor={iconColor} />

        <ProfileCardIconWithContent
          content={aboutMeData.emergency_relationship}
          Icon={PeopleIcon}
          iconColor={iconColor}
        />

        <ProfileCardIconWithContent content={aboutMeData.emergency_email} Icon={MailIcon} iconColor={iconColor} />

        <ProfileCardIconWithContent content={aboutMeData.emergency_phone} Icon={PhoneIcon} iconColor={iconColor} />
      </ColumnBox>
    </ProfileCard>
  );
}
