import { Box, Typography } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import { isNil } from "lodash";
import Dot, { DotColors } from "./Dot";

interface Props {
  label: string;
  color?: DotColors;
  variant?: Variant;
}

export default function Status({ label, color, variant }: Props) {
  return (
    <Box display="flex" alignItems="center" gap={1} justifyContent="center" mr={2}>
      {!isNil(color) ? <Dot color={color} /> : null}
      <Typography variant={variant ?? "inherit"}>{label}</Typography>
    </Box>
  );
}
