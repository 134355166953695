import { Grid, Link, Theme, Typography } from "@mui/material";
import { SxProps } from "@mui/system";
import ENV from "../../../constants/envConstants";
import { currentYear } from "../../../utils/formatDateTime";

export const FOOTER_HEIGHT = 44;

const footerSxProps: SxProps<Theme> = {
  height: FOOTER_HEIGHT,
  display: "flex",
  backgroundColor: "text.primary",
  alignContent: "center",
  justifyContent: "center",
};

const Footer = (): JSX.Element => (
  <Grid container sx={footerSxProps}>
    <Typography variant="body2" color="#FFFFFF">
      &copy; {currentYear} WeFlex Pty Ltd |{" "}
      <Link href={ENV.WEFLEX_TERMS_AND_CONDITIONS_URL} variant="body2" color="#FFFFFF" target="_blank">
        Terms and Conditions
      </Link>
    </Typography>
  </Grid>
);

export default Footer;
