import { useCallback, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CardMedia,
  Container,
  Fade,
  FormControlLabel,
  Link,
  Radio,
  Typography,
} from "@mui/material";
import { useHistory, Link as RouterLink } from "react-router-dom";
import fpImage from "../../assets/img/fp.jpeg";
import pwadImage from "../../assets/img/pwad.jpeg";
import * as ROUTES from "../../constants/routes";
import useDocumentTitles from "../../utils/useDocumentTitles";

export default function CreateAccount(): JSX.Element {
  useDocumentTitles("WeFlex Portal - Health and Fitness for Every Body");

  const [role, setRole] = useState<"pwad" | "fp" | null>(null);
  const [inAnimation, setInAnimation] = useState<boolean>(true);

  const history = useHistory();

  const handleClickOnLetsGo = (): void => {
    setInAnimation(false);
  };

  const goToJoinNow = useCallback(() => {
    if (role === "pwad") {
      history.push(ROUTES.JOIN_NOW_PWAD);
    } else if (role === "fp") {
      history.push(ROUTES.JOIN_NOW_FP);
    }
  }, [history, role]);

  return (
    <Container maxWidth="lg">
      <Fade in={inAnimation} enter={false} onExited={goToJoinNow}>
        <Box display="flex" flexDirection="column">
          <Typography variant="h1" align="center" mt={4}>
            Create a WeFlex account
          </Typography>

          <Typography variant="body1" align="center" mt={1}>
            Get started with WeFlex today, it only takes about 2-3 minutes.
          </Typography>

          <Typography variant="h3" align="center" mt={4} mb={2}>
            I want to become a...
          </Typography>

          <Box display="flex" flexWrap="wrap" justifyContent="center" gap={2}>
            <Card raised sx={{ maxWidth: "350px" }}>
              <CardActionArea onClick={() => setRole("pwad")}>
                <CardHeader
                  title={
                    <FormControlLabel
                      value="pwad"
                      control={<Radio checked={role === "pwad"} />}
                      label={<Typography variant="h3">WeFlex client</Typography>}
                    />
                  }
                />
                <CardMedia component="img" alt="pwad" image={pwadImage} sx={{ height: "250px" }} />
                <CardContent>
                  <Typography>Connect with fitness professionals who match your needs.</Typography>
                </CardContent>
              </CardActionArea>
            </Card>
            <Card raised sx={{ maxWidth: "350px" }}>
              <CardActionArea onClick={() => setRole("fp")}>
                <CardHeader
                  title={
                    <FormControlLabel
                      value="pt"
                      control={<Radio checked={role === "fp"} />}
                      label={<Typography variant="h3">WeFlex fitness professional</Typography>}
                    />
                  }
                />
                <CardMedia component="img" alt="fp" image={fpImage} sx={{ height: "250px" }} />
                <CardContent>
                  <Typography>
                    Be an inclusive champion in no time with our support, training and connections.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Box>

          <Container maxWidth="sm">
            <Box
              display="flex"
              alignSelf="center"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              mt={4}
            >
              <Button fullWidth variant="contained" size="large" onClick={handleClickOnLetsGo} disabled={role === null}>
                Get Started
              </Button>

              <Typography variant="body2" my={4}>
                Already have an account?{" "}
                <Link variant="link1" color="link.main" component={RouterLink} to={ROUTES.LOGIN}>
                  <b>Log in</b>
                </Link>
              </Typography>
            </Box>
          </Container>
        </Box>
      </Fade>
    </Container>
  );
}
