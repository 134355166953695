import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { Box, CircularProgress, Container, useMediaQuery } from "@mui/material";
import { isNil } from "lodash";
import { generatePath, Redirect, Route, RouteComponentProps, Switch, useParams } from "react-router-dom";
import AboutMe from "./AboutMe";
import Business from "./Business";
import CurrentStepStatusAlert from "./CurrentStepStatusAlert";
import Documents from "./Documents";
import AboutMeForm from "./edit/AboutMeForm";
import MyBusinessForm from "./edit/MyBusinessForm";
import MyDocumentsForm from "./edit/MyDocumentsForm";
import MyServicesForm from "./edit/MyServicesForm";
import PayoutDetailsForm from "./edit/PayoutDetailsForm";
import SubmitForm from "./edit/SubmitForm";
import TermsForm from "./edit/TermsForm";
import Payout from "./Payout";
import Services from "./Services";
import Statistics from "./Statistics";
import Terms from "./Terms";
import { useGetFPProfileAboutMe } from "../../../api/queries/FPProfile";
import { profileStepNumber } from "../../../constants/fpProfileConstants";
import * as ROUTES from "../../../constants/routes";
import { FPProfileStatus } from "../../../models/ProfileBase";
import theme from "../../../styles/_theme";
import { useAuth } from "../../../utils/AuthProvider";
import { getFPProfileIsLive, useCurrentUserProfileIsEditable, getFPProfileStatus } from "../../../utils/person";
import ErrorAlert from "../../common/ErrorAlert";
import NavTabs from "../../common/NavTabs";
import SnackbarAlert from "../../common/SnackbarAlert";

interface Props extends RouteComponentProps {
  setProfileRootSnackbarMessage: Dispatch<SetStateAction<string>>;
}

export default function Profile({ setProfileRootSnackbarMessage }: Props): JSX.Element {
  const { currentUser } = useAuth();
  const isProfileEditable = useCurrentUserProfileIsEditable();
  const { profileId } = useParams<{ profileId: string }>();
  const mobileMode = useMediaQuery(theme.breakpoints.down("lg"));
  const { data: aboutMeData, isLoading: aboutMeLoading, error: aboutMeError } = useGetFPProfileAboutMe(profileId);
  // Note: this does not user the current user because the current user could be an admin looking at an FP's profile.
  const isProfileLive = getFPProfileIsLive(aboutMeData);
  const status = getFPProfileStatus(aboutMeData);

  const [savedSnackbarMessage, setSavedSnackbarMessage] = useState("");

  const navTabItems = useMemo(() => {
    if (isProfileLive === false) {
      const notLiveTabItems = [
        {
          label: "About Me",
          path: generatePath(ROUTES.FP_PROFILE_ABOUT_ME, { profileId }),
        },
        {
          label: "My Business",
          path: generatePath(ROUTES.FP_PROFILE_MY_BUSINESS, { profileId }),
        },
        {
          label: "My Services",
          path: generatePath(ROUTES.FP_PROFILE_MY_SERVICES, { profileId }),
        },
        {
          label: "My Documents",
          path: generatePath(ROUTES.FP_PROFILE_MY_DOCUMENTS, { profileId }),
        },
        {
          label: "Payout Details",
          path: generatePath(ROUTES.FP_PROFILE_PAYOUT_DETAILS, { profileId }),
        },
        {
          label: "Terms",
          path: generatePath(ROUTES.FP_PROFILE_TERMS, { profileId }),
        },
      ];

      if (status === FPProfileStatus.PROFILE_CREATED) {
        notLiveTabItems.push({
          label: "Submit",
          path: generatePath(ROUTES.FP_PROFILE_SUBMIT, { profileId }),
        });
      }
      return notLiveTabItems;
    }

    const items = [
      { label: "About Me", path: generatePath(ROUTES.FP_PROFILE_ABOUT_ME, { profileId }) },
      { label: "My Business", path: generatePath(ROUTES.FP_PROFILE_MY_BUSINESS, { profileId }) },
      { label: "My Documents", path: generatePath(ROUTES.FP_PROFILE_MY_DOCUMENTS, { profileId }) },
      { label: "My Services", path: generatePath(ROUTES.FP_PROFILE_MY_SERVICES, { profileId }) },
      { label: "Payout Details", path: generatePath(ROUTES.FP_PROFILE_PAYOUT_DETAILS, { profileId }) },
      { label: "Terms", path: generatePath(ROUTES.FP_PROFILE_TERMS, { profileId }) },
    ];

    if (currentUser?.is_admin) {
      items.push({ label: "Statistics", path: generatePath(ROUTES.FP_PROFILE_STATISTICS, { profileId }) });
    }

    return items;
  }, [isProfileLive, profileId, currentUser, status]);

  if (aboutMeLoading || !aboutMeData || isNil(status)) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (aboutMeError) {
    return <ErrorAlert message="Profile details failed to load" />;
  }

  return (
    <Container maxWidth="xl" sx={{ px: 2 }}>
      <CurrentStepStatusAlert stepNumber={profileStepNumber} status={status} isLive={!!isProfileLive} />
      <NavTabs
        scrollButtons="auto"
        allowScrollButtonsMobile
        variant={mobileMode ? "scrollable" : "standard"}
        sx={{ ".MuiTabs-flexContainer": { py: { lg: 1 } } }}
        items={navTabItems}
      />

      {isProfileEditable && (
        <Switch>
          <Route
            path={ROUTES.FP_PROFILE_ABOUT_ME}
            render={(props) => <AboutMeForm {...props} setSavedSnackbarMessage={setSavedSnackbarMessage} />}
          />
          <Route
            path={ROUTES.FP_PROFILE_MY_BUSINESS}
            render={(props) => <MyBusinessForm {...props} setSavedSnackbarMessage={setSavedSnackbarMessage} />}
          />
          <Route
            path={ROUTES.FP_PROFILE_MY_SERVICES}
            render={(props) => <MyServicesForm {...props} setSavedSnackbarMessage={setSavedSnackbarMessage} />}
          />
          <Route
            path={ROUTES.FP_PROFILE_MY_DOCUMENTS}
            render={(props) => <MyDocumentsForm {...props} setSavedSnackbarMessage={setSavedSnackbarMessage} />}
          />
          <Route
            path={ROUTES.FP_PROFILE_PAYOUT_DETAILS}
            render={(props) => <PayoutDetailsForm {...props} setSavedSnackbarMessage={setSavedSnackbarMessage} />}
          />

          <Route
            path={ROUTES.FP_PROFILE_TERMS}
            render={(props) => <TermsForm {...props} setSavedSnackbarMessage={setSavedSnackbarMessage} />}
          />
          <Route
            path={ROUTES.FP_PROFILE_SUBMIT}
            render={(props) => <SubmitForm {...props} setSavedSnackbarMessage={setProfileRootSnackbarMessage} />}
          />
          <Redirect to={ROUTES.FP_PROFILE_ABOUT_ME} />
        </Switch>
      )}
      {!isProfileEditable && (
        <Switch>
          <Route exact path={ROUTES.FP_PROFILE_ABOUT_ME} component={AboutMe} />
          <Route exact path={ROUTES.FP_PROFILE_MY_BUSINESS} component={Business} />
          <Route exact path={ROUTES.FP_PROFILE_MY_SERVICES} component={Services} />
          <Route exact path={ROUTES.FP_PROFILE_MY_DOCUMENTS} component={Documents} />
          <Route exact path={ROUTES.FP_PROFILE_PAYOUT_DETAILS} component={Payout} />
          <Route exact path={ROUTES.FP_PROFILE_TERMS} component={Terms} />

          <Route exact path={ROUTES.FP_PROFILE_STATISTICS} component={Statistics} />
          <Redirect to={ROUTES.FP_PROFILE_ABOUT_ME} />
        </Switch>
      )}
      <SnackbarAlert
        title="Saved"
        content={savedSnackbarMessage}
        severity="success"
        open={!!savedSnackbarMessage}
        onClose={() => setSavedSnackbarMessage("")}
      />
    </Container>
  );
}
