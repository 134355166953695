import { Typography, Grid, Box, CircularProgress, Paper } from "@mui/material";
import { isEmpty } from "lodash";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { ErrorResponse } from "../../../api/Generic";
import { BookingWithSessionReportModel } from "../../../models/Booking";
import ErrorAlert from "../../common/ErrorAlert";
import PWADSessionReportList from "../../profile-cards/PWADSessionReportList";

export default function SessionReports(): JSX.Element | null {
  const { pwadProfileId } = useParams<{ pwadProfileId: string }>();

  const {
    data: bookingWithSessionReportData,
    isLoading: bookingWithSessionReportLoading,
    error: bookingWithSessionReportError,
  } = useQuery<{ bookings: BookingWithSessionReportModel[] }, ErrorResponse>(
    `/booking/sessions/pwad/?pwad_profile=${pwadProfileId}&is_session_report_completed=true`,
  );

  if (bookingWithSessionReportLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (bookingWithSessionReportError) {
    return <ErrorAlert message="Session reports failed to load" />;
  }

  if (!bookingWithSessionReportData) {
    return null;
  }

  if (isEmpty(bookingWithSessionReportData.bookings)) {
    return (
      <Paper sx={{ p: 4, display: "flex", justifyContent: "center", mt: 2 }} elevation={0}>
        <Typography>You have no completed session reports for this client</Typography>
      </Paper>
    );
  }

  return (
    <Grid container spacing={2} py={2}>
      <Grid item xs={12}>
        <Typography variant="h1" mb={3}>
          Session{" "}
          <Typography display="inline" variant="h1" component="span" color="link.main">
            Reports.
          </Typography>
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <PWADSessionReportList bookingsWithSessionReport={bookingWithSessionReportData.bookings} />
      </Grid>
    </Grid>
  );
}
