import { LoadingButton } from "@mui/lab";
import { Box, Button, useMediaQuery } from "@mui/material";
import { isNil } from "lodash";
import { SessionReportStepsEnum } from "./SessionReportConstants";
import theme from "../../styles/_theme";

interface Props {
  isBookingMarkedAsCompleted: boolean;
  isStarted: boolean;
  isCompleted: boolean;
  isEditMode: boolean;
  activeStep: SessionReportStepsEnum;
  canEdit: boolean;
  onEditClick: React.MouseEventHandler<HTMLButtonElement>;
  onPreviousClick: React.MouseEventHandler<HTMLButtonElement>;
  isSubmitPending?: boolean;
}

export default function SessionReportActions({
  isBookingMarkedAsCompleted,
  isStarted,
  isCompleted,
  isEditMode,
  activeStep,
  canEdit,
  onEditClick,
  onPreviousClick,
  isSubmitPending,
}: Props) {
  const mobileMode = useMediaQuery(theme.breakpoints.down("lg"));

  function renderButtons() {
    if (isCompleted && !canEdit) {
      return null;
    }

    if (!isBookingMarkedAsCompleted && !isStarted) {
      return null;
    }

    if (!isCompleted) {
      switch (activeStep) {
        case SessionReportStepsEnum.GOALS:
          return (
            <LoadingButton
              fullWidth={mobileMode}
              type="submit"
              form="session-report-detail-form"
              variant="contained"
              loading={isSubmitPending}
              sx={{ lineHeight: "30px" }}
            >
              {!isStarted ? "Start" : "Continue"}
            </LoadingButton>
          );
        case SessionReportStepsEnum.SUBMIT:
          return (
            <>
              <Button fullWidth={mobileMode} variant="outlined" onClick={onPreviousClick}>
                Previous
              </Button>
              <LoadingButton
                fullWidth={mobileMode}
                type="submit"
                form="session-report-detail-form"
                variant="contained"
                loading={isSubmitPending}
              >
                Submit
              </LoadingButton>
            </>
          );
        default:
          return (
            <>
              <Button fullWidth={mobileMode} variant="outlined" onClick={onPreviousClick}>
                Previous
              </Button>
              <LoadingButton
                fullWidth={mobileMode}
                type="submit"
                form="session-report-detail-form"
                variant="contained"
                loading={isSubmitPending}
              >
                {mobileMode ? "Save" : "Save and Continue"}
              </LoadingButton>
            </>
          );
      }
    } else {
      switch (activeStep) {
        case SessionReportStepsEnum.GOALS:
        case SessionReportStepsEnum.SUBMIT:
          return null;
        default:
          return (
            <>
              {isEditMode ? (
                <LoadingButton
                  fullWidth={mobileMode}
                  type="submit"
                  form="session-report-detail-form"
                  variant="contained"
                  loading={isSubmitPending}
                  sx={{ lineHeight: "30px" }}
                >
                  Save
                </LoadingButton>
              ) : (
                <Button fullWidth={mobileMode} variant="contained" onClick={onEditClick} sx={{ lineHeight: "30px" }}>
                  Edit
                </Button>
              )}
            </>
          );
      }
    }
  }

  const buttons = renderButtons();

  if (isNil(buttons)) {
    return null;
  }

  return (
    <Box
      display="flex"
      justifyContent={{ xs: "center", lg: "flex-end" }}
      gap={3}
      p={{ xs: 2, lg: 0 }}
      position={{ xs: "fixed", lg: "relative" }}
      bottom={{ xs: 0 }}
      left={{ xs: 0 }}
      width="100%"
      bgcolor={{ xs: "white", lg: "transparent" }}
      boxSizing="border-box"
      zIndex={2}
      boxShadow={{
        xs: "0px -3px 3px -2px rgba(0, 0, 0, 0.1), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 8px rgba(0, 0, 0, 0.1)",
        lg: "none",
      }}
    >
      {buttons}
    </Box>
  );
}
