export type ServerResponse<T = any> = {
  data: T;
};

export type ErrorResponse = {
  response: {
    status: number;
    data?: {
      message: string;
    };
  };
};

export function isErrorResponse(error: any): error is ErrorResponse {
  return (
    (<ErrorResponse>error).response?.status !== undefined &&
    (<ErrorResponse>error).response?.data !== undefined &&
    (<ErrorResponse>error).response?.data?.message !== undefined
  );
}
