// Images
export const IMAGE_MIME_TYPES = ["image/png", "image/jpg", "image/jpeg", "image/heic"];

// Adobe PDF
export const PDF_MIME_TYPES = ["application/pdf"];

// Microsoft Word
export const WORD_DOCUMENT_MIME_TYPES = [
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

// Microsoft Excel
export const EXCEL_DOCUMENT_MIME_TYPES = [
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];
