import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import DescriptionIcon from "@mui/icons-material/Description";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import { Link, Typography } from "@mui/material";
import * as Sentry from "@sentry/browser";
import { startsWith } from "lodash";
import { generatePath, Link as RouterLink } from "react-router-dom";
import ProfileCardIconWithContent from "./ProfileCardIconWithContent";
import { useGetPrivateFile, useGetPrivateFileUrl } from "../../api/mutations/PrivateFile";
import * as ROUTES from "../../constants/routes";

interface Props {
  privateFileId: string;
  viewFileText?: string;
}

export default function PrivateFileLink({ privateFileId, viewFileText }: Props) {
  const [isPrivateFileUrlLoading, setIsPrivateFileUrlLoading] = useState(false);

  const {
    data: privateFileData,
    isLoading: privateFileDataLoading,
    error: privateFileDataError,
  } = useGetPrivateFile(privateFileId);

  const { mutateAsync: getPrivateFileUrlAsync } = useGetPrivateFileUrl();

  const handleClickViewFile = async () => {
    try {
      if (isPrivateFileUrlLoading) {
        return;
      }

      setIsPrivateFileUrlLoading(true);

      const privateFileUrlResponse = await getPrivateFileUrlAsync({ fileId: privateFileId });

      setIsPrivateFileUrlLoading(false);

      window.open(privateFileUrlResponse.data.url, "_blank");
    } catch (e) {
      setIsPrivateFileUrlLoading(false);

      console.error(e);
      Sentry.captureException(e);
    }
  };

  const loadingContent = (
    <ProfileCardIconWithContent
      Icon={DescriptionIcon}
      iconColor="grey.600"
      content={<Typography>Loading saved file...</Typography>}
    />
  );

  if (privateFileDataLoading) {
    return loadingContent;
  }

  if (privateFileDataError) {
    return (
      <ProfileCardIconWithContent
        Icon={CloseIcon}
        iconColor="error.main"
        content={<Typography color="error.main">Saved file failed to load.</Typography>}
      />
    );
  }

  if (!privateFileData) {
    return loadingContent;
  }

  if (privateFileData.type === "application/pdf") {
    return (
      <ProfileCardIconWithContent
        Icon={PictureAsPdfIcon}
        iconColor="grey.600"
        content={
          <Typography>
            <Link
              component={RouterLink}
              to={generatePath(ROUTES.DOCUMENT_VIEWER, { fileId: privateFileId })}
              color="link.main"
              sx={{
                cursor: "pointer",
                textDecoration: "none",
                maxWidth: "100%",
                wordBreak: "break-all",
                overflowWrap: "break-word",
                whiteSpace: "normal",
              }}
            >
              {viewFileText ? viewFileText : `View ${privateFileData.file_name}`}
            </Link>
          </Typography>
        }
      />
    );
  }

  let Icon = DescriptionIcon;

  if (startsWith(privateFileData.type, "image/")) {
    Icon = PhotoLibraryIcon;
  } else if (startsWith(privateFileData.type, "video/")) {
    Icon = VideoLibraryIcon;
  }

  return (
    <ProfileCardIconWithContent
      Icon={Icon}
      iconColor="grey.600"
      content={
        <Typography
          onClick={handleClickViewFile}
          color="link.main"
          sx={{
            cursor: "pointer",
            textDecoration: "none",
            maxWidth: "100%",
            wordBreak: "break-all",
            overflowWrap: "break-word",
            whiteSpace: "normal",
          }}
        >
          {viewFileText ? viewFileText : `View ${privateFileData.file_name}`}
        </Typography>
      }
    />
  );
}
