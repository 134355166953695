import { Box, BoxProps } from "@mui/material";
import { isEmpty } from "lodash";
import ReactPlayer from "react-player";

interface Props {
  videoUrl: string | null | undefined;
  containerProps: BoxProps;
}

export default function VideoPlayer({ videoUrl, containerProps }: Props) {
  if (isEmpty(videoUrl)) {
    return null;
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width={{ lg: 485, xs: 320 }}
      height={{ lg: 273, xs: 200 }}
      {...containerProps}
    >
      <ReactPlayer width="100%" height="100%" url={videoUrl!} controls />
    </Box>
  );
}
