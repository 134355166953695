import React, { useCallback, useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Theme,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import * as Sentry from "@sentry/browser";
import { isNil } from "lodash";
import { useParams } from "react-router-dom";
import { isErrorResponse } from "../../../api/Generic";
import { useSubmitPWADProfile } from "../../../api/mutations/PWADProfile";
import { GENERIC_REQUEST_ERROR_MESSAGE } from "../../../constants/validation";
import { PWADProfileStatus } from "../../../models/ProfileBase";
import { useAuth } from "../../../utils/AuthProvider";
import getErrorMessages from "../../../utils/getErrorMessages";
import { getUserPWADProfileIsCompleted, getUserPWADProfileStatus } from "../../../utils/person";
import ErrorDialog from "../../common/ErrorDialog";
import SnackbarAlert from "../../common/SnackbarAlert";
import StepNumber from "../../common/StepNumber";

const ProfileCompletionBanner = () => {
  const { profileId } = useParams<{ profileId: string }>();

  const { currentUser, refetchCurrentUser } = useAuth();
  const pwadProfileStatus = getUserPWADProfileStatus(currentUser);
  const pwadProfileIsCompleted = getUserPWADProfileIsCompleted(currentUser);

  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [isSubmitModalVisible, setIsSubmitModalVisible] = useState(false);
  const [isSubmittedSnackbarVisible, setIsSubmittedSnackbarVisible] = useState<boolean>(false);
  const [errorDialogMessages, setErrorDialogMessages] = useState<Array<string>>([]);

  const { mutateAsync: submitPWADProfile } = useSubmitPWADProfile(profileId);

  const theme: Theme = useTheme();
  const breakpointSM = useMediaQuery(theme.breakpoints.up("sm"));
  const breakpointMD = useMediaQuery(theme.breakpoints.up("md"));

  const handleSubmitProfile = useCallback(async () => {
    try {
      setIsSubmitLoading(true);

      await submitPWADProfile();
      await refetchCurrentUser();

      setIsSubmittedSnackbarVisible(true);
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);

      if (isErrorResponse(error)) {
        setErrorDialogMessages(getErrorMessages(error.response.data?.message));
      } else {
        setErrorDialogMessages([GENERIC_REQUEST_ERROR_MESSAGE]);
      }
    }

    setIsSubmitModalVisible(false);
    setIsSubmitLoading(false);
  }, [profileId, refetchCurrentUser]);

  const renderLine = () => {
    if (!breakpointMD) {
      return null;
    }

    return <div style={{ height: 2, width: 60, marginLeft: 16, marginRight: 16, backgroundColor: "#bdbdbd" }} />;
  };

  if (isNil(pwadProfileStatus) || isNil(pwadProfileIsCompleted)) {
    return null;
  }

  if (pwadProfileStatus === PWADProfileStatus.SUBMITTED) {
    return null;
  }

  return (
    <Card sx={{ width: "100%", mb: 2 }}>
      <Grid
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexDirection={{ xs: "column", sm: "row" }}
        p={3}
      >
        <Grid
          item
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          alignItems={{ xs: "flex-start", md: "center" }}
        >
          <Grid item flexDirection="row" display="flex" alignItems="center">
            <CheckCircleIcon sx={{ color: "primary.main" }} fontSize="large" />
            <Box ml={1}>Sign Up</Box>
          </Grid>

          {renderLine()}

          <Grid item flexDirection="row" display="flex" alignItems="center">
            <Box display="flex" alignItems="center" justifyContent="center" height={35} width={35}>
              <StepNumber number={2} />
            </Box>
            <Box ml={1}>Complete Profile</Box>
          </Grid>
        </Grid>

        <Grid item>
          <LoadingButton
            variant={pwadProfileIsCompleted ? "contained" : "outlined"}
            size={breakpointMD ? "medium" : "small"}
            sx={breakpointSM ? undefined : { marginTop: 2 }}
            disabled={isSubmitLoading || !pwadProfileIsCompleted}
            onClick={() => setIsSubmitModalVisible(true)}
          >
            Submit Profile
          </LoadingButton>
        </Grid>
      </Grid>

      <Dialog
        open={isSubmitModalVisible}
        onClose={(event, reason) => {
          if (isSubmitLoading && (reason === "backdropClick" || reason === "escapeKeyDown")) {
            return;
          }

          setIsSubmitModalVisible(false);
        }}
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Submit profile</DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to submit your profile? Once your profile has been submitted, you will no longer be
            able to update your profile and you will need to contact WeFlex if your profile needs to be updated.
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            disabled={isSubmitLoading}
            onClick={() => {
              setIsSubmitModalVisible(false);
            }}
          >
            Cancel
          </Button>

          <LoadingButton loading={isSubmitLoading} onClick={handleSubmitProfile} autoFocus>
            Submit
          </LoadingButton>
        </DialogActions>
      </Dialog>

      {!!errorDialogMessages.length && (
        <ErrorDialog
          title="Unable to submit profile"
          messages={errorDialogMessages}
          onClose={() => setErrorDialogMessages([])}
        />
      )}

      <SnackbarAlert
        content="Profile submitted. Well done!"
        severity="success"
        open={isSubmittedSnackbarVisible}
        onClose={() => setIsSubmittedSnackbarVisible(false)}
      />
    </Card>
  );
};

export default ProfileCompletionBanner;
