import { useEffect } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import * as Sentry from "@sentry/browser";
import { isEmpty } from "lodash";
import { useForm, FormProvider } from "react-hook-form";
import { useQuery } from "react-query";
import { ErrorResponse } from "../../api/Generic";
import { useUpdateSessionReport } from "../../api/mutations/SessionReport";
import FormTextField from "../../form/FormTextField";
import { SessionReportModel } from "../../models/Booking";
import ColumnBox from "../common/ColumnBox";
import ErrorAlert from "../common/ErrorAlert";

interface AdditionalNotesFormInputs {
  additionalNotes: string;
}
interface Props {
  sessionReportId: string;
  isEditMode: boolean;
  onFormLoading(isLoading: boolean): void;
  onFormSubmitting(isSubmitting: boolean): void;
  onSuccess(showSavedConfirmation: boolean): void;
  onError(error: unknown): void;
}

export default function SessionReportAdditionalNotes({
  sessionReportId,
  isEditMode,
  onFormLoading,
  onFormSubmitting,
  onSuccess,
  onError,
}: Props) {
  const formMethods = useForm<AdditionalNotesFormInputs>();

  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = formMethods;

  const {
    data: sessionReportData,
    isLoading: isSessionReportLoading,
    error: sessionReportError,
    refetch: refetchSessionReport,
  } = useQuery<SessionReportModel, ErrorResponse>(`/session-report/${sessionReportId}/`);
  const { mutateAsync: updateSessionReport } = useUpdateSessionReport();

  useEffect(() => {
    onFormLoading(isSessionReportLoading);
  }, [isSessionReportLoading]);

  useEffect(() => {
    if (!isEmpty(sessionReportData)) {
      reset({ additionalNotes: sessionReportData!.additional_notes });
    }
  }, [sessionReportData, reset]);

  const onSubmit = async (values: AdditionalNotesFormInputs) => {
    try {
      if (isDirty) {
        onFormSubmitting(true);
        await updateSessionReport({
          sessionReportId,
          additional_notes: values.additionalNotes,
        });
        await refetchSessionReport();
      }
      onSuccess(isDirty);
    } catch (error) {
      Sentry.captureException(error);
      onError(error);
    } finally {
      onFormSubmitting(false);
    }
  };

  if (isSessionReportLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (sessionReportError) {
    return <ErrorAlert message="Session report details failed to load" />;
  }

  return (
    <FormProvider {...formMethods}>
      <form id="session-report-detail-form" onSubmit={handleSubmit(onSubmit)}>
        <ColumnBox>
          <Typography variant="h2">Additional Notes</Typography>

          <Typography mt={2}>
            Add anything else specific to this session&apos;s workout that would be helpful for you or the next trainer
            to know for the next session.
          </Typography>

          <ColumnBox mt={3}>
            <FormTextField
              name="additionalNotes"
              placeholder="For example: In this session we focused on upper body. Next session needs to be lower body."
              multiline
              minRows={4}
              InputProps={{ sx: { bgcolor: "white" } }}
              readOnly={!isEditMode}
            />
          </ColumnBox>
        </ColumnBox>
      </form>
    </FormProvider>
  );
}
