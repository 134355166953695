import ImageIcon from "@mui/icons-material/Image";
import { Grid } from "@mui/material";
import MediaMarketingConsentContent from "./PWADMediaMarketingConsentContent";
import { FPProfileTermsEditModel } from "../../models/FPProfile";
import BooleanValueDisplayBox from "../common/BooleanValueDisplayBox";
import ProfileCard from "../common/ProfileCard";
import TermsFPAgreement from "../fp/profile/edit/TermsFPAgreement";

interface Props {
  termsData: FPProfileTermsEditModel;
}

export default function FPTerms({ termsData }: Props) {
  return (
    <>
      <Grid item lg={6} xs={12}>
        <ProfileCard title="Our Agreement" Icon={ImageIcon}>
          <TermsFPAgreement />

          <BooleanValueDisplayBox
            value={termsData.waivers_consent_commitment_waiver}
            trueLabel="Yes, I agree"
            falseLabel="No, I do not agree"
            nullLabel="I have not yet indicated my agreement"
            mt={4}
          />
        </ProfileCard>
      </Grid>
      <Grid item lg={6} xs={12}>
        <ProfileCard title="Media and Marketing" Icon={ImageIcon}>
          <MediaMarketingConsentContent />

          <BooleanValueDisplayBox
            value={termsData.waivers_consent_media_marketing_waiver}
            trueLabel="Yes, I give my consent"
            falseLabel="No, I do not give my consent"
            nullLabel="I have not yet indicated whether I give consent"
            mt={4}
          />
        </ProfileCard>
      </Grid>
    </>
  );
}
