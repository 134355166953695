import React, { ReactNode } from "react";
import { Box, Paper, SvgIconTypeMap, Typography } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

interface Props {
  title: string | ReactNode;
  subtitle?: string;
  Icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  children: ReactNode;
}

export default function ProfileCard({ title, subtitle, Icon, children }: Props): JSX.Element {
  return (
    <Box component={Paper} p={4}>
      <Box mb={4}>
        <Box display="flex" alignItems="center" gap={1}>
          <Icon sx={{ color: "primary.main" }} fontSize="medium" />

          <Typography variant="h2">{title}</Typography>
        </Box>

        {!!subtitle && (
          <Typography variant="body2" color="grey.800" mt={1}>
            {subtitle}
          </Typography>
        )}
      </Box>

      {children}
    </Box>
  );
}
