import { PersonModel } from "./Person";

export interface InvoiceModel {
  id: string;
  status: InvoiceStatusEnum;
  invoice_date: string;
  invoice_number: string;
  biller_name: string;
  biller_address1: string;
  biller_address2: string;
  biller_abn: string;
  weflex_company_name: string;
  weflex_address1: string;
  weflex_address2: string;
  line_items: [LineItemModel];
  biller_account_name: string;
  biller_account_bsb: string;
  biller_account_number: string;
  gst_amount: string;
  items_amount: string;
  total_amount: string;
}

export interface LineItemModel {
  id: string;
  date: string;
  description: string;
  unit_price_amount: string;
  gst_amount: string;
  total_amount: string;
}

export enum InvoiceStatusEnum {
  APPROVED = "APPROVED",
  DECLINED = "DECLINED",
}

export interface InvoiceEditModel {
  status: InvoiceStatusEnum;
}

export interface InvoiceListModel {
  uuid: string;
  date: string;
  status: InvoiceStatusEnum | "";
  invoice_number: string;
}
