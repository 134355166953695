import { useEffect, useMemo } from "react";
import { isNil, find, isUndefined } from "lodash";
import { useLocation, matchPath } from "react-router-dom";
import * as ROUTES from "../constants/routes";

const DEFAULT_PAGE_TITLE = "WeFlex Portal";

const PAGES = [
  // Private routes
  // FP profile view
  {
    matchProps: { path: ROUTES.FP_PROFILE_ABOUT_ME, exact: true },
    title: "My Profile - About Me | WeFlex Portal",
  },
  {
    matchProps: { path: ROUTES.FP_PROFILE_MY_BUSINESS, exact: true },
    title: "My Profile - My Business | WeFlex Portal",
  },
  {
    matchProps: { path: ROUTES.FP_PROFILE_MY_SERVICES, exact: true },
    title: "My Profile - My Services | WeFlex Portal",
  },
  {
    matchProps: { path: ROUTES.FP_PROFILE_PAYOUT_DETAILS, exact: true },
    title: "My Profile - My Payout Details | WeFlex Portal",
  },
  {
    matchProps: { path: ROUTES.FP_PROFILE_TERMS, exact: true },
    title: "My Profile - Terms | WeFlex Portal",
  },
  // PWAD profile view
  {
    matchProps: { path: ROUTES.PWAD_PROFILE_ABOUT_ME, exact: true },
    title: "My Profile - About Me | WeFlex Portal",
  },
  {
    matchProps: { path: ROUTES.PWAD_PROFILE_MY_UNIQUE_NEEDS, exact: true },
    title: "My Profile - My Unique Needs | WeFlex Portal",
  },
  {
    matchProps: { path: ROUTES.PWAD_PROFILE_MY_GOALS, exact: true },
    title: "My Profile - My Goals | WeFlex Portal",
  },
  {
    matchProps: { path: ROUTES.PWAD_PROFILE_MY_PREFERENCES, exact: true },
    title: "My Profile - My Preferences | WeFlex Portal",
  },
  {
    matchProps: { path: ROUTES.PWAD_PROFILE_MY_HEALTH, exact: true },
    title: "My Profile - My Health | WeFlex Portal",
  },
  {
    matchProps: { path: ROUTES.PWAD_PROFILE_CONSENT, exact: true },
    title: "My Profile - Consent | WeFlex Portal",
  },
  {
    matchProps: { path: ROUTES.PWAD_PROFILE_SESSION_REPORTS, exact: true },
    title: "My Profile - Session Reports | WeFlex Portal",
  },
  // FP view PWAD profile
  {
    matchProps: { path: ROUTES.FP_VIEW_PWAD_PROFILE_ABOUT_ME, exact: true },
    title: "Client Profile - About Me | WeFlex Portal",
  },
  {
    matchProps: { path: ROUTES.FP_VIEW_PWAD_PROFILE_MY_UNIQUE_NEEDS, exact: true },
    title: "Client Profile - My Unique Needs | WeFlex Portal",
  },
  {
    matchProps: { path: ROUTES.FP_VIEW_PWAD_PROFILE_MY_GOALS, exact: true },
    title: "Client Profile - My Goals | WeFlex Portal",
  },
  {
    matchProps: { path: ROUTES.FP_VIEW_PWAD_PROFILE_MY_PREFERENCES, exact: true },
    title: "Client Profile - My Preferences | WeFlex Portal",
  },
  {
    matchProps: { path: ROUTES.FP_VIEW_PWAD_PROFILE_MY_HEALTH, exact: true },
    title: "Client Profile - My Health | WeFlex Portal",
  },
  {
    matchProps: { path: ROUTES.FP_VIEW_PWAD_PROFILE_CONSENT, exact: true },
    title: "Client Profile - Consent | WeFlex Portal",
  },
  {
    matchProps: { path: ROUTES.FP_VIEW_PWAD_PROFILE_SESSION_REPORTS, exact: true },
    title: "Client Profile - Session Reports | WeFlex Portal",
  },
  // PWAD view FP profile
  {
    matchProps: { path: ROUTES.PWAD_VIEW_FP_PROFILE_ABOUT_ME, exact: true },
    title: "Fitness Professional Profile - About Me | WeFlex Portal",
  },
  {
    matchProps: { path: ROUTES.PWAD_VIEW_FP_PROFILE_MY_SERVICES, exact: true },
    title: "Fitness Professional Profile - My Services | WeFlex Portal",
  },
  {
    matchProps: { path: ROUTES.PWAD_VIEW_FP_PROFILE_MY_QUALIFICATIONS, exact: true },
    title: "Fitness Professional Profile - My Qualifications | WeFlex Portal",
  },
  // Make a booking
  {
    matchProps: { path: ROUTES.PWAD_MAKE_A_BOOKING, exact: true },
    title: "Make a Booking | WeFlex Portal",
  },
  {
    matchProps: { path: ROUTES.PWAD_REQUEST_SESSION, exact: true },
    title: "Request a Booking | WeFlex Portal",
  },
  // Document viewer
  {
    matchProps: { path: ROUTES.DOCUMENT_VIEWER, exact: true },
    title: "Document Viewer | WeFlex Portal",
  },
  // My session reports
  {
    matchProps: { path: ROUTES.FP_MY_SESSION_REPORTS_TO_DO, exact: true },
    title: "My Session Reports - To Do | WeFlex Portal",
  },
  {
    matchProps: { path: ROUTES.FP_MY_SESSION_REPORTS_COMPLETED, exact: true },
    title: "My Session Reports - Completed | WeFlex Portal",
  },
  // Session report details
  {
    matchProps: { path: ROUTES.SESSION_REPORT_DETAILS },
    title: "Session Report Details | WeFlex Portal",
  },
  // Set booking outcome
  {
    matchProps: { path: ROUTES.FP_SET_BOOKING_OUTCOME },
    title: "Booking Outcome | WeFlex Portal",
  },
  // Home
  {
    matchProps: { path: ROUTES.FP_MY_BOOKINGS },
    title: "My Bookings | WeFlex Portal",
  },
  {
    matchProps: { path: ROUTES.PWAD_MY_BOOKINGS },
    title: "My Bookings | WeFlex Portal",
  },
  {
    matchProps: { path: ROUTES.HOME },
    title: "Home | WeFlex Portal",
  },
];

export default function useDocumentTitles(title?: string) {
  const { pathname } = useLocation();

  const pageTitle = useMemo(() => {
    if (!isNil(title)) {
      return title;
    }

    const page = find(PAGES, (item) => matchPath(pathname, item.matchProps) !== null);

    if (!isUndefined(page)) {
      return page.title;
    }

    return DEFAULT_PAGE_TITLE;
  }, [pathname]);

  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle]);
}
