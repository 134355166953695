import { PersonModel } from "./Person";
import { FPProfileBaseModel, FPProfileStatus } from "./ProfileBase";
import { ServiceModel, SessionLocationModel, SpecialtyModel } from "./Session";

export enum BusinessTypeEnum {
  INDEPENDENT_CONTRACTOR = "INDEPENDENT_CONTRACTOR",
  EMPLOYEE = "EMPLOYEE",
}
export type CertificationModel = {
  id: string;
  name: string;
};

export type MandatoryDocumentTypeModel = {
  id: string;
  name: string;
};

export type MandatoryDocumentModel = {
  id: string;
  mandatory_document_type: MandatoryDocumentTypeModel;
  expires_at: string | null;
};

export interface FPProfileAboutMeModel extends FPProfileBaseModel {
  id: string;
  status: FPProfileStatus;
  is_live: boolean;
  about_me: string;
  about_me_video: string;
  about_me_social_fb: string;
  about_me_social_insta: string;
  about_me_things_you_love: string;
  emergency_first_name: string;
  emergency_last_name: string;
  emergency_relationship: string;
  emergency_phone: string;
  emergency_email: string;
  person: PersonModel;
}

export interface FPProfileAboutMeModelAsPWAD extends FPProfileBaseModel {
  id: string;
  about_me: string;
  about_me_video: string;
  about_me_social_fb: string;
  about_me_social_insta: string;
  about_me_things_you_love: string;
  person: PersonModel;
}

export interface FPProfileModel extends FPProfileBaseModel {
  id?: string;
  about_me?: string;
  about_me_social_fb: string;
  about_me_social_insta: string;
  about_me_things_you_love: string;
  about_me_video?: string;
  business_type?: BusinessTypeEnum;
  employer?: string;
  business_name?: string;
  business_abn?: string;
  business_registered_gst?: boolean;
  payout_details_account_holder?: string;
  payout_details_bsb?: string;
  payout_details_account_number?: string;
  emergency_first_name?: string;
  emergency_last_name?: string;
  emergency_relationship?: string;
  emergency_phone?: string;
  emergency_email?: string;
  person?: PersonModel;
  specialties: SpecialtyModel[];
  services: ServiceModel[];
  all_specialties: SpecialtyModel[];
  all_services: ServiceModel[];
  certifications?: CertificationModel[];
  mandatory_documents?: MandatoryDocumentModel[];
  session_locations?: SessionLocationModel[];
}

export type FPNetworkProfileModel = {
  id: string;
  person: PersonModel;
  about_me: string | null;
  session_locations: SessionLocationModel[];
  services: ServiceModel[];
};

export interface FPProfileAboutMeEditModel {
  about_me: string;
  about_me_social_fb: string;
  about_me_social_insta: string;
  about_me_things_you_love: string;
  emergency_first_name: string;
  emergency_last_name: string;
  emergency_relationship: string;
  emergency_phone: string;
  emergency_email: string;
}

export interface FPProfileMyDocumentsQueryModel {
  business_type: BusinessTypeEnum | null;
  primary_qualification_file: string;
  additional_qualification_file1: string;
  additional_qualification_file2: string;
  fitness_body_membership_file: string;
  professional_and_liability_insurance_file: string;
  professional_and_liability_insurance_file_expires_at: string;
  first_aid_cpr_file: string;
  first_aid_cpr_expires_at: string;
  cpr_file: string;
  covid_vaccination_file: string;
  working_with_children_file: string;
  working_with_children_expires_at: string;
}

export interface FPProfileMyBusinessEditModel {
  business_type: string;
  business_name: string;
  business_abn: string;
  business_registered_gst: boolean;
}

export interface FPProfileMyServicesEditModel {
  id: string;
  specialties: SpecialtyModel[];
  services: ServiceModel[];
  availability_guide: string;
  all_specialties: SpecialtyModel[];
  all_services: ServiceModel[];
}

export interface FPProfilePayoutDetailsEditModel {
  business_type: string;
  payout_details_account_holder: string;
  payout_details_bsb: string;
  payout_details_account_number: string;
}

export interface FPProfileTermsEditModel {
  waivers_consent_commitment_waiver: boolean;
  waivers_consent_media_marketing_waiver: boolean;
}

export interface FPProfileTrainingModel {
  ndis_orientation_module_file: string;
  ndis_worker_screen_number: string;
}
