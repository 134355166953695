import { useMutation } from "react-query";
import Axios from "../Axios";
import { ErrorResponse } from "../Generic";

type LogInParameters = {
  username: string;
  password: string;
};

export type LogInResponse = {
  data: {
    key: string;
  };
};

export function useLogIn() {
  return useMutation<LogInResponse, ErrorResponse, LogInParameters>(({ username, password }: LogInParameters) =>
    Axios.post<LogInResponse, any>("/auth/login/", { username, email: username, password }),
  );
}

type ForgotPasswordParameters = {
  email: string;
};

export type ForgotPasswordResponse = {
  data: {
    key: string;
  };
};

export function useForgotPassword() {
  return useMutation<ForgotPasswordResponse, ErrorResponse, ForgotPasswordParameters>(
    ({ email }: ForgotPasswordParameters) =>
      Axios.post<ForgotPasswordResponse, any>("/auth/password/reset/", { email }),
  );
}

type ResetPasswordParameters = {
  new_password1: string;
  new_password2: string;
  token: string;
  uid: string;
};

export type ResetPasswordResponse = {
  data: {
    key: string;
  };
};

export function useResetPassword() {
  return useMutation<ResetPasswordResponse, ErrorResponse, ResetPasswordParameters>(
    ({ new_password1, new_password2, token, uid }: ResetPasswordParameters) =>
      Axios.post<ResetPasswordResponse, any>("/auth/password/reset/confirm/", {
        new_password1,
        new_password2,
        token,
        uid,
      }),
  );
}

export function useLogout() {
  return useMutation<{}, ErrorResponse, {}>(() => Axios.post<any, any>("/auth/logout/"));
}
