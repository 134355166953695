import { Variant } from "@mui/material/styles/createTypography";
import { InvoiceStatusEnum } from "../../../models/Invoice";
import isBlank from "../../../utils/string";
import Status from "../../common/Status";

interface Props {
  status: InvoiceStatusEnum | "";
  variant?: Variant;
}

export default function InvoiceStatus({ status, variant }: Props) {
  if (isBlank(status)) {
    return null;
  }

  if (status === InvoiceStatusEnum.DECLINED) {
    return <Status color="warning" label="Declined" variant={variant} />;
  }

  if (status === InvoiceStatusEnum.APPROVED) {
    return <Status color="success" label="Approved" variant={variant} />;
  }
  return null;
}
