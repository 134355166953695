import { Box, BoxProps } from "@mui/material";

export default function BorderedBox(props: BoxProps) {
  return (
    <Box
      display="flex"
      alignItems="center"
      gap={2}
      p={3}
      border="1px solid"
      borderColor="grey.300"
      borderRadius="8px"
      {...props}
    />
  );
}
