import React from "react";
import { Typography, Grid, CircularProgress, Box } from "@mui/material";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import RequestSessionButton from "./RequestSessionButton";
import { ErrorResponse } from "../../../api/Generic";
import { FPProfileAboutMeModelAsPWAD } from "../../../models/FPProfile";
import ColumnBox from "../../common/ColumnBox";
import ErrorAlert from "../../common/ErrorAlert";
import FPAboutMe from "../../profile-cards/FPAboutMe";
import FPBasicInformation from "../../profile-cards/FPBasicInformation";
import FPCulture from "../../profile-cards/FPCulture";
import FPLikes from "../../profile-cards/FPLikes";

export default function AboutMe(): JSX.Element {
  const { fpId } = useParams<{ fpId: string }>();

  const {
    data: aboutMeData,
    isLoading: aboutMeLoading,
    error: aboutMeError,
  } = useQuery<FPProfileAboutMeModelAsPWAD, ErrorResponse>(`/pwadprofile/fpprofile/about-me/?fpprofile=${fpId}`);

  if (aboutMeLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (aboutMeError) {
    return <ErrorAlert message="Profile details failed to load" />;
  }

  return (
    <Grid container spacing={2} py={2}>
      <Grid item xs={12}>
        <Box mb={3}>
          <Grid
            item
            display="flex"
            flexDirection={{ xs: "column", md: "row" }}
            alignItems={{ xs: "flex-start", md: "center" }}
            justifyContent={{ xs: "flex-start", md: "space-between" }}
          >
            <Grid item>
              <Typography variant="h1">
                About{" "}
                <Typography display="inline" variant="h1" component="span" color="link.main">
                  Me.
                </Typography>
              </Typography>
            </Grid>

            <Grid item>
              <RequestSessionButton fpId={fpId} />
            </Grid>
          </Grid>
        </Box>
      </Grid>

      <Grid item lg={6} xs={12}>
        <ColumnBox gap={2}>
          <FPAboutMe aboutMeData={aboutMeData} />
          <FPBasicInformation aboutMeData={aboutMeData} />
          <FPLikes aboutMeData={aboutMeData} />
        </ColumnBox>
      </Grid>

      <Grid item lg={6} xs={12}>
        <ColumnBox gap={2}>
          <FPCulture aboutMeData={aboutMeData} />
        </ColumnBox>
      </Grid>
    </Grid>
  );
}
