import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { LineItemModel } from "../../../models/Invoice";

interface Props {
  lineItems: LineItemModel[];
  gstAmount: string;
  itemsAmount: string;
  totalAmount: string;
}

function LineItemTable({ lineItems, gstAmount, itemsAmount, totalAmount }: Props): JSX.Element {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Unit Price</TableCell>
            <TableCell>GST</TableCell>
            <TableCell>Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {lineItems.map((lineItem) => (
            <TableRow key={lineItem.id}>
              <TableCell>{lineItem.date}</TableCell>
              <TableCell>{lineItem.description}</TableCell>
              <TableCell>{lineItem.unit_price_amount}</TableCell>
              <TableCell>{lineItem.gst_amount}</TableCell>
              <TableCell>{lineItem.total_amount}</TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell colSpan={2} />
            <TableCell>
              <strong>Subtotal</strong>
            </TableCell>
            <TableCell>
              <strong>{gstAmount}</strong>
            </TableCell>
            <TableCell>
              <strong>{itemsAmount}</strong>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={3} />
            <TableCell>
              <strong>TOTAL</strong>
            </TableCell>
            <TableCell>
              <strong>{totalAmount}</strong>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default LineItemTable;
