import React, { ReactNode } from "react";
import { Alert, AlertTitle, Snackbar } from "@mui/material";
import { AlertColor } from "@mui/material/Alert/Alert";

interface Props {
  title?: ReactNode | string;
  content: ReactNode | string;
  severity: AlertColor;
  open: boolean;
  autoHideDuration?: number;
  onClose: () => void;
}

export default function SnackbarAlert({
  title,
  content,
  severity,
  open,
  autoHideDuration = 5000,
  onClose,
}: Props): JSX.Element {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      sx={{ minWidth: { lg: "400px" } }}
    >
      <Alert severity={severity} variant="filled" onClose={onClose} sx={{ width: "100%" }}>
        {!!title && <AlertTitle>{title}</AlertTitle>}

        {content}
      </Alert>
    </Snackbar>
  );
}
