import WorkIcon from "@mui/icons-material/Work";
import { Typography } from "@mui/material";
import { BusinessTypeEnum, FPProfileModel } from "../../models/FPProfile";
import BorderedBox from "../common/BorderedBox";
import ColumnBox from "../common/ColumnBox";
import ProfileCard from "../common/ProfileCard";
import ProfileCardTitleWithContent, { TitleContentType } from "../common/ProfileCardTitleWithContent";

interface Props {
  myBusinessData?: FPProfileModel;
}

export default function FPEmploymentDetails({ myBusinessData }: Props) {
  const employmentType =
    myBusinessData?.business_type === BusinessTypeEnum.INDEPENDENT_CONTRACTOR
      ? BusinessTypeEnum.INDEPENDENT_CONTRACTOR
      : BusinessTypeEnum.EMPLOYEE;

  return (
    <ProfileCard title="Employment Details" Icon={WorkIcon}>
      <BorderedBox mb={4}>
        {employmentType === BusinessTypeEnum.INDEPENDENT_CONTRACTOR ? (
          <Typography variant="body1">I will be working with WeFlex as an independent contractor</Typography>
        ) : (
          <Typography variant="body1">I will be working with WeFlex via my employer</Typography>
        )}
      </BorderedBox>

      {employmentType === BusinessTypeEnum.INDEPENDENT_CONTRACTOR ? (
        <ColumnBox gap={3}>
          <ProfileCardTitleWithContent title="Business Name" content={myBusinessData?.business_name} />

          <ProfileCardTitleWithContent title="ABN" content={myBusinessData?.business_abn} />

          <ProfileCardTitleWithContent
            title="Registered for GST"
            content={myBusinessData?.business_registered_gst === true ? "true" : "false"}
            contentType={TitleContentType.CHECKBOX}
          />
        </ColumnBox>
      ) : (
        <ProfileCardTitleWithContent title="Employer" content={myBusinessData?.business_name} />
      )}
    </ProfileCard>
  );
}
