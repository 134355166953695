import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import ImageIcon from "@mui/icons-material/Image";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import * as Sentry from "@sentry/browser";
import { Controller, FormProvider, SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { RouteComponentProps, generatePath, useParams } from "react-router-dom";
import * as yup from "yup";
import TermsFPAgreement from "./TermsFPAgreement";
import { ErrorResponse, isErrorResponse } from "../../../../api/Generic";
import { useEditFPProfileTerms } from "../../../../api/mutations/FPProfile";
import * as ROUTES from "../../../../constants/routes";
import {
  GENERIC_REQUEST_ERROR_MESSAGE,
  GENERIC_REQUIRED_VALIDATION_ERROR_MESSAGE,
  GENERIC_SUBMIT_VALIDATION_ERROR_MESSAGE,
} from "../../../../constants/validation";
import { FPProfileTermsEditModel } from "../../../../models/FPProfile";
import { useAuth } from "../../../../utils/AuthProvider";
import getErrorMessages from "../../../../utils/getErrorMessages";
import { getUserFPProfileIsCompleted } from "../../../../utils/person";
import ErrorAlert from "../../../common/ErrorAlert";
import ErrorDialog from "../../../common/ErrorDialog";
import ProfileCard from "../../../common/ProfileCard";
import ProfileCardDivider from "../../../common/ProfileCardDivider";
import UnsavedChangesPrompt from "../../../common/UnsavedChangesPrompt";
import MediaMarketingConsentContent from "../../../profile-cards/PWADMediaMarketingConsentContent";

type TermsFormInput = FPProfileTermsEditModel;

// @ts-ignore
const TermsFormSchema: yup.SchemaOf<TermsFormInput> = yup.object({
  waivers_consent_commitment_waiver: yup
    .bool()
    .defined()
    .nullable()
    .required(GENERIC_REQUIRED_VALIDATION_ERROR_MESSAGE)
    .oneOf([true], "You must agree to the WeFlex agreement."),
  waivers_consent_media_marketing_waiver: yup
    .bool()
    .defined()
    .nullable()
    .required(GENERIC_REQUIRED_VALIDATION_ERROR_MESSAGE),
});

interface TermsReactHookFormProps {
  defaultValues: TermsFormInput;
  onSubmit: SubmitHandler<TermsFormInput>;
  onError: SubmitErrorHandler<TermsFormInput>;
}

function TermsReactHookForm({ defaultValues, onSubmit, onError }: TermsReactHookFormProps): JSX.Element {
  const { currentUser } = useAuth();
  const fpProfileIsCompleted = getUserFPProfileIsCompleted(currentUser);

  const formMethods = useForm<TermsFormInput>({
    resolver: yupResolver(TermsFormSchema),
    mode: "onTouched",
    criteriaMode: "firstError",
    defaultValues: defaultValues,
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting, isDirty },
  } = formMethods;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const theme: Theme = useTheme();
  const breakpointMD = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <Grid container spacing={2} py={2}>
          <Grid item xs={12}>
            <Box mb={3}>
              <Grid
                item
                display="flex"
                flexDirection={{ xs: "column", md: "row" }}
                alignItems={{ xs: "flex-start", md: "center" }}
                justifyContent={{ xs: "flex-start", md: "space-between" }}
              >
                <Grid item>
                  <Typography variant="h1">
                    WeFlex{" "}
                    <Typography display="inline" variant="h1" component="span" color="link.main">
                      Terms.
                    </Typography>
                  </Typography>
                </Grid>

                <Grid item>
                  <LoadingButton
                    type="submit"
                    variant={fpProfileIsCompleted ? "outlined" : "contained"}
                    size={breakpointMD ? "medium" : "small"}
                    sx={breakpointMD ? undefined : { marginTop: 2 }}
                    loading={isSubmitting}
                    disabled={isSubmitting}
                  >
                    Save Terms
                  </LoadingButton>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid item lg={6} xs={12}>
            <ProfileCard title="Our Agreement" Icon={FitnessCenterIcon}>
              <TermsFPAgreement />
              <ProfileCardDivider />

              <Controller
                control={control}
                name="waivers_consent_commitment_waiver"
                render={({ field: { onChange, onBlur, value }, fieldState: { error, invalid } }) => (
                  <FormControl error={invalid}>
                    <RadioGroup
                      aria-labelledby="waivers_consent_commitment_waiver"
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                    >
                      <FormControlLabel value control={<Radio />} label="Yes, I agree" />

                      <FormControlLabel value={false} control={<Radio />} label="No, I do not agree" />
                    </RadioGroup>

                    {!!error?.message && <FormHelperText error>{error.message}</FormHelperText>}
                  </FormControl>
                )}
              />
            </ProfileCard>
          </Grid>

          <Grid item lg={6} xs={12}>
            <ProfileCard title="Media and Marketing" Icon={ImageIcon}>
              <MediaMarketingConsentContent />

              <ProfileCardDivider />

              <Controller
                control={control}
                name="waivers_consent_media_marketing_waiver"
                render={({ field: { onChange, onBlur, value }, fieldState: { error, invalid } }) => (
                  <FormControl error={invalid}>
                    <FormLabel id="waivers_consent_media_marketing_waiver" required>
                      Media and marketing consent
                    </FormLabel>

                    <RadioGroup
                      aria-labelledby="waivers_consent_media_marketing_waiver"
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                    >
                      <FormControlLabel value control={<Radio />} label="Yes, I give my consent" />

                      <FormControlLabel value={false} control={<Radio />} label="No, I do not give my consent" />
                    </RadioGroup>

                    {!!error?.message && <FormHelperText error>{error.message}</FormHelperText>}
                  </FormControl>
                )}
              />
            </ProfileCard>
          </Grid>
        </Grid>
      </form>

      <UnsavedChangesPrompt showPrompt={isDirty} />
    </FormProvider>
  );
}

interface Props extends RouteComponentProps {
  setSavedSnackbarMessage: Dispatch<SetStateAction<string>>;
}

export default function TermsForm({ setSavedSnackbarMessage, history }: Props): JSX.Element {
  const { profileId } = useParams<{ profileId: string }>();

  const { refetchCurrentUser } = useAuth();

  const [errorDialogMessages, setErrorDialogMessages] = useState<Array<string>>([]);

  const {
    data: termsData,
    isLoading: termsLoading,
    error: termsError,
    refetch: termsRefetch,
  } = useQuery<FPProfileTermsEditModel, ErrorResponse>(`/fpprofile/terms/?fpprofile=${profileId}`);

  const { mutateAsync: editFPProfileTerms } = useEditFPProfileTerms(profileId);

  const defaultValues = useMemo((): TermsFormInput | null => {
    if (!termsData) {
      return null;
    }

    return termsData;
  }, [termsData]);

  const onSubmit: SubmitHandler<TermsFormInput> = async (values) => {
    try {
      await editFPProfileTerms({ fp_profile: values });
      await termsRefetch();
      await refetchCurrentUser();

      setSavedSnackbarMessage("Terms saved successfully.");
      history.push(generatePath(ROUTES.FP_PROFILE_SUBMIT, { profileId }));
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);

      if (isErrorResponse(error)) {
        setErrorDialogMessages(getErrorMessages(error.response.data?.message));
      } else {
        setErrorDialogMessages([GENERIC_REQUEST_ERROR_MESSAGE]);
      }
    }
  };

  const onError: SubmitErrorHandler<TermsFormInput> = (error) => {
    console.error({ error });
    setErrorDialogMessages([GENERIC_SUBMIT_VALIDATION_ERROR_MESSAGE]);
  };

  if (termsLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (termsError) {
    return <ErrorAlert message="Profile details failed to load" />;
  }

  if (!defaultValues || !termsData) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <TermsReactHookForm defaultValues={defaultValues} onSubmit={onSubmit} onError={onError} />

      {!!errorDialogMessages.length && (
        <ErrorDialog title="Unable to save" messages={errorDialogMessages} onClose={() => setErrorDialogMessages([])} />
      )}
    </>
  );
}
