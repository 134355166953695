import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import BadgeIcon from "@mui/icons-material/Badge";
import SchoolIcon from "@mui/icons-material/School";
import { LoadingButton } from "@mui/lab";
import { Box, CircularProgress, Grid, TextField, Theme, Typography, useMediaQuery, useTheme } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as Sentry from "@sentry/browser";
import { omit } from "lodash";
import { Controller, FormProvider, SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { RouteComponentProps, generatePath, useParams } from "react-router-dom";
import * as yup from "yup";
import { ErrorResponse, isErrorResponse } from "../../../../api/Generic";
import { useEditFPProfileMyDocuments } from "../../../../api/mutations/FPProfile";
import { IMAGE_MIME_TYPES, PDF_MIME_TYPES, WORD_DOCUMENT_MIME_TYPES } from "../../../../constants/mimeTypes";
import * as ROUTES from "../../../../constants/routes";
import {
  GENERIC_REQUEST_ERROR_MESSAGE,
  GENERIC_SUBMIT_VALIDATION_ERROR_MESSAGE,
} from "../../../../constants/validation";
import PrivateFileUploadControl from "../../../../form/PrivateFileUploadControl";
import { BusinessTypeEnum, FPProfileMyDocumentsQueryModel } from "../../../../models/FPProfile";
import { useAuth } from "../../../../utils/AuthProvider";
import getErrorMessages from "../../../../utils/getErrorMessages";
import { getUserFPProfileIsCompleted } from "../../../../utils/person";
import ColumnBox from "../../../common/ColumnBox";
import ErrorAlert from "../../../common/ErrorAlert";
import ErrorDialog from "../../../common/ErrorDialog";
import ProfileCard from "../../../common/ProfileCard";
import UnsavedChangesPrompt from "../../../common/UnsavedChangesPrompt";

type MyDocumentFormInput = {
  primary_qualification_file: string;
  additional_qualification_file1: string;
  additional_qualification_file2: string;
  fitness_body_membership_file: string;
  professional_and_liability_insurance_file: string;
  first_aid_cpr_file: string;
  cpr_file: string;
  covid_vaccination_file: string;
  working_with_children_file: string;
};

interface MyDocumentReactHookFormProps {
  defaultValues: MyDocumentFormInput;
  businessType: BusinessTypeEnum | null;
  onSubmit: SubmitHandler<MyDocumentFormInput>;
  onError: SubmitErrorHandler<MyDocumentFormInput>;
}

function MyDocumentReactHookForm({
  defaultValues,
  businessType,
  onSubmit,
  onError,
}: MyDocumentReactHookFormProps): JSX.Element {
  const { currentUser } = useAuth();
  const fpProfileIsCompleted = getUserFPProfileIsCompleted(currentUser);
  const myDocumentFormSchema = useMemo(() => {
    const spec = {
      primary_qualification_file: yup.string().trim().nullable().required("Primary qualification is required."),
      additional_qualification_file1: yup.string().trim().nullable(),
      additional_qualification_file2: yup.string().trim().nullable(),
      fitness_body_membership_file: yup.string().trim().nullable(),
      professional_and_liability_insurance_file: yup.string().trim().nullable(),
      first_aid_cpr_file: yup.string().trim().nullable().required("First aid and CPR certificate is required."),
      cpr_file: yup.string().trim().nullable(),
      covid_vaccination_file: yup.string().trim().nullable(),
      working_with_children_file: yup.string().trim().nullable(),
    };
    if (businessType === BusinessTypeEnum.INDEPENDENT_CONTRACTOR) {
      spec.professional_and_liability_insurance_file = yup
        .string()
        .trim()
        .nullable()
        .required("Professional and liability insurance is required for independent contractors.");
    }
    return yup.object(spec);
  }, [businessType]);

  const formMethods = useForm<MyDocumentFormInput>({
    resolver: yupResolver(myDocumentFormSchema),
    mode: "onTouched",
    criteriaMode: "firstError",
    defaultValues: defaultValues,
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting, isDirty },
  } = formMethods;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const theme: Theme = useTheme();
  const breakpointMD = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <Grid container spacing={2} py={2}>
            <Grid item xs={12}>
              <Box mb={3}>
                <Grid
                  item
                  display="flex"
                  flexDirection={{ xs: "column", md: "row" }}
                  alignItems={{ xs: "flex-start", md: "center" }}
                  justifyContent={{ xs: "flex-start", md: "space-between" }}
                >
                  <Grid item>
                    <Typography variant="h1">
                      My{" "}
                      <Typography display="inline" variant="h1" component="span" color="link.main">
                        Documents.
                      </Typography>
                    </Typography>
                  </Grid>

                  <Grid item>
                    <LoadingButton
                      type="submit"
                      variant={fpProfileIsCompleted ? "outlined" : "contained"}
                      size={breakpointMD ? "medium" : "small"}
                      sx={breakpointMD ? undefined : { marginTop: 2 }}
                      loading={isSubmitting}
                      disabled={isSubmitting}
                    >
                      Save My Documents
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid item lg={6} xs={12}>
              <ProfileCard title="Qualifications" Icon={SchoolIcon}>
                <ColumnBox gap={6}>
                  <Controller
                    control={control}
                    name="primary_qualification_file"
                    render={({ field, fieldState }) => (
                      <PrivateFileUploadControl
                        label="Primary qualification"
                        required
                        allowedMimeTypes={[...IMAGE_MIME_TYPES, ...WORD_DOCUMENT_MIME_TYPES, ...PDF_MIME_TYPES]}
                        field={field}
                        fieldState={fieldState}
                        helperText="E.g. Cert III in Fitness Certificate, bachelors degree, etc. Allowed file types: images, documents, and PDFs"
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name="additional_qualification_file1"
                    render={({ field, fieldState }) => (
                      <PrivateFileUploadControl
                        label="Additional qualification 1"
                        allowedMimeTypes={[...IMAGE_MIME_TYPES, ...WORD_DOCUMENT_MIME_TYPES, ...PDF_MIME_TYPES]}
                        field={field}
                        fieldState={fieldState}
                        helperText="Upload optional additional qualifications. Allowed file types: images, documents, and PDFs"
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name="additional_qualification_file2"
                    render={({ field, fieldState }) => (
                      <PrivateFileUploadControl
                        label="Additional qualification 2"
                        allowedMimeTypes={[...IMAGE_MIME_TYPES, ...WORD_DOCUMENT_MIME_TYPES, ...PDF_MIME_TYPES]}
                        field={field}
                        fieldState={fieldState}
                        helperText="Upload optional additional qualifications. Allowed file types: images, documents, and PDFs"
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name="fitness_body_membership_file"
                    render={({ field, fieldState }) => (
                      <PrivateFileUploadControl
                        label="Fitness body membership certificate"
                        allowedMimeTypes={[...IMAGE_MIME_TYPES, ...WORD_DOCUMENT_MIME_TYPES, ...PDF_MIME_TYPES]}
                        field={field}
                        fieldState={fieldState}
                        helperText="e.g.: AUSactive Membership Certificate, Fitrec Membership Certificate, ESSA Membership Certificate. Allowed file types: images, documents, and PDFs"
                      />
                    )}
                  />
                </ColumnBox>
              </ProfileCard>
            </Grid>
            <Grid item lg={6} xs={12}>
              <ProfileCard title="Supporting Documents" Icon={BadgeIcon}>
                <ColumnBox gap={6}>
                  <Controller
                    control={control}
                    name="professional_and_liability_insurance_file"
                    render={({ field, fieldState }) => (
                      <PrivateFileUploadControl
                        label="Professional and liability insurance"
                        required={businessType === BusinessTypeEnum.INDEPENDENT_CONTRACTOR}
                        allowedMimeTypes={[...IMAGE_MIME_TYPES, ...WORD_DOCUMENT_MIME_TYPES, ...PDF_MIME_TYPES]}
                        field={field}
                        fieldState={fieldState}
                        helperText="Required for independent contractors. Allowed file types: images, documents, and PDFs"
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name="first_aid_cpr_file"
                    render={({ field, fieldState }) => (
                      <PrivateFileUploadControl
                        label="First aid and CPR certificate"
                        required
                        allowedMimeTypes={[...IMAGE_MIME_TYPES, ...WORD_DOCUMENT_MIME_TYPES, ...PDF_MIME_TYPES]}
                        field={field}
                        fieldState={fieldState}
                        helperText="Allowed file types: images, documents, and PDFs"
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name="cpr_file"
                    render={({ field, fieldState }) => (
                      <PrivateFileUploadControl
                        label="CPR certificate"
                        allowedMimeTypes={[...IMAGE_MIME_TYPES, ...WORD_DOCUMENT_MIME_TYPES, ...PDF_MIME_TYPES]}
                        field={field}
                        fieldState={fieldState}
                        helperText="Add your renewed renewed CPR certificate. Allowed file types: images, documents, and PDFs"
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name="covid_vaccination_file"
                    render={({ field, fieldState }) => (
                      <PrivateFileUploadControl
                        label="Covid vaccination certificate"
                        allowedMimeTypes={[...IMAGE_MIME_TYPES, ...WORD_DOCUMENT_MIME_TYPES, ...PDF_MIME_TYPES]}
                        field={field}
                        fieldState={fieldState}
                        helperText="Add your Covid certificate to work with immune-compromised clients. Allowed file types: images, documents, and PDFs"
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name="working_with_children_file"
                    render={({ field, fieldState }) => (
                      <PrivateFileUploadControl
                        label="Working with children check"
                        allowedMimeTypes={[...IMAGE_MIME_TYPES, ...WORD_DOCUMENT_MIME_TYPES, ...PDF_MIME_TYPES]}
                        field={field}
                        fieldState={fieldState}
                        helperText="Mandatory if you wish to work with children. Allowed file types: images, documents, and PDFs"
                      />
                    )}
                  />
                </ColumnBox>
              </ProfileCard>
            </Grid>
          </Grid>
        </form>
      </FormProvider>

      <UnsavedChangesPrompt showPrompt={isDirty} />
    </LocalizationProvider>
  );
}

interface Props extends RouteComponentProps {
  setSavedSnackbarMessage: Dispatch<SetStateAction<string>>;
}

export default function MyDocumentForm({ setSavedSnackbarMessage, history }: Props): JSX.Element {
  const { profileId } = useParams<{ profileId: string }>();

  const { refetchCurrentUser } = useAuth();

  const [errorDialogMessages, setErrorDialogMessages] = useState<Array<string>>([]);

  const {
    data: myDocumentsData,
    isLoading: myDocumentsLoading,
    error: myDocumentsError,
    refetch: myDocumentsRefetch,
  } = useQuery<FPProfileMyDocumentsQueryModel, ErrorResponse>(`/fpprofile/my-documents/?fpprofile=${profileId}`);

  const { mutateAsync: editFPProfileMyDocuments } = useEditFPProfileMyDocuments(profileId);

  const defaultValues = useMemo((): MyDocumentFormInput | null => {
    if (!myDocumentsData) {
      return null;
    }

    return omit(myDocumentsData, "business_type");
  }, [myDocumentsData]);

  const onSubmit: SubmitHandler<MyDocumentFormInput> = async (values) => {
    try {
      await editFPProfileMyDocuments({ fp_profile: values });
      await myDocumentsRefetch();
      await refetchCurrentUser();

      setSavedSnackbarMessage("My documents saved successfully.");

      history.push(generatePath(ROUTES.FP_PROFILE_PAYOUT_DETAILS, { profileId }));
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);

      if (isErrorResponse(error)) {
        setErrorDialogMessages(getErrorMessages(error.response.data?.message));
      } else {
        setErrorDialogMessages([GENERIC_REQUEST_ERROR_MESSAGE]);
      }
    }
  };

  const onError: SubmitErrorHandler<MyDocumentFormInput> = (error) => {
    console.error({ error });
    setErrorDialogMessages([GENERIC_SUBMIT_VALIDATION_ERROR_MESSAGE]);
  };

  if (myDocumentsLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (myDocumentsError) {
    return <ErrorAlert message="Profile details failed to load" />;
  }

  if (!defaultValues || !myDocumentsData) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <MyDocumentReactHookForm
        defaultValues={defaultValues}
        businessType={myDocumentsData?.business_type}
        onSubmit={onSubmit}
        onError={onError}
      />

      {!!errorDialogMessages.length && (
        <ErrorDialog title="Unable to save" messages={errorDialogMessages} onClose={() => setErrorDialogMessages([])} />
      )}
    </>
  );
}
