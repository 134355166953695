import React, { ReactNode } from "react";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Avatar, BoxProps, Typography } from "@mui/material";
import BorderedBox from "./BorderedBox";

interface Props {
  value: boolean | null;
  trueLabel: ReactNode | string;
  falseLabel: ReactNode | string;
  nullLabel: ReactNode | string;
}

export default function BooleanValueDisplayBox({
  value,
  trueLabel,
  falseLabel,
  nullLabel,
  ...restProps
}: Props & BoxProps) {
  return (
    <BorderedBox {...restProps}>
      {value === true && (
        <>
          <Avatar sx={{ bgcolor: "#E6FBF2" }}>
            <CheckIcon sx={{ color: "primary.main" }} fontSize="small" />
          </Avatar>

          <Typography variant="body1">{trueLabel}</Typography>
        </>
      )}

      {value === false && (
        <>
          <Avatar sx={{ bgcolor: "error.light" }}>
            <CloseIcon sx={{ color: "error.contrastText" }} fontSize="small" />
          </Avatar>

          <Typography variant="body1">{falseLabel}</Typography>
        </>
      )}

      {value === null && (
        <>
          <Avatar sx={{ bgcolor: "info.light" }}>
            <HelpOutlineIcon sx={{ color: "info.contrastText" }} fontSize="small" />
          </Avatar>

          <Typography variant="body1">{nullLabel}</Typography>
        </>
      )}
    </BorderedBox>
  );
}
