import React from "react";
import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormHelperText,
  FormLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  Select as MuiSelect,
  Theme,
} from "@mui/material";
import { SxProps } from "@mui/system";
import { find, toString } from "lodash";
import { ControllerFieldState } from "react-hook-form";
import { ControllerRenderProps } from "react-hook-form/dist/types/controller";

export const SELECT_BOOLEAN_OPTIONS = [
  {
    value: false,
    label: "No",
  },
  {
    value: true,
    label: "Yes",
  },
];

interface Props {
  options: Array<{ value: string | boolean; label: string; secondaryLabel?: string }>;
  label?: string;
  helperText?: string;
  required?: boolean;
  multiple?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  labelSeparate?: boolean;
  sx?: SxProps<Theme> | undefined;
  field: ControllerRenderProps;
  fieldState: ControllerFieldState;
}

export default function SelectControl({
  options,
  label,
  helperText,
  required = false,
  multiple = false,
  disabled = false,
  fullWidth = false,
  labelSeparate = false,
  sx,
  field,
  fieldState: { error, invalid },
}: Props): JSX.Element {
  const renderValueAsChip = multiple;

  const getLabelForValue = (value: any): string => {
    const matchingOption = find(options, (option) => option.value === value);

    if (!matchingOption) {
      return "";
    }

    return matchingOption.label;
  };

  const renderValue = (value: any) => {
    if (renderValueAsChip) {
      if (Array.isArray(value)) {
        return (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {value.map((optionValue) => (
              <Chip
                key={toString(optionValue)}
                label={getLabelForValue(optionValue)}
                size="small"
                sx={{ maxWidth: "100%" }}
              />
            ))}
          </Box>
        );
      }

      // Note: for now, since `renderValueAsChip` is true when `multiple` is true, there should not be any scenario
      // where we're rendering the value as a Chip and the field value is not an array, i.e. we should not reach this
      // code. But in the future we may have more complex logic that makes it so that we sometimes render the value as a
      // Chip even when we only allow a single value rather than multiple values.
      return <Chip key={toString(value)} label={getLabelForValue(value)} size="small" sx={{ maxWidth: "100%" }} />;
    }

    return getLabelForValue(value);
  };

  const showLabelInField = !!label && !labelSeparate;
  const showLabelSeparate = !!label && !!labelSeparate;

  return (
    <FormControl error={invalid} fullWidth={fullWidth} style={{ width: "100%" }}>
      {showLabelInField && (
        <InputLabel id={field.name} required={required}>
          {label}
        </InputLabel>
      )}

      {showLabelSeparate && (
        <FormLabel id={field.name} required={required} sx={{ mb: 1 }}>
          {label}
        </FormLabel>
      )}

      <MuiSelect
        labelId={field.name}
        label={showLabelInField ? label : undefined}
        value={field.value ?? ""}
        onChange={field.onChange}
        onBlur={field.onBlur}
        inputRef={field.ref}
        error={invalid}
        required={required}
        multiple={multiple}
        disabled={disabled}
        renderValue={renderValue}
        sx={{
          width: "100%",
          ...sx,
        }}
      >
        {options.map((option) => {
          if (multiple) {
            return (
              <MenuItem key={toString(option.value)} value={option.value as any}>
                <Checkbox checked={field.value.includes(option.value)} />
                <ListItemText primary={option.label} secondary={option.secondaryLabel} />
              </MenuItem>
            );
          }

          return (
            <MenuItem key={toString(option.value)} value={option.value as any}>
              <ListItemText primary={option.label} secondary={option.secondaryLabel} />
            </MenuItem>
          );
        })}
      </MuiSelect>

      {!!error?.message && <FormHelperText error>{error.message}</FormHelperText>}

      {!error?.message && !!helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}
