import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import SchoolIcon from "@mui/icons-material/School";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as Sentry from "@sentry/browser";
import { Controller, FormProvider, SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { generatePath, RouteComponentProps, useParams } from "react-router-dom";
import * as yup from "yup";
import { ErrorResponse, isErrorResponse } from "../../../../api/Generic";
import { useEditFPProfileTraining } from "../../../../api/mutations/FPProfile";
import { IMAGE_MIME_TYPES, PDF_MIME_TYPES, WORD_DOCUMENT_MIME_TYPES } from "../../../../constants/mimeTypes";
import * as ROUTES from "../../../../constants/routes";
import {
  GENERIC_REQUEST_ERROR_MESSAGE,
  GENERIC_SUBMIT_VALIDATION_ERROR_MESSAGE,
} from "../../../../constants/validation";
import PrivateFileUploadControl from "../../../../form/PrivateFileUploadControl";
import { FPProfileTrainingModel } from "../../../../models/FPProfile";
import { useAuth } from "../../../../utils/AuthProvider";
import getErrorMessages from "../../../../utils/getErrorMessages";
import ColumnBox from "../../../common/ColumnBox";
import ErrorAlert from "../../../common/ErrorAlert";
import ErrorDialog from "../../../common/ErrorDialog";
import ProfileCard from "../../../common/ProfileCard";
import UnsavedChangesPrompt from "../../../common/UnsavedChangesPrompt";

// @ts-ignore
const TrainingFormSchema: yup.SchemaOf<FPProfileTrainingModel> = yup.object({
  ndis_worker_screen_number: yup.string().trim().nullable().required("Please enter your NDIS worker screen number."),
  ndis_orientation_module_file: yup
    .string()
    .trim()
    .nullable()
    .required("NDIS orientation module certificate is required."),
});

interface TrainingReactHookFormProps {
  defaultValues: FPProfileTrainingModel;
  onSubmit: SubmitHandler<FPProfileTrainingModel>;
  onError: SubmitErrorHandler<FPProfileTrainingModel>;
  isSubmitLoading: boolean;
  showSubmitModal: boolean;
  setShowSubmitModal: Dispatch<SetStateAction<boolean>>;
  submitErrors: string[] | null;
  setSubmitErrors: Dispatch<SetStateAction<string[] | null>>;
}

function TrainingReactHookForm({
  defaultValues,
  onSubmit,
  onError,
  isSubmitLoading,
  showSubmitModal,
  setShowSubmitModal,
  submitErrors,
  setSubmitErrors,
}: TrainingReactHookFormProps): JSX.Element {
  const formMethods = useForm<FPProfileTrainingModel>({
    resolver: yupResolver(TrainingFormSchema),
    mode: "onTouched",
    criteriaMode: "firstError",
    defaultValues: defaultValues,
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting, isDirty },
    getValues,
  } = formMethods;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const theme: Theme = useTheme();
  const breakpointMD = useMediaQuery(theme.breakpoints.up("md"));

  const onFormSubmit = () => {
    setShowSubmitModal(true);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onFormSubmit, onError)}>
          <Grid container spacing={2} py={2}>
            <Grid item xs={12}>
              <Box mb={3}>
                <Grid
                  item
                  display="flex"
                  flexDirection={{ xs: "column", md: "row" }}
                  alignItems={{ xs: "flex-start", md: "center" }}
                  justifyContent={{ xs: "flex-start", md: "space-between" }}
                  gap={3}
                >
                  <Grid item>
                    <Typography variant="h1" mb={3}>
                      My{" "}
                      <Typography display="inline" variant="h1" component="span" color="link.main">
                        Training.
                      </Typography>
                    </Typography>

                    <Typography variant="body1" mb={3}>
                      The following training modules are required for every WeFlex fitness professional. Once you have
                      completed these modules simply add your data below and submit to let us know you&apos;ve completed
                      these requirements.
                    </Typography>
                  </Grid>

                  <Grid item>
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      size={breakpointMD ? "medium" : "small"}
                      sx={breakpointMD ? undefined : { marginTop: 2 }}
                      loading={isSubmitting}
                      disabled={isSubmitting}
                    >
                      Submit
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid item lg={6} xs={12}>
              <ColumnBox gap={3}>
                <ProfileCard title="WeFlex Academy" Icon={SchoolIcon}>
                  <Typography variant="body1" mb={3}>
                    <ol>
                      <li>
                        To create your Academy account and get started go to:{" "}
                        <a
                          target="_blank"
                          href="https://weflex.myabsorb.com.au/?KeyName=Personal_Trainers"
                          rel="noreferrer"
                        >
                          https://weflex.myabsorb.com.au/?KeyName=Personal_Trainers
                        </a>
                      </li>
                      <Typography variant="body1" mb={3} mt={3}>
                        OR
                      </Typography>
                      <li>
                        Already setup your account? Just login here:
                        <a target="_blank" href="https://weflex.myabsorb.com.au/#/login" rel="noreferrer">
                          https://weflex.myabsorb.com.au/#/login{" "}
                        </a>
                      </li>
                    </ol>
                  </Typography>
                </ProfileCard>

                <ProfileCard title="NDIS Worker Screen Number" Icon={CheckCircleOutlineIcon}>
                  <ColumnBox gap={3}>
                    <Typography variant="body1" mb={3}>
                      For instructions on how to apply for a NDIS worker screen number visit:{" "}
                      <a
                        target="_blank"
                        href="https://www.ndiscommission.gov.au/workers/worker-screening/where-apply-worker-screening"
                        rel="noreferrer"
                      >
                        this page.
                      </a>
                    </Typography>

                    <Controller
                      control={control}
                      name="ndis_worker_screen_number"
                      render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                        <TextField
                          label="NDIS Worker Screen Number"
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          inputRef={ref}
                          error={invalid}
                          helperText={error?.message}
                          required
                        />
                      )}
                    />
                  </ColumnBox>
                </ProfileCard>
              </ColumnBox>
            </Grid>

            <Grid item lg={6} xs={12}>
              <ColumnBox gap={3}>
                <ProfileCard title="NDIS Worker Orientation Module" Icon={CheckCircleOutlineIcon}>
                  <ColumnBox gap={6}>
                    <Typography variant="body1" mb={3}>
                      To complete this module visit:{" "}
                      <a
                        target="_blank"
                        href="https://training.ndiscommission.gov.au/course/index.php?categoryid=2"
                        rel="noreferrer"
                      >
                        https://training.ndiscommission.gov.au/course/index.php?categoryid=2
                      </a>
                    </Typography>

                    <Controller
                      control={control}
                      name="ndis_orientation_module_file"
                      render={({ field, fieldState }) => (
                        <PrivateFileUploadControl
                          label="NDIS new worker orientation module certificate"
                          required
                          allowedMimeTypes={[...IMAGE_MIME_TYPES, ...WORD_DOCUMENT_MIME_TYPES, ...PDF_MIME_TYPES]}
                          field={field}
                          fieldState={fieldState}
                          helperText="Allowed file types: images, documents, and PDFs"
                        />
                      )}
                    />
                  </ColumnBox>
                </ProfileCard>
              </ColumnBox>
            </Grid>
          </Grid>
        </form>
      </FormProvider>

      <Dialog
        open={showSubmitModal}
        onClose={() => {
          setShowSubmitModal(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Submit Training Information</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please note submitting cannot be undone and you will no longer be able to update your profile. Are you sure
            you want to submit?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowSubmitModal(false);
            }}
          >
            Cancel
          </Button>
          <LoadingButton loading={isSubmitLoading} onClick={() => onSubmit(getValues())} autoFocus>
            Submit
          </LoadingButton>
        </DialogActions>
      </Dialog>

      {submitErrors && (
        <ErrorDialog title="Unable to submit profile" messages={submitErrors} onClose={() => setSubmitErrors(null)} />
      )}
      <UnsavedChangesPrompt showPrompt={isDirty} />
    </LocalizationProvider>
  );
}

interface Props extends RouteComponentProps {
  setSavedSnackbarMessage: Dispatch<SetStateAction<string>>;
}

export default function TrainingForm({ setSavedSnackbarMessage, history }: Props): JSX.Element {
  const { profileId } = useParams<{ profileId: string }>();
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [submitErrors, setSubmitErrors] = useState<string[] | null>(null);
  const [showSubmitModal, setShowSubmitModal] = useState(false);

  const { refetchCurrentUser } = useAuth();

  const [errorDialogMessages, setErrorDialogMessages] = useState<Array<string>>([]);

  const {
    data: trainingData,
    isLoading: trainingLoading,
    error: trainingError,
    refetch: trainingRefetch,
  } = useQuery<FPProfileTrainingModel, ErrorResponse>(`/fpprofile/training/?fpprofile=${profileId}`);

  const { mutateAsync: editFPProfileAboutMe } = useEditFPProfileTraining(profileId);

  const defaultValues = useMemo((): FPProfileTrainingModel | null => {
    if (!trainingData) {
      return null;
    }

    return trainingData;
  }, [trainingData]);

  const onSubmit: SubmitHandler<FPProfileTrainingModel> = async (values) => {
    try {
      setIsSubmitLoading(true);
      await editFPProfileAboutMe({
        fp_profile: values,
      });

      await trainingRefetch();
      await refetchCurrentUser();

      setSavedSnackbarMessage("Training information submitted.");

      history.push(generatePath(ROUTES.FP_PROFILE_INTERVIEW, { profileId }));
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);

      if (isErrorResponse(error)) {
        const errorMessage = getErrorMessages(error.response.data?.message);
        setErrorDialogMessages(errorMessage);
        setSubmitErrors(errorMessage);
      } else {
        setErrorDialogMessages([GENERIC_REQUEST_ERROR_MESSAGE]);
        setSubmitErrors([GENERIC_REQUEST_ERROR_MESSAGE]);
      }
    }
    setIsSubmitLoading(false);
  };

  const onError: SubmitErrorHandler<FPProfileTrainingModel> = (error) => {
    console.error({ error });
    setErrorDialogMessages([GENERIC_SUBMIT_VALIDATION_ERROR_MESSAGE]);
    setSubmitErrors([GENERIC_SUBMIT_VALIDATION_ERROR_MESSAGE]);
  };

  if (trainingLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (trainingError) {
    return <ErrorAlert message="Profile details failed to load" />;
  }

  if (!defaultValues || !trainingData) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <TrainingReactHookForm
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        onError={onError}
        isSubmitLoading={isSubmitLoading}
        showSubmitModal={showSubmitModal}
        setShowSubmitModal={setShowSubmitModal}
        submitErrors={submitErrors}
        setSubmitErrors={setSubmitErrors}
      />

      {!!errorDialogMessages.length && (
        <ErrorDialog title="Unable to save" messages={errorDialogMessages} onClose={() => setErrorDialogMessages([])} />
      )}
    </>
  );
}
