import ColumnBox from "../common/ColumnBox";
import WarningAlert from "../common/WarningAlert";

interface Props {
  show: boolean;
}

export default function SessionReportNoGoalsAlert({ show }: Props) {
  if (!show) {
    return null;
  }

  return (
    <ColumnBox mb={4}>
      <WarningAlert message="Client has no goals" description="To set client goals, please contact WeFlex." />
    </ColumnBox>
  );
}
