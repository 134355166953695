import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { ErrorResponse } from "../../../api/Generic";
import { PWADProfileMyUniqueNeedsModel } from "../../../models/PWADProfile";
import ErrorAlert from "../../common/ErrorAlert";
import PWADNeeds from "../../profile-cards/PWADNeeds";
import PWADSupportConsiderations from "../../profile-cards/PWADSupportConsiderations";

export default function MyUniqueNeeds(): JSX.Element | null {
  const { pwadProfileId } = useParams<{ pwadProfileId: string }>();

  const {
    data: myUniqueNeedsData,
    isLoading: myUniqueNeedsLoading,
    error: myUniqueNeedsError,
  } = useQuery<PWADProfileMyUniqueNeedsModel, ErrorResponse>(
    `/pwadprofile/my-unique-needs/?pwad_profile=${pwadProfileId}`,
  );

  if (myUniqueNeedsLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (myUniqueNeedsError) {
    return <ErrorAlert message="Profile details failed to load" />;
  }

  if (!myUniqueNeedsData) {
    return null;
  }

  return (
    <Grid container spacing={2} py={2}>
      <Grid item xs={12}>
        <Typography variant="h1" mb={3}>
          My{" "}
          <Typography display="inline" variant="h1" component="span" color="link.main">
            Unique Needs.
          </Typography>
        </Typography>
      </Grid>

      <Grid item lg={6} xs={12}>
        <PWADNeeds myUniqueNeedsData={myUniqueNeedsData} />
      </Grid>

      <Grid item lg={6} xs={12}>
        <PWADSupportConsiderations myUniqueNeedsData={myUniqueNeedsData} />
      </Grid>
    </Grid>
  );
}
