import { FPProfileBaseModel, PWADProfileBaseModel } from "./ProfileBase";

export enum UserSourceTypeEnum {
  FRIEND = "FRIEND",
  WORD_OF_MOUTH = "WORD_OF_MOUTH",
  GOOGLE = "GOOGLE",
  OTHER = "OTHER",
}

export const UserSourceTypeLabel: { [key in UserSourceTypeEnum]: string } = {
  FRIEND: "Friend",
  WORD_OF_MOUTH: "Word of mouth",
  GOOGLE: "Google",
  OTHER: "Other",
};

export enum PersonGenderEnum {
  FEMALE = "FEMALE",
  MALE = "MALE",
  NON_BINARY = "NON_BINARY",
  RATHER_NOT_TO_SAY = "RATHER_NOT_TO_SAY",
}

export const PersonGenderLabel: { [key in PersonGenderEnum]: string } = {
  FEMALE: "Female",
  MALE: "Male",
  NON_BINARY: "Non-binary",
  RATHER_NOT_TO_SAY: "Rather not say",
};

export interface AvatarModel {
  crop_200_200: string;
  full_size: string;
}

export enum PersonRelationshipTypeEnum {
  NEXT_OF_KIN = "NEXT_OF_KIN",
  CARER = "CARER",
  SUPPORT_WORKER = "SUPPORT_WORKER",
}

export const PersonRelationshipTypeLabel: { [key in PersonRelationshipTypeEnum]: string } = {
  NEXT_OF_KIN: "Next of kin",
  CARER: "Carer",
  SUPPORT_WORKER: "Support worker",
};

export enum PersonPrimaryContactTypeEnum {
  MYSELF = "MYSELF",
  REPRESENTATIVE = "REPRESENTATIVE",
  PARENT = "PARENT",
  OTHER_FAMILY_MEMBER = "OTHER_FAMILY_MEMBER",
  FRIEND = "FRIEND",
  SUPPORT_COORDINATOR = "SUPPORT_COORDINATOR",
  OTHER = "OTHER",
}

export const PersonPrimaryContactTypeLabel: { [key in PersonPrimaryContactTypeEnum]: string } = {
  MYSELF: "Myself",
  REPRESENTATIVE: "Myself (representative)",
  PARENT: "Parent",
  OTHER_FAMILY_MEMBER: "Other family member",
  FRIEND: "Friend",
  SUPPORT_COORDINATOR: "Support coordinator",
  OTHER: "Other",
};

export type RelatedPersonModel = {
  person: {
    first_name: string;
    last_name: string;
    preferred_name: string;
    email: string;
    phone: string;
  };
  type: PersonRelationshipTypeEnum;
  emergency: boolean;
};

export interface PersonBasicModel {
  id?: string;
  first_name: string;
  last_name: string;
  preferred_name: string;
}

export type Address = {
  place: string;
  latitude: number;
  longitude: number;
};

export interface PersonModel extends PersonBasicModel {
  email: string;
  phone: string;
  dob?: string | null;
  gender?: PersonGenderEnum | "";
  address?: Address | null;
  avatar?: AvatarModel;
  nationality?: string;
  spoken_languages?: string[];
  primary_contact_type?: PersonPrimaryContactTypeEnum | "";
  alternate_email?: string;
  alternate_phone?: string;
  source?: UserSourceTypeEnum | "";
  pwad_profile?: PWADProfileBaseModel | null;
  fp_profile?: FPProfileBaseModel | null;
  emergency_contact?: RelatedPersonModel | null;
  represented_by?: PersonModel | null;
}

export interface PWADPersonEditModel extends PersonBasicModel {
  phone: string;
  dob: Date | null;
  gender: PersonGenderEnum | "";
  address_place: string;
  address_latitude: number | null;
  address_longitude: number | null;
  avatar: string;
  primary_contact_type: PersonPrimaryContactTypeEnum | "";
  alternate_email: string;
  alternate_phone: string;
}

export interface FPPersonEditModel extends PersonBasicModel {
  phone: string;
  dob: Date | null;
  gender: PersonGenderEnum | "";
  nationality?: string;
  spoken_languages?: string[];
  avatar: string;
}

export interface RepresentedByPersonEditModel extends PersonBasicModel {
  phone: string;
}

export type UserModel = {
  id?: string;
  username: string;
  email: string;
  is_active?: boolean;
  person?: PersonModel;
  is_superuser: boolean;
  is_staff: boolean;
};
