import { Typography } from "@mui/material";

interface Props {
  children: React.ReactNode;
}
export default function HomeInfoCard({ children }: Props): JSX.Element {
  return (
    <Typography component="span" fontStyle="italic">
      {children}
    </Typography>
  );
}
