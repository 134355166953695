import React, { useCallback, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import * as Sentry from "@sentry/browser";
import { isEmpty, isNil, keys } from "lodash";
import { generatePath, useParams } from "react-router-dom";
import BulkActions from "./BulkActions";
import { useUpdateBookings } from "../../../../api/mutations/Booking";
import * as ROUTES from "../../../../constants/routes";
import { BookingModel, BookingStatusEnum } from "../../../../models/Booking";
import Dot from "../../../common/Dot";
import SessionRow from "../../../common/SessionRow";

interface Props {
  bookings: BookingModel[];
  refetchBookings: any;
}

function renderStatus(status: BookingStatusEnum) {
  if (status === BookingStatusEnum.PENDING) {
    return <Dot color="warning" sx={{ mt: 1 }} />;
  }

  if (status === BookingStatusEnum.ACCEPTED) {
    return <Dot color="success" sx={{ mt: 1 }} />;
  }

  return null;
}

const BulkBookingList = ({ bookings, refetchBookings }: Props): JSX.Element => {
  const { status } = useParams<{ status: BookingStatusEnum }>();
  const [bookingUpdates, setBookingUpdates] = useState<Record<string, string>>({});
  const { mutate: updateBookings } = useUpdateBookings();
  const [buttonsDisabled, setButtonsDisabled] = useState<boolean>(false);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

  if (isEmpty(bookings)) {
    return (
      <Paper sx={{ p: 4, display: "flex", justifyContent: "center", mt: 2 }} elevation={0}>
        <Typography>You have no sessions booked this month.</Typography>
      </Paper>
    );
  }

  const getButtonValue = useCallback(
    (sessionId: string) => {
      if (sessionId in bookingUpdates) {
        return bookingUpdates[sessionId];
      }
      return null;
    },
    [bookingUpdates],
  );

  const handleChange = useCallback(
    (event: React.MouseEvent<HTMLElement>, newVal: string) => {
      const sessionId = event.currentTarget.getAttribute("data-session-id");
      const values = { ...bookingUpdates };
      if (isNil(sessionId)) {
        return;
      }

      if (isNil(newVal)) {
        if (sessionId in values) {
          delete values[sessionId];
        }
      } else {
        values[sessionId] = newVal;
      }

      setBookingUpdates(values);
    },
    [bookingUpdates],
  );

  const onSubmit = useCallback(() => {
    const updates = keys(bookingUpdates).map((bookingId) => ({
      booking: bookingId,
      action: bookingUpdates[bookingId],
    }));

    setButtonsDisabled(true);

    updateBookings(
      { updates },
      {
        onError: (error) => {
          Sentry.captureException(error);
          setShowErrorModal(true);
          setButtonsDisabled(false);
        },
        onSuccess: async () => {
          setBookingUpdates({});
          await refetchBookings();
          setButtonsDisabled(false);
        },
      },
    );
  }, [bookingUpdates]);

  return (
    <Box mt={2} mb={18}>
      {bookings.map((session, index, array) => (
        <React.Fragment key={session.id}>
          <SessionRow
            person={session.pwad_profile.person}
            date={session.session.date}
            startTime={session.session.start_time}
            location={session.session.session_location}
            profileLinkUrl={generatePath(ROUTES.FP_VIEW_PWAD_PROFILE, { pwadProfileId: session.pwad_profile.id })}
            renderDateStatus={() => renderStatus(status)}
            renderActions={() => (
              <Box
                display="flex"
                justifyContent={{ lg: "flex-start", xs: "center" }}
                width="265px"
                alignItems="center"
                gap={3}
              >
                <ToggleButtonGroup value={getButtonValue(session.id)} exclusive onChange={handleChange}>
                  <ToggleButton data-session-id={session.id} value="accept">
                    Accept
                  </ToggleButton>
                  <ToggleButton
                    data-session-id={session.id}
                    value="decline"
                    sx={{
                      "&.Mui-selected": {
                        "backgroundColor": "#ED6C02",
                        "color": "#fff",
                        "&:hover": {
                          backgroundColor: "#C77700",
                        },
                      },
                    }}
                  >
                    Decline
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
            )}
          />
          {index < array.length - 1 && <Divider />}
          <BulkActions buttonValues={bookingUpdates} onSubmit={onSubmit} loading={buttonsDisabled} />
        </React.Fragment>
      ))}

      <Dialog
        open={showErrorModal}
        onClose={() => {
          setShowErrorModal(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">An error has occurred</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            An error has occurred. Please check your selections and try again. If the problem persists please contact
            WeFlex support.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowErrorModal(false);
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default BulkBookingList;
