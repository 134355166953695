import { useState } from "react";
import { Container, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { format, isValid, parseISO } from "date-fns";
import { isEmpty } from "lodash";
import { generatePath, Redirect, useHistory, useParams } from "react-router-dom";
import Bookings from "./bookings/Bookings";
import * as ROUTES from "../../../constants/routes";
import { useAuth } from "../../../utils/AuthProvider";
import { getFirstName } from "../../../utils/person";
import Calendar, { CalendarAnnotations } from "../../calendar/Calendar";
import ColumnBox from "../../common/ColumnBox";

export default function Complete(): JSX.Element {
  const { year, month } = useParams<{ year?: string; month?: string }>();
  const history = useHistory();
  const { currentUser } = useAuth();
  const [calendarAnnotations, setCalendarAnnotations] = useState<CalendarAnnotations>({});
  const parsedDate = parseISO(`${year}-${month}-01`);
  const [selectedDate, setSelectedDate] = useState<Date>(isValid(parsedDate) ? parsedDate : new Date());

  const handleClickToday = () => {
    setSelectedDate(new Date());
  };

  if (isEmpty(year) || isEmpty(month) || !isValid(parsedDate)) {
    const now = new Date();

    return (
      <Redirect to={generatePath(ROUTES.FP_MY_BOOKINGS, { year: format(now, "yyyy"), month: format(now, "MM") })} />
    );
  }

  const handleMonthChange = (date: Date) => {
    history.push(generatePath(ROUTES.FP_MY_BOOKINGS, { year: format(date, "yyyy"), month: format(date, "MM") }));
  };

  const currentMonthTitle = isValid(parsedDate) ? format(parsedDate, "MMMM yyyy") : "selected month";

  return (
    <Container maxWidth="lg">
      <Box pt={4}>
        <Typography variant="h1" mb={{ xs: 2, lg: 3 }}>
          Hello,{" "}
          <Typography display="inline" variant="h1" component="span" color="link.main">
            {getFirstName(currentUser?.person)}.
          </Typography>
        </Typography>
        <Typography variant="h3">View and manage your upcoming bookings with your WeFlex clients here.</Typography>
      </Box>
      <ColumnBox alignContent="center">
        <Box display="flex" alignSelf="flex-end" my={2}>
          {/* <Button variant="outlined" onClick={handleClickToday}> */}
          {/*  Today */}
          {/* </Button> */}
        </Box>
        <Calendar date={selectedDate} onMonthChange={handleMonthChange} annotations={calendarAnnotations} />
      </ColumnBox>
      <Box mt={4}>
        <Typography variant="h2" mb={2}>
          Bookings for {currentMonthTitle}
        </Typography>

        <Bookings year={year!} month={month!} setCalendarAnnotations={setCalendarAnnotations} />
      </Box>
    </Container>
  );
}
