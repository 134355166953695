import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
import HomeIcon from "@mui/icons-material/Home";
import LocalPhone from "@mui/icons-material/LocalPhone";
import PersonIcon from "@mui/icons-material/Person";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import SearchIcon from "@mui/icons-material/Search";
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Link as MuiLink,
  IconButton,
  Link,
} from "@mui/material";
import { generatePath, Link as RouterLink } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/img/WeFlexLogo.svg";
import ENV from "../../constants/envConstants";
import * as ROUTES from "../../constants/routes";
import { useAuth } from "../../utils/AuthProvider";
import { useCurrentUserProfileIsLive } from "../../utils/person";

export const drawerWidth = 250;

type ResponsiveDrawerProps = {
  mobileOpen: boolean;
  toggleDrawer: CallableFunction;
  window?: () => Window;
};

function OpenInNewTabLink({ title }: { title: string }): JSX.Element {
  return (
    <Box display="flex" alignItems="center">
      <Typography>{title}</Typography>
      <ChevronRightIcon sx={{ width: 24, height: 24, color: "grey.600" }} />
    </Box>
  );
}

function DrawerFooter() {
  return (
    <Box mt="auto" display="flex" flexDirection="column" justifyContent="center" alignItems="center" pb={2}>
      <Link href={`tel:${ENV.WEFLEX_PHONE_NUMBER}`}>
        <Avatar sx={{ bgcolor: "primary.main", height: 30, width: 30 }}>
          <LocalPhone htmlColor="black" />
        </Avatar>
      </Link>

      <Typography variant="body1" mt={1}>
        Need help?
      </Typography>
      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
        <Link href={`tel:${ENV.WEFLEX_PHONE_NUMBER}`} color="text.primary">
          <b>{ENV.WEFLEX_PHONE_NUMBER}</b>
        </Link>
      </Typography>
    </Box>
  );
}

export default function ResponsiveDrawer({ mobileOpen = false, toggleDrawer, window }: ResponsiveDrawerProps) {
  const { currentUser } = useAuth();
  const isProfileLive = useCurrentUserProfileIsLive();

  const container = window !== undefined ? () => window().document.body : undefined;

  function renderNavigationLinks() {
    if (currentUser?.is_admin) {
      return (
        <List>
          <ListItem button component={RouterLink} to={ROUTES.HOME}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>
        </List>
      );
    }

    if (currentUser?.is_fp) {
      return (
        <List>
          <ListItem button component={RouterLink} to={ROUTES.HOME}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary={isProfileLive ? "My Bookings" : "Home"} />
          </ListItem>
          <ListItem button component={RouterLink} to={ROUTES.FP_MY_SESSION_REPORTS} disabled={!isProfileLive}>
            <ListItemIcon>
              <AutoGraphIcon />
            </ListItemIcon>
            <ListItemText primary="My Session Reports" />
          </ListItem>
          <ListItem
            button
            component={RouterLink}
            to={generatePath(ROUTES.FP_PROFILE, { profileId: currentUser?.person?.fp_profile?.id })}
          >
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary="My Profile" />
          </ListItem>
          <ListItem button component={RouterLink} to={ROUTES.FP_MY_INVOICES} disabled={!isProfileLive}>
            <ListItemIcon>
              <ReceiptLongIcon />
            </ListItemIcon>
            <ListItemText primary="My Invoices" />
          </ListItem>
        </List>
      );
    }

    if (currentUser?.is_pwad) {
      return (
        <List>
          <ListItem button component={RouterLink} to={ROUTES.HOME}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary={isProfileLive ? "My Bookings" : "Home"} />
          </ListItem>
          <ListItem button component={RouterLink} to={ROUTES.PWAD_MAKE_A_BOOKING}>
            <ListItemIcon>
              <SearchIcon />
            </ListItemIcon>
            <ListItemText primary="Make a Booking" />
          </ListItem>
          <ListItem
            button
            component={RouterLink}
            to={generatePath(ROUTES.PWAD_PROFILE, { profileId: currentUser?.person?.pwad_profile?.id })}
          >
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary="My Profile" />
          </ListItem>
        </List>
      );
    }

    return (
      <List>
        <ListItem button component={RouterLink} to={ROUTES.HOME}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
      </List>
    );
  }

  function renderExternalLinks() {
    return (
      <List>
        <ListItem button component={MuiLink} href={ENV.WEFLEX_CONTACT_US_URL} target="_blank" rel="noopener noreferrer">
          <ListItemText primary={<OpenInNewTabLink title="Contact Us" />} />
        </ListItem>
        {currentUser?.is_fp === true && (
          <ListItem button component={MuiLink} href={ENV.WEFLEX_ACADEMY_URL} target="_blank" rel="noopener noreferrer">
            <ListItemText primary={<OpenInNewTabLink title="WeFlex Academy" />} />
          </ListItem>
        )}
        <ListItem
          button
          component={MuiLink}
          href={ENV.WEFLEX_TERMS_AND_CONDITIONS_URL}
          target="_blank"
          rel="noopener noreferrer"
        >
          <ListItemText primary={<OpenInNewTabLink title="Terms & Conditions" />} />
        </ListItem>
      </List>
    );
  }

  return (
    <>
      <Drawer
        PaperProps={{
          sx: {
            borderRadius: "0px",
          },
        }}
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={() => toggleDrawer()}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          "display": { xs: "block", lg: "none" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}
      >
        <Box p={1} mb={3} display="flex" justifyContent="space-between">
          <IconButton onClick={() => toggleDrawer()} sx={{ backgroundColor: "primary.main" }}>
            <CloseIcon sx={{ color: "black" }} />
          </IconButton>
        </Box>

        {renderNavigationLinks()}

        <Divider sx={{ my: 2 }} />

        {renderExternalLinks()}

        <DrawerFooter />
      </Drawer>

      <Drawer
        PaperProps={{
          sx: {
            borderRadius: "0px",
          },
        }}
        variant="permanent"
        sx={{
          "display": { xs: "none", lg: "block" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}
        open
      >
        <Box pt={2} mb={3} alignContent="center" justifyContent="center" display="flex">
          <RouterLink to={ROUTES.HOME}>
            <Logo width="130px" height="55px" />
          </RouterLink>
        </Box>

        {renderNavigationLinks()}

        <Divider sx={{ my: 2 }} />

        {renderExternalLinks()}

        <DrawerFooter />
      </Drawer>
    </>
  );
}
