import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { Box, CircularProgress, Paper, Typography } from "@mui/material";
import * as Sentry from "@sentry/browser";
import { groupBy, isEmpty, isNil, merge } from "lodash";
import { useQuery } from "react-query";
import { generatePath, Redirect, Route, Switch } from "react-router-dom";
import BookingAcceptedConfirmation from "./BookingAcceptedConfirmation";
import BookingDeclinedConfirmation from "./BookingDeclinedConfirmation";
import BookingDeclineWarning from "./BookingDeclineWarning";
import GenericBookingList from "./GenericBookingList";
import { ErrorResponse, isErrorResponse } from "../../../../api/Generic";
import { useAcceptBooking, useDeclineBooking } from "../../../../api/mutations/Booking";
import * as ROUTES from "../../../../constants/routes";
import { GENERIC_REQUEST_ERROR_MESSAGE } from "../../../../constants/validation";
import { BookingModel, BookingStatusEnum } from "../../../../models/Booking";
import getErrorMessages from "../../../../utils/getErrorMessages";
import { CalendarAnnotations } from "../../../calendar/Calendar";
import ErrorAlert from "../../../common/ErrorAlert";
import ErrorDialog from "../../../common/ErrorDialog";
import NavTabs from "../../../common/NavTabs";

interface Props {
  year: string;
  month: string;
  setCalendarAnnotations: Dispatch<SetStateAction<CalendarAnnotations>>;
}

export default function Bookings({ year, month, setCalendarAnnotations }: Props): JSX.Element {
  const { mutate: acceptBooking } = useAcceptBooking();
  const { mutate: declineBooking } = useDeclineBooking();
  const [loadingSessionDetails, setLoadingSessionDetails] = useState<{
    id: string;
    action: "accept" | "decline";
  } | null>(null);
  const [submitErrors, setSubmitErrors] = useState<{ title: string; messages: string[] } | null>(null);
  const [openBookingAcceptedConfirmation, setOpenBookingAcceptedConfirmation] = useState(false);
  const [openBookingDeclinedConfirmation, setOpenBookingDeclinedConfirmation] = useState(false);
  const [openBookingDeclineWarning, setOpenBookingDeclineWarning] = useState(false);

  const {
    data: bookingsData,
    isLoading: isBookingsLoading,
    error: bookingsError,
    refetch: refetchBookings,
  } = useQuery<{ bookings: BookingModel[] }, ErrorResponse>(`/booking/list/?year=${year}&month=${month}`);

  useEffect(() => {
    if (!isEmpty(bookingsData?.bookings)) {
      const annotations = bookingsData!.bookings.reduce(
        (result: CalendarAnnotations, { status, session: { date } }: BookingModel) => {
          if (isNil(result[date])) {
            // eslint-disable-next-line no-param-reassign
            result[date] = [];
          }

          const dayAnnotations = result[date];
          if (status === BookingStatusEnum.ACCEPTED) {
            dayAnnotations.push("success");
          } else if (status === BookingStatusEnum.PENDING) {
            dayAnnotations.push("warning");
          }

          return result;
        },
        {},
      );
      setCalendarAnnotations(annotations);
    }
  }, [bookingsData, setCalendarAnnotations]);

  const groupedBookings = useMemo(() => {
    const grouped = {
      [BookingStatusEnum.PENDING]: [],
      [BookingStatusEnum.ACCEPTED]: [],
      [BookingStatusEnum.DECLINED]: [],
      [BookingStatusEnum.CANCELED]: [],
    };
    if (isEmpty(bookingsData?.bookings)) {
      return grouped;
    }

    return merge(grouped, groupBy(bookingsData?.bookings, "status"));
  }, [bookingsData]);

  const handleAcceptBooking = (bookingId: string) => {
    setLoadingSessionDetails({ id: bookingId, action: "accept" });
    acceptBooking(
      { booking: bookingId },
      {
        onError: (error) => {
          Sentry.captureException(error);
          setLoadingSessionDetails(null);

          if (isErrorResponse(error)) {
            setSubmitErrors({
              title: "Failed to accept the booking",
              messages: getErrorMessages(error.response.data?.message),
            });
          } else {
            setSubmitErrors({
              title: "Failed to accept the booking",
              messages: [GENERIC_REQUEST_ERROR_MESSAGE],
            });
          }
        },
        onSuccess: async () => {
          await refetchBookings();
          setLoadingSessionDetails(null);
          setOpenBookingAcceptedConfirmation(true);
        },
      },
    );
  };

  const handleDeclineBooking = (bookingId: string) => {
    setLoadingSessionDetails({ id: bookingId, action: "decline" });
    setOpenBookingDeclineWarning(true);
  };

  const handleBookingDeclineWarningOk = () => {
    setOpenBookingDeclineWarning(false);

    declineBooking(
      { booking: loadingSessionDetails!.id },
      {
        onError: (error) => {
          Sentry.captureException(error);
          setLoadingSessionDetails(null);

          if (isErrorResponse(error)) {
            setSubmitErrors({
              title: "Failed to decline the booking",
              messages: getErrorMessages(error.response.data?.message),
            });
          } else {
            setSubmitErrors({
              title: "Failed to decline the booking",
              messages: [GENERIC_REQUEST_ERROR_MESSAGE],
            });
          }
        },
        onSuccess: async () => {
          await refetchBookings();
          setLoadingSessionDetails(null);
          setOpenBookingDeclinedConfirmation(true);
        },
      },
    );
  };

  const handleBookingDeclineWarningClose = () => {
    setLoadingSessionDetails(null);
    setOpenBookingDeclineWarning(false);
  };

  const handleBookingAcceptedConfirmationClose = () => {
    setOpenBookingAcceptedConfirmation(false);
  };

  const handleBookingDeclinedConfirmationClose = () => {
    setOpenBookingDeclinedConfirmation(false);
  };

  const handleErrorClose = () => {
    setSubmitErrors(null);
  };

  const navTabItems = useMemo(
    () => [
      {
        label: `Pending (${groupedBookings[BookingStatusEnum.PENDING].length})`,
        path: generatePath(ROUTES.FP_MY_BOOKINGS_LIST, { year, month, status: BookingStatusEnum.PENDING }),
      },
      {
        label: `Accepted (${groupedBookings[BookingStatusEnum.ACCEPTED].length})`,
        path: generatePath(ROUTES.FP_MY_BOOKINGS_LIST, { year, month, status: BookingStatusEnum.ACCEPTED }),
      },
      {
        label: `Declined (${groupedBookings[BookingStatusEnum.DECLINED].length})`,
        path: generatePath(ROUTES.FP_MY_BOOKINGS_LIST, { year, month, status: BookingStatusEnum.DECLINED }),
      },
      {
        label: `Cancelled (${groupedBookings[BookingStatusEnum.CANCELED].length})`,
        path: generatePath(ROUTES.FP_MY_BOOKINGS_LIST, { year, month, status: BookingStatusEnum.CANCELED }),
      },
    ],
    [groupedBookings, year, month],
  );

  if (isBookingsLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (bookingsError) {
    return <ErrorAlert message="Bookings failed to load" />;
  }

  if (isEmpty(bookingsData?.bookings)) {
    return (
      <Paper sx={{ p: 4, display: "flex", justifyContent: "center", textAlign: "center" }} elevation={0}>
        <Typography>You have no sessions booked this month.</Typography>
      </Paper>
    );
  }

  return (
    <>
      <NavTabs scrollButtons="auto" allowScrollButtonsMobile variant="scrollable" items={navTabItems} />
      <Switch>
        <Route
          exact
          path={ROUTES.FP_MY_BOOKINGS_LIST}
          render={() => (
            <GenericBookingList
              groupedBookings={groupedBookings}
              onAcceptBooking={handleAcceptBooking}
              onDeclineBooking={handleDeclineBooking}
              loading={loadingSessionDetails}
              refetchBookings={refetchBookings}
            />
          )}
        />
        <Redirect to={generatePath(ROUTES.FP_MY_BOOKINGS_LIST, { year, month, status: BookingStatusEnum.PENDING })} />
      </Switch>
      <BookingAcceptedConfirmation
        open={openBookingAcceptedConfirmation}
        onClose={handleBookingAcceptedConfirmationClose}
      />
      <BookingDeclinedConfirmation
        open={openBookingDeclinedConfirmation}
        onClose={handleBookingDeclinedConfirmationClose}
      />
      <BookingDeclineWarning
        open={openBookingDeclineWarning}
        onOk={handleBookingDeclineWarningOk}
        onClose={handleBookingDeclineWarningClose}
      />
      {!isNil(submitErrors) ? (
        <ErrorDialog title={submitErrors.title} messages={submitErrors.messages} onClose={handleErrorClose} />
      ) : null}
    </>
  );
}
