import { Box, Card, CardActionArea, CardHeader, FormControlLabel, Radio, Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import * as yup from "yup";
import { GENERIC_REQUIRED_VALIDATION_ERROR_MESSAGE } from "../../../constants/validation";
import { CustomerTypeEnum } from "../common/CustomerType";
import { StepProps } from "../common/Step";

export type CustomerInput = {
  type: CustomerTypeEnum | "";
};

export const CustomerStepValidationSchema: yup.SchemaOf<CustomerInput> = yup.object({
  type: yup.mixed().defined().oneOf(Object.values(CustomerTypeEnum), GENERIC_REQUIRED_VALIDATION_ERROR_MESSAGE),
});

const CustomerStep = ({ name }: StepProps): JSX.Element => {
  const { control } = useFormContext();

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="space-between">
      <Box maxWidth="md">
        <Typography variant="h1" align="center">
          Who is seeking support?
        </Typography>
      </Box>

      <Box maxWidth="sm" mt={1}>
        <Typography variant="body1" align="center">
          Are you seeking support for yourself or another person?
        </Typography>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        alignItems="stretch"
        justifyContent="space-between"
        width="100%"
        maxWidth="sm"
        mt={5}
        mb={3}
        gap={2}
      >
        <Controller
          name={`${name}.type`}
          control={control}
          render={({ field: { onChange, value } }) => (
            <>
              <Card raised>
                <CardActionArea onClick={() => onChange(CustomerTypeEnum.PWAD_MYSELF)}>
                  <CardHeader
                    title={
                      <FormControlLabel
                        value={CustomerTypeEnum.PWAD_MYSELF}
                        checked={value === CustomerTypeEnum.PWAD_MYSELF}
                        control={<Radio />}
                        label="I'm seeking support for myself"
                      />
                    }
                  />
                </CardActionArea>
              </Card>

              <Card raised>
                <CardActionArea onClick={() => onChange(CustomerTypeEnum.PWAD_REPRESENTATIVE)}>
                  <CardHeader
                    title={
                      <FormControlLabel
                        value={CustomerTypeEnum.PWAD_REPRESENTATIVE}
                        checked={value === CustomerTypeEnum.PWAD_REPRESENTATIVE}
                        control={<Radio />}
                        label="I'm a representative of another person"
                      />
                    }
                  />
                </CardActionArea>
              </Card>
            </>
          )}
        />
      </Box>
    </Box>
  );
};

export default CustomerStep;
