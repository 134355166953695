import React from "react";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { ErrorResponse } from "../../../../api/Generic";
import { PWADProfileMyPreferencesModel } from "../../../../models/PWADProfile";
import ColumnBox from "../../../common/ColumnBox";
import ErrorAlert from "../../../common/ErrorAlert";
import PWADNDISPreferences from "../../../profile-cards/PWADNDISPreferences";
import PWADPaymentPreferences from "../../../profile-cards/PWADPaymentPreferences";
import PWADTrainingPreferences from "../../../profile-cards/PWADTrainingPreferences";

export default function MyPreferences(): JSX.Element | null {
  const { profileId } = useParams<{ profileId: string }>();

  const {
    data: myPreferencesData,
    error: myPreferencesError,
    isLoading: myPreferencesLoading,
  } = useQuery<PWADProfileMyPreferencesModel, ErrorResponse>(`/pwadprofile/my-preferences/?pwad_profile=${profileId}`);

  if (myPreferencesLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (myPreferencesError) {
    return <ErrorAlert message="Profile details failed to load" />;
  }

  if (!myPreferencesData) {
    return null;
  }

  return (
    <Grid container spacing={2} py={2}>
      <Grid item xs={12}>
        <Typography variant="h1" mb={3}>
          My{" "}
          <Typography display="inline" variant="h1" component="span" color="link.main">
            Preferences.
          </Typography>
        </Typography>
      </Grid>

      <Grid item lg={6} xs={12}>
        <ColumnBox gap={2}>
          <PWADTrainingPreferences myPreferencesData={myPreferencesData} />

          <PWADPaymentPreferences myPreferencesData={myPreferencesData} />
        </ColumnBox>
      </Grid>

      <Grid item lg={6} xs={12}>
        <ColumnBox gap={2}>
          <PWADNDISPreferences myPreferencesData={myPreferencesData} />
        </ColumnBox>
      </Grid>
    </Grid>
  );
}
