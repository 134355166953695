import { Typography, Grid, CircularProgress, Box } from "@mui/material";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { ErrorResponse } from "../../../api/Generic";
import { FPProfileTermsEditModel } from "../../../models/FPProfile";
import ErrorAlert from "../../common/ErrorAlert";
import FPTerms from "../../profile-cards/FPTerms";

export default function Terms(): JSX.Element | null {
  const { profileId } = useParams<{ profileId: string }>();

  const {
    data: termsData,
    isLoading,
    error,
  } = useQuery<FPProfileTermsEditModel, ErrorResponse>(`/fpprofile/terms/?fpprofile=${profileId}`);

  if (isLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <ErrorAlert message="Profile details failed to load" />;
  }

  if (!termsData) {
    return null;
  }

  return (
    <Grid container spacing={2} py={2}>
      <Grid item xs={12}>
        <Typography variant="h1" mb={3}>
          WeFlex{" "}
          <Typography display="inline" variant="h1" component="span" color="link.main">
            Terms.
          </Typography>
        </Typography>
      </Grid>
      <FPTerms termsData={termsData} />
    </Grid>
  );
}
