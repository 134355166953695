import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  CircularProgress,
  Grid,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as Sentry from "@sentry/browser";
import { omit } from "lodash";
import { Controller, FormProvider, SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { generatePath, RouteComponentProps, useParams } from "react-router-dom";
import * as yup from "yup";
import { ErrorResponse, isErrorResponse } from "../../../../api/Generic";
import { useEditFPProfilePayoutDetails } from "../../../../api/mutations/FPProfile";
import * as ROUTES from "../../../../constants/routes";
import {
  GENERIC_REQUEST_ERROR_MESSAGE,
  GENERIC_SUBMIT_VALIDATION_ERROR_MESSAGE,
} from "../../../../constants/validation";
import { BusinessTypeEnum, FPProfilePayoutDetailsEditModel } from "../../../../models/FPProfile";
import { useAuth } from "../../../../utils/AuthProvider";
import getErrorMessages from "../../../../utils/getErrorMessages";
import { getUserFPProfileIsCompleted } from "../../../../utils/person";
import ColumnBox from "../../../common/ColumnBox";
import ErrorAlert from "../../../common/ErrorAlert";
import ErrorDialog from "../../../common/ErrorDialog";
import ProfileCard from "../../../common/ProfileCard";
import UnsavedChangesPrompt from "../../../common/UnsavedChangesPrompt";

type PayoutDetailsFormInput = {
  payout_details_account_holder: string;
  payout_details_bsb: string;
  payout_details_account_number: string;
};

interface PayoutDetailsReactHookFormProps {
  defaultValues: PayoutDetailsFormInput;
  businessType: BusinessTypeEnum;
  onSubmit: SubmitHandler<PayoutDetailsFormInput>;
  onError: SubmitErrorHandler<PayoutDetailsFormInput>;
}

function PayoutDetailsReactHookForm({
  defaultValues,
  businessType,
  onSubmit,
  onError,
}: PayoutDetailsReactHookFormProps): JSX.Element {
  const { currentUser } = useAuth();
  const fpProfileIsCompleted = getUserFPProfileIsCompleted(currentUser);

  const PayoutDetailsFormSchema: yup.SchemaOf<PayoutDetailsFormInput | {}> = useMemo(() => {
    if (businessType === BusinessTypeEnum.INDEPENDENT_CONTRACTOR) {
      return yup.object({
        payout_details_account_holder: yup.string().trim().required("Account holder is required"),
        payout_details_bsb: yup.string().trim().required("BSB is required."),
        payout_details_account_number: yup.string().trim().required("Account number is required."),
      });
    }
    return yup.object({});
  }, [businessType]);

  const formMethods = useForm<PayoutDetailsFormInput>({
    resolver: yupResolver(PayoutDetailsFormSchema),
    mode: "onTouched",
    criteriaMode: "firstError",
    defaultValues: defaultValues,
  });

  const introText = useMemo(() => {
    if (businessType === BusinessTypeEnum.INDEPENDENT_CONTRACTOR) {
      return "When you receive payment for a session, we call that payment to you a 'payout'. Our payment cycle is fortnightly and the time it takes for the funds to appear in your account is dependent on your bank.";
    }

    return "Your payments will come to you via your employer. When you receive a payment for a session, we call that payment to you a 'payout'. Our payment cycle is fortnightly and the time it takes for the funds to appear in your account is dependent on your bank and your employer.";
  }, [businessType]);

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting, isDirty },
  } = formMethods;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const theme: Theme = useTheme();
  const breakpointMD = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Alert severity="info" variant="filled" sx={{ mt: 3 }}>
        {introText}
      </Alert>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <Grid container spacing={2} py={2}>
            <Grid item xs={12}>
              <Box mb={3}>
                <Grid
                  item
                  display="flex"
                  flexDirection={{ xs: "column", md: "row" }}
                  alignItems={{ xs: "flex-start", md: "center" }}
                  justifyContent={{ xs: "flex-start", md: "space-between" }}
                >
                  <Grid item>
                    <Typography variant="h1">
                      Payout{" "}
                      <Typography display="inline" variant="h1" component="span" color="link.main">
                        Details.
                      </Typography>
                    </Typography>
                  </Grid>

                  <Grid item>
                    <LoadingButton
                      type="submit"
                      variant={fpProfileIsCompleted ? "outlined" : "contained"}
                      size={breakpointMD ? "medium" : "small"}
                      sx={breakpointMD ? undefined : { marginTop: 2 }}
                      loading={isSubmitting}
                      disabled={isSubmitting}
                    >
                      Save Payout Details
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid item lg={6} xs={12}>
              <ColumnBox gap={3}>
                <ProfileCard title="Bank Account" Icon={CreditCardIcon}>
                  <ColumnBox gap={3}>
                    {businessType === BusinessTypeEnum.INDEPENDENT_CONTRACTOR && (
                      <>
                        <Controller
                          control={control}
                          name="payout_details_account_holder"
                          render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                            <TextField
                              label="Account Holder"
                              value={value}
                              onChange={onChange}
                              onBlur={onBlur}
                              inputRef={ref}
                              error={invalid}
                              helperText={error?.message}
                              required
                            />
                          )}
                        />

                        <Controller
                          control={control}
                          name="payout_details_bsb"
                          render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                            <TextField
                              label="BSB"
                              value={value}
                              onChange={onChange}
                              onBlur={onBlur}
                              inputRef={ref}
                              error={invalid}
                              helperText={error?.message}
                              required
                            />
                          )}
                        />
                        <Controller
                          control={control}
                          name="payout_details_account_number"
                          render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                            <TextField
                              label="Account number"
                              value={value}
                              onChange={onChange}
                              onBlur={onBlur}
                              inputRef={ref}
                              error={invalid}
                              helperText={error?.message}
                              required
                            />
                          )}
                        />
                      </>
                    )}
                  </ColumnBox>
                </ProfileCard>
              </ColumnBox>
            </Grid>
          </Grid>
        </form>
      </FormProvider>

      <UnsavedChangesPrompt showPrompt={isDirty} />
    </LocalizationProvider>
  );
}

interface Props extends RouteComponentProps {
  setSavedSnackbarMessage: Dispatch<SetStateAction<string>>;
}

export default function PayoutDetailsForm({ setSavedSnackbarMessage, history }: Props): JSX.Element {
  const { profileId } = useParams<{ profileId: string }>();

  const { refetchCurrentUser } = useAuth();

  const [errorDialogMessages, setErrorDialogMessages] = useState<Array<string>>([]);

  const {
    data: payoutDetailsData,
    isLoading: payoutDetailsLoading,
    error: payoutDetailsError,
    refetch: payoutDetailsRefetch,
  } = useQuery<FPProfilePayoutDetailsEditModel, ErrorResponse>(`/fpprofile/payout-details/?fpprofile=${profileId}`);

  const { mutateAsync: editFPProfilePayoutDetails } = useEditFPProfilePayoutDetails(profileId);

  const defaultValues = useMemo((): PayoutDetailsFormInput | null => {
    if (!payoutDetailsData) {
      return null;
    }

    return omit(payoutDetailsData, "business_type");
  }, [payoutDetailsData]);

  const onSubmit: SubmitHandler<PayoutDetailsFormInput> = async (values) => {
    if (payoutDetailsData?.business_type === BusinessTypeEnum.EMPLOYEE) {
      // skip form submission because we have nothing to submit.
      setSavedSnackbarMessage("Payout details saved successfully.");

      history.push(generatePath(ROUTES.FP_PROFILE_TERMS, { profileId }));
      return;
    }

    try {
      await editFPProfilePayoutDetails({ fp_profile: values });
      await payoutDetailsRefetch();
      await refetchCurrentUser();

      setSavedSnackbarMessage("Payout details saved successfully.");

      history.push(generatePath(ROUTES.FP_PROFILE_TERMS, { profileId }));
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);

      if (isErrorResponse(error)) {
        setErrorDialogMessages(getErrorMessages(error.response.data?.message));
      } else {
        setErrorDialogMessages([GENERIC_REQUEST_ERROR_MESSAGE]);
      }
    }
  };

  const onError: SubmitErrorHandler<PayoutDetailsFormInput> = (error) => {
    console.error({ error });
    setErrorDialogMessages([GENERIC_SUBMIT_VALIDATION_ERROR_MESSAGE]);
  };

  if (payoutDetailsLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (payoutDetailsError) {
    return <ErrorAlert message="Profile details failed to load" />;
  }

  if (!defaultValues || !payoutDetailsData) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <PayoutDetailsReactHookForm
        defaultValues={defaultValues}
        businessType={payoutDetailsData.business_type as BusinessTypeEnum}
        onSubmit={onSubmit}
        onError={onError}
      />

      {!!errorDialogMessages.length && (
        <ErrorDialog title="Unable to save" messages={errorDialogMessages} onClose={() => setErrorDialogMessages([])} />
      )}
    </>
  );
}
