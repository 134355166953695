import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import WorkIcon from "@mui/icons-material/Work";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as Sentry from "@sentry/browser";
import { Controller, FormProvider, SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { RouteComponentProps, generatePath, useParams } from "react-router-dom";
import * as yup from "yup";
import { ErrorResponse, isErrorResponse } from "../../../../api/Generic";
import { useEditFPProfileMyBusiness } from "../../../../api/mutations/FPProfile";
import * as ROUTES from "../../../../constants/routes";
import {
  GENERIC_REQUEST_ERROR_MESSAGE,
  GENERIC_SUBMIT_VALIDATION_ERROR_MESSAGE,
} from "../../../../constants/validation";
import FormCheckboxField from "../../../../form/FormCheckboxField";
import { BusinessTypeEnum, FPProfileMyBusinessEditModel } from "../../../../models/FPProfile";
import { useAuth } from "../../../../utils/AuthProvider";
import getErrorMessages from "../../../../utils/getErrorMessages";
import { getUserFPProfileIsCompleted } from "../../../../utils/person";
import ColumnBox from "../../../common/ColumnBox";
import ErrorAlert from "../../../common/ErrorAlert";
import ErrorDialog from "../../../common/ErrorDialog";
import ProfileCard from "../../../common/ProfileCard";
import UnsavedChangesPrompt from "../../../common/UnsavedChangesPrompt";

type MyBusinessFormInput = {
  business_type: string;
  business_name: string;
  business_abn: string;
  business_registered_gst: boolean;
};

interface MyBusinessReactHookFormProps {
  defaultValues: MyBusinessFormInput;
  onSubmit: SubmitHandler<MyBusinessFormInput>;
  onError: SubmitErrorHandler<MyBusinessFormInput>;
}

function MyBusinessReactHookForm({ defaultValues, onSubmit, onError }: MyBusinessReactHookFormProps): JSX.Element {
  const { currentUser } = useAuth();
  const fpProfileIsCompleted = getUserFPProfileIsCompleted(currentUser);
  const [businessType, setBusinessType] = useState(defaultValues.business_type);

  const formSchema = useMemo(() => {
    if (businessType === BusinessTypeEnum.INDEPENDENT_CONTRACTOR) {
      return yup.object({
        business_type: yup.string().trim().required("Business type is required."),
        business_name: yup.string().trim().required("Business name is required."),
        business_abn: yup.string().trim().required("Business ABN is required."),
        business_registered_gst: yup.bool(),
      });
    }
    return yup.object({
      business_type: yup.string().trim().required("Business type is required."),
      business_name: yup.string().trim().required("Employer is required."),
    });
  }, [businessType]);

  const formMethods = useForm<MyBusinessFormInput>({
    resolver: yupResolver(formSchema),
    mode: "onTouched",
    criteriaMode: "firstError",
    defaultValues: defaultValues,
  });

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { isSubmitting, isDirty },
  } = formMethods;

  const businessTypeValue = watch("business_type");

  useEffect(() => {
    if (!businessTypeValue) {
      setBusinessType(defaultValues.business_type);
    } else {
      setBusinessType(businessTypeValue);
    }
  }, [businessTypeValue, defaultValues]);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const theme: Theme = useTheme();
  const breakpointMD = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <Grid container spacing={2} py={2}>
            <Grid item xs={12}>
              <Box mb={3}>
                <Grid
                  item
                  display="flex"
                  flexDirection={{ xs: "column", md: "row" }}
                  alignItems={{ xs: "flex-start", md: "center" }}
                  justifyContent={{ xs: "flex-start", md: "space-between" }}
                >
                  <Grid item>
                    <Typography variant="h1">
                      My{" "}
                      <Typography display="inline" variant="h1" component="span" color="link.main">
                        Business.
                      </Typography>
                    </Typography>
                  </Grid>

                  <Grid item>
                    <LoadingButton
                      type="submit"
                      variant={fpProfileIsCompleted ? "outlined" : "contained"}
                      size={breakpointMD ? "medium" : "small"}
                      sx={breakpointMD ? undefined : { marginTop: 2 }}
                      loading={isSubmitting}
                      disabled={isSubmitting}
                    >
                      Save My Business
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid item lg={6} xs={12}>
              <ProfileCard title="Employment Details" Icon={WorkIcon}>
                <ColumnBox gap={3}>
                  <Controller
                    control={control}
                    name="business_type"
                    render={({ field: { onChange, onBlur, value }, fieldState: { error, invalid } }) => (
                      <FormControl error={invalid}>
                        <FormLabel id="business_type_label" required>
                          I will be working with WeFlex
                        </FormLabel>

                        <RadioGroup
                          aria-labelledby="business_type_group"
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                        >
                          <FormControlLabel
                            value={BusinessTypeEnum.INDEPENDENT_CONTRACTOR}
                            control={<Radio />}
                            label="as an independent contractor"
                            checked={value === BusinessTypeEnum.INDEPENDENT_CONTRACTOR}
                          />

                          <FormControlLabel
                            value={BusinessTypeEnum.EMPLOYEE}
                            control={<Radio />}
                            label="via my employer"
                            checked={value === BusinessTypeEnum.EMPLOYEE}
                          />
                        </RadioGroup>

                        {!!error?.message && <FormHelperText error>{error.message}</FormHelperText>}
                      </FormControl>
                    )}
                  />
                  {businessTypeValue === BusinessTypeEnum.INDEPENDENT_CONTRACTOR ? (
                    <>
                      <Controller
                        control={control}
                        name="business_name"
                        render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                          <TextField
                            label="Business name"
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            inputRef={ref}
                            error={invalid}
                            helperText={error?.message}
                            required
                          />
                        )}
                      />

                      <Controller
                        control={control}
                        name="business_abn"
                        render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                          <TextField
                            label="ABN"
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            inputRef={ref}
                            error={invalid}
                            helperText={error?.message}
                            required
                          />
                        )}
                      />

                      <Controller
                        control={control}
                        name="business_registered_gst"
                        render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                          <FormCheckboxField
                            name="business_registered_gst"
                            label="Registered for GST"
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            inputRef={ref}
                          />
                        )}
                      />
                    </>
                  ) : (
                    <Controller
                      control={control}
                      name="business_name"
                      render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                        <TextField
                          label="Employer"
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          inputRef={ref}
                          error={invalid}
                          helperText={error?.message}
                          required
                        />
                      )}
                    />
                  )}
                </ColumnBox>
              </ProfileCard>
            </Grid>
          </Grid>
        </form>
      </FormProvider>

      <UnsavedChangesPrompt showPrompt={isDirty} />
    </LocalizationProvider>
  );
}

interface Props extends RouteComponentProps {
  setSavedSnackbarMessage: Dispatch<SetStateAction<string>>;
}

export default function MyBusinessForm({ setSavedSnackbarMessage, history }: Props): JSX.Element {
  const { profileId } = useParams<{ profileId: string }>();

  const { refetchCurrentUser } = useAuth();

  const [errorDialogMessages, setErrorDialogMessages] = useState<Array<string>>([]);

  const {
    data: myBusinessData,
    isLoading: myBusinessLoading,
    error: myBusinessError,
    refetch: myBusinessRefetch,
  } = useQuery<FPProfileMyBusinessEditModel, ErrorResponse>(`/fpprofile/my-business/?fpprofile=${profileId}`);

  const { mutateAsync: editFPProfileMyBusiness } = useEditFPProfileMyBusiness(profileId);

  const defaultValues = useMemo((): MyBusinessFormInput | null => {
    if (!myBusinessData) {
      return null;
    }

    return myBusinessData;
  }, [myBusinessData]);

  const onSubmit: SubmitHandler<MyBusinessFormInput> = async (values) => {
    try {
      await editFPProfileMyBusiness({ fp_profile: values });
      await myBusinessRefetch();
      await refetchCurrentUser();

      setSavedSnackbarMessage("My business saved successfully.");

      history.push(generatePath(ROUTES.FP_PROFILE_MY_SERVICES, { profileId }));
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);

      if (isErrorResponse(error)) {
        setErrorDialogMessages(getErrorMessages(error.response.data?.message));
      } else {
        setErrorDialogMessages([GENERIC_REQUEST_ERROR_MESSAGE]);
      }
    }
  };

  const onError: SubmitErrorHandler<MyBusinessFormInput> = (error) => {
    console.error({ error });
    setErrorDialogMessages([GENERIC_SUBMIT_VALIDATION_ERROR_MESSAGE]);
  };

  if (myBusinessLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (myBusinessError) {
    return <ErrorAlert message="Profile details failed to load" />;
  }

  if (!defaultValues || !myBusinessData) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <MyBusinessReactHookForm defaultValues={defaultValues} onSubmit={onSubmit} onError={onError} />

      {!!errorDialogMessages.length && (
        <ErrorDialog title="Unable to save" messages={errorDialogMessages} onClose={() => setErrorDialogMessages([])} />
      )}
    </>
  );
}
