import CreditCardIcon from "@mui/icons-material/CreditCard";
import { FPProfileModel } from "../../models/FPProfile";
import ColumnBox from "../common/ColumnBox";
import ProfileCard from "../common/ProfileCard";
import ProfileCardTitleWithContent from "../common/ProfileCardTitleWithContent";

interface Props {
  payoutDetailsData?: FPProfileModel;
}

export default function FPBankDetails({ payoutDetailsData }: Props) {
  return (
    <ProfileCard title="Bank Account" Icon={CreditCardIcon}>
      <ColumnBox gap={3}>
        <ProfileCardTitleWithContent
          title="Account holder"
          content={payoutDetailsData?.payout_details_account_holder}
        />

        <ProfileCardTitleWithContent title="BSB" content={payoutDetailsData?.payout_details_bsb} />

        <ProfileCardTitleWithContent
          title="Account number"
          content={payoutDetailsData?.payout_details_account_number}
        />
      </ColumnBox>
    </ProfileCard>
  );
}
