import { ReactNode, ReactNodeArray } from "react";
import { Typography } from "@mui/material";
import { isEmpty, isNil } from "lodash";

interface Props {
  children: ReactNode | ReactNodeArray | string;
}

export default function OptionalValue({ children }: Props) {
  // Special handling for boolean `children` values to just pass through the value, as our logic below does not
  // currently handle them. It is recommended not to pass a raw boolean value to this component, and to instead pass
  // something (e.g. a string) that is calculated based on the boolean value.
  if (children === true || children === false) {
    return <>{children}</>;
  }

  if (isNil(children) || isEmpty(children) || children === "") {
    return (
      <Typography component="span" variant="subtitle2" color="grey.400">
        [None]
      </Typography>
    );
  }

  return <>{children}</>;
}
