import { Box, Typography } from "@mui/material";

interface Props {
  number: number;
  size?: number;
  enabled?: boolean;
}

const StepNumber = ({ number, size = 29, enabled }: Props) => {
  return (
    <Box
      boxSizing="border-box"
      borderRadius={30}
      borderColor="primary.main"
      p={1}
      width={size}
      height={size}
      sx={{ backgroundColor: enabled ? "primary.main" : "#bdbdbd" }}
    >
      <Typography variant="h3" lineHeight="15px" textAlign="center" sx={{ fontWeight: "900", color: "#ffffff" }}>
        {number}
      </Typography>
    </Box>
  );
};

export default StepNumber;
