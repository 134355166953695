import React from "react";
import { Box, Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import * as yup from "yup";
import { CustomerTypeEnum } from "./CustomerType";
import { StepProps } from "./Step";
import { GENERIC_REQUIRED_VALIDATION_ERROR_MESSAGE, PHONE_VALIDATION_REGEX } from "../../../constants/validation";
import FormPasswordField from "../../../form/FormPasswordField";
import FormTextField from "../../../form/FormTextField";
import PasswordRequirements from "../../../form/PasswordRequirements";
import SelectControl from "../../../form/SelectControl";
import { UserSourceTypeEnum, UserSourceTypeLabel } from "../../../models/Person";
import { RepresentativeRelationshipEnum, RepresentativeRelationshipLabel } from "../../../models/PWADProfile";

export type DetailsMyselfInput = {
  firstName: string;
  lastName: string;
  preferredName: string;
  email: string;
  mobile: string;
  password: string;
  source: UserSourceTypeEnum | "";
};

export const DetailsStepMyselfValidationSchema: yup.SchemaOf<DetailsMyselfInput> = yup.object({
  firstName: yup.string().required("First name is required."),
  lastName: yup.string().required("Last name is required."),
  preferredName: yup.string().default("").optional(),
  email: yup.string().email("Please enter a valid email address.").required("Email address is required."),
  mobile: yup
    .string()
    .trim()
    .required("Mobile phone number is required.")
    .test("phoneWithMinDigits", "Please enter a valid mobile phone number.", (value) => {
      if (!value) {
        return true;
      }

      return PHONE_VALIDATION_REGEX.test(value);
    }),
  password: yup
    .string()
    .required("Password is required.")
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must contain at least 8 characters, one uppercase, one lowercase, one number and one special character.",
    ),
  source: yup
    .mixed()
    .defined()
    .oneOf([...Object.values(UserSourceTypeEnum), ""], GENERIC_REQUIRED_VALIDATION_ERROR_MESSAGE),
});

export type DetailsRepresentativeInput = {
  firstName: string;
  lastName: string;
  preferredName: string;
  email: string;
  mobile: string;
  representedBy: {
    firstName: string;
    lastName: string;
    preferredName: string;
    relationship: RepresentativeRelationshipEnum | "";
    email: string;
    mobile: string;
    password: string;
    source: UserSourceTypeEnum | "";
  };
};

export const DetailsStepRepresentativeValidationSchema: yup.SchemaOf<DetailsRepresentativeInput> = yup.object({
  firstName: yup.string().required("First name is required."),
  lastName: yup.string().required("Last name is required."),
  preferredName: yup.string().default("").optional(),
  email: yup.string().defined().email("Please enter a valid email address."),
  mobile: yup
    .string()
    .defined()
    .trim()
    .test("phoneWithMinDigits", "Please enter a valid mobile phone number.", (value) => {
      if (!value) {
        return true;
      }

      return PHONE_VALIDATION_REGEX.test(value);
    }),
  representedBy: yup.object({
    firstName: yup.string().required("First name is required."),
    lastName: yup.string().required("Last name is required."),
    preferredName: yup.string().default("").optional(),
    relationship: yup
      .mixed()
      .defined()
      .oneOf(Object.values(RepresentativeRelationshipEnum), "Relationship is required."),
    email: yup.string().email("Please enter a valid email address.").required("Email address is required."),
    mobile: yup
      .string()
      .trim()
      .required("Mobile phone number is required.")
      .test("phoneWithMinDigits", "Please enter a valid mobile phone number.", (value) => {
        if (!value) {
          return true;
        }

        return PHONE_VALIDATION_REGEX.test(value);
      }),
    password: yup
      .string()
      .required("Password is required.")
      .matches(
        // eslint-disable-next-line no-useless-escape
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must contain at least 8 characters, one uppercase, one lowercase, one number and one special character.",
      ),
    source: yup
      .mixed()
      .defined()
      .oneOf([...Object.values(UserSourceTypeEnum), ""], GENERIC_REQUIRED_VALIDATION_ERROR_MESSAGE),
  }),
});

const DetailsStep = ({ name }: StepProps): JSX.Element => {
  const { getValues, control } = useFormContext();

  const customerType: CustomerTypeEnum = getValues("customer.type");
  const isRepresentative = customerType === CustomerTypeEnum.PWAD_REPRESENTATIVE;

  let title: string;
  let subtitle: string;

  if (isRepresentative) {
    title = "What are the person's details?";
    subtitle = "Enter the details of the person you are representing.";
  } else {
    title = "What are your details?";
    subtitle = "Please provide your details to set up your account.";
  }

  const renderPersonFields = () => {
    return (
      <>
        <Box maxWidth="md">
          <Typography variant="h1" align="center">
            {title}
          </Typography>
        </Box>

        <Box maxWidth="sm" mt={1}>
          <Typography variant="body1" align="center">
            {subtitle}
          </Typography>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          alignItems="stretch"
          justifyContent="space-between"
          width="100%"
          maxWidth="sm"
          mt={5}
          gap={2}
        >
          <FormTextField
            required
            name={`${name}.firstName`}
            id="first-name-text-field"
            label="First name"
            variant="outlined"
          />

          <FormTextField
            required
            name={`${name}.lastName`}
            id="last-name-text-field"
            label="Last name"
            variant="outlined"
          />

          <FormTextField
            name={`${name}.preferredName`}
            id="preferred-name-text-field"
            label="Preferred name"
            variant="outlined"
          />

          <FormTextField
            required={!isRepresentative}
            name={`${name}.email`}
            id="email-text-field"
            label="Email"
            variant="outlined"
          />

          <FormTextField
            required={!isRepresentative}
            name={`${name}.mobile`}
            id="mobile-text-field"
            label="Mobile"
            variant="outlined"
          />

          {!isRepresentative && (
            <>
              <FormPasswordField
                required
                name={`${name}.password`}
                id="password-text-field"
                label="Password"
                variant="outlined"
              />

              <Box>
                <PasswordRequirements />
              </Box>

              <Controller
                control={control}
                name={`${name}.source`}
                render={({ field, fieldState }) => (
                  <SelectControl
                    options={Object.values(UserSourceTypeEnum).map((option) => ({
                      value: option,
                      label: UserSourceTypeLabel[option],
                    }))}
                    label="How did you hear about WeFlex?"
                    helperText="This will help us reach more people who need our services."
                    field={field}
                    fieldState={fieldState}
                  />
                )}
              />
            </>
          )}
        </Box>
      </>
    );
  };

  const renderRepresentedByFields = () => {
    if (!isRepresentative) {
      return null;
    }

    return (
      <>
        <Box maxWidth="md" mt={5}>
          <Typography variant="h1" align="center">
            What are your details?
          </Typography>
        </Box>

        <Box maxWidth="sm" mt={1}>
          <Typography variant="body1" align="center">
            Enter your own details, as the person who will be managing this account.
          </Typography>
        </Box>

        <Box
          mt={5}
          display="flex"
          flexDirection="column"
          alignItems="stretch"
          justifyContent="space-between"
          width="100%"
          maxWidth="sm"
          gap={2}
        >
          <FormTextField
            required
            name={`${name}.representedBy.firstName`}
            id="represented-by-first-name-text-field"
            label="First name"
            variant="outlined"
          />

          <FormTextField
            required
            name={`${name}.representedBy.lastName`}
            id="represented-by-last-name-text-field"
            label="Last name"
            variant="outlined"
          />

          <FormTextField
            name={`${name}.representedBy.preferredName`}
            id="represented-by-preferred-name-text-field"
            label="Preferred name"
            variant="outlined"
          />

          <Controller
            control={control}
            name={`${name}.representedBy.relationship`}
            render={({ field, fieldState }) => (
              <SelectControl
                options={Object.values(RepresentativeRelationshipEnum).map((option) => ({
                  value: option,
                  label: RepresentativeRelationshipLabel[option],
                }))}
                label="Relationship"
                helperText="Your relationship to the person you are representing."
                required
                field={field}
                fieldState={fieldState}
              />
            )}
          />

          <FormTextField
            required
            name={`${name}.representedBy.email`}
            id="represented-by-email-text-field"
            label="Email"
            variant="outlined"
            helperText="This is the email address you will use to log in to the WeFlex Portal."
          />

          <FormTextField
            required
            name={`${name}.representedBy.mobile`}
            id="represented-by-mobile-text-field"
            label="Mobile"
            variant="outlined"
          />

          <FormPasswordField
            required
            name={`${name}.representedBy.password`}
            id="represented-by-password-text-field"
            label="Password"
            variant="outlined"
          />

          <Controller
            control={control}
            name={`${name}.representedBy.source`}
            render={({ field, fieldState }) => (
              <SelectControl
                options={Object.values(UserSourceTypeEnum).map((option) => ({
                  value: option,
                  label: UserSourceTypeLabel[option],
                }))}
                label="How did you hear about WeFlex?"
                helperText="This will help us reach more people who need our services."
                field={field}
                fieldState={fieldState}
              />
            )}
          />
        </Box>
      </>
    );
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="space-between">
      {renderPersonFields()}

      {renderRepresentedByFields()}
    </Box>
  );
};

export default DetailsStep;
