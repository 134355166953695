import { useMemo } from "react";
import { Box, Card, CircularProgress, Container, useMediaQuery } from "@mui/material";
import { useQuery } from "react-query";
import { generatePath, Redirect, Route, Switch, useParams } from "react-router-dom";
import AboutMe from "./AboutMe";
import Qualifications from "./Qualifications";
import Services from "./Services";
import { ErrorResponse } from "../../../api/Generic";
import * as ROUTES from "../../../constants/routes";
import { FPProfileAboutMeModelAsPWAD } from "../../../models/FPProfile";
import theme from "../../../styles/_theme";
import { getFullName } from "../../../utils/person";
import ErrorAlert from "../../common/ErrorAlert";
import NavTabs from "../../common/NavTabs";
import ProfileBanner from "../../common/ProfileBanner";

export default function Profile(): JSX.Element {
  const mobileMode = useMediaQuery(theme.breakpoints.down("lg"));
  const { fpId } = useParams<{ fpId: string }>();

  const {
    data: aboutMeData,
    isLoading: aboutMeLoading,
    error: aboutMeError,
  } = useQuery<FPProfileAboutMeModelAsPWAD, ErrorResponse>(`/pwadprofile/fpprofile/about-me/?fpprofile=${fpId}`);

  const navTabItems = useMemo(
    () => [
      { label: "About Me", path: generatePath(ROUTES.PWAD_VIEW_FP_PROFILE_ABOUT_ME, { fpId }) },
      { label: "My Services", path: generatePath(ROUTES.PWAD_VIEW_FP_PROFILE_MY_SERVICES, { fpId }) },
      { label: "My Qualifications", path: generatePath(ROUTES.PWAD_VIEW_FP_PROFILE_MY_QUALIFICATIONS, { fpId }) },
    ],
    [fpId],
  );

  if (aboutMeLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (aboutMeError) {
    return <ErrorAlert message="Profile details failed to load" />;
  }

  return (
    <Container maxWidth="xl" sx={{ px: 2 }}>
      <Card sx={{ width: "100%", mb: { xs: 2, lg: 4 } }}>
        <ProfileBanner
          fullName={getFullName(aboutMeData?.person)}
          avatar={aboutMeData?.person?.avatar}
          title="WeFlex fitness professional"
          instagramUrl={aboutMeData?.about_me_social_insta}
          facebookUrl={aboutMeData?.about_me_social_fb}
          email={aboutMeData?.person?.email}
        />

        <NavTabs
          scrollButtons="auto"
          allowScrollButtonsMobile
          variant={mobileMode ? "scrollable" : "standard"}
          sx={{ ".MuiTabs-flexContainer": { justifyContent: { lg: "flex-end" }, py: { lg: 1 } } }}
          items={navTabItems}
        />
      </Card>
      <Switch>
        <Route exact path={ROUTES.PWAD_VIEW_FP_PROFILE_ABOUT_ME} component={AboutMe} />
        <Route exact path={ROUTES.PWAD_VIEW_FP_PROFILE_MY_SERVICES} component={Services} />
        <Route exact path={ROUTES.PWAD_VIEW_FP_PROFILE_MY_QUALIFICATIONS} component={Qualifications} />
        <Redirect to={ROUTES.PWAD_VIEW_FP_PROFILE_ABOUT_ME} />
      </Switch>
    </Container>
  );
}
