import { useMemo } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, Grid, Tab, TabProps, Typography, useMediaQuery } from "@mui/material";
import { isNil } from "lodash";
import { Link as RouterLink } from "react-router-dom";
import StepNumber from "./StepNumber";
import theme from "../../styles/_theme";

interface Props {
  stepNumber?: number;
  isCurrentStep?: boolean;
  isStepCompleted?: boolean;
  hideIcon?: boolean;
}

export default function LinkTab({
  label,
  hideIcon = false,
  to,
  stepNumber,
  isCurrentStep,
  isStepCompleted,
  ...restProps
}: Props & TabProps<RouterLink>) {
  const mobileMode = useMediaQuery(theme.breakpoints.down("lg"));

  const stepIcon = useMemo(() => {
    if (hideIcon) {
      return null;
    }

    if (isNil(stepNumber)) {
      return null;
    }

    let step = <StepNumber number={stepNumber} />;
    if (isCurrentStep) {
      step = <StepNumber enabled number={stepNumber} />;
    }

    if (isStepCompleted) {
      step = <CheckCircleIcon sx={{ color: "primary.main" }} fontSize="large" />;
    }

    return (
      <Box display="flex" alignItems="center" justifyContent="center" height={15} width={35}>
        {step}
      </Box>
    );
  }, [stepNumber, isCurrentStep, isStepCompleted]);

  const labelTypography = (
    <Grid item flexDirection="row" display="flex" alignItems="center">
      <Box mr={1}>{stepIcon}</Box>
      <Typography variant={mobileMode ? "h4" : "h3"} sx={{ textTransform: "none" }}>
        {label}
      </Typography>
    </Grid>
  );

  return <Tab {...restProps} component={RouterLink} to={to} label={labelTypography} />;
}
