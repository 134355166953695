import LocalPhone from "@mui/icons-material/LocalPhone";
import { Avatar, Box, Container, Link, Theme, Typography } from "@mui/material";
import { SxProps } from "@mui/system";
import { Link as RouterLink } from "react-router-dom";
import "./Header.css";
import { ReactComponent as Logo } from "../../../assets/img/WeFlexLogo.svg";
import ENV from "../../../constants/envConstants";
import * as ROUTES from "../../../constants/routes";

export const HEADER_HEIGHT = 85;
export const HEADER_MARGIN_HEIGHT = 3;

const headerContainerSxProps: SxProps<Theme> = {
  height: HEADER_HEIGHT,
  display: "flex",
  alignItems: "center",
  borderBottom: `solid ${HEADER_MARGIN_HEIGHT}px`,
  borderColor: "primary.main",
};

const headerInnerContainerSxProps: SxProps<Theme> = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  maxWidth: "lg",
};

const Header = (): JSX.Element => {
  return (
    <Box sx={headerContainerSxProps}>
      <Container sx={headerInnerContainerSxProps} className="container">
        <RouterLink to={ROUTES.HOME}>
          <Logo />
        </RouterLink>

        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography variant="body1" sx={{ mr: 1 }} className="phone-number">
            Need help? Phone{" "}
            <Link href={`tel:${ENV.WEFLEX_PHONE_NUMBER}`} color="text.primary">
              <b>{ENV.WEFLEX_PHONE_NUMBER}</b>
            </Link>
          </Typography>
          <Link href={`tel:${ENV.WEFLEX_PHONE_NUMBER}`}>
            <Avatar sx={{ bgcolor: "primary.main", height: 30, width: 30 }}>
              <LocalPhone />
            </Avatar>
          </Link>
        </Box>
      </Container>
    </Box>
  );
};

export default Header;
