import { isEmpty } from "lodash";
import { AvatarModel } from "../models/Person";

export default function getAvatarUrl(avatar?: AvatarModel): string | undefined {
  if (isEmpty(avatar)) {
    return undefined;
  }

  return avatar?.crop_200_200;
}
