/* eslint-disable import/prefer-default-export */
import { useMutation } from "react-query";
import { SessionReportModel } from "../../models/Booking";
import Axios from "../Axios";
import { ErrorResponse, ServerResponse } from "../Generic";

interface CreateSessionReportParameters {
  booking: string;
}

export function useCreateSessionReport() {
  return useMutation<ServerResponse, ErrorResponse, CreateSessionReportParameters>(
    (input: CreateSessionReportParameters) => Axios.post<ServerResponse, any>("session-report/", input),
  );
}

interface UpdateSessionReportAchievementsParameters {
  sessionReportId: string;
  achievements: { id: string; content: string }[];
}

export function useUpdateSessionReportAchievements() {
  return useMutation<ServerResponse, ErrorResponse, UpdateSessionReportAchievementsParameters>(
    ({ sessionReportId, achievements }: UpdateSessionReportAchievementsParameters) =>
      Axios.put<ServerResponse, any>(`/session-report/${sessionReportId}/achievements/update/`, achievements),
  );
}

type UpdateSessionReportParameters = {
  sessionReportId: string;
} & Partial<SessionReportModel>;

export function useUpdateSessionReport() {
  return useMutation<ServerResponse, ErrorResponse, UpdateSessionReportParameters>(
    ({ sessionReportId, ...body }: UpdateSessionReportParameters) =>
      Axios.patch<ServerResponse, any>(`/session-report/${sessionReportId}/update/`, body),
  );
}

export function useSubmitSessionReport() {
  return useMutation<ServerResponse, ErrorResponse, UpdateSessionReportParameters>(
    ({ sessionReportId }: UpdateSessionReportParameters) =>
      Axios.put<ServerResponse, any>(`/session-report/${sessionReportId}/complete/`, {}),
  );
}
