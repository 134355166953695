import { useMemo } from "react";
import StarIcon from "@mui/icons-material/Star";
import { find, isEmpty, isNil, sortBy } from "lodash";
import { FPProfileModel } from "../../models/FPProfile";
import { ServiceModel, SpecialtyModel } from "../../models/Session";
import { getServicesFromServicesData } from "../../utils/person";
import CheckIconList from "../common/CheckIconList";
import ProfileCard from "../common/ProfileCard";

interface Props {
  myServicesData?: FPProfileModel;
}

export default function FPSpecialties({ myServicesData }: Props) {
  const selectedServices = useMemo<ServiceModel[]>(() => getServicesFromServicesData(myServicesData), [myServicesData]);

  const selectedSpecialities = useMemo<SpecialtyModel[]>(() => {
    if (isEmpty(myServicesData?.specialties)) {
      return [];
    }

    return sortBy(myServicesData!.specialties, "name");
  }, [myServicesData]);

  const availableSpecialties = useMemo<SpecialtyModel[]>(() => {
    if (isEmpty(myServicesData?.all_specialties)) {
      return [];
    }

    const specialities = myServicesData!.all_specialties?.filter(
      (specialty) => !isNil(find(selectedServices, (service) => service.id === specialty.service.id)),
    );

    return sortBy(specialities, "name");
  }, [myServicesData]);

  const selectableValues: { [key in string]: { name: string; secondary?: string } } = {};
  const selectedValues: { [key in string]: { name: string } } = {};

  selectedSpecialities.forEach((element) => {
    selectedValues[element.id] = { name: element.name };
  });

  availableSpecialties.forEach((element) => {
    selectableValues[element.id] = {
      name: element.name,
      secondary: element.service.name,
    };
  });

  return (
    <ProfileCard title="Specialties" Icon={StarIcon}>
      <CheckIconList showDivider list={selectedSpecialities} />
    </ProfileCard>
  );
}
