import DoneIcon from "@mui/icons-material/Done";
import { Avatar, Box, Button, Divider, Grid, Link, Paper, Typography } from "@mui/material";
import { generatePath, Link as RouterLink } from "react-router-dom";
import { ReactComponent as BookSessionIcon } from "../../../assets/icons/bookSession.svg";
import { ReactComponent as CompleteProfileIcon } from "../../../assets/icons/completeProfile.svg";
import { ReactComponent as HaveYourBackIcon } from "../../../assets/icons/haveYourBack.svg";
import { ReactComponent as ViewMatchesIcon } from "../../../assets/icons/viewMatches.svg";
import ENV from "../../../constants/envConstants";
import * as ROUTES from "../../../constants/routes";
import { useAuth } from "../../../utils/AuthProvider";
import { getFirstName } from "../../../utils/person";
import HomeInfoCard from "../../common/HomeInfoCard";
import VideoPlayer from "../../common/VideoPlayer";

export default function Incomplete(): JSX.Element {
  const { currentUser } = useAuth();

  // TODO: Update this to use status from PWAD profile once this is introduced.
  const isCreatedStatus = true;

  const profilePath = generatePath(ROUTES.PWAD_PROFILE, { profileId: currentUser?.person?.pwad_profile?.id });

  return (
    <Grid container padding={{ lg: 4, xs: 2 }} spacing={2}>
      <Grid item xs={12} textAlign={{ xs: "center", lg: "start" }} mb={{ xs: 2, lg: 4 }}>
        <Typography variant="h1">
          Welcome,{" "}
          <Typography display="inline" variant="h1" component="span" color="link.main">
            {getFirstName(currentUser?.person)}.
          </Typography>
        </Typography>
      </Grid>

      <Grid item xs={12} lg={6} display="flex" flexDirection="column" textAlign={{ xs: "center", lg: "start" }}>
        <Typography variant="body1" fontSize={20} lineHeight={1.5}>
          Congratulations on taking the first step to getting active with a WeFlex fitness professional. You have
          registered your basic details and now it&apos;s time to get to know you better. Matching you to the right
          fitness professional to meet your needs is our highest priority.
        </Typography>

        {isCreatedStatus && (
          <Box mt={4}>
            <Button component={RouterLink} to={profilePath} variant="contained">
              Complete Profile
            </Button>
          </Box>
        )}
      </Grid>

      <Grid item xs={12} lg={6}>
        <Box display="flex" justifyContent={{ xs: "center", lg: "flex-end" }}>
          <VideoPlayer videoUrl={ENV.WEFLEX_WELCOME_PWAD_VIDEO_URL} containerProps={{ component: Paper, p: 2 }} />
        </Box>
      </Grid>

      <Grid item xs={12} my={4}>
        <Divider />
      </Grid>

      <Grid item xs={12} display="flex" flexDirection="column" alignItems="center">
        <Typography variant="h2">How it works</Typography>
        <Box maxWidth={722} mt={1} mb={2}>
          <Typography variant="body1" align="center">
            WeFlex knows that no one fitness professional is going to work for everyone. We match people with a
            disability with the right fitness professional to help them get to were they want to be.
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={12} lg={3} display="flex">
        <HomeInfoCard>
          <Avatar sx={{ bgcolor: "primary.main", height: 35, width: 35, color: "black" }}>
            <Typography variant="body1">1</Typography>
          </Avatar>
          <Typography variant="h3">Complete your profile</Typography>
          <CompleteProfileIcon />
          <Typography variant="body2">
            <Link component={RouterLink} to={profilePath} color="link.main" sx={{ fontWeight: "bold" }}>
              Complete your profile
            </Link>{" "}
            so we can match you to the right fitness professional. If you need support,{" "}
            <Link href={`tel:${ENV.WEFLEX_PHONE_NUMBER}`} color="link.main" sx={{ fontWeight: "bold" }}>
              call us
            </Link>{" "}
            anytime for help.
          </Typography>
        </HomeInfoCard>
      </Grid>

      <Grid item xs={12} lg={3} display="flex">
        <HomeInfoCard>
          <Avatar sx={{ bgcolor: "primary.main", height: 35, width: 35, color: "black" }}>
            <Typography variant="body1">2</Typography>
          </Avatar>
          <Typography variant="h3">View your matches</Typography>
          <ViewMatchesIcon />
          <Typography variant="body2">
            Check{" "}
            <Link component={RouterLink} to={ROUTES.PWAD_MAKE_A_BOOKING} color="link.main" sx={{ fontWeight: "bold" }}>
              Make a Booking
            </Link>{" "}
            for WeFlex fitness professionals matched to your needs.
          </Typography>
        </HomeInfoCard>
      </Grid>

      <Grid item xs={12} lg={3} display="flex">
        <HomeInfoCard>
          <Avatar sx={{ bgcolor: "primary.main", height: 35, width: 35, color: "black" }}>
            <Typography variant="body1">3</Typography>
          </Avatar>
          <Typography variant="h3">Book in your session</Typography>
          <BookSessionIcon />
          <Typography variant="body2">
            It&apos;s easy to connect directly with WeFlex fitness professionals.{" "}
            <Link href={`tel:${ENV.WEFLEX_PHONE_NUMBER}`} color="link.main" sx={{ fontWeight: "bold" }}>
              Call us
            </Link>{" "}
            anytime for help.
          </Typography>
        </HomeInfoCard>
      </Grid>

      <Grid item xs={12} lg={3} display="flex">
        <HomeInfoCard>
          <Avatar sx={{ bgcolor: "primary.main", height: 35, width: 35, color: "black" }}>
            <Typography variant="body1">4</Typography>
          </Avatar>
          <Typography variant="h3">We have your back</Typography>
          <HaveYourBackIcon />
          <Typography variant="body2">
            <Link href={ENV.WEFLEX_TRUST_SAFETY_URL} target="_blank" color="link.main" sx={{ fontWeight: "bold" }}>
              Our Trust and Safety protocols
            </Link>{" "}
            provide peace of mind covering you with our strict systems.
          </Typography>
        </HomeInfoCard>
      </Grid>

      <Grid item xs={12} my={4}>
        <Divider />
      </Grid>

      <Grid item xs={12} display="flex" flexDirection="column" alignItems="center">
        <Typography variant="h2">What to look for in a fitness professional</Typography>
        <Box maxWidth={722} mt={1} mb={2}>
          <Typography variant="body1" align="center">
            There are a few things you can consider when looking for a WeFlex fitness professional.
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={12} lg={4} display="flex">
        <HomeInfoCard>
          <Avatar sx={{ bgcolor: "primary.main", height: 35, width: 35 }}>
            <DoneIcon sx={{ width: 30, height: 30 }} />
          </Avatar>
          <Typography variant="h3" fontWeight={500}>
            The Training Space
          </Typography>
          <Typography variant="body2">
            WeFlex has fitness professionals in all locations, local gyms, local parks, mobile to come to your home or
            even virtual. You let us know where you want to get active and we&apos;ll match to that.
          </Typography>
        </HomeInfoCard>
      </Grid>

      <Grid item xs={12} lg={4} display="flex">
        <HomeInfoCard>
          <Avatar sx={{ bgcolor: "primary.main", height: 35, width: 35 }}>
            <DoneIcon sx={{ width: 30, height: 30 }} />
          </Avatar>
          <Typography variant="h3" fontWeight={500}>
            Motivation Style
          </Typography>
          <Typography variant="body2">
            What motivates you? Do you want someone who will be patient and go slow to start? Do you have a favourite
            music artist you&apos;d like to hear while you are getting active? Do you need someone high energy that will
            give you a little push to keep you on track? Tell us your preference and we&apos;ll match with your needs.
          </Typography>
        </HomeInfoCard>
      </Grid>

      <Grid item xs={12} lg={4} display="flex">
        <HomeInfoCard>
          <Avatar sx={{ bgcolor: "primary.main", height: 35, width: 35 }}>
            <DoneIcon sx={{ width: 30, height: 30 }} />
          </Avatar>
          <Typography variant="h3" fontWeight={500}>
            Compatibility
          </Typography>
          <Typography variant="body2">
            Use the search to filter for a fitness professional based on what is important to you, such as, location,
            experience or training around your unique needs or gender.
          </Typography>
        </HomeInfoCard>
      </Grid>

      <Grid item xs={12} display="flex" flexDirection="column" alignItems="center" my={4}>
        <Button component={RouterLink} to={ROUTES.PWAD_MAKE_A_BOOKING} variant="contained">
          Find a Fitness Professional
        </Button>
      </Grid>
    </Grid>
  );
}
