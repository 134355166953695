import { useMemo } from "react";
import LabelImportantIcon from "@mui/icons-material/LabelImportant";
import { Box, Typography } from "@mui/material";
import { format, parseISO } from "date-fns";
import { sortBy, get } from "lodash";
import { FPProfileModel, MandatoryDocumentModel } from "../../models/FPProfile";
import CheckIconList, { CheckIconListItem } from "../common/CheckIconList";
import ProfileCard from "../common/ProfileCard";

interface Props {
  myQualificationsData?: FPProfileModel;
}

const getItemContent = (item: CheckIconListItem) => {
  if (!get(item, "secondary")) {
    return item.name;
  }

  const parsedDate = parseISO(item.secondary!);

  return (
    <Box display="block">
      <Typography variant="body1">{item.name}</Typography>
      <Typography variant="body2">Expires: {format(parsedDate, "dd MMM, yyyy")}</Typography>
    </Box>
  );
};

export default function FPMandatoryRequirements({ myQualificationsData }: Props) {
  const mandatoryDocuments = useMemo<{ id: string; name: string }[]>(
    () =>
      sortBy(myQualificationsData?.mandatory_documents, "mandatory_document_type.name").map(
        (document: MandatoryDocumentModel) => ({
          id: document.id,
          name: document.mandatory_document_type.name,
          secondary: document.expires_at,
        }),
      ),
    [myQualificationsData],
  );

  return (
    <ProfileCard title="Mandatory Requirements" Icon={LabelImportantIcon}>
      <CheckIconList showDivider list={mandatoryDocuments} contentFn={getItemContent} />
    </ProfileCard>
  );
}
