import { ReactEventHandler } from "react";
import { Radio } from "@material-ui/core";
import { Box, Card, FormControlLabel, Grid, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import SessionLocation from "./SessionLocation";
import { SessionLocationTypeEnum, SessionModel } from "../../../models/Session";
import { formatDateTimeStr } from "../../../utils/formatDateTime";

interface Props {
  date: string;
  sessions: ({
    place: string;
    locationType: SessionLocationTypeEnum;
    mobileLocation: string;
  } & SessionModel)[];
  selectedSessionId: string;
  onSessionClick: ReactEventHandler<HTMLButtonElement>;
}

export default function RequestSessionFormSessions({ date, sessions, selectedSessionId, onSessionClick }: Props) {
  if (isEmpty(sessions)) {
    return null;
  }

  return (
    <Box mt={4}>
      <Typography variant="body1" fontWeight="bold" mb={2}>
        Choose a time and location
      </Typography>

      {isEmpty(sessions) && (
        <Typography>
          Sorry, no sessions are available on this date for the chosen service and type of training.
        </Typography>
      )}

      {!isEmpty(sessions) && (
        <Grid container spacing={2}>
          {sessions.map(({ id, place, start_time, locationType, mobileLocation }) => (
            <Grid key={id} item xs={12}>
              <Card>
                <Box display="flex" flexDirection="row" alignItems="flex-start" p={2}>
                  <FormControlLabel
                    name="session"
                    value={id}
                    checked={selectedSessionId === id}
                    control={<Radio onClick={onSessionClick} />}
                    label={
                      <Typography variant="h3" fontWeight="bold">
                        {formatDateTimeStr(`${date} ${start_time}`, "hh:mmaaa")}
                      </Typography>
                    }
                    sx={{ mt: -1 }}
                  />
                  <Box>
                    <SessionLocation place={place} locationType={locationType} mobileLocation={mobileLocation} />
                  </Box>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
}
