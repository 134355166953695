import { isNil } from "lodash";
import {
  BookingWithSessionReportModel,
  SessionReportStatusEnum,
  SessionReportStatusLabel,
} from "../../../models/Booking";
import Status from "../../common/Status";

interface Props {
  bookingWithSessionReport: BookingWithSessionReportModel;
}

export default function SessionReportItemStatus({ bookingWithSessionReport }: Props) {
  if (isNil(bookingWithSessionReport.session_report)) {
    return <Status color="error" label="Not Started" />;
  }

  const { status } = bookingWithSessionReport.session_report;

  if (status === SessionReportStatusEnum.IN_PROGRESS) {
    return <Status color="warning" label={SessionReportStatusLabel.IN_PROGRESS} />;
  }

  if (status === SessionReportStatusEnum.COMPLETED) {
    return <Status color="success" label={SessionReportStatusLabel.COMPLETED} />;
  }

  return null;
}
