import { Avatar, Box, Card, CircularProgress, Typography } from "@mui/material";
import { useQuery } from "react-query";
import { generatePath, Link as RouterLink } from "react-router-dom";
import { ErrorResponse } from "../../../api/Generic";
import * as ROUTES from "../../../constants/routes";
import { FPProfileAboutMeModelAsPWAD } from "../../../models/FPProfile";
import getAvatarUrl from "../../../utils/getAvatarUrl";
import { getFullName } from "../../../utils/person";
import ColumnBox from "../../common/ColumnBox";
import ErrorAlert from "../../common/ErrorAlert";

interface Props {
  fpId: string;
}

export default function FPCard({ fpId }: Props) {
  const {
    data: getAboutFPData,
    isLoading,
    error,
  } = useQuery<FPProfileAboutMeModelAsPWAD, ErrorResponse>(`/pwadprofile/fpprofile/about-me/?fpprofile=${fpId}`);

  if (isLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <ErrorAlert message="Profile details failed to load" />;
  }

  if (!getAboutFPData?.person) {
    return null;
  }

  const fpFullName = getFullName(getAboutFPData.person);

  return (
    <Box display="flex" justifyContent="flex-end">
      <Card>
        <ColumnBox alignItems="center" padding={6}>
          <Box mb={2}>
            <Typography variant="body2">You are requesting a booking with:</Typography>
          </Box>
          <Avatar alt={fpFullName} src={getAvatarUrl(getAboutFPData.person.avatar)} sx={{ width: 200, height: 200 }} />
          <Box mt={2}>
            <RouterLink to={generatePath(ROUTES.PWAD_VIEW_FP_PROFILE, { fpId })}>
              <Typography color="link.main" sx={{ fontWeight: "bold" }}>
                {fpFullName}
              </Typography>
            </RouterLink>
          </Box>
          <Typography>WeFlex fitness professional</Typography>
        </ColumnBox>
      </Card>
    </Box>
  );
}
