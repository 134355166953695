import React, { useMemo, useState } from "react";
import { Alert, AlertTitle, Box, Card, CircularProgress, Container, useMediaQuery } from "@mui/material";
import { generatePath, Redirect, Route, Switch, useParams } from "react-router-dom";
import AboutMeForm from "./edit/AboutMeForm";
import ConsentForm from "./edit/ConsentForm";
import MyGoalsForm from "./edit/MyGoalsForm";
import MyHealthForm from "./edit/MyHealthForm";
import MyPreferencesForm from "./edit/MyPreferencesForm";
import MyUniqueNeedsForm from "./edit/MyUniqueNeedsForm";
import ProfileCompletionBanner from "./ProfileCompletionBanner";
import AboutMe from "./read/AboutMe";
import Consent from "./read/Consent";
import MyGoals from "./read/MyGoals";
import MyHealth from "./read/MyHealth";
import MyPreferences from "./read/MyPreferences";
import MyUniqueNeeds from "./read/MyUniqueNeeds";
import { useGetPWADProfileAboutMe } from "../../../api/queries/PWADProfile";
import * as ROUTES from "../../../constants/routes";
import { PWADProfileStatus } from "../../../models/ProfileBase";
import theme from "../../../styles/_theme";
import { useAuth } from "../../../utils/AuthProvider";
import {
  getFullName,
  getUserPWADProfileIsCompleted,
  getUserPWADProfileStatus,
  useCurrentUserProfileIsEditable,
} from "../../../utils/person";
import ErrorAlert from "../../common/ErrorAlert";
import NavTabs from "../../common/NavTabs";
import ProfileBanner from "../../common/ProfileBanner";
import SnackbarAlert from "../../common/SnackbarAlert";

function ProfileNotLive(): JSX.Element {
  const { profileId } = useParams<{ profileId?: string }>();

  const isProfileEditable = useCurrentUserProfileIsEditable();
  const { currentUser } = useAuth();
  const pwadProfileIsCompleted = getUserPWADProfileIsCompleted(currentUser);
  const pwadProfileStatus = getUserPWADProfileStatus(currentUser);

  const { data: aboutMeData, isLoading: aboutMeLoading, error: aboutMeError } = useGetPWADProfileAboutMe(profileId);

  const navTabItems = useMemo(
    () => [
      { label: "About Me", path: generatePath(ROUTES.PWAD_PROFILE_ABOUT_ME, { profileId }) },
      { label: "My Unique Needs", path: generatePath(ROUTES.PWAD_PROFILE_MY_UNIQUE_NEEDS, { profileId }) },
      { label: "My Goals", path: generatePath(ROUTES.PWAD_PROFILE_MY_GOALS, { profileId }) },
      { label: "My Preferences", path: generatePath(ROUTES.PWAD_PROFILE_MY_PREFERENCES, { profileId }) },
      { label: "My Health", path: generatePath(ROUTES.PWAD_PROFILE_MY_HEALTH, { profileId }) },
      { label: "Consent", path: generatePath(ROUTES.PWAD_PROFILE_CONSENT, { profileId }) },
    ],
    [profileId],
  );

  const [savedSnackbarMessage, setSavedSnackbarMessage] = useState("");

  const mobileMode = useMediaQuery(theme.breakpoints.down("lg"));

  if (aboutMeLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (aboutMeError) {
    return <ErrorAlert message="Profile details failed to load" />;
  }

  const isProfileSubmitted = pwadProfileStatus === PWADProfileStatus.SUBMITTED;

  return (
    <Container maxWidth="xl" sx={{ p: 2 }}>
      <ProfileCompletionBanner />

      {!isProfileSubmitted && pwadProfileIsCompleted && (
        <Box mb={2}>
          <Alert severity="info" variant="filled">
            <AlertTitle>Ready to submit</AlertTitle>
            Congratulations, you&apos;ve completed your profile! You are now ready to submit your profile.
          </Alert>
        </Box>
      )}

      {isProfileSubmitted && (
        <Box mb={2}>
          <Alert severity="success" variant="filled">
            <AlertTitle>Now reviewing</AlertTitle>
            Thank you for submitting your profile. The WeFlex team will call you once your profile has been reviewed to
            complete your service agreement (NDIS) or confirm payment method details (personal funding).
          </Alert>
        </Box>
      )}

      <Card sx={{ width: "100%", mb: { xs: 2, lg: 4 } }}>
        <ProfileBanner fullName={getFullName(aboutMeData?.person)} avatar={aboutMeData?.person?.avatar} />

        <NavTabs
          scrollButtons="auto"
          allowScrollButtonsMobile
          variant={mobileMode ? "scrollable" : "standard"}
          sx={{ ".MuiTabs-flexContainer": { justifyContent: { lg: "flex-end" }, py: { lg: 1 } } }}
          items={navTabItems}
        />
      </Card>

      {isProfileEditable && (
        <Switch>
          <Route
            path={ROUTES.PWAD_PROFILE_ABOUT_ME}
            render={(props) => <AboutMeForm {...props} setSavedSnackbarMessage={setSavedSnackbarMessage} />}
          />

          <Route
            path={ROUTES.PWAD_PROFILE_MY_UNIQUE_NEEDS}
            render={(props) => <MyUniqueNeedsForm {...props} setSavedSnackbarMessage={setSavedSnackbarMessage} />}
          />

          <Route
            path={ROUTES.PWAD_PROFILE_MY_GOALS}
            render={(props) => <MyGoalsForm {...props} setSavedSnackbarMessage={setSavedSnackbarMessage} />}
          />

          <Route
            path={ROUTES.PWAD_PROFILE_MY_PREFERENCES}
            render={(props) => <MyPreferencesForm {...props} setSavedSnackbarMessage={setSavedSnackbarMessage} />}
          />

          <Route
            path={ROUTES.PWAD_PROFILE_MY_HEALTH}
            render={(props) => <MyHealthForm {...props} setSavedSnackbarMessage={setSavedSnackbarMessage} />}
          />

          <Route
            path={ROUTES.PWAD_PROFILE_CONSENT}
            render={(props) => <ConsentForm {...props} setSavedSnackbarMessage={setSavedSnackbarMessage} />}
          />

          <Redirect to={ROUTES.PWAD_PROFILE_ABOUT_ME} />
        </Switch>
      )}

      {!isProfileEditable && (
        <Switch>
          <Route path={ROUTES.PWAD_PROFILE_ABOUT_ME} component={AboutMe} />
          <Route path={ROUTES.PWAD_PROFILE_MY_UNIQUE_NEEDS} component={MyUniqueNeeds} />
          <Route path={ROUTES.PWAD_PROFILE_MY_GOALS} component={MyGoals} />
          <Route path={ROUTES.PWAD_PROFILE_MY_PREFERENCES} component={MyPreferences} />
          <Route path={ROUTES.PWAD_PROFILE_MY_HEALTH} component={MyHealth} />
          <Route path={ROUTES.PWAD_PROFILE_CONSENT} component={Consent} />
          <Redirect to={ROUTES.PWAD_PROFILE_ABOUT_ME} />
        </Switch>
      )}

      <SnackbarAlert
        title="Saved"
        content={savedSnackbarMessage}
        severity="success"
        open={!!savedSnackbarMessage}
        onClose={() => setSavedSnackbarMessage("")}
      />
    </Container>
  );
}

export default ProfileNotLive;
