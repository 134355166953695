import { Fragment, useMemo } from "react";
import PinDropIcon from "@mui/icons-material/PinDrop";
import { FPProfileModel } from "../../models/FPProfile";
import { SessionLocationModel, SessionLocationTypeEnum } from "../../models/Session";
import CheckIconList from "../common/CheckIconList";
import ColumnBox from "../common/ColumnBox";
import OptionalValue from "../common/OptionalValue";
import ProfileCard from "../common/ProfileCard";
import ProfileCardDivider from "../common/ProfileCardDivider";
import ProfileCardTitleWithContent from "../common/ProfileCardTitleWithContent";
import SessionLocation from "../pwad/request-session/SessionLocation";

interface Props {
  myServicesData?: FPProfileModel;
}

export default function FPSessionLocations({ myServicesData }: Props) {
  const sessionLocations = useMemo<SessionLocationModel[]>(
    () => myServicesData?.session_locations ?? [],
    [myServicesData],
  );
  const sessionLocationsLength = sessionLocations.length;

  return (
    <ProfileCard title="Sessions Locations" Icon={PinDropIcon}>
      <OptionalValue>
        {sessionLocations
          // We actually want to have these in the order Fixed, Mobile, Online. It just happens that this corresponds to alpha sorting.
          .sort((a, b) => a.location_type.localeCompare(b.location_type))
          .map((sessionLocation, index) => (
            <Fragment key={sessionLocation.id}>
              <ColumnBox gap={4}>
                <ProfileCardTitleWithContent
                  content={
                    <ColumnBox>
                      <SessionLocation
                        place={sessionLocation.place}
                        locationType={sessionLocation.location_type}
                        mobileLocation={sessionLocation.mobile_location}
                      />
                    </ColumnBox>
                  }
                />
                <ProfileCardTitleWithContent
                  title={
                    sessionLocation.location_type === SessionLocationTypeEnum.VIRTUAL
                      ? "Services offered online"
                      : "Services offered at this location"
                  }
                  content={<CheckIconList list={sessionLocation?.services} />}
                />
              </ColumnBox>
              {index < sessionLocationsLength - 1 && <ProfileCardDivider sx={{ my: 4 }} />}
            </Fragment>
          ))}
      </OptionalValue>
    </ProfileCard>
  );
}
