import MuiPickersDay, { PickersDayProps } from "@mui/lab/PickersDay";
import { Box, Typography } from "@mui/material";
import { isEmpty, omit } from "lodash";
import ColumnBox from "../common/ColumnBox";
import Dot, { DotColors } from "../common/Dot";

const MAX_ANNOTATION_NUMBER = 3;

export type DayAnnotations = DotColors[];

function Plus() {
  return (
    <Typography variant="h4" sx={{ lineHeight: 0, color: "black" }}>
      +
    </Typography>
  );
}

interface Props extends PickersDayProps<unknown> {
  annotations?: DayAnnotations;
}

export default function PickersDay(props: Props) {
  const { day, annotations } = props;
  const dayValue = (day as Date).getDate();

  function renderDayAnnotations() {
    if (isEmpty(annotations)) {
      return null;
    }
    // eslint-disable-next-line react/no-array-index-key
    const dots = annotations!.slice(0, MAX_ANNOTATION_NUMBER).map((color, index) => <Dot key={index} color={color} />);
    if (annotations!.length > MAX_ANNOTATION_NUMBER) {
      dots.push(<Plus key={MAX_ANNOTATION_NUMBER} />);
    }

    return (
      <Box
        position="absolute"
        top="25px"
        left="-4px"
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        gap="1px"
        width="43px"
      >
        {dots}
      </Box>
    );
  }

  return (
    <MuiPickersDay {...omit(props, "annotations")}>
      <ColumnBox justifyContent="flex-end">
        <Typography variant="h3">{dayValue}</Typography>
        {renderDayAnnotations()}
      </ColumnBox>
    </MuiPickersDay>
  );
}
