import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Alert, Box, Container, Link, Stack, Typography } from "@mui/material";
import * as Sentry from "@sentry/browser";
import { get } from "lodash";
import { SubmitHandler, useForm, FormProvider } from "react-hook-form";
import { Link as RouterLink, generatePath, useHistory } from "react-router-dom";
import * as yup from "yup";
import { isErrorResponse } from "../../api/Generic";
import { useResetPassword } from "../../api/mutations/User";
import ENV from "../../constants/envConstants";
import * as ROUTES from "../../constants/routes";
import { GENERIC_REQUEST_ERROR_MESSAGE } from "../../constants/validation";
import FormPasswordField from "../../form/FormPasswordField";
import PasswordRequirements from "../../form/PasswordRequirements";
import getErrorMessages from "../../utils/getErrorMessages";
import isBlank from "../../utils/string";
import useQueryParams from "../../utils/useQueryParams";

type ResetPasswordParameters = {
  uid: string;
  token: string;
  new_password1: string;
  new_password2: string;
};

const isExpiredResetLinkError = (message: string | undefined) => {
  const parsedMessage = JSON.parse(message ?? "");
  return ["uid", "token"].includes(get(parsedMessage, "errors.0.field"));
};

const ResetPasswordFormSchema: yup.SchemaOf<ResetPasswordParameters> = yup.object({
  uid: yup.string().defined(),
  token: yup.string().defined(),
  new_password1: yup
    .string()
    .min(8, "Password must be at least 8 characters.")
    .test("is-not-numeric", "Password cannot be all numeric", (value, context) => !value?.match(/^\d+$/))
    .required(),
  new_password2: yup
    .string()
    .required()
    .test("is-equal", "Passwords do not match", function f(v) {
      // Don't use arrow functions
      const ref = yup.ref("new_password1");
      return v === this.resolve(ref);
    }),
});

const ResetPassword = () => {
  const query = useQueryParams();
  const history = useHistory();

  const methods = useForm<ResetPasswordParameters>({
    resolver: yupResolver(ResetPasswordFormSchema),
    criteriaMode: "firstError",
    defaultValues: {
      new_password1: "",
      new_password2: "",
      token: query.get("token") ?? "",
      uid: query.get("uid") ?? "",
    },
  });

  const { mutate: resetPassword, isLoading: resetLoading } = useResetPassword();

  const [submitErrorMessage, setSubmitErrorMessage] = useState<React.ReactFragment | null>(null);
  const [linkErrorMessage, setLinkErrorMessage] = useState("");

  useEffect(() => {
    if (isBlank(query.get("uid")) || isBlank(query.get("token"))) {
      setLinkErrorMessage(
        "There is an error with this password reset URL. Please double check the link exactly matches the link in the email.",
      );
    }
  }, [query]);

  const onSubmit: SubmitHandler<ResetPasswordParameters> = async (values) => {
    resetPassword(
      {
        new_password1: values.new_password1,
        new_password2: values.new_password2,
        token: values.token,
        uid: values.uid,
      },
      {
        onError: (error) => {
          Sentry.captureException(error);
          if (isErrorResponse(error)) {
            if (isExpiredResetLinkError(error.response.data?.message)) {
              setSubmitErrorMessage(
                <Typography variant="body2" component="p">
                  Your password could not be updated. The password reset link may have expired, or the URL may be
                  incorrect. Please submit a new request to{" "}
                  <Link color="inherit" component={RouterLink} to={ROUTES.FORGOT_PASSWORD}>
                    reset your password
                  </Link>{" "}
                  and try again.
                </Typography>,
              );
            } else {
              setSubmitErrorMessage(getErrorMessages(error.response.data?.message));
            }
          } else {
            setSubmitErrorMessage([GENERIC_REQUEST_ERROR_MESSAGE]);
          }
        },
        onSuccess: async () => {
          history.push(generatePath(ROUTES.LOGIN), { resetPasswordSuccess: true });
        },
      },
    );
  };

  return (
    <Container maxWidth="md">
      <Stack sx={{ alignItems: "center" }} spacing={3} mt={15}>
        <Typography variant="h1">Change password</Typography>
        <Typography variant="body1">Enter your new password below.</Typography>

        {submitErrorMessage && (
          <Alert severity="error" variant="filled" sx={{ mt: 3 }}>
            {submitErrorMessage}
          </Alert>
        )}

        {linkErrorMessage && (
          <Alert severity="error" variant="filled" sx={{ mt: 3 }}>
            {linkErrorMessage}
          </Alert>
        )}

        <FormProvider {...methods}>
          <Stack
            sx={{ alignItems: "center", width: { xs: "100%", md: "50%" } }}
            spacing={2}
            component="form"
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <FormPasswordField
              required
              name="new_password1"
              id="new_password1-text-field"
              label="New password"
              variant="outlined"
              fullWidth
            />
            <FormPasswordField
              required
              name="new_password2"
              id="new_password2-text-field"
              label="Repeat new password"
              variant="outlined"
              fullWidth
            />

            <Box>
              <PasswordRequirements />
            </Box>

            <LoadingButton loading={resetLoading} type="submit" variant="contained" fullWidth sx={{ width: "60%" }}>
              Set New Password
            </LoadingButton>
            <Box>
              <Typography variant="body2" component="span">
                Need help? Phone{" "}
                <Link href={`tel:${ENV.WEFLEX_PHONE_NUMBER}`} color="text.primary">
                  <b>{ENV.WEFLEX_PHONE_NUMBER}</b>
                </Link>
              </Typography>
            </Box>
          </Stack>
        </FormProvider>
      </Stack>
    </Container>
  );
};

export default ResetPassword;
