import CheckIcon from "@mui/icons-material/Check";
import { Avatar, Typography, Box, Select, MenuItem, SelectChangeEvent } from "@mui/material";
import { generatePath, useHistory } from "react-router-dom";
import { SessionReportReturnToEnum, SessionReportStepsItemModel, SESSION_REPORT_STEPS } from "./SessionReportConstants";
import * as ROUTES from "../../constants/routes";

interface Props {
  isCompleted: boolean;
  bookingId: string;
  activeStepIndex: number;
  returnTo: SessionReportReturnToEnum;
}

export default function SessionReportStepsDesktop({ bookingId, isCompleted, activeStepIndex, returnTo }: Props) {
  const history = useHistory();

  function renderIncompleteStep({ key, label }: SessionReportStepsItemModel, index: number) {
    let avatarBg: string;
    if (activeStepIndex > index) {
      avatarBg = "link.main";
    } else if (activeStepIndex === index) {
      avatarBg = "black";
    } else {
      avatarBg = "grey.300";
    }

    return (
      <MenuItem key={key} value={index}>
        <Box display="flex" gap={2} alignItems="center">
          <Avatar sx={{ width: 24, height: 24, bgcolor: avatarBg }}>
            {index < activeStepIndex ? (
              <CheckIcon fontSize="small" />
            ) : (
              <Typography variant="body2" color={index === activeStepIndex ? "white" : "black"}>
                {index + 1}
              </Typography>
            )}
          </Avatar>
          <Typography variant="h4">{label}</Typography>
        </Box>
      </MenuItem>
    );
  }

  function renderCompleteStep({ key, label }: SessionReportStepsItemModel, index: number) {
    let avatarBg: string;
    if (activeStepIndex === index) {
      avatarBg = "black";
    } else {
      avatarBg = "link.main";
    }

    return (
      <MenuItem key={key} value={index}>
        <Box display="flex" gap={2} alignItems="center">
          <Avatar sx={{ width: 24, height: 24, bgcolor: avatarBg }}>
            <CheckIcon fontSize="small" />
          </Avatar>
          <Typography variant="h4" color={avatarBg}>
            {label}
          </Typography>
        </Box>
      </MenuItem>
    );
  }

  function renderStep(step: SessionReportStepsItemModel, index: number) {
    if (isCompleted) {
      return renderCompleteStep(step, index);
    }
    return renderIncompleteStep(step, index);
  }

  const handleSelectChange = (event: SelectChangeEvent<number>) => {
    if (isCompleted) {
      history.push(
        generatePath(ROUTES.SESSION_REPORT_DETAILS, {
          bookingId,
          activeStep: SESSION_REPORT_STEPS[event.target.value as number].key,
          returnTo,
        }),
      );
    }
  };

  return (
    <Select fullWidth value={activeStepIndex} onChange={handleSelectChange}>
      {SESSION_REPORT_STEPS.map((step, index) => renderStep(step, index))}
    </Select>
  );
}
