export enum PWADProfileStatus {
  CREATED = "CREATED",
  SUBMITTED = "SUBMITTED",
}

export enum FPProfileStatus {
  PROFILE_CREATED = "CR",
  INTERVIEW_REJECTED = "RJ",
  INTERVIEW_APPROVED = "AP",
  PROFILE_SUBMITTED = "SM",
  TRAINING_COMPLETED = "TR",
}

export interface ProfileBaseModel {
  id?: string;
  is_live?: boolean;
}

export interface PWADProfileBaseModel extends ProfileBaseModel {
  status?: PWADProfileStatus;
  is_profile_completed?: boolean;
  profile_completed_validation_errors?: Array<string>;
}

export interface FPProfileBaseModel extends ProfileBaseModel {
  status?: FPProfileStatus;
  is_profile_completed?: boolean;
}
