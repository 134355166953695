import { useState } from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box, CircularProgress, Container, Grid, Link, Typography } from "@mui/material";
import { useQuery } from "react-query";
import { useParams, Link as RouterLink } from "react-router-dom";
import EditInvoice from "./EditInvoice";
import InvoiceDetail from "./InvoiceDetail";
import InvoiceStatus from "./InvoiceStatus";
import { ErrorResponse } from "../../../api/Generic";
import * as ROUTES from "../../../constants/routes";
import { InvoiceModel } from "../../../models/Invoice";
import ErrorAlert from "../../common/ErrorAlert";
import SnackbarAlert from "../../common/SnackbarAlert";

interface ReadOnlyProps {
  invoiceData: InvoiceModel;
}

function ReadOnlyInvoice({ invoiceData }: ReadOnlyProps): JSX.Element {
  return (
    <Grid item xs={12} mb={3}>
      <InvoiceDetail invoice={invoiceData} />
    </Grid>
  );
}

function ViewInvoice(): JSX.Element | null {
  const { invoiceId } = useParams<{ invoiceId: string }>();
  const [savedSnackbarMessage, setSavedSnackbarMessage] = useState("");

  const {
    data: invoiceData,
    isLoading: invoiceLoading,
    error: invoiceError,
  } = useQuery<InvoiceModel, ErrorResponse>(`/invoice/${invoiceId}`);

  if (invoiceLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (invoiceError) {
    return <ErrorAlert message="Invoice failed to load" />;
  }

  if (!invoiceData) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  let invoiceContent = null;
  if (invoiceData.status) {
    invoiceContent = <ReadOnlyInvoice invoiceData={invoiceData} />;
  } else {
    invoiceContent = <EditInvoice invoiceId={invoiceId} setSavedSnackbarMessage={setSavedSnackbarMessage} />;
  }

  return (
    <>
      <Container maxWidth="xl">
        <Grid container p={2}>
          <Grid item xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Link
                  component={RouterLink}
                  to={ROUTES.FP_MY_INVOICES}
                  color="link.main"
                  display="flex"
                  alignItems="center"
                  underline="none"
                  width="min-content"
                >
                  <ChevronLeftIcon sx={{ width: 30, height: 30 }} />
                  <Typography sx={{ fontSize: "18px", fontWeight: 600, whiteSpace: "nowrap" }}>My Invoices</Typography>
                </Link>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h1">
                  WeFlex{" "}
                  <Typography display="inline" variant="h1" component="span" color="link.main">
                    Invoice.
                  </Typography>
                </Typography>
              </Grid>
              {invoiceData.status && (
                <Grid item mb={2}>
                  <Box mb={1}>
                    <Typography variant="h3">Status</Typography>
                  </Box>
                  <Box>
                    <InvoiceStatus status={invoiceData.status} />
                  </Box>
                </Grid>
              )}
              {invoiceContent}
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <SnackbarAlert
        title="Saved"
        content={savedSnackbarMessage}
        severity="success"
        open={!!savedSnackbarMessage}
        onClose={() => setSavedSnackbarMessage("")}
      />
    </>
  );
}

export default ViewInvoice;
