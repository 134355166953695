import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import * as Sentry from "@sentry/browser";
import { orderBy } from "lodash";
import { Controller, FormProvider, SubmitErrorHandler, SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { generatePath, RouteComponentProps, useParams } from "react-router-dom";
import * as yup from "yup";
import { ErrorResponse, isErrorResponse } from "../../../../api/Generic";
import { useEditPWADProfileMyGoals } from "../../../../api/mutations/PWADProfile";
import * as ROUTES from "../../../../constants/routes";
import {
  GENERIC_MINIMUM_VALIDATION_ERROR_MESSAGE,
  GENERIC_REQUEST_ERROR_MESSAGE,
  GENERIC_REQUIRED_VALIDATION_ERROR_MESSAGE,
  GENERIC_SUBMIT_VALIDATION_ERROR_MESSAGE,
} from "../../../../constants/validation";
import SelectControl from "../../../../form/SelectControl";
import {
  GoalTypeEnum,
  GoalTypeLabel,
  PWADProfileMyGoalsEditModel,
  PWADProfileMyGoalsModel,
} from "../../../../models/PWADProfile";
import { useAuth } from "../../../../utils/AuthProvider";
import getErrorMessages from "../../../../utils/getErrorMessages";
import { getUserPWADProfileIsCompleted } from "../../../../utils/person";
import ColumnBox from "../../../common/ColumnBox";
import ErrorAlert from "../../../common/ErrorAlert";
import ErrorDialog from "../../../common/ErrorDialog";
import ProfileCard from "../../../common/ProfileCard";
import UnsavedChangesPrompt from "../../../common/UnsavedChangesPrompt";

type MyGoalsFormInput = PWADProfileMyGoalsEditModel;

const MyGoalsFormSchema: yup.SchemaOf<MyGoalsFormInput> = yup.object({
  goals: yup
    .array(
      yup.object({
        type: yup.mixed().defined().oneOf(Object.values(GoalTypeEnum), GENERIC_REQUIRED_VALIDATION_ERROR_MESSAGE),
        description: yup.string().trim().required(GENERIC_REQUIRED_VALIDATION_ERROR_MESSAGE),
      }),
    )
    .ensure()
    .min(1, GENERIC_MINIMUM_VALIDATION_ERROR_MESSAGE),
});

interface MyGoalsReactHookFormProps {
  defaultValues: MyGoalsFormInput;
  onSubmit: SubmitHandler<MyGoalsFormInput>;
  onError: SubmitErrorHandler<MyGoalsFormInput>;
}

function MyGoalsReactHookForm({ defaultValues, onSubmit, onError }: MyGoalsReactHookFormProps): JSX.Element {
  const { currentUser } = useAuth();
  const pwadProfileIsCompleted = getUserPWADProfileIsCompleted(currentUser);

  const formMethods = useForm<MyGoalsFormInput>({
    resolver: yupResolver(MyGoalsFormSchema),
    mode: "onTouched",
    criteriaMode: "firstError",
    defaultValues: defaultValues,
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting, isDirty },
  } = formMethods;

  const {
    fields: goalsFields,
    append: goalsAppend,
    remove: goalsRemove,
  } = useFieldArray({
    control,
    name: "goals",
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const theme: Theme = useTheme();
  const breakpointMD = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <Grid container spacing={2} py={2}>
          <Grid item xs={12}>
            <Box mb={3}>
              <Grid
                item
                display="flex"
                flexDirection={{ xs: "column", md: "row" }}
                alignItems={{ xs: "flex-start", md: "center" }}
                justifyContent={{ xs: "flex-start", md: "space-between" }}
              >
                <Grid item>
                  <Typography variant="h1">
                    My{" "}
                    <Typography display="inline" variant="h1" component="span" color="link.main">
                      Goals.
                    </Typography>
                  </Typography>
                </Grid>

                <Grid item>
                  <LoadingButton
                    type="submit"
                    variant={pwadProfileIsCompleted ? "outlined" : "contained"}
                    size={breakpointMD ? "medium" : "small"}
                    sx={breakpointMD ? undefined : { marginTop: 2 }}
                    loading={isSubmitting}
                    disabled={isSubmitting}
                  >
                    Save My Goals
                  </LoadingButton>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid item lg={6} xs={12}>
            <ProfileCard title="Goals" Icon={EmojiEventsIcon}>
              <Box>
                {!goalsFields.length && (
                  <Alert severity="warning" sx={{ mb: 4 }}>
                    No goals added yet. You must add at least one goal in order to be able to submit your profile.
                  </Alert>
                )}

                {!!goalsFields.length &&
                  goalsFields.map((goalField, goalFieldIndex) => (
                    <Box key={goalField.id} width="100%">
                      <ColumnBox gap={3}>
                        <Controller
                          control={control}
                          name={`goals.${goalFieldIndex}.type`}
                          render={({ field, fieldState }) => (
                            <SelectControl
                              options={Object.values(GoalTypeEnum).map((option) => ({
                                value: option,
                                label: GoalTypeLabel[option],
                              }))}
                              label="Type"
                              required
                              field={field}
                              fieldState={fieldState}
                            />
                          )}
                        />

                        <Controller
                          key={goalField.id}
                          control={control}
                          name={`goals.${goalFieldIndex}.description`}
                          render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                            <TextField
                              label="Description"
                              value={value}
                              onChange={onChange}
                              onBlur={onBlur}
                              inputRef={ref}
                              error={invalid}
                              helperText={error?.message}
                              required
                              multiline
                              rows={3}
                            />
                          )}
                        />
                      </ColumnBox>

                      <Button
                        size="small"
                        variant="outlined"
                        startIcon={<RemoveCircleOutlineIcon fontSize="small" />}
                        sx={{
                          mt: 3,
                          py: 0.5,
                          px: 2,
                          borderColor: "warning.main",
                        }}
                        onClick={() => {
                          goalsRemove(goalFieldIndex);
                        }}
                      >
                        Remove Goal
                      </Button>

                      <Divider sx={{ my: 6 }} />
                    </Box>
                  ))}

                <Box>
                  <Button
                    size="large"
                    variant="outlined"
                    color="primary"
                    startIcon={<AddCircleOutlineIcon fontSize="small" />}
                    onClick={() => {
                      goalsAppend({ type: "", description: "" });
                    }}
                  >
                    Add a Goal
                  </Button>
                </Box>
              </Box>
            </ProfileCard>
          </Grid>
        </Grid>
      </form>

      <UnsavedChangesPrompt showPrompt={isDirty} />
    </FormProvider>
  );
}

interface Props extends RouteComponentProps {
  setSavedSnackbarMessage: Dispatch<SetStateAction<string>>;
}

export default function MyGoalsForm({ setSavedSnackbarMessage, history }: Props): JSX.Element {
  const { profileId } = useParams<{ profileId: string }>();

  const { refetchCurrentUser } = useAuth();

  const [errorDialogMessages, setErrorDialogMessages] = useState<Array<string>>([]);

  const {
    data: myGoalsData,
    isLoading: myGoalsLoading,
    error: myGoalsError,
    refetch: myGoalsRefetch,
  } = useQuery<PWADProfileMyGoalsModel, ErrorResponse>(`/pwadprofile/my-goals/?pwad_profile=${profileId}`);

  const { mutateAsync: editPWADProfileMyGoals } = useEditPWADProfileMyGoals(profileId);

  const defaultValues = useMemo((): MyGoalsFormInput | null => {
    if (!myGoalsData) {
      return null;
    }

    const goalRecordsSorted = orderBy(myGoalsData.goals, [(goal) => goal.ndis], ["asc"]);

    const goals = goalRecordsSorted.map((goal) => ({
      type: goal.ndis ? GoalTypeEnum.NDIS : GoalTypeEnum.FITNESS,
      description: goal.description,
    }));

    return {
      goals,
    };
  }, [myGoalsData]);

  const onSubmit: SubmitHandler<MyGoalsFormInput> = async (values) => {
    try {
      const variables = {
        goals: values.goals.map((goal) => ({
          ndis: goal.type === GoalTypeEnum.NDIS,
          description: goal.description,
        })),
      };

      await editPWADProfileMyGoals(variables);

      await myGoalsRefetch();
      await refetchCurrentUser();

      setSavedSnackbarMessage("My Goals saved successfully.");

      history.push(generatePath(ROUTES.PWAD_PROFILE_MY_PREFERENCES, { profileId }));
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);

      if (isErrorResponse(error)) {
        setErrorDialogMessages(getErrorMessages(error.response.data?.message));
      } else {
        setErrorDialogMessages([GENERIC_REQUEST_ERROR_MESSAGE]);
      }
    }
  };

  const onError: SubmitErrorHandler<MyGoalsFormInput> = () => {
    setErrorDialogMessages([GENERIC_SUBMIT_VALIDATION_ERROR_MESSAGE]);
  };

  if (myGoalsLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (myGoalsError) {
    return <ErrorAlert message="Profile details failed to load" />;
  }

  if (!defaultValues) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <MyGoalsReactHookForm defaultValues={defaultValues} onSubmit={onSubmit} onError={onError} />

      {!!errorDialogMessages.length && (
        <ErrorDialog title="Unable to save" messages={errorDialogMessages} onClose={() => setErrorDialogMessages([])} />
      )}
    </>
  );
}
