import React, { useMemo, useState } from "react";
import { Box, FormControl, FormHelperText, FormLabel } from "@mui/material";
import * as Sentry from "@sentry/browser";
import axios, { AxiosResponse } from "axios";
import { FilePondFile, FilePondInitialFile } from "filepond";
import FilePondPluginFileRename from "filepond-plugin-file-rename";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond/dist/filepond.min.css";
import { toString, get } from "lodash";
import { FilePond, registerPlugin } from "react-filepond";
import { ControllerFieldState, useFormContext } from "react-hook-form";
import { ControllerRenderProps } from "react-hook-form/dist/types/controller";
import "./FileUploadControl.css";
import AxiosApiClient from "../api/Axios";
import { isErrorResponse } from "../api/Generic";
import ErrorDialog from "../components/common/ErrorDialog";
import PublicFileLink from "../components/common/PublicFileLink";
import { useAuth } from "../utils/AuthProvider";
import {
  DEFAULT_ALLOWED_MIME_TYPES,
  DEFAULT_ALLOWED_MIME_TYPES_MESSAGE,
  getFileToUploadFromFilePondFile,
  handleRenameFile,
  handleValidateFileType,
  InitiateFileUploadParameters,
  InitiateFileUploadResponse,
  MAX_FILE_SIZE_MEGABYTES,
} from "../utils/file";
import getErrorMessages from "../utils/getErrorMessages";

registerPlugin(
  FilePondPluginFileRename,
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType,
);

interface InitiatePublicFileUploadParameters extends InitiateFileUploadParameters {}

interface InitiatePublicFileUploadResponse extends InitiateFileUploadResponse {}

interface CompletePublicFileUploadParameters {
  policy: string;
}

interface CompletePublicFileUploadResponse {
  public_file_id: string;
}

interface FilePondControlProps {
  helperText?: string;
  disabled: boolean;
  allowedMimeTypes?: Array<string>;
  allowedMimeTypesMessage?: string;
  field: ControllerRenderProps;
  fieldState: ControllerFieldState;
}

function FilePondControl({
  helperText,
  disabled,
  allowedMimeTypes,
  allowedMimeTypesMessage,
  field,
  fieldState,
}: FilePondControlProps): JSX.Element | null {
  const [errorDialogMessages, setErrorDialogMessages] = useState<Array<string>>([]);

  const initialFiles = useMemo((): Array<FilePondInitialFile> => {
    if (!field.value) {
      return [];
    }

    return [
      {
        source: field.value,
        options: {
          type: "local",
        },
      },
    ];
  }, [field.value]);

  const [files, setFiles] = useState<Array<FilePondFile["file"] | FilePondInitialFile>>(initialFiles);

  const { currentUser } = useAuth();

  const handleUpdateFiles = (fileItems: Array<FilePondFile>) => {
    const newFiles = fileItems.map((fileItem: FilePondFile) => fileItem.file);

    setFiles(newFiles);

    if (!newFiles.length) {
      field.onChange("");
    }

    field.onBlur();
  };

  const acceptedFileTypes = allowedMimeTypes ? allowedMimeTypes : DEFAULT_ALLOWED_MIME_TYPES;

  const fileValidateTypeLabelExpectedTypes = allowedMimeTypesMessage
    ? allowedMimeTypesMessage
    : DEFAULT_ALLOWED_MIME_TYPES_MESSAGE;

  const labelIdle = 'Drag and drop a file, or click to <span class="filepond--label-action">select a file</span>';

  const fieldHasErrorMessage = !!fieldState.error?.message;
  const personId = currentUser?.person?.id;

  if (!personId) {
    return null;
  }

  return (
    <>
      <FilePond
        name={field.name}
        files={files}
        onupdatefiles={handleUpdateFiles}
        disabled={disabled}
        allowMultiple={false}
        credits={false}
        maxFileSize={`${MAX_FILE_SIZE_MEGABYTES}MB`}
        allowFileTypeValidation
        acceptedFileTypes={acceptedFileTypes}
        fileValidateTypeDetectType={handleValidateFileType}
        fileRenameFunction={handleRenameFile}
        labelFileTypeNotAllowed="File type is not allowed"
        fileValidateTypeLabelExpectedTypes={fileValidateTypeLabelExpectedTypes}
        labelIdle={labelIdle}
        server={{
          timeout: 99999999,
          process: (
            filePondFieldName,
            filePondFile,
            filePondMetadata,
            filePondHandleLoad,
            filePondHandleError,
            filePondHandleProgress,
            filePondHandleAbort,
          ) => {
            const cancelTokenSource = axios.CancelToken.source();

            getFileToUploadFromFilePondFile(filePondFile)
              .then((fileToUpload) => {
                const initiatePublicFileUploadPromise = AxiosApiClient.post<
                  any,
                  AxiosResponse<InitiatePublicFileUploadResponse>,
                  InitiatePublicFileUploadParameters
                >(
                  "/files/initiate-public-file-upload/",
                  {
                    file_name: fileToUpload.name,
                    content_type: fileToUpload.type,
                    person: personId,
                  },
                  { cancelToken: cancelTokenSource.token },
                );

                // Pass through `fileToUpload` while also performing the request to initiate the public file upload.
                // This gives us access to later use our `fileToUpload`, which we need when making the request to
                // upload the file to S3.
                return Promise.all([fileToUpload, initiatePublicFileUploadPromise]);
              })
              .then(async ([fileToUpload, initiatePublicFileUploadResponse]) => {
                const { data: publicFileUploadData } = initiatePublicFileUploadResponse;

                // Build AWS S3 upload `FormData` object.
                const s3UploadFormData = new FormData();
                s3UploadFormData.append("key", publicFileUploadData.key);
                s3UploadFormData.append("policy", publicFileUploadData.policy);
                s3UploadFormData.append("signature", publicFileUploadData.signature);
                s3UploadFormData.append("AWSAccessKeyId", publicFileUploadData.aws_access_key_id);
                s3UploadFormData.append("Content-Type", fileToUpload.type);
                s3UploadFormData.append("acl", "public-read");

                // `file` must be added to our AWS S3 upload `FormData` last, otherwise AWS returns an error.
                s3UploadFormData.append("file", fileToUpload);

                const uploadFileToS3Promise = axios.post(publicFileUploadData.url, s3UploadFormData, {
                  cancelToken: cancelTokenSource.token,
                  onUploadProgress: (progressEvent) => {
                    // Notify FilePond about upload progress.
                    filePondHandleProgress(progressEvent.lengthComputable, progressEvent.loaded, progressEvent.total);
                  },
                });

                // Pass through `initiatePublicFileUploadResponse` while also performing the request to upload the file
                // to S3. This gives us access to later use our `initiatePublicFileUploadResponse` data, which we need
                // when making the request to complete the public file upload.
                return Promise.all([initiatePublicFileUploadResponse, uploadFileToS3Promise]);
              })
              .then(([initiatePublicFileUploadResponse]) => {
                const completePublicFileUploadPromise = AxiosApiClient.post<
                  any,
                  AxiosResponse<CompletePublicFileUploadResponse>,
                  CompletePublicFileUploadParameters
                >(
                  "/files/complete-public-file-upload/",
                  {
                    policy: initiatePublicFileUploadResponse.data.policy,
                  },
                  { cancelToken: cancelTokenSource.token },
                );

                return Promise.all([initiatePublicFileUploadResponse, completePublicFileUploadPromise]);
              })
              .then(([initiatePublicFileUploadResponse]) => {
                const publicFileKey = initiatePublicFileUploadResponse.data.key;

                // Notify FilePond that the file was uploaded successfully.
                filePondHandleLoad(publicFileKey);

                // Set the PublicFile key as the new value for our form field.
                field.onChange(publicFileKey);
                field.onBlur();
              })
              .catch((error) => {
                if (axios.isCancel(error)) {
                  console.log("AWS S3 upload request cancelled.");
                } else {
                  console.error(error);
                  Sentry.captureException(error);
                  filePondHandleError(toString(error));

                  if (isErrorResponse(error)) {
                    setErrorDialogMessages(getErrorMessages(error.response.data?.message));
                  } else {
                    setErrorDialogMessages([
                      "There was an issue with your file upload request. Please try again, and if the problem persists, contact WeFlex.",
                    ]);
                  }
                }
              });

            return {
              abort: () => {
                // Handle the user choosing to cancel the upload.
                cancelTokenSource.cancel("AWS S3 upload request cancelled by the user.");

                // Notify FilePond that the upload was cancelled.
                filePondHandleAbort();
              },
            };
          },
          load: (
            filePondSource,
            filePondHandleLoad,
            filePondHandleError,
            filePondHandleProgress,
            filePondHandleAbort,
          ) => {
            const cancelTokenSource = axios.CancelToken.source();

            axios
              .get(filePondSource, {
                responseType: "blob",
                cancelToken: cancelTokenSource.token,
                onUploadProgress: (progressEvent) => {
                  // Notify FilePond about load progress.
                  filePondHandleProgress(progressEvent.lengthComputable, progressEvent.loaded, progressEvent.total);
                },
              })
              .then((res) => res.data)
              .then(filePondHandleLoad)
              .catch((error) => {
                if (axios.isCancel(error)) {
                  console.log("AWS S3 load request cancelled.");
                } else {
                  console.error(error);
                  Sentry.captureException(error);
                  filePondHandleError(toString(error));
                }
              });
            return {
              abort: () => {
                // Handle the user choosing to cancel the load request.
                cancelTokenSource.cancel("AWS S3 load request cancelled by the user.");

                // Notify FilePond that the load request was cancelled.
                filePondHandleAbort();
              },
            };
          },
          fetch: null,
          restore: null,
          revert: null,
          remove: null,
        }}
      />

      {!fieldHasErrorMessage && !!helperText && <FormHelperText>{helperText}</FormHelperText>}

      {!fieldHasErrorMessage && !helperText && <FormHelperText>{fileValidateTypeLabelExpectedTypes}.</FormHelperText>}

      {!!errorDialogMessages.length && (
        <ErrorDialog
          title="Unable to upload file"
          messages={errorDialogMessages}
          onClose={() => setErrorDialogMessages([])}
        />
      )}
    </>
  );
}

interface Props {
  label?: string;
  helperText?: string;
  required?: boolean;
  disabled?: boolean;
  allowedMimeTypes?: Array<string>;
  allowedMimeTypesMessage?: string;
  viewFileText?: string;
  field: ControllerRenderProps;
  fieldState: ControllerFieldState;
}

/**
 * Note: this component will only behave correctly if the form's default values accurately reflect what is saved on
 * the backend when the form first mounts. As such, the form must be implemented such that it only renders *after* the
 * saved values are successfully loaded from the backend.
 */
export default function PublicFileUploadControl({
  label,
  helperText,
  required = false,
  disabled = false,
  allowedMimeTypes,
  allowedMimeTypesMessage,
  viewFileText,
  field,
  fieldState,
}: Props): JSX.Element {
  const { formState } = useFormContext();

  const fieldDefaultValue = formState.defaultValues ? get(formState.defaultValues, field.name) : null;

  const fieldHasErrorMessage = !!fieldState.error?.message;

  return (
    <div>
      <FormControl
        error={fieldHasErrorMessage}
        style={{ width: "100%" }}
        className={`file-upload-control ${fieldHasErrorMessage ? "file-upload-control--error" : ""}`}
      >
        <FormLabel id={field.name} required={required} sx={{ mb: 1 }}>
          {label}
        </FormLabel>

        <FilePondControl
          key={field.name}
          helperText={helperText}
          disabled={disabled}
          allowedMimeTypes={allowedMimeTypes}
          allowedMimeTypesMessage={allowedMimeTypesMessage}
          field={field}
          fieldState={fieldState}
        />

        {fieldHasErrorMessage && <FormHelperText error>{fieldState.error?.message}</FormHelperText>}
      </FormControl>

      {!!fieldDefaultValue && (
        <Box mt={1}>
          <PublicFileLink publicFileUrl={fieldDefaultValue} viewFileText={viewFileText} />
        </Box>
      )}
    </div>
  );
}
