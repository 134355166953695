import { Button, Theme, useMediaQuery, useTheme } from "@mui/material";
import { generatePath, Link } from "react-router-dom";
import * as ROUTES from "../../../constants/routes";

interface Props {
  fpId: string;
}

export default function RequestSessionButton({ fpId }: Props) {
  const theme: Theme = useTheme();
  const breakpointMD = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Button
      component={Link}
      to={generatePath(ROUTES.PWAD_REQUEST_SESSION, { fpId })}
      variant="contained"
      size={breakpointMD ? "medium" : "small"}
      sx={breakpointMD ? undefined : { marginTop: 2 }}
    >
      Request a Booking
    </Button>
  );
}
