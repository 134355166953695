import React from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box, Container, Grid, IconButton, Typography } from "@mui/material";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { generatePath, useHistory } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import FormTextField from "../../form/FormTextField";
import SelectControl from "../../form/SelectControl";

const PROFILE_TYPE_OPTIONS = [
  {
    value: "pwad",
    label: "PWAD",
  },
  {
    value: "fp",
    label: "FP",
  },
];

interface NavigateToProfileInputType {
  profileType: string;
  profileId: string;
}

export default function NavigateToProfile(): JSX.Element {
  const history = useHistory();

  const methods = useForm<NavigateToProfileInputType>({
    criteriaMode: "firstError",
    defaultValues: {
      profileType: "fp",
      profileId: "",
    },
  });

  const onSubmit = ({ profileType, profileId }: NavigateToProfileInputType) => {
    if (profileType === "fp") {
      history.push(generatePath(ROUTES.FP_PROFILE, { profileId }));
    } else if (profileType === "pwad") {
      history.push(generatePath(ROUTES.PWAD_PROFILE, { profileId }));
    }
  };

  return (
    <Container maxWidth="md">
      <Grid container spacing={2} py={4}>
        <Grid item xs={12}>
          <Box mb={5}>
            <Typography variant="h1">
              View a{" "}
              <Typography display="inline" variant="h1" component="span" color="link.main">
                Profile.
              </Typography>
            </Typography>
          </Box>

          <FormProvider {...methods}>
            <Box
              component="form"
              mt={2}
              display="flex"
              onSubmit={methods.handleSubmit(onSubmit)}
              gap={2}
              alignItems="flex-start"
            >
              <div style={{ width: "150px" }}>
                <Controller
                  control={methods.control}
                  name="profileType"
                  render={({ field, fieldState }) => (
                    <SelectControl
                      options={PROFILE_TYPE_OPTIONS}
                      label="Profile type"
                      required
                      field={field}
                      fieldState={fieldState}
                    />
                  )}
                />
              </div>

              <FormTextField
                required
                name="profileId"
                label="Profile ID"
                variant="outlined"
                fullWidth
                inputProps={{ autoComplete: "off", autofill: "off" }}
              />

              <IconButton type="submit" color="primary" sx={{ mb: 3 }}>
                <ChevronRightIcon sx={{ width: 40, height: 40 }} />
              </IconButton>
            </Box>
          </FormProvider>
        </Grid>
      </Grid>
    </Container>
  );
}
