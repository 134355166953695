import { PersonModel, Address } from "./Person";
import { PWADProfileStatus } from "./ProfileBase";

export enum CommunicationStyleCommunicateEnum {
  VERBAL = "VERBAL",
  VERBAL_LIMITED = "VERBAL_LIMITED",
  NON_VERBAL = "NON_VERBAL",
  PECS_CARDS = "PECS_CARDS",
  SIGN_LANGUAGE = "SIGN_LANGUAGE",
  APP = "APP",
}

export const CommunicationStyleCommunicateLabel: { [key in CommunicationStyleCommunicateEnum]: string } = {
  VERBAL: "Verbal",
  VERBAL_LIMITED: "Verbal (Limited)",
  NON_VERBAL: "Non-Verbal",
  PECS_CARDS: "Picture Exchange Communication System (PECS cards)",
  SIGN_LANGUAGE: "Sign Language",
  APP: "App",
};

export enum CommunicationStyleReceiveInformationEnum {
  FOLLOW_MULTI_STEP_DIRECTIONS = "FOLLOW_MULTI_STEP_DIRECTIONS",
  FOLLOW_SIMPLE_DIRECTIONS = "FOLLOW_SIMPLE_DIRECTIONS",
  PREFER_VISUAL_INFORMATION = "PREFER_VISUAL_INFORMATION",
  REPEAT_INFORMATION = "REPEAT_INFORMATION",
}

export const CommunicationStyleReceiveInformationLabel: { [key in CommunicationStyleReceiveInformationEnum]: string } =
  {
    FOLLOW_MULTI_STEP_DIRECTIONS: "Follow multi-step directions",
    FOLLOW_SIMPLE_DIRECTIONS: "Follow simple directions",
    PREFER_VISUAL_INFORMATION: "Prefer visual information",
    REPEAT_INFORMATION: "Repeat the information",
  };

export enum FPGenderPreferenceEnum {
  MALE = "MALE",
  FEMALE = "FEMALE",
  NO_PREFERENCE = "NO_PREFERENCE",
}

export const FPGenderPreferenceLabel: { [key in FPGenderPreferenceEnum]: string } = {
  MALE: "Male",
  FEMALE: "Female",
  NO_PREFERENCE: "No preference",
};

export enum BookingFundingMethodEnum {
  CLIENT_FUNDED = "CLIENT_FUNDED",
  NDIS_SELF_MANAGED = "NDIS_SELF_MANAGED",
  NDIS_PLAN_MANAGED = "NDIS_PLAN_MANAGED",
  NDIS_AGENCY_MANAGED = "NDIS_AGENCY_MANAGED",
  OTHER = "OTHER",
}

export const BookingFundingMethodLabel: { [key in BookingFundingMethodEnum]: string } = {
  CLIENT_FUNDED: "Personal funding (fee for service)",
  NDIS_SELF_MANAGED: "NDIS - Self-managed",
  NDIS_PLAN_MANAGED: "NDIS - Plan-managed",
  NDIS_AGENCY_MANAGED: "NDIS - Agency-managed",
  OTHER: "Other",
};

export enum PaymentMethodEnum {
  DIRECT_DEBIT = "DIRECT_DEBIT",
  CREDIT_CARD = "CREDIT_CARD",
  INVOICE = "INVOICE",
  OTHER = "OTHER",
}

export const PaymentMethodLabel: { [key in PaymentMethodEnum]: string } = {
  DIRECT_DEBIT: "Direct debit",
  CREDIT_CARD: "Credit card",
  INVOICE: "Invoice",
  OTHER: "Other",
};

export enum TrainingEnvironmentEnum {
  GYM = "GYM",
  PARK = "PARK",
  HOME = "HOME",
  VIRTUAL = "VIRTUAL",
  OTHER = "OTHER",
}

export const TrainingEnvironmentLabel: { [key in TrainingEnvironmentEnum]: string } = {
  GYM: "Gym",
  PARK: "Local park",
  HOME: "My home",
  VIRTUAL: "Online/virtual",
  OTHER: "Other",
};

export enum TrainingFrequencyEnum {
  ONE_PER_WEEK = "ONE_PER_WEEK",
  TWO_PER_WEEK = "TWO_PER_WEEK",
  THREE_OR_MORE_PER_WEEK = "THREE_OR_MORE_PER_WEEK",
  OTHER = "OTHER",
}

export const TrainingFrequencyLabel: { [key in TrainingFrequencyEnum]: string } = {
  ONE_PER_WEEK: "Once a week",
  TWO_PER_WEEK: "Twice a week",
  THREE_OR_MORE_PER_WEEK: "Three or more times a week",
  OTHER: "Other",
};

export enum GoalTypeEnum {
  FITNESS = "FITNESS",
  NDIS = "NDIS",
}

export const GoalTypeLabel: { [key in GoalTypeEnum]: string } = {
  FITNESS: "General fitness goal",
  NDIS: "NDIS goal",
};

export enum RepresentativeRelationshipEnum {
  PARENT = "PARENT",
  OTHER_FAMILY_MEMBER = "OTHER_FAMILY_MEMBER",
  CARER = "CARER",
  SUPPORT_COORDINATOR = "SUPPORT_COORDINATOR",
  ALLIED_HEALTH_REPRESENTATIVE = "ALLIED_HEALTH_REPRESENTATIVE",
  OTHER = "OTHER",
}

export const RepresentativeRelationshipLabel: { [key in RepresentativeRelationshipEnum]: string } = {
  PARENT: "Parent",
  OTHER_FAMILY_MEMBER: "Other family member",
  CARER: "Carer",
  SUPPORT_COORDINATOR: "Support coordinator",
  ALLIED_HEALTH_REPRESENTATIVE: "Allied Health Representative",
  OTHER: "Other",
};

export enum PWADHealthFieldEnum {
  health_heart_condition_stroke = "health_heart_condition_stroke",
  health_chest_pain = "health_chest_pain",
  health_faint_dizzy_exercise = "health_faint_dizzy_exercise",
  health_asthma_attack_last_12_months = "health_asthma_attack_last_12_months",
  health_diabetes = "health_diabetes",
  health_blood_sugar = "health_blood_sugar",
  health_other_conditions = "health_other_conditions",
}

export const PWADHealthFieldLabel: { [key in PWADHealthFieldEnum]: string } = {
  health_heart_condition_stroke:
    "Has your medical practitioner ever told you that you have a heart condition or have suffered a stroke?",
  health_chest_pain:
    "Do you ever experience unexplained pains or discomfort in your chest at rest or during physical activity?",
  health_faint_dizzy_exercise: "Do you ever feel faint, dizzy or lose balance during physical activity/exercise?",
  health_asthma_attack_last_12_months:
    "Have you had an asthma attack requiring immediate medical attention at any time over the last 12 months?",
  health_diabetes: "Do you have diabetes?",
  health_blood_sugar: "Have you had trouble controlling your blood sugar (glucose) in the last 3 months?",
  health_other_conditions:
    "Do you have any other conditions that may require special consideration for you to exercise?",
};

export enum NDISFundingCategoryEnum {
  IMPROVED_HEALTH_AND_WELLBEING = "IMPROVED_HEALTH_AND_WELLBEING",
  IMPROVED_DAILY_LIVING = "IMPROVED_DAILY_LIVING",
  INNOVATIVE_COMMUNITY_PARTICIPATION = "INNOVATIVE_COMMUNITY_PARTICIPATION",
  ASSISTANCE_WITH_SOCIAL_ECONOMIC_COMMUNITY_PARTICIPATION = "ASSISTANCE_WITH_SOCIAL_ECONOMIC_COMMUNITY_PARTICIPATION",
}

export const NDISFundingCategoryLabel: { [key in NDISFundingCategoryEnum]: string } = {
  IMPROVED_HEALTH_AND_WELLBEING: "Improved Health and Wellbeing",
  IMPROVED_DAILY_LIVING: "Improved Daily Living",
  INNOVATIVE_COMMUNITY_PARTICIPATION: "Innovative Community Participation",
  ASSISTANCE_WITH_SOCIAL_ECONOMIC_COMMUNITY_PARTICIPATION:
    "Assistance with Social, Economic & Community Participation (Self/Plan Managed only)",
};

export enum NDISSupportPersonTypeEnum {
  SUPPORT_COORDINATOR = "SUPPORT_COORDINATOR",
  PLAN_MANAGEMENT_PROVIDER = "PLAN_MANAGEMENT_PROVIDER",
  OTHER = "OTHER",
}

export const NDISSupportPersonTypeLabel: { [key in NDISSupportPersonTypeEnum]: string } = {
  SUPPORT_COORDINATOR: "Support Coordinator",
  PLAN_MANAGEMENT_PROVIDER: "Plan Management Provider",
  OTHER: "Other",
};

export interface PWADProfileAboutMeEditModel {
  about_me_likes_what_i_love: string;
  about_me_likes_my_strengths: string;
  about_me_motivations_me: string;
  about_me_motivations_feel_confident: string;
  communication_style_communicate: Array<CommunicationStyleCommunicateEnum>;
  communication_style_receive_information: Array<CommunicationStyleReceiveInformationEnum>;
  communication_other_preferences: string;
  gym_experience_have_you_been_before: boolean | null;
  gym_experience_with_exercise: string;
  gym_experience_how_i_feel_about_starting_with_weflex: string;
  gym_experience_what_else_you_should_know: string;
  representative_relationship: RepresentativeRelationshipEnum | "";
  emergency_first_name: string;
  emergency_last_name: string;
  emergency_relationship: string;
  emergency_phone: string;
  emergency_email: string;
}

export interface PWADProfileAboutMeModel extends PWADProfileAboutMeEditModel {
  status: PWADProfileStatus;
  is_live: boolean;
  is_profile_completed: boolean;
  profile_completed_validation_errors: Array<string>;
  person: PersonModel;
}

export interface PWADProfileMyUniqueNeedsBaseModel {
  unique_needs_diagnosis: string;
  unique_needs_my_needs: string;
  unique_needs_accessibility: string;
  unique_needs_behaviour_support: string;
  unique_needs_sensory_activity: string;
  unique_needs_seizure_activity: string;
  unique_needs_behaviour_support_private_file: string | null;
  unique_needs_sensory_private_file: string | null;
  unique_needs_seizure_private_file: string | null;
}

export interface PWADProfileMyUniqueNeedsEditModel extends PWADProfileMyUniqueNeedsBaseModel {}

export interface PWADProfileMyUniqueNeedsModel extends PWADProfileMyUniqueNeedsBaseModel {}

export interface PWADProfileGoalEditModel {
  description: string;
  ndis: boolean;
}

export interface PWADGoalModel {
  id: string;
  description: string;
  ndis: boolean;
  is_active: boolean;
}

export interface PWADProfileMyGoalsModel {
  goals: Array<PWADGoalModel>;
}

export interface PWADProfileMyGoalsEditModel {
  goals: Array<{ type: GoalTypeEnum | ""; description: string }>;
}

export interface PWADProfileMyPreferencesBaseModel {
  fp_gender_preference: FPGenderPreferenceEnum | "";
  preferred_training_frequency: TrainingFrequencyEnum | "";
  preferred_training_environments: Array<TrainingEnvironmentEnum>;
  preferred_training_days_times: string;
  ndis_participant: boolean | null;
  ndis_participant_number: string;
  ndis_plan_nominee_guardian: string;
  ndis_funding_category: NDISFundingCategoryEnum | "";
  ndis_support_person_type: NDISSupportPersonTypeEnum | "";
  ndis_support_person_first_name: string;
  ndis_support_person_last_name: string;
  ndis_support_person_phone: string;
  ndis_support_person_email: string;
  typical_funding_method: BookingFundingMethodEnum | "";
  preferred_payment_method: PaymentMethodEnum | "";
  invoicing_email: string;
}

export interface PWADProfileMyPreferencesModel extends PWADProfileMyPreferencesBaseModel {
  ndis_plan_start_date: string | null;
  ndis_plan_end_date: string | null;
  ndis_support_person_address: Address | null;
}

export interface PWADProfileMyPreferencesEditModel extends PWADProfileMyPreferencesBaseModel {
  ndis_plan_start_date: Date | null;
  ndis_plan_end_date: Date | null;
  ndis_support_person_address_place: string;
  ndis_support_person_address_latitude: number | null;
  ndis_support_person_address_longitude: number | null;
}

export interface PWADProfileMyPreferencesEditMutationModel extends PWADProfileMyPreferencesBaseModel {
  ndis_plan_start_date: string | null;
  ndis_plan_end_date: string | null;
  ndis_support_person_address_place: string;
  ndis_support_person_address_latitude: number | null;
  ndis_support_person_address_longitude: number | null;
}

export interface PWADProfileMyHealthModel {
  health_heart_condition_stroke: boolean | null;
  health_chest_pain: boolean | null;
  health_faint_dizzy_exercise: boolean | null;
  health_asthma_attack_last_12_months: boolean | null;
  health_diabetes: boolean | null;
  health_blood_sugar: boolean | null;
  health_other_conditions: boolean | null;
}

export interface PWADProfileConsentModel {
  waivers_agree_term_and_conditions: boolean | null;
  waivers_consent_media_marketing_waiver: boolean | null;
}
