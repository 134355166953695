import { FPProfileStatus } from "../models/ProfileBase";

export const profileStepNumber = 1;
export const trainingStepNumber = 2;
export const interviewStepNumber = 3;

export const currentStepFromStatus = {
  [FPProfileStatus.PROFILE_CREATED]: profileStepNumber,
  [FPProfileStatus.PROFILE_SUBMITTED]: trainingStepNumber,
  [FPProfileStatus.TRAINING_COMPLETED]: interviewStepNumber,
  [FPProfileStatus.INTERVIEW_APPROVED]: interviewStepNumber,
  [FPProfileStatus.INTERVIEW_REJECTED]: interviewStepNumber,
};

export const isCurrentStep = (status: FPProfileStatus, stepNumber: number) =>
  currentStepFromStatus[status] === stepNumber;

export const isStepCompleted = (status: FPProfileStatus, stepNumber: number) =>
  currentStepFromStatus[status] > stepNumber;

export const isFutureStep = (status: FPProfileStatus, stepNumber: number) => currentStepFromStatus[status] < stepNumber;
