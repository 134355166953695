export enum SessionReportReturnToEnum {
  MY_SESSION_REPORTS_TO_DO = "my-session-reports-to-do",
  MY_SESSION_REPORTS_COMPLETED = "my-session-reports-complete",
  PWAD_PROFILE = "client-profile",
}

export enum SessionReportStepsEnum {
  GOALS = "goals",
  ACHIEVEMENTS = "achievements",
  ADDITIONAL_NOTES = "additional-notes",
  INCIDENT_REPORT = "incident-report",
  SUBMIT = "submit",
}

export interface SessionReportStepsItemModel {
  key: SessionReportStepsEnum;
  label: string;
}

export const SESSION_REPORT_STEPS: SessionReportStepsItemModel[] = [
  { key: SessionReportStepsEnum.GOALS, label: "Goals" },
  {
    key: SessionReportStepsEnum.ACHIEVEMENTS,
    label: "Achievements",
  },
  {
    key: SessionReportStepsEnum.ADDITIONAL_NOTES,
    label: "Additional Notes",
  },
  {
    key: SessionReportStepsEnum.INCIDENT_REPORT,
    label: "Incident Report",
  },
  { key: SessionReportStepsEnum.SUBMIT, label: "Submit Report" },
];
