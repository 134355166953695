import React, { useMemo, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Container, Step, StepLabel, Stepper } from "@mui/material";
import * as Sentry from "@sentry/browser";
import { FormProvider, SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import CustomerStep, { CustomerInput, CustomerStepValidationSchema } from "./CustomerStep";
import { isErrorResponse } from "../../../api/Generic";
import { useInsertPWADProfile } from "../../../api/mutations/PWADProfile";
import * as ROUTES from "../../../constants/routes";
import { GENERIC_REQUEST_ERROR_MESSAGE, GENERIC_SUBMIT_VALIDATION_ERROR_MESSAGE } from "../../../constants/validation";
import { StepNameEnum } from "../../../models/Onboarding";
import { PersonModel } from "../../../models/Person";
import { useAuth } from "../../../utils/AuthProvider";
import getErrorMessages from "../../../utils/getErrorMessages";
import useDocumentTitles from "../../../utils/useDocumentTitles";
import ErrorDialog from "../../common/ErrorDialog";
import ConfirmStep, { ConfirmInput, ConfirmStepValidationSchema } from "../common/ConfirmStep";
import { CustomerTypeEnum } from "../common/CustomerType";
import DetailsStep, {
  DetailsMyselfInput,
  DetailsRepresentativeInput,
  DetailsStepMyselfValidationSchema,
  DetailsStepRepresentativeValidationSchema,
} from "../common/DetailsStep";

const ORDERED_STEP: StepNameEnum[] = [StepNameEnum.Customer, StepNameEnum.Details, StepNameEnum.Confirm];

type OnboardingPWADInputs = {
  customer: CustomerInput;
  details: any;
  confirm: ConfirmInput;
};

const OnboardingPWADSchema: yup.SchemaOf<OnboardingPWADInputs> = yup.object({
  customer: CustomerStepValidationSchema,
  details: yup.mixed().when("customer", {
    is: (customer: CustomerInput) => customer.type === CustomerTypeEnum.PWAD_MYSELF,
    then: () => DetailsStepMyselfValidationSchema,
    otherwise: () => DetailsStepRepresentativeValidationSchema,
  }),
  confirm: ConfirmStepValidationSchema,
});

export default function OnboardingPWAD(): JSX.Element {
  useDocumentTitles("Client Sign Up | WeFlex Portal");

  const { refetchCurrentUser, clearCurrentUser, isRefetching: isRefetchingCurrentUser } = useAuth();

  const formMethods = useForm<OnboardingPWADInputs>({
    mode: "onTouched",
    resolver: yupResolver(OnboardingPWADSchema),
    criteriaMode: "firstError",
    defaultValues: {
      [StepNameEnum.Customer]: {
        type: "",
      },
      [StepNameEnum.Details]: {
        firstName: "",
        lastName: "",
        preferredName: "",
        mobile: "",
        email: "",
        password: "",
        source: "",
        representedBy: {
          firstName: "",
          lastName: "",
          preferredName: "",
          relationship: "",
          mobile: "",
          email: "",
          password: "",
          source: "",
        },
      },
      [StepNameEnum.Confirm]: {
        tandc: false,
      },
    },
  });

  const { getValues, watch } = formMethods;

  const customerTypeValue = watch("customer.type");

  const history = useHistory();

  const [activeStep, setActiveStep] = useState<number>(0);

  const [errorDialogMessages, setErrorDialogMessages] = useState<Array<string>>([]);

  const { mutate: insertPWADProfileMutate, isLoading: insertPWADProfileLoading } = useInsertPWADProfile();

  const isLastStep = useMemo(() => activeStep === ORDERED_STEP.length - 1, [activeStep]);

  const currentStepName: StepNameEnum = ORDERED_STEP[activeStep];

  const handleNext = (): void => {
    let schema;

    if (activeStep === 0) {
      schema = CustomerStepValidationSchema;
    } else if (activeStep === 1) {
      if (customerTypeValue === CustomerTypeEnum.PWAD_MYSELF) {
        schema = DetailsStepMyselfValidationSchema;
      } else if (customerTypeValue === CustomerTypeEnum.PWAD_REPRESENTATIVE) {
        schema = DetailsStepRepresentativeValidationSchema;
      }
    } else if (activeStep === 2) {
      schema = ConfirmStepValidationSchema;
    }

    if (!schema) {
      throw new Error("No validation schema.");
    }

    try {
      schema.validateSync(getValues(currentStepName));
    } catch (error) {
      setErrorDialogMessages([GENERIC_SUBMIT_VALIDATION_ERROR_MESSAGE]);

      return;
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handlePrevious = (): void => {
    if (activeStep === 0) {
      history.push(ROUTES.HOME);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const onSubmit: SubmitHandler<OnboardingPWADInputs> = async (values) => {
    clearCurrentUser();

    const isRepresentative = values.customer.type === CustomerTypeEnum.PWAD_REPRESENTATIVE;

    let variables;

    if (!isRepresentative) {
      const detailsValues: DetailsMyselfInput = values.details;

      const person: PersonModel = {
        first_name: detailsValues.firstName,
        last_name: detailsValues.lastName,
        preferred_name: detailsValues.preferredName,
        email: detailsValues.email,
        phone: detailsValues.mobile,
        source: detailsValues.source,
      };

      variables = {
        person,
        represented_by: null,
        customer_type: values.customer.type as CustomerTypeEnum,
        representative_relationship: "" as "",
        password: detailsValues.password,
      };
    } else {
      const detailsValues: DetailsRepresentativeInput = values.details;

      const person: PersonModel = {
        first_name: detailsValues.firstName,
        last_name: detailsValues.lastName,
        preferred_name: detailsValues.preferredName,
        email: detailsValues.email,
        phone: detailsValues.mobile,
        source: detailsValues.representedBy.source,
      };

      const representedBy: PersonModel = {
        first_name: detailsValues.representedBy.firstName,
        last_name: detailsValues.representedBy.lastName,
        preferred_name: detailsValues.representedBy.preferredName,
        email: detailsValues.representedBy.email,
        phone: detailsValues.representedBy.mobile,
        source: detailsValues.representedBy.source,
      };

      variables = {
        person,
        represented_by: representedBy,
        customer_type: values.customer.type as CustomerTypeEnum,
        representative_relationship: detailsValues.representedBy.relationship,
        password: detailsValues.representedBy.password,
      };
    }

    insertPWADProfileMutate(variables, {
      onError: (error) => {
        console.error(error);
        Sentry.captureException(error);

        if (isErrorResponse(error)) {
          setErrorDialogMessages(getErrorMessages(error.response.data?.message));
        } else {
          setErrorDialogMessages([GENERIC_REQUEST_ERROR_MESSAGE]);
        }
      },
      onSuccess: async (response) => {
        try {
          await refetchCurrentUser?.(response.data.token);

          // Add a slight delay to redirecting, to avoid an issue where the redirect appears to happen too quickly and
          // the user sees the main Sign Up page, as if they aren't authenticated.
          setTimeout(() => {
            history.push(ROUTES.HOME);
          }, 200);
        } catch (error) {
          Sentry.captureException(error);

          setErrorDialogMessages([GENERIC_REQUEST_ERROR_MESSAGE]);
        }
      },
    });
  };

  const onError: SubmitErrorHandler<OnboardingPWADInputs> = () => {
    setErrorDialogMessages([GENERIC_SUBMIT_VALIDATION_ERROR_MESSAGE]);
  };

  const renderStep = (): JSX.Element => {
    if (activeStep === 0) {
      return <CustomerStep name={StepNameEnum.Customer} />;
    }

    if (activeStep === 1) {
      return <DetailsStep name={StepNameEnum.Details} />;
    }

    return <ConfirmStep name={StepNameEnum.Confirm} />;
  };

  return (
    <Container maxWidth="md" sx={{ mt: 3 }}>
      <Stepper activeStep={activeStep} sx={{ mb: 5 }}>
        {ORDERED_STEP.map((value: string, stepIndex: number) => (
          <Step
            key={value}
            completed={activeStep > stepIndex}
            sx={{
              "& .MuiStepIcon-root": {
                "color": "action.disabledBackground",
                "fontSize": {
                  md: "2rem",
                },
                "& .MuiStepIcon-text": {
                  fill: "black",
                },
                "&.Mui-active": {
                  "color": "text.secondary",
                  "& .MuiStepIcon-text": {
                    fill: "white",
                  },
                },
              },
              "& .Mui-completed": {
                color: "primary.main",
              },
            }}
          >
            <StepLabel />
          </Step>
        ))}
      </Stepper>

      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit, onError)}>
          {renderStep()}

          <Container maxWidth="sm">
            <Box display="flex" justifyContent="space-between" gap={5} my={5}>
              <Button onClick={handlePrevious} size="large" variant="outlined" color="primary" fullWidth>
                Back
              </Button>

              {isLastStep ? (
                <LoadingButton
                  type="submit"
                  loading={insertPWADProfileLoading || isRefetchingCurrentUser}
                  size="large"
                  variant="contained"
                  fullWidth
                >
                  Submit
                </LoadingButton>
              ) : (
                <Button onClick={handleNext} size="large" variant="contained" fullWidth>
                  Next
                </Button>
              )}
            </Box>
          </Container>
        </form>
      </FormProvider>

      {!!errorDialogMessages.length && (
        <ErrorDialog
          title="Unable to submit"
          messages={errorDialogMessages}
          onClose={() => setErrorDialogMessages([])}
        />
      )}
    </Container>
  );
}
