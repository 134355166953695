import { useMemo } from "react";
import { Typography, Grid, Box, CircularProgress, Alert } from "@mui/material";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { ErrorResponse } from "../../../api/Generic";
import { BusinessTypeEnum, FPProfileModel } from "../../../models/FPProfile";
import ErrorAlert from "../../common/ErrorAlert";
import FPBankDetails from "../../profile-cards/FPBankDetails";

export default function Payout(): JSX.Element {
  const { profileId } = useParams<{ profileId: string }>();

  const {
    data: payoutDetailsData,
    isLoading,
    error,
  } = useQuery<FPProfileModel, ErrorResponse>(`/fpprofile/payout-details/?fpprofile=${profileId}`);

  const introText = useMemo(() => {
    if (payoutDetailsData?.business_type === BusinessTypeEnum.INDEPENDENT_CONTRACTOR) {
      return "When you receive payment for a session, we call that payment to you a 'payout'. Our payment cycle is fortnightly and the time it takes for the funds to appear in your account is dependent on your bank.";
    }

    return "Your payments will come to you via your employer. When you receive a payment for a session, we call that payment to you a 'payout'. Our payment cycle is fortnightly and the time it takes for the funds to appear in your account is dependent on your bank and your employer.";
  }, [payoutDetailsData?.business_type]);

  if (isLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <ErrorAlert message="Profile details failed to load" />;
  }

  return (
    <Grid container spacing={2} py={2}>
      <Grid item xs={12}>
        <Box mb={3}>
          <Typography variant="h1">
            <Typography display="inline" variant="h1" component="span" color="link.main">
              Payout
            </Typography>{" "}
            Details.
          </Typography>

          <Alert severity="info" variant="filled" sx={{ mt: 3 }}>
            {introText}
          </Alert>
        </Box>
      </Grid>

      {payoutDetailsData?.business_type === BusinessTypeEnum.INDEPENDENT_CONTRACTOR && (
        <Grid item lg={6} xs={12}>
          <FPBankDetails payoutDetailsData={payoutDetailsData} />
        </Grid>
      )}
    </Grid>
  );
}
