import FavoriteIcon from "@mui/icons-material/Favorite";
import { PWADProfileAboutMeModel } from "../../models/PWADProfile";
import ProfileCard from "../common/ProfileCard";
import ProfileCardDivider from "../common/ProfileCardDivider";
import ProfileCardTitleWithContent from "../common/ProfileCardTitleWithContent";

interface Props {
  aboutMeData: PWADProfileAboutMeModel;
}

export default function PWADLikes({ aboutMeData }: Props) {
  return (
    <ProfileCard title="Likes" Icon={FavoriteIcon}>
      <ProfileCardTitleWithContent
        title="What I love to spend time doing"
        content={aboutMeData.about_me_likes_what_i_love}
      />

      <ProfileCardDivider />

      <ProfileCardTitleWithContent title="My strengths" content={aboutMeData.about_me_likes_my_strengths} />
    </ProfileCard>
  );
}
