export function getToken(): string | null {
  return localStorage.getItem("authToken");
}

export function setToken(token: string) {
  localStorage.setItem("authToken", token);
}

export function clearToken() {
  localStorage.removeItem("authToken");
  localStorage.removeItem("token"); // temporary to clean up saved users
  localStorage.removeItem("user"); // temporary to clean up saved users
}
