import { useState } from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { Redirect, Route, Switch } from "react-router-dom";
import NavigateToProfile from "./admin/NavigateToProfile";
import PrivateDocumentViewer from "./common/PrivateDocumentViewer";
import FPSetBookingOutcome from "./fp/booking/SetBookingOutcome";
import FPHome from "./fp/home/Home";
import FPInvoiceList from "./fp/invoice/InvoiceList";
import FPViewInvoice from "./fp/invoice/ViewInvoice";
import FPMySessionReports from "./fp/my-session-reports/MySessionReports";
import FPProfileRoot from "./fp/profile/ProfileRoot";
import FPViewPWADProfile from "./fp/pwad-profile/Profile";
import AppBar, { APP_BAR_HEIGHT } from "./layout/AppBar";
import ResponsiveDrawer, { drawerWidth } from "./layout/ResponsiveDrawer";
import PWADViewFPProfile from "./pwad/fp-profile/Profile";
import PWADHome from "./pwad/home/Home";
import PWADMyNetwork from "./pwad/my-network/MyNetwork";
import PWADProfile from "./pwad/profile/Profile";
import PWADRequestSession from "./pwad/request-session/RequestSession";
import SessionReportDetails from "./session-report-details/SessionReportDetails";
import * as ROUTES from "../constants/routes";
import { useAuth } from "../utils/AuthProvider";
import { currentYear } from "../utils/formatDateTime";
import useDocumentTitles from "../utils/useDocumentTitles";

export default function PrivateRoutes(): JSX.Element {
  useDocumentTitles();

  const [mobileOpen, setMobileOpen] = useState(false);
  const { currentUser } = useAuth();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  function renderRoutes() {
    if (currentUser?.is_admin) {
      return (
        <Switch>
          <Route path={ROUTES.FP_PROFILE} component={FPProfileRoot} />
          <Route path={ROUTES.PWAD_PROFILE} component={PWADProfile} />
          <Route path={ROUTES.DOCUMENT_VIEWER} component={PrivateDocumentViewer} />
          <Route path={ROUTES.SESSION_REPORT_DETAILS} component={SessionReportDetails} />
          <Route component={NavigateToProfile} />
        </Switch>
      );
    }

    if (currentUser?.is_fp === true) {
      return (
        <Switch>
          <Route exact path={ROUTES.PWAD_ALL} render={() => <Redirect to={ROUTES.HOME} />} />
          <Route path={ROUTES.FP_MY_BOOKINGS} component={FPHome} />
          <Route path={ROUTES.FP_VIEW_PWAD_PROFILE} component={FPViewPWADProfile} />
          <Route path={ROUTES.FP_PROFILE} component={FPProfileRoot} />
          <Route path={ROUTES.FP_MY_SESSION_REPORTS} component={FPMySessionReports} />
          <Route path={ROUTES.DOCUMENT_VIEWER} component={PrivateDocumentViewer} />
          <Route path={ROUTES.SESSION_REPORT_DETAILS} component={SessionReportDetails} />
          <Route path={ROUTES.FP_SET_BOOKING_OUTCOME} component={FPSetBookingOutcome} />
          <Route path={ROUTES.FP_MY_INVOICES} component={FPInvoiceList} />
          <Route path={ROUTES.FP_INVOICE_DETAIL} component={FPViewInvoice} />
          {/* Redirect public routes to home */}
          <Route path={ROUTES.JOIN_NOW_FP} render={() => <Redirect to={ROUTES.HOME} />} />
          <Route path={ROUTES.JOIN_NOW_PWAD} render={() => <Redirect to={ROUTES.HOME} />} />
          <Route path={ROUTES.LOGIN} render={() => <Redirect to={ROUTES.HOME} />} />
          <Route path={ROUTES.FORGOT_PASSWORD} render={() => <Redirect to={ROUTES.HOME} />} />
          <Route path={ROUTES.RESET_PASSWORD} render={() => <Redirect to={ROUTES.HOME} />} />
          <Route path={ROUTES.HOME} component={FPHome} />
        </Switch>
      );
    }

    if (currentUser?.is_pwad === true) {
      return (
        <Switch>
          <Route exact path={ROUTES.FP_ALL} render={() => <Redirect to={ROUTES.HOME} />} />
          <Route path={ROUTES.PWAD_VIEW_FP_PROFILE} component={PWADViewFPProfile} />
          <Route path={ROUTES.PWAD_REQUEST_SESSION} component={PWADRequestSession} />
          <Route path={ROUTES.PWAD_MAKE_A_BOOKING} component={PWADMyNetwork} />
          <Route path={ROUTES.PWAD_PROFILE} component={PWADProfile} />
          <Route path={ROUTES.PWAD_MY_BOOKINGS} component={PWADHome} />
          <Route path={ROUTES.DOCUMENT_VIEWER} component={PrivateDocumentViewer} />
          <Route path={ROUTES.SESSION_REPORT_DETAILS} component={SessionReportDetails} />
          {/* Redirect public routes to home */}
          <Route path={ROUTES.JOIN_NOW_FP} render={() => <Redirect to={ROUTES.HOME} />} />
          <Route path={ROUTES.JOIN_NOW_PWAD} render={() => <Redirect to={ROUTES.HOME} />} />
          <Route path={ROUTES.LOGIN} render={() => <Redirect to={ROUTES.HOME} />} />
          <Route path={ROUTES.FORGOT_PASSWORD} render={() => <Redirect to={ROUTES.HOME} />} />
          <Route path={ROUTES.RESET_PASSWORD} render={() => <Redirect to={ROUTES.HOME} />} />
          <Route path={ROUTES.HOME} component={PWADHome} />
        </Switch>
      );
    }

    return (
      <Switch>
        <Route render={() => <>404 Private</>} />
      </Switch>
    );
  }

  return (
    <Box sx={{ display: "flex", minHeight: "100vh", height: "100%", bgcolor: "#FAFAFA" }}>
      <AppBar toggleDrawer={handleDrawerToggle} />
      <Box component="nav" sx={{ width: { lg: drawerWidth }, flexShrink: { lg: 0 } }} aria-label="Navigation">
        <ResponsiveDrawer mobileOpen={mobileOpen} toggleDrawer={handleDrawerToggle} />
      </Box>
      <Box
        component="main"
        display="flex"
        flexDirection="column"
        alignContent="center"
        alignItems="center"
        sx={{
          width: { xs: "100%", lg: `calc(100% - ${drawerWidth}px)` },
          mt: `${APP_BAR_HEIGHT}px`,
          px: { lg: 3 },
          pt: 2,
        }}
      >
        {renderRoutes()}
        <Box mt="auto" py={2}>
          <Typography variant="body2">&copy; {currentYear} WeFlex Pty Ltd</Typography>
        </Box>
      </Box>
    </Box>
  );
}
