import React from "react";
import { Typography } from "@mui/material";

export default function MediaMarketingConsentContent(): JSX.Element {
  return (
    <>
      <Typography variant="body1">
        I give permission for any photo, video, audio, or any other form of electronic recording of myself to be used
        for the purposes marketing, social media, publicity, education and/or merchandising for WeFlex Pty Ltd (ABN: 44
        644 941 873), associated partners, associates and media outlets.
      </Typography>

      <Typography variant="body1" mt={1}>
        I authorise the use or reproduction of any image/recording referred to above without acknowledgment and without
        being entitled to remuneration or compensation. The image/recording may appear in print, electronic, or video
        media, by WeFlex Pty Ltd, associated partners, associates, and media outlets. I understand it may be available
        to a global audience. I authorise WeFlex Pty Ltd to alter or modify photographs as needed for the purposes
        stated above. I acknowledge that ownership of any image/recording will be retained by WeFlex and I understand
        that I do not have any interest in the copyright to the photograph(s).
      </Typography>

      <Typography variant="body1" mt={1}>
        I understand and agree that if I wish to withdraw this authorisation, it will be my responsibility to inform the
        company in writing by emailing hello@weflex.com.au.
      </Typography>

      <Typography variant="body1" mt={1}>
        Privacy Statement – Personal information is collected from you for the purpose of obtaining consent to publish
        photographs, audio, or video footage to be used in printed or electronic publications for WeFlex. Personal
        information is managed in accordance with the Privacy Act 1988 (Cth) and is not shared with any other parties. A
        copy of WeFlex&apos;s Privacy Policy is available on request by emailing hello@weflex.com.au.
      </Typography>
    </>
  );
}
