import { format, getYear, parseISO } from "date-fns";

export function formatDateTimeStr(dateTimeStr: string, formatStr: string) {
  return format(parseISO(dateTimeStr), formatStr);
}

export function formatISODate(date: Date) {
  return format(date, "yyyy-MM-dd");
}

export const currentYear = getYear(Date.now());
