import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import GroupIcon from "@mui/icons-material/Group";
import ModeCommentIcon from "@mui/icons-material/ModeComment";
import PersonIcon from "@mui/icons-material/Person";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as Sentry from "@sentry/browser";
import { parseISO } from "date-fns";
import { omit } from "lodash";
import { Controller, FormProvider, SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { generatePath, RouteComponentProps, useParams } from "react-router-dom";
import * as yup from "yup";
import { isErrorResponse } from "../../../../api/Generic";
import { useEditPWADProfileAboutMe } from "../../../../api/mutations/PWADProfile";
import { useGetPWADProfileAboutMe } from "../../../../api/queries/PWADProfile";
import { IMAGE_MIME_TYPES } from "../../../../constants/mimeTypes";
import * as ROUTES from "../../../../constants/routes";
import {
  GENERIC_ADDRESS_REQUIRED_VALIDATION_ERROR_MESSAGE,
  GENERIC_MINIMUM_VALIDATION_ERROR_MESSAGE,
  GENERIC_REQUEST_ERROR_MESSAGE,
  GENERIC_REQUIRED_VALIDATION_ERROR_MESSAGE,
  GENERIC_SUBMIT_VALIDATION_ERROR_MESSAGE,
  PHONE_VALIDATION_REGEX,
} from "../../../../constants/validation";
import GooglePlacesAutocompleteControl from "../../../../form/GooglePlacesAutocompleteControl";
import PublicFileUploadControl from "../../../../form/PublicFileUploadControl";
import SelectControl, { SELECT_BOOLEAN_OPTIONS } from "../../../../form/SelectControl";
import {
  PWADPersonEditModel,
  PersonGenderEnum,
  PersonGenderLabel,
  PersonModel,
  PersonPrimaryContactTypeEnum,
  PersonPrimaryContactTypeLabel,
  RepresentedByPersonEditModel,
} from "../../../../models/Person";
import {
  CommunicationStyleCommunicateEnum,
  CommunicationStyleCommunicateLabel,
  CommunicationStyleReceiveInformationEnum,
  CommunicationStyleReceiveInformationLabel,
  PWADProfileAboutMeEditModel,
  RepresentativeRelationshipEnum,
  RepresentativeRelationshipLabel,
} from "../../../../models/PWADProfile";
import { useAuth } from "../../../../utils/AuthProvider";
import { formatISODate } from "../../../../utils/formatDateTime";
import getErrorMessages from "../../../../utils/getErrorMessages";
import { getUserPWADProfileIsCompleted } from "../../../../utils/person";
import ColumnBox from "../../../common/ColumnBox";
import ErrorAlert from "../../../common/ErrorAlert";
import ErrorDialog from "../../../common/ErrorDialog";
import ProfileCard from "../../../common/ProfileCard";
import UnsavedChangesPrompt from "../../../common/UnsavedChangesPrompt";

type AboutMeFormInput = {
  represented_by: RepresentedByPersonEditModel | null;
  person: PWADPersonEditModel;
  pwad_profile: PWADProfileAboutMeEditModel;
};

const MIN_DOB_VALUE = new Date("1900-01-01");
const MAX_DOB_VALUE = new Date();

function getIsPrimaryContactTypeDetailsVisible(primaryContactTypeValue: PersonPrimaryContactTypeEnum | "") {
  return (
    !!primaryContactTypeValue &&
    primaryContactTypeValue !== PersonPrimaryContactTypeEnum.MYSELF &&
    primaryContactTypeValue !== PersonPrimaryContactTypeEnum.REPRESENTATIVE
  );
}

// @ts-ignore
const AboutMeFormSchema: yup.SchemaOf<AboutMeFormInput> = yup.object({
  represented_by: yup
    .object({
      first_name: yup.string().trim().required("First name is required."),
      last_name: yup.string().trim().required("Last name is required."),
      preferred_name: yup.string().trim().defined(),
      phone: yup
        .string()
        .trim()
        .test("phoneWithMinDigits", "Please enter a valid mobile phone number.", (value) => {
          if (!value) {
            return true;
          }

          return PHONE_VALIDATION_REGEX.test(value);
        })
        .required("Mobile phone number is required."),
    })
    .defined()
    .nullable(),
  person: yup.object({
    first_name: yup.string().trim().required("First name is required."),
    last_name: yup.string().trim().required("Last name is required."),
    preferred_name: yup.string().trim().defined(),
    phone: yup
      .string()
      .trim()
      .defined()
      .test("phoneWithMinDigits", "Please enter a valid mobile phone number.", (value) => {
        if (!value) {
          return true;
        }

        return PHONE_VALIDATION_REGEX.test(value);
      })
      .test(
        "valueRequiredBasedOnRepresentedBy",
        "Mobile phone number is required.",
        function valueRequiredFromRepresentedBy(value) {
          const { from } = this as any;

          const formValues: AboutMeFormInput = from[1].value;
          const isRepresentative = !!formValues.represented_by;

          if (isRepresentative) {
            return true;
          }

          return !!value;
        },
      ),
    address_place: yup.string().required(GENERIC_ADDRESS_REQUIRED_VALIDATION_ERROR_MESSAGE),
    address_latitude: yup.number().nullable().required("Address latitude coordinate is required."),
    address_longitude: yup.number().nullable().required("Address longitude coordinate is required."),
    dob: yup
      .date()
      .nullable()
      .required("Date of birth is required.")
      .typeError("Please enter a valid date in the format dd/mm/yyyy.")
      .min(MIN_DOB_VALUE, "Date of birth must not be before 1900.")
      .max(MAX_DOB_VALUE, "Date of birth must not be after today."),
    gender: yup.mixed().defined().oneOf(Object.values(PersonGenderEnum), "Gender is required."),
    avatar: yup.string().defined(),
    primary_contact_type: yup
      .mixed()
      .defined()
      .oneOf(Object.values(PersonPrimaryContactTypeEnum), GENERIC_REQUIRED_VALIDATION_ERROR_MESSAGE),
    alternate_email: yup
      .string()
      .email("Please enter a valid email address.")
      .defined()
      .when("primary_contact_type", {
        is: (primary_contact_type: PersonPrimaryContactTypeEnum | "") =>
          getIsPrimaryContactTypeDetailsVisible(primary_contact_type),
        then: (schema) => schema.required(GENERIC_REQUIRED_VALIDATION_ERROR_MESSAGE),
        otherwise: (schema) => schema,
      }),
    alternate_phone: yup
      .string()
      .trim()
      .defined()
      .test("phoneWithMinDigits", "Please enter a valid mobile phone number.", (value) => {
        if (!value) {
          return true;
        }

        return PHONE_VALIDATION_REGEX.test(value);
      })
      .when("primary_contact_type", {
        is: (primary_contact_type: PersonPrimaryContactTypeEnum | "") =>
          getIsPrimaryContactTypeDetailsVisible(primary_contact_type),
        then: (schema) => schema.required(GENERIC_REQUIRED_VALIDATION_ERROR_MESSAGE),
        otherwise: (schema) => schema,
      }),
  }),
  pwad_profile: yup.object({
    representative_relationship: yup
      .mixed()
      .defined()
      .test(
        "valueRequiredBasedOnRepresentedBy",
        GENERIC_REQUIRED_VALIDATION_ERROR_MESSAGE,
        function valueRequiredFromRepresentedBy(value) {
          const { from } = this as any;

          const formValues: AboutMeFormInput = from[1].value;
          const isRepresentative = !!formValues.represented_by;

          if (!isRepresentative) {
            return true;
          }

          return Object.values(RepresentativeRelationshipEnum).includes(value);
        },
      ),
    about_me_likes_what_i_love: yup.string().trim().required(GENERIC_REQUIRED_VALIDATION_ERROR_MESSAGE),
    about_me_likes_my_strengths: yup.string().trim().required(GENERIC_REQUIRED_VALIDATION_ERROR_MESSAGE),
    about_me_motivations_me: yup.string().trim().required(GENERIC_REQUIRED_VALIDATION_ERROR_MESSAGE),
    about_me_motivations_feel_confident: yup.string().trim().required(GENERIC_REQUIRED_VALIDATION_ERROR_MESSAGE),
    communication_style_communicate: yup
      .array(
        yup
          .mixed()
          .defined()
          .oneOf(Object.values(CommunicationStyleCommunicateEnum), GENERIC_REQUIRED_VALIDATION_ERROR_MESSAGE),
      )
      .ensure()
      .min(1, GENERIC_MINIMUM_VALIDATION_ERROR_MESSAGE),
    communication_style_receive_information: yup
      .array(
        yup
          .mixed()
          .defined()
          .oneOf(Object.values(CommunicationStyleReceiveInformationEnum), GENERIC_REQUIRED_VALIDATION_ERROR_MESSAGE),
      )
      .ensure()
      .min(1, GENERIC_MINIMUM_VALIDATION_ERROR_MESSAGE),
    communication_other_preferences: yup.string().trim().defined(),
    gym_experience_have_you_been_before: yup
      .bool()
      .defined()
      .nullable()
      .required(GENERIC_REQUIRED_VALIDATION_ERROR_MESSAGE),
    gym_experience_with_exercise: yup.string().trim().required(GENERIC_REQUIRED_VALIDATION_ERROR_MESSAGE),
    gym_experience_how_i_feel_about_starting_with_weflex: yup.string().trim().defined(),
    gym_experience_what_else_you_should_know: yup.string().trim().defined(),
    emergency_first_name: yup.string().trim().required("Emergency contact first name is required."),
    emergency_last_name: yup.string().trim().required("Emergency contact last name is required."),
    emergency_relationship: yup.string().trim().required("Emergency contact relationship is required."),
    emergency_email: yup
      .string()
      .email("Please enter a valid email address.")
      .required("Emergency contact email address is required."),
    emergency_phone: yup
      .string()
      .trim()
      .test("phoneWithMinDigits", "Please enter a valid mobile phone number.", (value) => {
        if (!value) {
          return true;
        }

        return PHONE_VALIDATION_REGEX.test(value);
      })
      .required("Emergency contact mobile phone number is required."),
  }),
});

interface AboutMeReactHookFormProps {
  person: PersonModel;
  defaultValues: AboutMeFormInput;
  onSubmit: SubmitHandler<AboutMeFormInput>;
  onError: SubmitErrorHandler<AboutMeFormInput>;
}

function AboutMeReactHookForm({ person, defaultValues, onSubmit, onError }: AboutMeReactHookFormProps): JSX.Element {
  const { currentUser } = useAuth();
  const pwadProfileIsCompleted = getUserPWADProfileIsCompleted(currentUser);

  const formMethods = useForm<AboutMeFormInput>({
    resolver: yupResolver(AboutMeFormSchema),
    mode: "onTouched",
    criteriaMode: "firstError",
    defaultValues: defaultValues,
  });

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { isSubmitting, isDirty },
  } = formMethods;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const [
    personPrimaryContactTypeValue,
    representedByFirstNameValue,
    representedByLastNameValue,
    representedByPhoneValue,
    representativeRelationshipValue,
  ] = watch([
    "person.primary_contact_type",
    "represented_by.first_name",
    "represented_by.last_name",
    "represented_by.phone",
    "pwad_profile.representative_relationship",
  ]);

  const showAlternateEmailPhoneFields = getIsPrimaryContactTypeDetailsVisible(personPrimaryContactTypeValue);

  useEffect(() => {
    if (!showAlternateEmailPhoneFields) {
      setValue("person.alternate_email", "", { shouldValidate: true, shouldDirty: true, shouldTouch: true });
      setValue("person.alternate_phone", "", { shouldValidate: true, shouldDirty: true, shouldTouch: true });
    }
  }, [showAlternateEmailPhoneFields]);

  const populateEmergencyContactFieldsFromRepresentative = () => {
    setValue("pwad_profile.emergency_first_name", representedByFirstNameValue, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });

    setValue("pwad_profile.emergency_last_name", representedByLastNameValue, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });

    setValue(
      "pwad_profile.emergency_relationship",
      representativeRelationshipValue ? RepresentativeRelationshipLabel[representativeRelationshipValue] : "",
      { shouldValidate: true, shouldDirty: true, shouldTouch: true },
    );

    setValue("pwad_profile.emergency_email", person.represented_by?.email ?? "", {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });

    setValue("pwad_profile.emergency_phone", representedByPhoneValue, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  const theme: Theme = useTheme();
  const breakpointMD = useMediaQuery(theme.breakpoints.up("md"));

  const isRepresentative = !!person.represented_by;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <Grid container spacing={2} py={2}>
            <Grid item xs={12}>
              <Box mb={3}>
                <Grid
                  item
                  display="flex"
                  flexDirection={{ xs: "column", md: "row" }}
                  alignItems={{ xs: "flex-start", md: "center" }}
                  justifyContent={{ xs: "flex-start", md: "space-between" }}
                >
                  <Grid item>
                    <Typography variant="h1">
                      About{" "}
                      <Typography display="inline" variant="h1" component="span" color="link.main">
                        Me.
                      </Typography>
                    </Typography>
                  </Grid>

                  <Grid item>
                    <LoadingButton
                      type="submit"
                      variant={pwadProfileIsCompleted ? "outlined" : "contained"}
                      size={breakpointMD ? "medium" : "small"}
                      sx={breakpointMD ? undefined : { marginTop: 2 }}
                      loading={isSubmitting}
                      disabled={isSubmitting}
                    >
                      Save About Me
                    </LoadingButton>
                  </Grid>
                </Grid>

                <Alert severity="info" variant="filled" sx={{ mt: 3 }}>
                  After completing a profile tab, use the &quot;Save&quot; button to save the information you&apos;ve
                  provided. You must complete all of the required fields on each of the profile tabs before you can
                  submit your profile.
                </Alert>
              </Box>
            </Grid>

            <Grid item lg={6} xs={12}>
              <ColumnBox gap={2}>
                <ProfileCard title="Basic Information" Icon={PersonIcon}>
                  <ColumnBox gap={3}>
                    <Controller
                      control={control}
                      name="person.first_name"
                      render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                        <TextField
                          label="First name"
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          inputRef={ref}
                          error={invalid}
                          helperText={error?.message}
                          required
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name="person.last_name"
                      render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                        <TextField
                          label="Last name"
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          inputRef={ref}
                          error={invalid}
                          helperText={error?.message}
                          required
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name="person.preferred_name"
                      render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                        <TextField
                          label="Preferred name"
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          inputRef={ref}
                          error={invalid}
                          helperText={error?.message}
                        />
                      )}
                    />

                    <TextField
                      value={person.email ?? ""}
                      label="Email"
                      helperText="Contact WeFlex if you need to update this email address."
                      required={!isRepresentative}
                      disabled
                    />

                    <Controller
                      control={control}
                      name="person.phone"
                      render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                        <TextField
                          label="Mobile"
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          inputRef={ref}
                          error={invalid}
                          helperText={error?.message}
                          required={!isRepresentative}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name="person.address_place"
                      render={({ field, fieldState }) => (
                        <GooglePlacesAutocompleteControl
                          label="Address"
                          required
                          latitudeFieldName="person.address_latitude"
                          longitudeFieldName="person.address_longitude"
                          field={field}
                          fieldState={fieldState}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name="person.dob"
                      render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                        <DesktopDatePicker
                          label="Date of birth"
                          inputFormat="dd/MM/yyyy"
                          value={value}
                          onChange={onChange}
                          inputRef={ref}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              onBlur={onBlur}
                              error={invalid}
                              helperText={error?.message}
                              required
                            />
                          )}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name="person.gender"
                      render={({ field, fieldState }) => (
                        <SelectControl
                          label="Gender"
                          options={Object.values(PersonGenderEnum).map((option) => ({
                            value: option,
                            label: PersonGenderLabel[option],
                          }))}
                          required
                          field={field}
                          fieldState={fieldState}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name="person.avatar"
                      render={({ field, fieldState }) => (
                        <PublicFileUploadControl
                          label="Profile picture / avatar"
                          allowedMimeTypes={IMAGE_MIME_TYPES}
                          allowedMimeTypesMessage="Allowed file types: images"
                          viewFileText="View current profile picture / avatar"
                          field={field}
                          fieldState={fieldState}
                        />
                      )}
                    />
                  </ColumnBox>
                </ProfileCard>

                {isRepresentative && (
                  <ProfileCard title="Representative Information" Icon={GroupIcon}>
                    <ColumnBox gap={3}>
                      <Controller
                        control={control}
                        name="represented_by.first_name"
                        render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                          <TextField
                            label="First name"
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            inputRef={ref}
                            error={invalid}
                            helperText={error?.message}
                            required
                          />
                        )}
                      />

                      <Controller
                        control={control}
                        name="represented_by.last_name"
                        render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                          <TextField
                            label="Last name"
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            inputRef={ref}
                            error={invalid}
                            helperText={error?.message}
                            required
                          />
                        )}
                      />

                      <Controller
                        control={control}
                        name="represented_by.preferred_name"
                        render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                          <TextField
                            label="Preferred name"
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            inputRef={ref}
                            error={invalid}
                            helperText={error?.message}
                          />
                        )}
                      />

                      <TextField
                        value={person.represented_by?.email ?? ""}
                        label="Email"
                        helperText="Contact WeFlex if you need to update this email address."
                        required
                        disabled
                      />

                      <Controller
                        control={control}
                        name="represented_by.phone"
                        render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                          <TextField
                            label="Mobile"
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            inputRef={ref}
                            error={invalid}
                            helperText={error?.message}
                            required
                          />
                        )}
                      />

                      <Controller
                        control={control}
                        name="pwad_profile.representative_relationship"
                        render={({ field, fieldState }) => (
                          <SelectControl
                            label="Relationship"
                            options={Object.values(RepresentativeRelationshipEnum).map((option) => ({
                              value: option,
                              label: RepresentativeRelationshipLabel[option],
                            }))}
                            helperText="Your relationship to the person you are representing."
                            required
                            field={field}
                            fieldState={fieldState}
                          />
                        )}
                      />
                    </ColumnBox>
                  </ProfileCard>
                )}

                <ProfileCard title="Primary Contact" Icon={ContactPhoneIcon}>
                  <ColumnBox gap={3}>
                    <Controller
                      control={control}
                      name="person.primary_contact_type"
                      render={({ field, fieldState }) => (
                        <SelectControl
                          label="Who should we contact regarding your WeFlex sessions?"
                          options={Object.values(PersonPrimaryContactTypeEnum)
                            .filter((option) => {
                              if (isRepresentative) {
                                return option !== PersonPrimaryContactTypeEnum.MYSELF;
                              }

                              return option !== PersonPrimaryContactTypeEnum.REPRESENTATIVE;
                            })
                            .map((option) => ({
                              value: option,
                              label: PersonPrimaryContactTypeLabel[option],
                            }))}
                          required
                          field={field}
                          fieldState={fieldState}
                        />
                      )}
                    />

                    {showAlternateEmailPhoneFields && (
                      <>
                        <Controller
                          control={control}
                          name="person.alternate_email"
                          render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                            <TextField
                              label="Email"
                              value={value}
                              onChange={onChange}
                              onBlur={onBlur}
                              inputRef={ref}
                              error={invalid}
                              helperText={error?.message}
                              required
                            />
                          )}
                        />

                        <Controller
                          control={control}
                          name="person.alternate_phone"
                          render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                            <TextField
                              label="Mobile"
                              value={value}
                              onChange={onChange}
                              onBlur={onBlur}
                              inputRef={ref}
                              error={invalid}
                              helperText={error?.message}
                              required
                            />
                          )}
                        />
                      </>
                    )}
                  </ColumnBox>
                </ProfileCard>

                <ProfileCard title="Emergency Contact" Icon={ContactPhoneIcon}>
                  {isRepresentative && (
                    <Box mb={5}>
                      <Button
                        size="small"
                        variant="outlined"
                        startIcon={<GroupIcon fontSize="small" />}
                        sx={{ py: 0.5, px: 2, borderColor: "grey.600" }}
                        onClick={() => populateEmergencyContactFieldsFromRepresentative()}
                      >
                        Use My Details (Representative Information)
                      </Button>
                    </Box>
                  )}

                  <ColumnBox gap={3}>
                    <Controller
                      control={control}
                      name="pwad_profile.emergency_first_name"
                      render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                        <TextField
                          label="First name"
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          inputRef={ref}
                          error={invalid}
                          helperText={error?.message}
                          required
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name="pwad_profile.emergency_last_name"
                      render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                        <TextField
                          label="Last name"
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          inputRef={ref}
                          error={invalid}
                          helperText={error?.message}
                          required
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name="pwad_profile.emergency_relationship"
                      render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                        <TextField
                          label="Relationship"
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          inputRef={ref}
                          error={invalid}
                          helperText={error?.message}
                          required
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name="pwad_profile.emergency_email"
                      render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                        <TextField
                          label="Email"
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          inputRef={ref}
                          error={invalid}
                          helperText={error?.message}
                          required
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name="pwad_profile.emergency_phone"
                      render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                        <TextField
                          label="Mobile"
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          inputRef={ref}
                          error={invalid}
                          helperText={error?.message}
                          required
                        />
                      )}
                    />
                  </ColumnBox>
                </ProfileCard>
              </ColumnBox>
            </Grid>

            <Grid item lg={6} xs={12}>
              <ColumnBox gap={2}>
                <ProfileCard title="Likes" Icon={FavoriteIcon}>
                  <ColumnBox gap={3}>
                    <Controller
                      control={control}
                      name="pwad_profile.about_me_likes_what_i_love"
                      render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                        <TextField
                          label="What I love to spend time doing"
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          inputRef={ref}
                          error={invalid}
                          helperText={error?.message}
                          required
                          multiline
                          rows={3}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name="pwad_profile.about_me_likes_my_strengths"
                      render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                        <TextField
                          label="My strengths"
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          inputRef={ref}
                          error={invalid}
                          helperText={error?.message}
                          required
                          multiline
                          rows={3}
                        />
                      )}
                    />
                  </ColumnBox>
                </ProfileCard>

                <ProfileCard title="Motivations" Icon={EmojiEmotionsIcon}>
                  <ColumnBox gap={3}>
                    <Controller
                      control={control}
                      name="pwad_profile.about_me_motivations_me"
                      render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                        <TextField
                          label="What motivates me"
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          inputRef={ref}
                          error={invalid}
                          helperText={error?.message}
                          required
                          multiline
                          rows={3}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name="pwad_profile.about_me_motivations_feel_confident"
                      render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                        <TextField
                          label="What makes me feel confident"
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          inputRef={ref}
                          error={invalid}
                          helperText={error?.message}
                          required
                          multiline
                          rows={3}
                        />
                      )}
                    />
                  </ColumnBox>
                </ProfileCard>

                <ProfileCard title="Communication Style" Icon={ModeCommentIcon}>
                  <ColumnBox gap={3}>
                    <Controller
                      control={control}
                      name="pwad_profile.communication_style_communicate"
                      render={({ field, fieldState }) => (
                        <SelectControl
                          label="How I like to communicate"
                          options={Object.values(CommunicationStyleCommunicateEnum).map((option) => ({
                            value: option,
                            label: CommunicationStyleCommunicateLabel[option],
                          }))}
                          helperText="Please select all options that apply."
                          required
                          multiple
                          field={field}
                          fieldState={fieldState}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name="pwad_profile.communication_style_receive_information"
                      render={({ field, fieldState }) => (
                        <SelectControl
                          label="How I best receive information"
                          options={Object.values(CommunicationStyleReceiveInformationEnum).map((option) => ({
                            value: option,
                            label: CommunicationStyleReceiveInformationLabel[option],
                          }))}
                          helperText="Please select all options that apply."
                          required
                          multiple
                          field={field}
                          fieldState={fieldState}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name="pwad_profile.communication_other_preferences"
                      render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                        <TextField
                          label="Other notes about my communication style"
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          inputRef={ref}
                          error={invalid}
                          helperText={error?.message}
                          multiline
                          rows={3}
                        />
                      )}
                    />
                  </ColumnBox>
                </ProfileCard>

                <ProfileCard title="Exercise Experience" Icon={FitnessCenterIcon}>
                  <ColumnBox gap={3}>
                    <Controller
                      control={control}
                      name="pwad_profile.gym_experience_have_you_been_before"
                      render={({ field, fieldState }) => (
                        <SelectControl
                          label="Have you been to the gym before?"
                          options={SELECT_BOOLEAN_OPTIONS}
                          required
                          field={field}
                          fieldState={fieldState}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name="pwad_profile.gym_experience_with_exercise"
                      render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                        <TextField
                          label="My experience with exercise"
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          inputRef={ref}
                          error={invalid}
                          helperText={error?.message}
                          required
                          multiline
                          rows={3}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name="pwad_profile.gym_experience_how_i_feel_about_starting_with_weflex"
                      render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                        <TextField
                          label="How I feel about starting with WeFlex"
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          inputRef={ref}
                          error={invalid}
                          helperText={error?.message}
                          multiline
                          rows={3}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name="pwad_profile.gym_experience_what_else_you_should_know"
                      render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                        <TextField
                          label="Other notes about my exercise experience"
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          inputRef={ref}
                          error={invalid}
                          helperText={error?.message}
                          multiline
                          rows={3}
                        />
                      )}
                    />
                  </ColumnBox>
                </ProfileCard>
              </ColumnBox>
            </Grid>
          </Grid>
        </form>
      </FormProvider>

      <UnsavedChangesPrompt showPrompt={isDirty} />
    </LocalizationProvider>
  );
}

interface Props extends RouteComponentProps {
  setSavedSnackbarMessage: Dispatch<SetStateAction<string>>;
}

export default function AboutMeForm({ setSavedSnackbarMessage, history }: Props): JSX.Element {
  const { profileId } = useParams<{ profileId: string }>();

  const { refetchCurrentUser } = useAuth();

  const [errorDialogMessages, setErrorDialogMessages] = useState<Array<string>>([]);

  const {
    data: aboutMeData,
    isLoading: aboutMeLoading,
    error: aboutMeError,
    refetch: aboutMeRefetch,
  } = useGetPWADProfileAboutMe(profileId);

  const { mutateAsync: editPWADProfileAboutMe } = useEditPWADProfileAboutMe(profileId);

  const defaultValues = useMemo((): AboutMeFormInput | null => {
    if (!aboutMeData) {
      return null;
    }

    const { person } = aboutMeData;

    return {
      represented_by: person.represented_by ?? null,
      person: {
        ...person,
        dob: person.dob ? parseISO(person.dob) : null,
        gender: person.gender ?? "",
        avatar: person.avatar?.full_size ?? "",
        address_place: person.address?.place ?? "",
        address_latitude: person.address?.latitude ?? null,
        address_longitude: person.address?.longitude ?? null,
        primary_contact_type: person.primary_contact_type ?? "",
        alternate_email: person.alternate_email ?? "",
        alternate_phone: person.alternate_phone ?? "",
      },
      pwad_profile: omit(aboutMeData, "person"),
    };
  }, [aboutMeData]);

  const onSubmit: SubmitHandler<AboutMeFormInput> = async (values) => {
    try {
      await editPWADProfileAboutMe({
        person: {
          ...values.person,
          dob: formatISODate(values.person.dob as Date),
          gender: values.person.gender as PersonGenderEnum,
          address_latitude: values.person.address_latitude!,
          address_longitude: values.person.address_longitude!,
          primary_contact_type: values.person.primary_contact_type as PersonPrimaryContactTypeEnum,
        },
        represented_by: values.represented_by,
        pwad_profile: values.pwad_profile,
      });

      await aboutMeRefetch();
      await refetchCurrentUser();

      setSavedSnackbarMessage("About Me saved successfully.");

      history.push(generatePath(ROUTES.PWAD_PROFILE_MY_UNIQUE_NEEDS, { profileId }));
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);

      if (isErrorResponse(error)) {
        setErrorDialogMessages(getErrorMessages(error.response.data?.message));
      } else {
        setErrorDialogMessages([GENERIC_REQUEST_ERROR_MESSAGE]);
      }
    }
  };

  const onError: SubmitErrorHandler<AboutMeFormInput> = () => {
    setErrorDialogMessages([GENERIC_SUBMIT_VALIDATION_ERROR_MESSAGE]);
  };

  if (aboutMeLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (aboutMeError) {
    return <ErrorAlert message="Profile details failed to load" />;
  }

  if (!defaultValues || !aboutMeData) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <AboutMeReactHookForm
        person={aboutMeData.person}
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        onError={onError}
      />

      {!!errorDialogMessages.length && (
        <ErrorDialog title="Unable to save" messages={errorDialogMessages} onClose={() => setErrorDialogMessages([])} />
      )}
    </>
  );
}
