import { ReactEventHandler } from "react";
import { Box, Card, FormControl, FormControlLabel, Grid, MenuItem, Select, Switch, Typography } from "@mui/material";
import { isEmpty, range } from "lodash";
import { SessionModel, SessionRepeatFrequencyEnum } from "../../../models/Session";

interface Props {
  date: string;
  sessions: ({
    place: string;
  } & SessionModel)[];
  selectedSessionId: string;
  selectedSpecialtyId: string;
  onRepeatChange: ReactEventHandler<HTMLInputElement>;
  onFrequencyChange: any;
  onCountChange: any;
  isRepeated: boolean;
  selectedRepeatFrequency: string;
  selectedRepeatCount: number;
}

export default function RequestSessionFormRepeat({
  date,
  sessions,
  selectedSessionId,
  selectedSpecialtyId,
  onRepeatChange,
  onFrequencyChange,
  onCountChange,
  isRepeated,
  selectedRepeatFrequency,
  selectedRepeatCount,
}: Props) {
  if (isEmpty(selectedSessionId)) {
    return null;
  }

  return (
    <Box mt={4}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <Box display="flex" flexDirection="row" alignItems="flex-start" p={2}>
              <FormControlLabel
                control={<Switch onChange={onRepeatChange} />}
                label={<Typography fontWeight="bold">Add repeat bookings</Typography>}
              />
            </Box>
            {isRepeated && (
              <Box mx={2} mb={2}>
                <Typography>Request to book this day and timeslot for up to 12 more weeks.</Typography>
                <Box display="flex" alignItems="flex-start" gap={2} mt={2}>
                  <Typography fontWeight="bold" lineHeight="40px">
                    Repeat
                  </Typography>
                  <FormControl size="small">
                    <Select value={selectedRepeatFrequency} onChange={onFrequencyChange}>
                      {SessionRepeatFrequencyEnum.map((option) => (
                        <MenuItem value={option.value} key={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl size="small">
                    <Select value={selectedRepeatCount} onChange={onCountChange}>
                      {range(1, 13).map((val) => (
                        <MenuItem value={val} key={val}>
                          {val}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Typography lineHeight="40px">more time(s)</Typography>
                </Box>
              </Box>
            )}
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
