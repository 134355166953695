import { Box, CircularProgress, Typography } from "@mui/material";
import * as Sentry from "@sentry/browser";
import { isNil } from "lodash";
import { useForm, FormProvider } from "react-hook-form";
import { useQuery } from "react-query";
import SessionReportNoGoalsAlert from "./SessionReportNoGoalsAlert";
import { ErrorResponse } from "../../api/Generic";
import { useCreateSessionReport } from "../../api/mutations/SessionReport";
import { SessionReportAchievementModel } from "../../models/Booking";
import { PWADProfileMyGoalsModel } from "../../models/PWADProfile";
import { useGetGoalsFromGoalAchievements, useGetGoalsFromPWADProfile } from "../../utils/goal";
import ColumnBox from "../common/ColumnBox";
import ErrorAlert from "../common/ErrorAlert";

interface Props {
  sessionReportId: string | null | undefined;
  bookingId: string;
  pwadProfileId: string;
  onFormSubmitting(isSubmitting: boolean): void;
  onSuccess(): void;
  onError(error: unknown): void;
  refetchBookingWithSessionReport(): Promise<unknown>;
}

export default function SessionReportGoals({
  sessionReportId,
  bookingId,
  pwadProfileId,
  refetchBookingWithSessionReport,
  onFormSubmitting,
  onSuccess,
  onError,
}: Props) {
  const sessionReportIsStarted = !!sessionReportId;

  const {
    data: achievementsData,
    isLoading: achievementsLoading,
    error: achievementsError,
  } = useQuery<SessionReportAchievementModel[], ErrorResponse>(
    `/session-report/achievements/?session_report=${sessionReportId}`,
    { enabled: sessionReportIsStarted },
  );

  const {
    data: myGoalsData,
    isLoading: myGoalsLoading,
    error: myGoalsError,
  } = useQuery<PWADProfileMyGoalsModel, ErrorResponse>(`/pwadprofile/my-goals/?pwad_profile=${pwadProfileId}`, {
    enabled: !sessionReportIsStarted,
  });

  const { fitnessGoals: fitnessGoalsFromAchievements, ndisGoals: ndisGoalsFromAchievements } =
    useGetGoalsFromGoalAchievements(achievementsData);

  const { fitnessGoals: fitnessGoalsFromProfile, ndisGoals: ndisGoalsFromProfile } =
    useGetGoalsFromPWADProfile(myGoalsData);

  let fitnessGoals;
  let ndisGoals;

  if (sessionReportIsStarted) {
    // If the session report has been started, we need to use the goals captured at that time via the goal achievement
    // records for this session report. This means that we show the correct goals, even if the PWAD's active goals have
    // changed since the time the session report was started/created.
    fitnessGoals = fitnessGoalsFromAchievements;
    ndisGoals = ndisGoalsFromAchievements;
  } else {
    // Otherwise, we just show the PWAD's current active goals, since those will be the ones captured via the goal
    // achievement records if/when the PWAD starts/creates the session report.
    fitnessGoals = fitnessGoalsFromProfile;
    ndisGoals = ndisGoalsFromProfile;
  }

  const formMethods = useForm();

  const { handleSubmit } = formMethods;

  const { mutateAsync: createSessionReport } = useCreateSessionReport();

  const hasNoGoals = fitnessGoals.length === 0 && ndisGoals.length === 0;

  const onSubmit = async () => {
    try {
      if (isNil(sessionReportId)) {
        onFormSubmitting(true);
        await createSessionReport({ booking: bookingId });
        await refetchBookingWithSessionReport();
      }

      onSuccess();
    } catch (error) {
      Sentry.captureException(error);
      onError(error);
    } finally {
      onFormSubmitting(false);
    }
  };

  if (achievementsLoading || myGoalsLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (achievementsError || myGoalsError) {
    return <ErrorAlert message="Session report details failed to load" />;
  }

  return (
    <FormProvider {...formMethods}>
      <form id="session-report-detail-form" onSubmit={handleSubmit(onSubmit)}>
        <ColumnBox>
          <SessionReportNoGoalsAlert show={hasNoGoals} />

          <Typography variant="h2">Goals</Typography>

          <Typography mt={2}>To update goals, please contact WeFlex.</Typography>

          <ColumnBox mt={4}>
            <Typography variant="h3">Fitness Goals</Typography>

            {!fitnessGoals.length && <Typography mt={2}>No fitness goals.</Typography>}

            {!!fitnessGoals.length && (
              <ColumnBox mt={2} gap={2}>
                {fitnessGoals.map((goal, index) => (
                  <Typography key={goal.id}>{`${index + 1}: ${goal.description}`}</Typography>
                ))}
              </ColumnBox>
            )}
          </ColumnBox>

          <ColumnBox mt={4}>
            <Typography variant="h3">NDIS Goals</Typography>

            {!ndisGoals.length && <Typography mt={2}>No NDIS goals.</Typography>}

            {!!ndisGoals.length && (
              <ColumnBox mt={2} gap={2}>
                {ndisGoals.map((goal, index) => (
                  <Typography key={goal.id}>{`${index + 1}: ${goal.description}`}</Typography>
                ))}
              </ColumnBox>
            )}
          </ColumnBox>
        </ColumnBox>
      </form>
    </FormProvider>
  );
}
