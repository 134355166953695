import { CircularProgress } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { Box } from "@mui/system";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import Axios from "./api/Axios";
import theme from "./styles/_theme";
import { AuthProvider, useAuth } from "./utils/AuthProvider";
import ScrollToTop from "./components/common/ScrollToTop";
import PrivateRoutes from "./components/PrivateRoutes";
import PublicRoutes from "./components/PublicRoutes";
import "./App.scss";

const defaultQueryFn = async ({ queryKey }: any) => {
  const response = await Axios.get(queryKey[0]);

  return response.data;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      queryFn: defaultQueryFn as any,
    },
  },
});

function Routes() {
  const auth = useAuth();

  if (auth.isLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (auth.isLoggedIn) {
    return <PrivateRoutes />;
  }

  return <PublicRoutes />;
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <AuthProvider>
            <ScrollToTop />
            <Routes />
          </AuthProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </ThemeProvider>
  );
}
export default App;
