import { Alert, AlertTitle } from "@mui/material";

interface Props {
  message: string;
  description: string;
}

export default function WarningAlert({ message, description }: Props) {
  return (
    <Alert severity="warning" sx={{ border: "1px solid", borderColor: "warning.main" }}>
      <AlertTitle>{message}</AlertTitle>
      {description}
    </Alert>
  );
}
