import { useMemo } from "react";
import ConstructionIcon from "@mui/icons-material/Construction";
import { isEmpty, sortBy, uniqBy } from "lodash";
import { FPProfileModel } from "../../models/FPProfile";
import { ServiceModel } from "../../models/Session";
import { getServicesFromServicesData } from "../../utils/person";
import CheckIconList from "../common/CheckIconList";
import ProfileCard from "../common/ProfileCard";

interface Props {
  myServicesData?: FPProfileModel;
  onUpdate?: (data: any) => void;
}

export default function FPServices({ myServicesData, onUpdate }: Props) {
  const services = useMemo<ServiceModel[]>(() => getServicesFromServicesData(myServicesData), [myServicesData]);

  const availableServices = useMemo<ServiceModel[]>(() => {
    if (isEmpty(myServicesData?.all_services)) {
      return [];
    }

    return sortBy(uniqBy(myServicesData!.all_services, "name"), "name");
  }, [myServicesData]);

  const selectableValues: { [key in string]: { name: string } } = {};
  const selectedValues: { [key in string]: { name: string } } = {};

  services.forEach((element) => {
    selectedValues[element.id] = { name: element.name };
  });

  availableServices.forEach((element) => {
    selectableValues[element.id] = { name: element.name };
  });

  return (
    <ProfileCard title="Services" Icon={ConstructionIcon}>
      <CheckIconList showDivider list={services} />
    </ProfileCard>
  );
}
