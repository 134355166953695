import React from "react";
import { Typography, Grid, CircularProgress, Box } from "@mui/material";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import RequestSessionButton from "./RequestSessionButton";
import { ErrorResponse } from "../../../api/Generic";
import { FPProfileModel } from "../../../models/FPProfile";
import ColumnBox from "../../common/ColumnBox";
import ErrorAlert from "../../common/ErrorAlert";
import FPCertifications from "../../profile-cards/FPCertifications";
import FPMandatoryRequirements from "../../profile-cards/FPMandatoryRequirements";

const Qualifications = (): JSX.Element => {
  const { fpId } = useParams<{ fpId: string }>();
  const {
    data: myQualificationsData,
    isLoading,
    error,
  } = useQuery<FPProfileModel, ErrorResponse>(`/pwadprofile/fpprofile/my-qualifications/?fpprofile=${fpId}`);

  if (isLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <ErrorAlert message="Profile details failed to load" />;
  }

  return (
    <Grid container spacing={2} py={2}>
      <Grid item xs={12}>
        <Box mb={3}>
          <Grid
            item
            display="flex"
            flexDirection={{ xs: "column", md: "row" }}
            alignItems={{ xs: "flex-start", md: "center" }}
            justifyContent={{ xs: "flex-start", md: "space-between" }}
          >
            <Grid item>
              <Typography variant="h1">
                My{" "}
                <Typography display="inline" variant="h1" component="span" color="link.main">
                  Qualifications.
                </Typography>
              </Typography>
            </Grid>

            <Grid item>
              <RequestSessionButton fpId={fpId} />
            </Grid>
          </Grid>
        </Box>
      </Grid>

      <Grid item lg={6} xs={12}>
        <ColumnBox gap={2}>
          <FPCertifications myQualificationsData={myQualificationsData} />
        </ColumnBox>
      </Grid>

      <Grid item lg={6} xs={12}>
        <ColumnBox gap={2}>
          <FPMandatoryRequirements myQualificationsData={myQualificationsData} />
        </ColumnBox>
      </Grid>
    </Grid>
  );
};

export default Qualifications;
