import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import PaidIcon from "@mui/icons-material/Paid";
import { LoadingButton } from "@mui/lab";
import { Box, CircularProgress, Grid, TextField, Theme, Typography, useMediaQuery, useTheme } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as Sentry from "@sentry/browser";
import { isAfter, parseISO, startOfDay } from "date-fns";
import { Controller, FormProvider, SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { generatePath, RouteComponentProps, useParams } from "react-router-dom";
import * as yup from "yup";
import { ErrorResponse, isErrorResponse } from "../../../../api/Generic";
import { useEditPWADProfileMyPreferences } from "../../../../api/mutations/PWADProfile";
import * as ROUTES from "../../../../constants/routes";
import {
  GENERIC_ADDRESS_REQUIRED_VALIDATION_ERROR_MESSAGE,
  GENERIC_MINIMUM_VALIDATION_ERROR_MESSAGE,
  GENERIC_REQUEST_ERROR_MESSAGE,
  GENERIC_REQUIRED_VALIDATION_ERROR_MESSAGE,
  GENERIC_SUBMIT_VALIDATION_ERROR_MESSAGE,
  PHONE_VALIDATION_REGEX,
} from "../../../../constants/validation";
import GooglePlacesAutocompleteControl from "../../../../form/GooglePlacesAutocompleteControl";
import SelectControl, { SELECT_BOOLEAN_OPTIONS } from "../../../../form/SelectControl";
import {
  BookingFundingMethodEnum,
  BookingFundingMethodLabel,
  FPGenderPreferenceEnum,
  FPGenderPreferenceLabel,
  NDISFundingCategoryEnum,
  NDISFundingCategoryLabel,
  NDISSupportPersonTypeEnum,
  NDISSupportPersonTypeLabel,
  PaymentMethodEnum,
  PaymentMethodLabel,
  PWADProfileMyPreferencesEditModel,
  PWADProfileMyPreferencesModel,
  TrainingEnvironmentEnum,
  TrainingEnvironmentLabel,
  TrainingFrequencyEnum,
  TrainingFrequencyLabel,
} from "../../../../models/PWADProfile";
import { useAuth } from "../../../../utils/AuthProvider";
import { formatISODate } from "../../../../utils/formatDateTime";
import getErrorMessages from "../../../../utils/getErrorMessages";
import { getUserPWADProfileIsCompleted } from "../../../../utils/person";
import ColumnBox from "../../../common/ColumnBox";
import ErrorAlert from "../../../common/ErrorAlert";
import ErrorDialog from "../../../common/ErrorDialog";
import ProfileCard from "../../../common/ProfileCard";
import UnsavedChangesPrompt from "../../../common/UnsavedChangesPrompt";

type MyPreferencesFormInput = PWADProfileMyPreferencesEditModel;

const MIN_NDIS_PLAN_END_DATE_VALUE = startOfDay(new Date());

function getIsNdisTypicalFundingMethodSelected(typicalFundingMethodValue: BookingFundingMethodEnum | "") {
  return (
    typicalFundingMethodValue === BookingFundingMethodEnum.NDIS_SELF_MANAGED ||
    typicalFundingMethodValue === BookingFundingMethodEnum.NDIS_PLAN_MANAGED ||
    typicalFundingMethodValue === BookingFundingMethodEnum.NDIS_AGENCY_MANAGED
  );
}

const MyPreferencesFormSchema: yup.SchemaOf<MyPreferencesFormInput> = yup.object({
  fp_gender_preference: yup
    .mixed()
    .defined()
    .oneOf(Object.values(FPGenderPreferenceEnum), GENERIC_REQUIRED_VALIDATION_ERROR_MESSAGE),
  preferred_training_frequency: yup
    .mixed()
    .defined()
    .oneOf(Object.values(TrainingFrequencyEnum), GENERIC_REQUIRED_VALIDATION_ERROR_MESSAGE),
  preferred_training_environments: yup
    .array(
      yup.mixed().defined().oneOf(Object.values(TrainingEnvironmentEnum), GENERIC_REQUIRED_VALIDATION_ERROR_MESSAGE),
    )
    .ensure()
    .min(1, GENERIC_MINIMUM_VALIDATION_ERROR_MESSAGE),
  preferred_training_days_times: yup.string().trim().defined(),
  typical_funding_method: yup
    .mixed()
    .defined()
    .oneOf(Object.values(BookingFundingMethodEnum), GENERIC_REQUIRED_VALIDATION_ERROR_MESSAGE),
  preferred_payment_method: yup
    .mixed()
    .defined()
    .when("typical_funding_method", {
      is: (typical_funding_method: BookingFundingMethodEnum | "") =>
        typical_funding_method === BookingFundingMethodEnum.CLIENT_FUNDED,
      then: (schema) => schema.oneOf(Object.values(PaymentMethodEnum), GENERIC_REQUIRED_VALIDATION_ERROR_MESSAGE),
      otherwise: (schema) => schema,
    }),
  invoicing_email: yup.string().trim().defined(),
  ndis_participant: yup
    .bool()
    .defined()
    .nullable()
    .required(GENERIC_REQUIRED_VALIDATION_ERROR_MESSAGE)
    .when("typical_funding_method", {
      is: (typical_funding_method: BookingFundingMethodEnum | "") =>
        getIsNdisTypicalFundingMethodSelected(typical_funding_method),
      then: (schema) =>
        schema.oneOf(
          [true],
          "You must specify that you are an NDIS participant, based on the typical funding method currently selected.",
        ),
      otherwise: (schema) => schema,
    }),
  ndis_participant_number: yup
    .string()
    .trim()
    .defined()
    .matches(/^\d{9}$/, { excludeEmptyString: true, message: "NDIS participant number must be exactly 9 digits." })
    .when("ndis_participant", {
      is: (ndis_participant: boolean | null) => ndis_participant === true,
      then: (schema) => schema.required(GENERIC_REQUIRED_VALIDATION_ERROR_MESSAGE),
      otherwise: (schema) => schema,
    }),
  ndis_plan_start_date: yup
    .date()
    .defined()
    .nullable()
    .typeError("Please enter a valid date in the format dd/mm/yyyy.")
    .when("ndis_participant", {
      is: (ndis_participant: boolean | null) => ndis_participant === true,
      then: (schema) => schema.required(GENERIC_REQUIRED_VALIDATION_ERROR_MESSAGE),
      otherwise: (schema) => schema,
    }),
  ndis_plan_end_date: yup
    .date()
    .defined()
    .nullable()
    .typeError("Please enter a valid date in the format dd/mm/yyyy.")
    .min(yup.ref("ndis_plan_start_date"), "NDIS plan end date cannot be before the NDIS plan start date.")
    .test("dateInFuture", "NDIS plan end date must be in the future.", (value) => {
      if (!value) {
        return true;
      }

      return isAfter(value, MIN_NDIS_PLAN_END_DATE_VALUE);
    })
    .when("ndis_participant", {
      is: (ndis_participant: boolean | null) => ndis_participant === true,
      then: (schema) => schema.required(GENERIC_REQUIRED_VALIDATION_ERROR_MESSAGE),
      otherwise: (schema) => schema,
    }),
  ndis_plan_nominee_guardian: yup
    .string()
    .trim()
    .defined()
    .when("ndis_participant", {
      is: (ndis_participant: boolean | null) => ndis_participant === true,
      then: (schema) => schema.required(GENERIC_REQUIRED_VALIDATION_ERROR_MESSAGE),
      otherwise: (schema) => schema,
    }),
  ndis_funding_category: yup
    .mixed()
    .defined()
    .oneOf([...Object.values(NDISFundingCategoryEnum), ""], GENERIC_REQUIRED_VALIDATION_ERROR_MESSAGE)
    .when("ndis_participant", {
      is: (ndis_participant: boolean | null) => ndis_participant === true,
      then: (schema) => schema.required(GENERIC_REQUIRED_VALIDATION_ERROR_MESSAGE),
      otherwise: (schema) => schema,
    }),
  ndis_support_person_type: yup
    .mixed()
    .defined()
    .oneOf([...Object.values(NDISSupportPersonTypeEnum), ""], GENERIC_REQUIRED_VALIDATION_ERROR_MESSAGE),
  ndis_support_person_first_name: yup.string().trim().defined(),
  ndis_support_person_last_name: yup.string().trim().defined(),
  ndis_support_person_email: yup.string().defined().email("Please enter a valid email address."),
  ndis_support_person_phone: yup
    .string()
    .defined()
    .trim()
    .test("phoneWithMinDigits", "Please enter a valid mobile phone number.", (value) => {
      if (!value) {
        return true;
      }

      return PHONE_VALIDATION_REGEX.test(value);
    }),
  ndis_support_person_address_place: yup.string().defined(),
  ndis_support_person_address_latitude: yup.number().defined().nullable(),
  ndis_support_person_address_longitude: yup.number().defined().nullable(),
});

interface MyPreferencesReactHookFormProps {
  defaultValues: MyPreferencesFormInput;
  onSubmit: SubmitHandler<MyPreferencesFormInput>;
  onError: SubmitErrorHandler<MyPreferencesFormInput>;
}

function MyPreferencesReactHookForm({
  defaultValues,
  onSubmit,
  onError,
}: MyPreferencesReactHookFormProps): JSX.Element {
  const { currentUser } = useAuth();
  const pwadProfileIsCompleted = getUserPWADProfileIsCompleted(currentUser);

  const formMethods = useForm<MyPreferencesFormInput>({
    resolver: yupResolver(MyPreferencesFormSchema),
    mode: "onTouched",
    criteriaMode: "firstError",
    defaultValues: defaultValues,
  });

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { isSubmitting, isDirty },
  } = formMethods;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const ndisParticipantValue: boolean | null = watch("ndis_participant");
  const showNDISFields = ndisParticipantValue === true;

  useEffect(() => {
    if (!showNDISFields) {
      setValue("ndis_participant_number", "", { shouldValidate: true, shouldDirty: true, shouldTouch: true });
      setValue("ndis_plan_start_date", null, { shouldValidate: true, shouldDirty: true, shouldTouch: true });
      setValue("ndis_plan_end_date", null, { shouldValidate: true, shouldDirty: true, shouldTouch: true });
      setValue("ndis_plan_nominee_guardian", "", { shouldValidate: true, shouldDirty: true, shouldTouch: true });
      setValue("ndis_funding_category", "", { shouldValidate: true, shouldDirty: true, shouldTouch: true });
      setValue("ndis_support_person_type", "", { shouldValidate: true, shouldDirty: true, shouldTouch: true });
      setValue("ndis_support_person_first_name", "", { shouldValidate: true, shouldDirty: true, shouldTouch: true });
      setValue("ndis_support_person_last_name", "", { shouldValidate: true, shouldDirty: true, shouldTouch: true });
      setValue("ndis_support_person_phone", "", { shouldValidate: true, shouldDirty: true, shouldTouch: true });
      setValue("ndis_support_person_email", "", { shouldValidate: true, shouldDirty: true, shouldTouch: true });
      setValue("ndis_support_person_address_place", "", { shouldValidate: true, shouldDirty: true, shouldTouch: true });
      setValue("ndis_support_person_address_latitude", null, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
      setValue("ndis_support_person_address_longitude", null, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    }
  }, [showNDISFields]);

  const typicalFundingMethodValue: BookingFundingMethodEnum | "" = watch("typical_funding_method");

  const showPreferredPaymentMethodField =
    typicalFundingMethodValue === BookingFundingMethodEnum.CLIENT_FUNDED ||
    typicalFundingMethodValue === BookingFundingMethodEnum.OTHER;

  useEffect(() => {
    if (!showPreferredPaymentMethodField) {
      setValue("preferred_payment_method", "", { shouldValidate: true, shouldDirty: true, shouldTouch: true });
    }
  }, [showPreferredPaymentMethodField]);

  const isNdisTypicalFundingMethodSelected = getIsNdisTypicalFundingMethodSelected(typicalFundingMethodValue);

  useEffect(() => {
    if (isNdisTypicalFundingMethodSelected) {
      setValue("ndis_participant", true, { shouldValidate: true, shouldDirty: true, shouldTouch: true });
    }
  }, [isNdisTypicalFundingMethodSelected]);

  const theme: Theme = useTheme();
  const breakpointMD = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <Grid container spacing={2} py={2}>
            <Grid item xs={12}>
              <Box mb={3}>
                <Grid
                  item
                  display="flex"
                  flexDirection={{ xs: "column", md: "row" }}
                  alignItems={{ xs: "flex-start", md: "center" }}
                  justifyContent={{ xs: "flex-start", md: "space-between" }}
                >
                  <Grid item>
                    <Typography variant="h1">
                      My{" "}
                      <Typography display="inline" variant="h1" component="span" color="link.main">
                        Preferences.
                      </Typography>
                    </Typography>
                  </Grid>

                  <Grid item>
                    <LoadingButton
                      type="submit"
                      variant={pwadProfileIsCompleted ? "outlined" : "contained"}
                      size={breakpointMD ? "medium" : "small"}
                      sx={breakpointMD ? undefined : { marginTop: 2 }}
                      loading={isSubmitting}
                      disabled={isSubmitting}
                    >
                      Save My Preferences
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid item lg={6} xs={12}>
              <ColumnBox gap={2}>
                <ProfileCard title="Training" Icon={FitnessCenterIcon}>
                  <ColumnBox gap={3}>
                    <Controller
                      control={control}
                      name="fp_gender_preference"
                      render={({ field, fieldState }) => (
                        <SelectControl
                          label="Gender preference for my fitness professional"
                          options={Object.values(FPGenderPreferenceEnum).map((option) => ({
                            value: option,
                            label: FPGenderPreferenceLabel[option],
                          }))}
                          required
                          field={field}
                          fieldState={fieldState}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name="preferred_training_frequency"
                      render={({ field, fieldState }) => (
                        <SelectControl
                          label="My preferred training frequency"
                          options={Object.values(TrainingFrequencyEnum).map((option) => ({
                            value: option,
                            label: TrainingFrequencyLabel[option],
                          }))}
                          required
                          field={field}
                          fieldState={fieldState}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name="preferred_training_environments"
                      render={({ field, fieldState }) => (
                        <SelectControl
                          label="My preferred training environments"
                          options={Object.values(TrainingEnvironmentEnum).map((option) => ({
                            value: option,
                            label: TrainingEnvironmentLabel[option],
                          }))}
                          required
                          multiple
                          field={field}
                          fieldState={fieldState}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name="preferred_training_days_times"
                      render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                        <TextField
                          label="My preferred training days/times"
                          placeholder="For example: Tuesdays and Fridays anytime between 10 am and 2 pm or Wednesdays at 3 pm."
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          inputRef={ref}
                          error={invalid}
                          helperText={error?.message}
                          multiline
                          rows={3}
                        />
                      )}
                    />
                  </ColumnBox>
                </ProfileCard>

                <ProfileCard title="Payment" Icon={PaidIcon}>
                  <ColumnBox gap={3}>
                    <Controller
                      control={control}
                      name="typical_funding_method"
                      render={({ field, fieldState }) => (
                        <SelectControl
                          label="Typical funding method"
                          options={Object.values(BookingFundingMethodEnum).map((option) => ({
                            value: option,
                            label: BookingFundingMethodLabel[option],
                          }))}
                          required
                          field={field}
                          fieldState={fieldState}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="invoicing_email"
                      render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                        <TextField
                          label="My invoicing email address"
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          inputRef={ref}
                          error={invalid}
                          required
                          helperText={error?.message}
                        />
                      )}
                    />

                    {showPreferredPaymentMethodField && (
                      <Controller
                        control={control}
                        name="preferred_payment_method"
                        render={({ field, fieldState }) => (
                          <SelectControl
                            label="Preferred payment method"
                            options={Object.values(PaymentMethodEnum).map((option) => ({
                              value: option,
                              label: PaymentMethodLabel[option],
                            }))}
                            required
                            field={field}
                            fieldState={fieldState}
                          />
                        )}
                      />
                    )}
                  </ColumnBox>
                </ProfileCard>
              </ColumnBox>
            </Grid>

            <Grid item lg={6} xs={12}>
              <ColumnBox gap={2}>
                <ProfileCard title="NDIS" Icon={CorporateFareIcon}>
                  <ColumnBox gap={3}>
                    <Controller
                      control={control}
                      name="ndis_participant"
                      render={({ field, fieldState }) => (
                        <SelectControl
                          label="Are you an NDIS participant?"
                          options={SELECT_BOOLEAN_OPTIONS}
                          required
                          field={field}
                          fieldState={fieldState}
                        />
                      )}
                    />

                    {showNDISFields && (
                      <>
                        <Controller
                          control={control}
                          name="ndis_participant_number"
                          render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                            <TextField
                              label="NDIS participant number"
                              value={value}
                              onChange={onChange}
                              onBlur={onBlur}
                              inputRef={ref}
                              error={invalid}
                              helperText={error?.message}
                              required
                            />
                          )}
                        />

                        <Controller
                          control={control}
                          name="ndis_plan_start_date"
                          render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                            <DesktopDatePicker
                              label="NDIS plan start date"
                              inputFormat="dd/MM/yyyy"
                              value={value}
                              onChange={onChange}
                              inputRef={ref}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  onBlur={onBlur}
                                  error={invalid}
                                  helperText={error?.message}
                                  required
                                />
                              )}
                            />
                          )}
                        />

                        <Controller
                          control={control}
                          name="ndis_plan_end_date"
                          render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                            <DesktopDatePicker
                              label="NDIS plan end date"
                              inputFormat="dd/MM/yyyy"
                              value={value}
                              onChange={onChange}
                              inputRef={ref}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  onBlur={onBlur}
                                  error={invalid}
                                  helperText={error?.message}
                                  required
                                />
                              )}
                            />
                          )}
                        />

                        <Controller
                          control={control}
                          name="ndis_plan_nominee_guardian"
                          render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                            <TextField
                              label="NDIS plan nominee and/or guardian"
                              value={value}
                              onChange={onChange}
                              onBlur={onBlur}
                              inputRef={ref}
                              error={invalid}
                              helperText={error?.message}
                              required
                            />
                          )}
                        />

                        <Controller
                          control={control}
                          name="ndis_funding_category"
                          render={({ field, fieldState }) => (
                            <SelectControl
                              label="NDIS funding category"
                              options={Object.values(NDISFundingCategoryEnum).map((option) => ({
                                value: option,
                                label: NDISFundingCategoryLabel[option],
                              }))}
                              required
                              field={field}
                              fieldState={fieldState}
                            />
                          )}
                        />
                      </>
                    )}
                  </ColumnBox>
                </ProfileCard>

                {showNDISFields && (
                  <ProfileCard title="NDIS Support Person" Icon={ContactPhoneIcon}>
                    <ColumnBox gap={3}>
                      <Controller
                        control={control}
                        name="ndis_support_person_type"
                        render={({ field, fieldState }) => (
                          <SelectControl
                            label="Type"
                            options={Object.values(NDISSupportPersonTypeEnum).map((option) => ({
                              value: option,
                              label: NDISSupportPersonTypeLabel[option],
                            }))}
                            field={field}
                            fieldState={fieldState}
                          />
                        )}
                      />

                      <Controller
                        control={control}
                        name="ndis_support_person_first_name"
                        render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                          <TextField
                            label="First name"
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            inputRef={ref}
                            error={invalid}
                            helperText={error?.message}
                          />
                        )}
                      />

                      <Controller
                        control={control}
                        name="ndis_support_person_last_name"
                        render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                          <TextField
                            label="Last name"
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            inputRef={ref}
                            error={invalid}
                            helperText={error?.message}
                          />
                        )}
                      />

                      <Controller
                        control={control}
                        name="ndis_support_person_email"
                        render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                          <TextField
                            label="Email"
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            inputRef={ref}
                            error={invalid}
                            helperText={error?.message}
                          />
                        )}
                      />

                      <Controller
                        control={control}
                        name="ndis_support_person_phone"
                        render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                          <TextField
                            label="Mobile"
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            inputRef={ref}
                            error={invalid}
                            helperText={error?.message}
                          />
                        )}
                      />

                      <Controller
                        control={control}
                        name="ndis_support_person_address_place"
                        render={({ field, fieldState }) => (
                          <GooglePlacesAutocompleteControl
                            label="Address"
                            latitudeFieldName="ndis_support_person_address_latitude"
                            longitudeFieldName="ndis_support_person_address_longitude"
                            field={field}
                            fieldState={fieldState}
                          />
                        )}
                      />
                    </ColumnBox>
                  </ProfileCard>
                )}
              </ColumnBox>
            </Grid>
          </Grid>
        </form>

        <UnsavedChangesPrompt showPrompt={isDirty} />
      </FormProvider>
    </LocalizationProvider>
  );
}

interface Props extends RouteComponentProps {
  setSavedSnackbarMessage: Dispatch<SetStateAction<string>>;
}

export default function MyPreferencesForm({ setSavedSnackbarMessage, history }: Props): JSX.Element {
  const { profileId } = useParams<{ profileId: string }>();

  const { refetchCurrentUser } = useAuth();

  const [errorDialogMessages, setErrorDialogMessages] = useState<Array<string>>([]);

  const {
    data: myPreferencesData,
    error: myPreferencesError,
    isLoading: myPreferencesLoading,
    refetch: myPreferencesRefetch,
  } = useQuery<PWADProfileMyPreferencesModel, ErrorResponse>(`/pwadprofile/my-preferences/?pwad_profile=${profileId}`);

  const { mutateAsync: editPWADProfileMyPreferences } = useEditPWADProfileMyPreferences(profileId);

  const defaultValues = useMemo((): MyPreferencesFormInput | null => {
    if (!myPreferencesData) {
      return null;
    }

    return {
      ...myPreferencesData,
      ndis_plan_start_date: myPreferencesData.ndis_plan_start_date
        ? parseISO(myPreferencesData.ndis_plan_start_date)
        : null,
      ndis_plan_end_date: myPreferencesData.ndis_plan_end_date ? parseISO(myPreferencesData.ndis_plan_end_date) : null,
      ndis_support_person_address_place: myPreferencesData.ndis_support_person_address?.place ?? "",
      ndis_support_person_address_latitude: myPreferencesData.ndis_support_person_address?.latitude ?? null,
      ndis_support_person_address_longitude: myPreferencesData.ndis_support_person_address?.longitude ?? null,
    };
  }, [myPreferencesData]);

  const onSubmit: SubmitHandler<MyPreferencesFormInput> = async (values) => {
    try {
      await editPWADProfileMyPreferences({
        pwad_profile: {
          ...values,
          ndis_plan_start_date: values.ndis_plan_start_date ? formatISODate(values.ndis_plan_start_date) : null,
          ndis_plan_end_date: values.ndis_plan_end_date ? formatISODate(values.ndis_plan_end_date) : null,
        },
      });

      await myPreferencesRefetch();
      await refetchCurrentUser();

      setSavedSnackbarMessage("My Preferences saved successfully.");

      history.push(generatePath(ROUTES.PWAD_PROFILE_MY_HEALTH, { profileId }));
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);

      if (isErrorResponse(error)) {
        setErrorDialogMessages(getErrorMessages(error.response.data?.message));
      } else {
        setErrorDialogMessages([GENERIC_REQUEST_ERROR_MESSAGE]);
      }
    }
  };

  const onError: SubmitErrorHandler<MyPreferencesFormInput> = () => {
    setErrorDialogMessages([GENERIC_SUBMIT_VALIDATION_ERROR_MESSAGE]);
  };

  if (myPreferencesLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (myPreferencesError) {
    return <ErrorAlert message="Profile details failed to load" />;
  }

  if (!defaultValues) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <MyPreferencesReactHookForm defaultValues={defaultValues} onSubmit={onSubmit} onError={onError} />

      {!!errorDialogMessages.length && (
        <ErrorDialog title="Unable to save" messages={errorDialogMessages} onClose={() => setErrorDialogMessages([])} />
      )}
    </>
  );
}
