import Complete from "./Complete";
import Incomplete from "./Incomplete";
import { useCurrentUserProfileIsLive } from "../../../utils/person";

const Home = (): JSX.Element => {
  const isProfileLive = useCurrentUserProfileIsLive();

  if (isProfileLive) {
    return <Complete />;
  }

  return <Incomplete />;
};

export default Home;
