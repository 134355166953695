import { useMemo, useState } from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar as MUIAppBar,
  Avatar,
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import * as Sentry from "@sentry/browser";
import { isEmpty } from "lodash";
import { generatePath, Link, useHistory, Link as RouterLink } from "react-router-dom";
import { drawerWidth } from "./ResponsiveDrawer";
import { useLogout } from "../../api/mutations/User";
import { ReactComponent as Logo } from "../../assets/img/WeFlexLogo.svg";
import * as ROUTES from "../../constants/routes";
import { GENERIC_REQUEST_ERROR_MESSAGE } from "../../constants/validation";
import { useAuth } from "../../utils/AuthProvider";
import getAvatarUrl from "../../utils/getAvatarUrl";
import { getFullName } from "../../utils/person";
import SnackbarAlert from "../common/SnackbarAlert";

export const APP_BAR_HEIGHT = 65;

type AppBarProps = {
  toggleDrawer: CallableFunction;
};

export default function AppBar({ toggleDrawer }: AppBarProps): JSX.Element {
  const { mutate: logout, isLoading: isLogoutLoading } = useLogout();
  const history = useHistory();
  const { currentUser, clearCurrentUser } = useAuth();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [logoutErrorMessage, setLogoutErrorMessage] = useState<string>("");

  const profilePath = useMemo(() => {
    if (currentUser?.is_admin) {
      return "";
    }

    if (currentUser?.is_fp === true) {
      return generatePath(ROUTES.FP_PROFILE, { profileId: currentUser.person?.fp_profile?.id });
    }

    if (currentUser?.is_pwad === true) {
      return generatePath(ROUTES.PWAD_PROFILE, { profileId: currentUser.person?.pwad_profile?.id });
    }

    return "";
  }, [currentUser]);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    logout(
      {},
      {
        onError: (error) => {
          console.log({ errorrrr: error });
          Sentry.captureException(error);
          setLogoutErrorMessage(GENERIC_REQUEST_ERROR_MESSAGE);
        },
        onSuccess: () => {
          clearCurrentUser();
          history.push(ROUTES.HOME);
        },
      },
    );
  };

  const fullName = currentUser?.is_admin ? currentUser.username : getFullName(currentUser?.person);

  return (
    <MUIAppBar
      position="fixed"
      sx={{
        bgcolor: { xs: "white", lg: "#FAFAFA" },
        height: APP_BAR_HEIGHT,
        width: { lg: `calc(100% - ${drawerWidth}px)` },
        ml: { lg: `${drawerWidth}px` },
        borderBottom: "none",
        boxShadow: "none",
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          backgroundColor: "white",
          borderBottom: 1,
          borderColor: "grey.300",
        }}
      >
        <IconButton
          color="inherit"
          aria-label="Open drawer"
          edge="start"
          onClick={() => toggleDrawer()}
          sx={{ mr: 1, display: { lg: "none" } }}
        >
          <MenuIcon htmlColor="#008330" />
        </IconButton>

        <IconButton
          size="large"
          aria-label="User account"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
          sx={{ ml: { lg: "auto" } }}
        >
          <Avatar
            alt={fullName}
            src={getAvatarUrl(currentUser?.person?.avatar)}
            sx={{
              border: "1px solid",
              borderColor: "grey.300",
            }}
          />
        </IconButton>

        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            disabled={isEmpty(profilePath)}
            sx={{ display: "flex", flexDirection: "column", width: "300px" }}
            divider
            component={Link}
            to={profilePath}
          >
            <Avatar
              alt={fullName}
              src={getAvatarUrl(currentUser?.person?.avatar)}
              sx={{
                height: "80px",
                width: "80px",
                border: "1px solid",
                borderColor: "grey.300",
              }}
            />
            <Typography
              variant="h3"
              mt={2}
              mb={1}
              sx={{
                width: "100%",
                textAlign: "center",
                wordBreak: "break-all",
                overflowWrap: "break-word",
                whiteSpace: "normal",
              }}
            >
              {fullName}
            </Typography>
            <Typography variant="body2" mb={1}>
              View Profile
            </Typography>
          </MenuItem>
          <MenuItem onClick={handleLogout} disabled={isLogoutLoading}>
            <ListItemIcon>
              <LogoutIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Log out</ListItemText>
          </MenuItem>
        </Menu>
        <Box ml="auto" display={{ lg: "none" }}>
          <RouterLink to={ROUTES.HOME}>
            <Logo width="116px" height="48px" />
          </RouterLink>
        </Box>
      </Toolbar>
      <SnackbarAlert
        title="An error occurred"
        content={logoutErrorMessage}
        severity="error"
        open={!!logoutErrorMessage}
        onClose={() => setLogoutErrorMessage("")}
      />
    </MUIAppBar>
  );
}
