import { useMemo } from "react";
import { Box, Card, CircularProgress, Container, useMediaQuery } from "@mui/material";
import { generatePath, Redirect, Route, Switch, useParams } from "react-router-dom";
import AboutMe from "./AboutMe";
import Consent from "./Consent";
import MyGoals from "./MyGoals";
import MyHealth from "./MyHealth";
import MyPreferences from "./MyPreferences";
import MyUniqueNeeds from "./MyUniqueNeeds";
import SessionReports from "./SessionReports";
import { useGetPWADProfileAboutMe } from "../../../api/queries/PWADProfile";
import * as ROUTES from "../../../constants/routes";
import theme from "../../../styles/_theme";
import { getFullName } from "../../../utils/person";
import ErrorAlert from "../../common/ErrorAlert";
import NavTabs from "../../common/NavTabs";
import ProfileBanner from "../../common/ProfileBanner";

function Profile(): JSX.Element {
  const { pwadProfileId } = useParams<{ pwadProfileId: string }>();

  const { data: aboutMeData, isLoading: aboutMeLoading, error: aboutMeError } = useGetPWADProfileAboutMe(pwadProfileId);

  const navTabItems = useMemo(
    () => [
      { label: "About Me", path: generatePath(ROUTES.FP_VIEW_PWAD_PROFILE_ABOUT_ME, { pwadProfileId }) },
      { label: "My Unique Needs", path: generatePath(ROUTES.FP_VIEW_PWAD_PROFILE_MY_UNIQUE_NEEDS, { pwadProfileId }) },
      { label: "My Goals", path: generatePath(ROUTES.FP_VIEW_PWAD_PROFILE_MY_GOALS, { pwadProfileId }) },
      { label: "My Preferences", path: generatePath(ROUTES.FP_VIEW_PWAD_PROFILE_MY_PREFERENCES, { pwadProfileId }) },
      { label: "My Health", path: generatePath(ROUTES.FP_VIEW_PWAD_PROFILE_MY_HEALTH, { pwadProfileId }) },
      { label: "Consent", path: generatePath(ROUTES.FP_VIEW_PWAD_PROFILE_CONSENT, { pwadProfileId }) },
      { label: "Session Reports", path: generatePath(ROUTES.FP_VIEW_PWAD_PROFILE_SESSION_REPORTS, { pwadProfileId }) },
    ],
    [pwadProfileId],
  );

  const mobileMode = useMediaQuery(theme.breakpoints.down("lg"));

  if (aboutMeLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (aboutMeError) {
    return <ErrorAlert message="Profile details failed to load" />;
  }

  return (
    <Container maxWidth="xl" sx={{ p: 2 }}>
      <Card sx={{ width: "100%", mb: { xs: 2, lg: 4 }, borderRadius: "4px 4px 0px 0px" }}>
        <ProfileBanner
          fullName={getFullName(aboutMeData?.person)}
          avatar={aboutMeData?.person?.avatar}
          email={aboutMeData?.person?.email}
        />

        <NavTabs
          scrollButtons="auto"
          allowScrollButtonsMobile
          variant={mobileMode ? "scrollable" : "standard"}
          sx={{ ".MuiTabs-flexContainer": { justifyContent: { lg: "flex-end" }, py: { lg: 1 } } }}
          items={navTabItems}
        />
      </Card>

      <Switch>
        <Route path={ROUTES.FP_VIEW_PWAD_PROFILE_ABOUT_ME} component={AboutMe} />
        <Route path={ROUTES.FP_VIEW_PWAD_PROFILE_MY_UNIQUE_NEEDS} component={MyUniqueNeeds} />
        <Route path={ROUTES.FP_VIEW_PWAD_PROFILE_MY_GOALS} component={MyGoals} />
        <Route path={ROUTES.FP_VIEW_PWAD_PROFILE_MY_PREFERENCES} component={MyPreferences} />
        <Route path={ROUTES.FP_VIEW_PWAD_PROFILE_MY_HEALTH} component={MyHealth} />
        <Route path={ROUTES.FP_VIEW_PWAD_PROFILE_CONSENT} component={Consent} />
        <Route path={ROUTES.FP_VIEW_PWAD_PROFILE_SESSION_REPORTS} component={SessionReports} />
        <Redirect to={ROUTES.FP_VIEW_PWAD_PROFILE_ABOUT_ME} />
      </Switch>
    </Container>
  );
}

export default Profile;
