import React, { ReactNode } from "react";
import { Typography } from "@mui/material";
import { format, parseISO } from "date-fns";
import { isEmpty, isNil, isNull, isString } from "lodash";
import OptionalValue from "./OptionalValue";

export enum TitleContentType {
  TEXT,
  COMBO,
  MULTI,
  CHECKBOX,
  DATE,
}

interface Props {
  title?: string;
  content: ReactNode | string | null | undefined;
  contentType?: TitleContentType;
}

function renderContent(content: ReactNode | string | null | undefined, contentType: TitleContentType) {
  if (isNil(content) || isEmpty(content) || content === "") {
    return null;
  }

  if ((contentType === TitleContentType.DATE && isString(content)) || isNull(content)) {
    const dateOfBirth = content ? format(parseISO(content), "dd MMM yyyy") : "";

    return <Typography variant="body1">{dateOfBirth}</Typography>;
  }

  if (isString(content)) {
    if (content === "true") {
      return <Typography variant="body1">Yes</Typography>;
    }

    if (content === "false") {
      return <Typography variant="body1">No</Typography>;
    }

    return <Typography variant="body1">{content}</Typography>;
  }

  // TODO figure out what to do for editing other types of content
  return content;
}

export default function ProfileCardTitleWithContent({ title, content, contentType }: Props): JSX.Element {
  const renderedContent = renderContent(content, contentType ? contentType : TitleContentType.TEXT);

  return (
    <div>
      {!!title && (
        <Typography variant="h3" mb={1}>
          {title}
        </Typography>
      )}

      <OptionalValue>{renderedContent}</OptionalValue>
    </div>
  );
}
