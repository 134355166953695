/* eslint-disable @typescript-eslint/naming-convention */
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import { Link, generatePath } from "react-router-dom";
import InvoiceStatus from "./InvoiceStatus";
import * as ROUTES from "../../../constants/routes";
import { InvoiceListModel } from "../../../models/Invoice";
import { formatDateTimeStr } from "../../../utils/formatDateTime";

interface Props {
  invoice: InvoiceListModel;
}

export default function InvoiceRow({ invoice }: Props): JSX.Element {
  const { date, status, invoice_number, uuid } = invoice;

  const sessionReportDetailsPath = generatePath(ROUTES.FP_INVOICE_DETAIL, {
    invoiceId: uuid,
  });
  return (
    <Grid container>
      <Grid item xs={12} lg={1}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          sx={{ backgroundColor: "grey.200" }}
          height={{ xs: 100, lg: 80 }}
        >
          <Typography variant="h3">{formatDateTimeStr(date, "eee MMM d")}</Typography>
        </Box>
      </Grid>

      <Grid item xs={12} lg={4}>
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            borderRadius: 0,
            minHeight: { xs: 20, lg: "100%" },
            px: 2,
          }}
          elevation={0}
        >
          <Typography variant="body1">Invoice Number: {invoice_number}</Typography>
        </Paper>
      </Grid>

      <Grid item xs={12} lg={4}>
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            borderRadius: 0,
            minHeight: { xs: 20, lg: "100%" },
            px: 2,
          }}
          elevation={0}
        >
          <InvoiceStatus status={status} />
        </Paper>
      </Grid>

      <Grid item xs={12} lg={2}>
        <Paper
          sx={{
            display: "flex",
            minHeight: { xs: 20, lg: "100%" },
            justifyContent: { xs: "center", lg: "flex-end" },
            alignItems: "center",
            px: 2,
            borderRadius: 0,
          }}
          elevation={0}
        >
          <Button variant="contained" component={Link} to={sessionReportDetailsPath} sx={{ minWidth: 140 }}>
            View
          </Button>
        </Paper>
      </Grid>
    </Grid>
  );
}
