import { styled } from "@material-ui/core/styles";
import { LocalizationProvider, CalendarPicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { PickersDayProps } from "@mui/lab/PickersDay";
import { Card } from "@mui/material";
import PickersDay, { DayAnnotations } from "./PickersDay";
import theme from "../../styles/_theme";
import { formatISODate } from "../../utils/formatDateTime";

export type CalendarAnnotations = {
  [key in string]: DayAnnotations;
};

const CalendarPickerStyled = styled(CalendarPicker)(() => ({
  "width": "100%",
  ".css-1dozdou": {
    "div[role=presentation]": {
      marginRight: 0,
    },
  },
  ".MuiCalendarPicker-viewTransitionContainer": {
    ".css-1n2mv2k": {
      justifyContent: "space-around",
    },
    ".PrivatePickersSlideTransition-root > div[role=grid] > div[role=row]": {
      justifyContent: "space-around",
    },
  },
}));

interface Props {
  date: Date;
  annotations?: CalendarAnnotations | undefined;
  selectedDates?: string[];
  onMonthChange?(date: unknown): void;
}

/**
 * TODO: Update component to use LocalizationProvider and AdapterDateFns from `@mui/x-date-pickers`.
 */
const Calendar = ({ onMonthChange, date, annotations, selectedDates }: Props): JSX.Element => {
  const renderPickersDay = (
    day: unknown,
    _selectedDates: unknown[],
    pickersDayProps: PickersDayProps<unknown>,
  ): JSX.Element => {
    const dateStr = formatISODate(day as Date);

    return (
      <PickersDay
        {...pickersDayProps}
        sx={{
          "&.Mui-disabled": {
            color: theme.palette.text.primary,
          },
        }}
        disabled
        selected={selectedDates?.includes(dateStr)}
        annotations={annotations?.[dateStr]}
      />
    );
  };

  return (
    <Card>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CalendarPickerStyled
          readOnly
          date={date}
          onMonthChange={onMonthChange}
          onChange={() => {}}
          views={["day"]}
          renderDay={renderPickersDay}
        />
      </LocalizationProvider>
    </Card>
  );
};

export default Calendar;
