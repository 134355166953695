import React from "react";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import PWADServicesConsentContent from "./PWADServicesConsentContent";
import { PWADProfileConsentModel } from "../../models/PWADProfile";
import BooleanValueDisplayBox from "../common/BooleanValueDisplayBox";
import ProfileCard from "../common/ProfileCard";

interface Props {
  consentData: PWADProfileConsentModel;
}

export default function PWADServicesConsent({ consentData }: Props): JSX.Element {
  return (
    <ProfileCard title="Services" Icon={FitnessCenterIcon}>
      <PWADServicesConsentContent />

      <BooleanValueDisplayBox
        value={consentData.waivers_agree_term_and_conditions}
        trueLabel="Yes, I consent to receive services from WeFlex as described above"
        falseLabel="No, I do not consent to receive services from WeFlex as described above"
        nullLabel="I have not yet indicated whether I give consent"
        mt={4}
      />
    </ProfileCard>
  );
}
