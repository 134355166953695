import { Typography } from "@mui/material";
import { includes } from "lodash";
import { SessionLocationTypeEnum } from "../../../models/Session";
import MapLink from "../../common/MapLink";

interface Props {
  place: string;
  locationType: SessionLocationTypeEnum;
  mobileLocation: string;
  showDirectionsLink?: boolean;
  showFixedLocationTitle?: boolean;
}

function getFixedLocationTitle(place: string) {
  if (!includes(place, "")) {
    return "";
  }

  return place.split(",")[0];
}

export default function SessionLocation({
  place,
  locationType,
  mobileLocation,
  showDirectionsLink = false,
  showFixedLocationTitle = true,
}: Props) {
  if (locationType === SessionLocationTypeEnum.FIXED) {
    const fixedLocationTitle = getFixedLocationTitle(place);

    return (
      <>
        {showFixedLocationTitle && !!fixedLocationTitle && (
          <Typography fontWeight="bold">{fixedLocationTitle}</Typography>
        )}

        <Typography variant="body1">{place}</Typography>

        {showDirectionsLink && (
          <Typography variant="body2">
            <MapLink location={place} />
          </Typography>
        )}
      </>
    );
  }

  if (locationType === SessionLocationTypeEnum.MOBILE) {
    return (
      <>
        <Typography fontWeight="bold">Mobile Session</Typography>

        <Typography variant="body1">{mobileLocation}</Typography>
      </>
    );
  }

  if (locationType === SessionLocationTypeEnum.VIRTUAL) {
    return (
      <>
        <Typography fontWeight="bold">Online Session</Typography>

        <Typography variant="body1">Link provided by fitness professional on booking confirmation</Typography>
      </>
    );
  }

  return null;
}
