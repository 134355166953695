import axios from "axios";
import ENV from "../constants/envConstants";
import { getToken } from "../utils/store";

const instance = axios.create({
  baseURL: `${ENV.API_HTTP_URL}/api/v1`,
  responseType: "json",
  headers: {
    "Content-type": "application/json",
  },
});

instance.interceptors.request.use((config) => {
  const token = getToken();

  if (token) {
    return {
      ...config,
      headers: {
        Authorization: `Token ${token}`,
      },
    };
  }

  return config;
});

export default instance;
