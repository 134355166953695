import { useMutation } from "react-query";
import Axios from "../Axios";
import { ErrorResponse, ServerResponse } from "../Generic";

type RequestSessionParameters = {
  session: string;
  specialty: string;
  isRepeated: boolean;
  repeatFrequency: string;
  repeatCount: number;
};

export default function useRequestSession() {
  return useMutation<ServerResponse, ErrorResponse, RequestSessionParameters>((input: RequestSessionParameters) =>
    Axios.post<ServerResponse, any>("session/request-booking/", input),
  );
}
