import React, { useEffect, useRef, useState } from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  Alert,
  AlertTitle,
  Avatar,
  Box,
  CircularProgress,
  Divider,
  Grid,
  Link,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { isNil } from "lodash";
import { useQuery } from "react-query";
import { generatePath, Link as RouterLink, Redirect, useHistory, useParams } from "react-router-dom";
import SessionReportAchievements from "./SessionReportAchievements";
import SessionReportActions from "./SessionReportActions";
import SessionReportAdditionalNotes from "./SessionReportAdditionalNotes";
import { SESSION_REPORT_STEPS, SessionReportReturnToEnum, SessionReportStepsEnum } from "./SessionReportConstants";
import SessionReportExitWarning from "./SessionReportExitWarning";
import SessionReportGoals from "./SessionReportGoals";
import SessionReportIncidentReport from "./SessionReportIncidentReport";
import SessionReportStepsDesktop from "./SessionReportStepsDesktop";
import SessionReportStepsMobile from "./SessionReportStepsMobile";
import SessionReportSubmit from "./SessionReportSubmit";
import SessionReportSubmittedConfirmation from "./SessionReportSubmittedConfirmation";
import { ErrorResponse, isErrorResponse } from "../../api/Generic";
import * as ROUTES from "../../constants/routes";
import { GENERIC_REQUEST_ERROR_MESSAGE } from "../../constants/validation";
import { BookingOutcomeEnum, BookingWithSessionReportModel, SessionReportStatusEnum } from "../../models/Booking";
import theme from "../../styles/_theme";
import { useAuth } from "../../utils/AuthProvider";
import { formatDateTimeStr } from "../../utils/formatDateTime";
import getAvatarUrl from "../../utils/getAvatarUrl";
import getErrorMessages from "../../utils/getErrorMessages";
import { getFullName } from "../../utils/person";
import ErrorAlert from "../common/ErrorAlert";
import ErrorDialog from "../common/ErrorDialog";
import SnackbarAlert from "../common/SnackbarAlert";
import SessionLocation from "../pwad/request-session/SessionLocation";

export default function SessionReportDetails() {
  const { currentUser } = useAuth();

  const history = useHistory();

  const { bookingId, activeStep, returnTo } =
    useParams<{ bookingId: string; activeStep: SessionReportStepsEnum; returnTo: SessionReportReturnToEnum }>();

  const {
    data: bookingWithSessionReportData,
    isLoading: isBookingWithSessionReportLoading,
    error: bookingWithSessionReportError,
    refetch: refetchBookingWithSessionReport,
  } = useQuery<BookingWithSessionReportModel, ErrorResponse>(`/booking/session-report-detail/${bookingId}/`);

  const [editMode, setEditMode] = useState(false);

  const mobileMode = useMediaQuery(theme.breakpoints.down("lg"));

  const [openSavedConfirmation, setOpenSavedConfirmation] = useState(false);
  const [openExitWarning, setOpenExitWarning] = useState(false);
  const [openSubmittedConfirmation, setOpenSubmittedConfirmation] = useState(false);
  const [submitErrors, setSubmitErrors] = useState<{ title: string; messages: string[] } | null>(null);
  const [isFormSubmitting, setFormSubmitting] = useState<boolean>(false);
  const [isFormLoading, setFormLoading] = useState<boolean>(false);
  const activeBookingId = useRef<string | null>();

  const activeStepIndex = SESSION_REPORT_STEPS.findIndex(({ key }) => key === activeStep);

  const sessionReportId = bookingWithSessionReportData?.session_report?.id;
  const isStarted = !isNil(sessionReportId);
  const isCompleted = bookingWithSessionReportData?.session_report?.status === SessionReportStatusEnum.COMPLETED;

  const canEdit =
    !isCompleted ||
    !!(
      currentUser?.is_fp && bookingWithSessionReportData?.session.fp_profile.id === currentUser?.person?.fp_profile?.id
    );

  const isEditMode = !isCompleted || editMode;

  useEffect(() => {
    if (editMode) {
      setEditMode(false);
    }
  }, [activeStepIndex]);

  useEffect(() => {
    activeBookingId.current = bookingId;
    return () => {
      activeBookingId.current = null;
    };
  }, [bookingId]);

  const exitSessionReport = () => {
    if (returnTo === SessionReportReturnToEnum.MY_SESSION_REPORTS_TO_DO) {
      history.push(ROUTES.FP_MY_SESSION_REPORTS_TO_DO);
    } else if (returnTo === SessionReportReturnToEnum.MY_SESSION_REPORTS_COMPLETED) {
      history.push(ROUTES.FP_MY_SESSION_REPORTS_COMPLETED);
    } else if (returnTo === SessionReportReturnToEnum.PWAD_PROFILE || returnTo === "client_profile") {
      // Note that we're supporting the old "client_profile" value temporarily after replacing it with "client-profile".
      if (currentUser?.is_admin || currentUser?.is_pwad) {
        history.push(
          generatePath(ROUTES.PWAD_PROFILE_SESSION_REPORTS, {
            profileId: bookingWithSessionReportData?.pwad_profile.id,
          }),
        );
      } else {
        history.push(
          generatePath(ROUTES.FP_VIEW_PWAD_PROFILE_SESSION_REPORTS, {
            pwadProfileId: bookingWithSessionReportData?.pwad_profile.id,
          }),
        );
      }
    } else {
      history.goBack();
    }
  };

  const goNextStep = () => {
    if (activeStepIndex < SESSION_REPORT_STEPS.length - 1) {
      history.push(
        generatePath(ROUTES.SESSION_REPORT_DETAILS, {
          bookingId,
          activeStep: SESSION_REPORT_STEPS[activeStepIndex + 1].key,
          returnTo,
        }),
      );
    }
  };

  const goPrevStep = () => {
    if (activeStepIndex > 0) {
      history.push(
        generatePath(ROUTES.SESSION_REPORT_DETAILS, {
          bookingId,
          activeStep: SESSION_REPORT_STEPS[activeStepIndex - 1].key,
          returnTo,
        }),
      );
    }
  };

  const handleEditClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    setEditMode(true);
  };

  const handleExitClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    if (isEditMode) {
      setOpenExitWarning(true);
    } else {
      exitSessionReport();
    }
  };

  const handleFormSubmitting = (isSubmitting: boolean) => {
    if (bookingId !== activeBookingId.current) {
      return; // to protect against callbacks that are called after the component has unmounted
    }
    setFormSubmitting(isSubmitting);
  };

  const handleFormLoading = (isLoading: boolean) => {
    if (bookingId !== activeBookingId.current) {
      return; // to protect against callbacks that are called after the component has unmounted
    }
    setFormLoading(isLoading);
  };

  const handleSuccess = (showSavedConfirmation: boolean = false) => {
    if (bookingId !== activeBookingId.current) {
      return; // to protect against callbacks that are called after the component has unmounted
    }

    if (activeStep === SessionReportStepsEnum.SUBMIT) {
      setOpenSubmittedConfirmation(true);
      return;
    }

    if (showSavedConfirmation) {
      setOpenSavedConfirmation(true);
    }

    if (isCompleted) {
      setEditMode(false);
    } else {
      goNextStep();
    }
  };

  const handleExitWarningOk = () => {
    setOpenExitWarning(false);
    exitSessionReport();
  };

  const handleSubmittedConfirmationClose = () => {
    setOpenSubmittedConfirmation(false);
    exitSessionReport();
  };

  const handleError = (error: unknown) => {
    if (bookingId !== activeBookingId.current) {
      return; // to protect against callbacks that are called after the component has unmounted
    }

    let errorTitle;

    switch (activeStep) {
      case SessionReportStepsEnum.GOALS:
        errorTitle = "Failed to start the session report";
        break;
      case SessionReportStepsEnum.SUBMIT:
        errorTitle = "Failed to submit the session report";
        break;
      default:
        errorTitle = "Failed to save the session report";
        break;
    }

    if (isErrorResponse(error)) {
      setSubmitErrors({ title: errorTitle, messages: getErrorMessages(error.response.data?.message) });
    } else {
      setSubmitErrors({ title: errorTitle, messages: [GENERIC_REQUEST_ERROR_MESSAGE] });
    }
  };

  const handleErrorDialogClose = () => {
    setSubmitErrors(null);
  };

  if (isBookingWithSessionReportLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (bookingWithSessionReportError) {
    return <ErrorAlert message="Session report details failed to load" />;
  }

  if (!bookingWithSessionReportData) {
    return null;
  }

  if (activeStepIndex > 0 && !isStarted) {
    return (
      <Redirect
        to={generatePath(ROUTES.SESSION_REPORT_DETAILS, {
          bookingId,
          activeStep: SessionReportStepsEnum.GOALS,
          returnTo,
        })}
      />
    );
  }

  const sessionPlace = bookingWithSessionReportData.session.session_location.place;
  const sessionLocationType = bookingWithSessionReportData.session.session_location.location_type;
  const sessionMobileLocation = bookingWithSessionReportData.session.session_location.mobile_location;
  const isBookingMarkedAsCompleted = bookingWithSessionReportData.outcome === BookingOutcomeEnum.COMPLETED;
  const { person } = bookingWithSessionReportData.pwad_profile;
  const pwadProfileId = bookingWithSessionReportData.pwad_profile.id;
  const pwadProfileUrl = generatePath(ROUTES.FP_VIEW_PWAD_PROFILE, { pwadProfileId });

  function renderSessionReportStep() {
    switch (activeStep) {
      case SessionReportStepsEnum.GOALS:
        return (
          <SessionReportGoals
            sessionReportId={sessionReportId}
            bookingId={bookingId}
            pwadProfileId={pwadProfileId}
            refetchBookingWithSessionReport={refetchBookingWithSessionReport}
            onFormSubmitting={handleFormSubmitting}
            onError={handleError}
            onSuccess={handleSuccess}
          />
        );

      case SessionReportStepsEnum.ACHIEVEMENTS:
        return (
          <SessionReportAchievements
            sessionReportId={sessionReportId!}
            isEditMode={isEditMode}
            onFormLoading={handleFormLoading}
            onFormSubmitting={handleFormSubmitting}
            onError={handleError}
            onSuccess={handleSuccess}
          />
        );

      case SessionReportStepsEnum.ADDITIONAL_NOTES:
        return (
          <SessionReportAdditionalNotes
            sessionReportId={sessionReportId!}
            isEditMode={isEditMode}
            onFormLoading={handleFormLoading}
            onFormSubmitting={handleFormSubmitting}
            onError={handleError}
            onSuccess={handleSuccess}
          />
        );
      case SessionReportStepsEnum.INCIDENT_REPORT:
        return (
          <SessionReportIncidentReport
            sessionReportId={sessionReportId!}
            isEditMode={isEditMode}
            onFormLoading={handleFormLoading}
            onFormSubmitting={handleFormSubmitting}
            onError={handleError}
            onSuccess={handleSuccess}
          />
        );
      case SessionReportStepsEnum.SUBMIT:
        return (
          <SessionReportSubmit
            sessionReportId={sessionReportId!}
            onFormSubmitting={handleFormSubmitting}
            onError={handleError}
            onSuccess={handleSuccess}
          />
        );
      default:
        return <ErrorAlert message="Invalid step" description="Please use links to navigate the session report." />;
    }
  }

  return (
    <>
      <Grid container p={2}>
        <Grid item xs={12}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Box
                component="span"
                display="flex"
                alignItems="center"
                onClick={handleExitClick}
                color="link.main"
                width="min-content"
                sx={{
                  cursor: "pointer",
                }}
              >
                <ChevronLeftIcon sx={{ width: 30, height: 30 }} />
                <Typography sx={{ fontSize: "18px", fontWeight: 600 }}>Exit</Typography>
              </Box>
            </Grid>

            <Grid item xs={12} display="flex" justifyContent={{ xs: "center", lg: "flex-start" }}>
              <Avatar src={getAvatarUrl(person.avatar)} sx={{ width: 142, height: 142 }} />
            </Grid>

            <Grid item xs={12} lg={6}>
              <Typography variant="h1">
                Session{" "}
                <Typography display="inline" variant="h1" component="span" color="link.main">
                  Report.
                </Typography>
              </Typography>
            </Grid>

            <Grid item xs={12} lg={6}>
              <SessionReportActions
                isBookingMarkedAsCompleted={isBookingMarkedAsCompleted}
                isStarted={isStarted}
                isCompleted={isCompleted}
                isEditMode={isEditMode}
                activeStep={activeStep}
                canEdit={canEdit}
                onEditClick={handleEditClick}
                onPreviousClick={goPrevStep}
                isSubmitPending={isFormLoading || isFormSubmitting}
              />
            </Grid>

            <Grid item xs={12}>
              <Box display="flex" alignItems="center" gap={1}>
                <Typography variant="h3">Client: </Typography>
                <Typography>
                  <Link component={RouterLink} to={pwadProfileUrl} target="_blank" color="link.main" variant="link1">
                    {getFullName(person)}
                  </Link>
                </Typography>
              </Box>

              <Box display="flex" alignItems="center" gap={1}>
                <Typography variant="h3">Session: </Typography>
                <Typography>
                  {formatDateTimeStr(
                    `${bookingWithSessionReportData.session.date} ${bookingWithSessionReportData.session.start_time}`,
                    "eeee MMMM dd, yyyy, h:mmaaa",
                  )}
                </Typography>
              </Box>

              <Box display="flex" alignItems="center" gap={1}>
                <Typography variant="h3">Location: </Typography>
                <SessionLocation
                  place={sessionPlace}
                  locationType={sessionLocationType}
                  mobileLocation={sessionMobileLocation}
                  showFixedLocationTitle={false}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>

        {!isBookingMarkedAsCompleted && !isStarted && (
          <Grid item xs={12} my={4}>
            <Alert severity="warning" variant="filled">
              <AlertTitle>Booking outcome not yet confirmed</AlertTitle>
              This session report can only be started if the associated booking has been marked as completed. Please
              refer to your &quot;Confirm WeFlex booking outcome&quot; email you have been sent, and confirm the booking
              outcome.
            </Alert>
          </Grid>
        )}

        <Grid item xs={12} my={4}>
          <Divider />
        </Grid>

        <Grid container display="flex" flexDirection="row-reverse" spacing={3}>
          <Grid item xs={12} lg={6}>
            <Box display="flex" flex={0} justifyContent={{ sx: "center", lg: "flex-end" }}>
              {mobileMode ? (
                <SessionReportStepsMobile
                  activeStepIndex={activeStepIndex}
                  bookingId={bookingId}
                  isCompleted={isCompleted}
                  returnTo={returnTo}
                />
              ) : (
                <SessionReportStepsDesktop
                  activeStepIndex={activeStepIndex}
                  bookingId={bookingId}
                  isCompleted={isCompleted}
                  returnTo={returnTo}
                />
              )}
            </Box>
          </Grid>

          <Grid item xs={12} lg={6} mb={{ xs: 6, lg: 0 }}>
            {renderSessionReportStep()}
          </Grid>
        </Grid>
      </Grid>

      <SnackbarAlert
        content="Saved"
        severity="success"
        open={openSavedConfirmation}
        onClose={() => setOpenSavedConfirmation(false)}
      />

      <SessionReportExitWarning
        open={openExitWarning}
        onOk={handleExitWarningOk}
        onClose={() => setOpenExitWarning(false)}
      />

      <SessionReportSubmittedConfirmation open={openSubmittedConfirmation} onClose={handleSubmittedConfirmationClose} />

      {!isNil(submitErrors) && (
        <ErrorDialog title={submitErrors.title} messages={submitErrors.messages} onClose={handleErrorDialogClose} />
      )}
    </>
  );
}
