import React from "react";
import InsightsIcon from "@mui/icons-material/Insights";
import PersonIcon from "@mui/icons-material/Person";
import { Box, CircularProgress, Grid, GridSize, Link, Typography } from "@mui/material";
import { useQuery } from "react-query";
import { generatePath, Link as RouterLink, useParams } from "react-router-dom";
import { ErrorResponse } from "../../../../api/Generic";
import * as ROUTES from "../../../../constants/routes";
import { UserStatisticsModel } from "../../../../models/Statistics";
import ErrorAlert from "../../../common/ErrorAlert";
import StatisticsProfileCard from "../../../common/StatisticsProfileCard";

export default function Statistics(): JSX.Element | null {
  const { profileId } = useParams<{ profileId: string }>();

  const {
    data: statisticsData,
    error: statisticsError,
    isLoading: statisticsLoading,
  } = useQuery<UserStatisticsModel, ErrorResponse>(`/statistics/pwad/${profileId}`);

  if (statisticsLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (statisticsError) {
    return <ErrorAlert message="Statistics failed to load" />;
  }

  if (!statisticsData) {
    return null;
  }

  let sizeProps: any;

  if (statisticsData.booking.statistics_per_person.length === 1) {
    // Use a maximum of 2 columns if we will only have two cards to show.
    sizeProps = { md: 6 as GridSize, xs: 12 as GridSize };
  } else {
    sizeProps = { xl: 4 as GridSize, md: 6 as GridSize, xs: 12 as GridSize };
  }

  return (
    <Grid container spacing={2} py={2}>
      <Grid item xs={12}>
        <Typography variant="h1" mb={3}>
          <Typography display="inline" variant="h1" component="span" color="link.main">
            Statistics.
          </Typography>
        </Typography>
      </Grid>

      <Grid item {...sizeProps}>
        <StatisticsProfileCard
          bookingStatisticsValues={statisticsData.booking.totals}
          title="All Fitness Professionals"
          Icon={InsightsIcon}
        />
      </Grid>

      {statisticsData.booking.statistics_per_person.map((statisticsForPerson) => {
        const fpProfileId = statisticsForPerson.profile.id;
        const fpName = statisticsForPerson.profile.full_name;
        const fpProfileUrl = generatePath(ROUTES.FP_PROFILE, { profileId: fpProfileId });

        return (
          <Grid item key={fpProfileId} {...sizeProps}>
            <StatisticsProfileCard
              bookingStatisticsValues={statisticsForPerson.statistics}
              title={
                <Link color="link.main" component={RouterLink} to={fpProfileUrl}>
                  {fpName}
                </Link>
              }
              Icon={PersonIcon}
            />
          </Grid>
        );
      })}
    </Grid>
  );
}
