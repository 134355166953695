import { useEffect, useMemo } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import * as Sentry from "@sentry/browser";
import { isEmpty } from "lodash";
import { useForm, FormProvider } from "react-hook-form";
import { useQuery } from "react-query";
import SessionReportNoGoalsAlert from "./SessionReportNoGoalsAlert";
import { ErrorResponse } from "../../api/Generic";
import { useUpdateSessionReportAchievements } from "../../api/mutations/SessionReport";
import FormTextField from "../../form/FormTextField";
import { SessionReportAchievementModel } from "../../models/Booking";
import { PWADGoalModel } from "../../models/PWADProfile";
import { useGetGoalsFromGoalAchievements } from "../../utils/goal";
import ColumnBox from "../common/ColumnBox";
import ErrorAlert from "../common/ErrorAlert";
import OptionalValue from "../common/OptionalValue";

interface AchievementsFormInputs {
  achievements: { [key in string]: string };
}

interface Props {
  sessionReportId: string;
  isEditMode: boolean;
  onFormSubmitting(isSubmitting: boolean): void;
  onFormLoading(isLoading: boolean): void;
  onSuccess(showSavedConfirmation: boolean): void;
  onError(error: unknown): void;
}

export default function SessionReportAchievements({
  sessionReportId,
  isEditMode,
  onFormLoading,
  onFormSubmitting,
  onSuccess,
  onError,
}: Props) {
  const formMethods = useForm<AchievementsFormInputs>();

  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = formMethods;

  const {
    data: achievementsData,
    isLoading: achievementsLoading,
    error: achievementsError,
    refetch: refetchAchievements,
  } = useQuery<SessionReportAchievementModel[], ErrorResponse>(
    `/session-report/achievements/?session_report=${sessionReportId}`,
  );

  const { fitnessGoals, ndisGoals } = useGetGoalsFromGoalAchievements(achievementsData);

  const { mutateAsync: updateSessionReportAchievements } = useUpdateSessionReportAchievements();

  const hasNoGoals = fitnessGoals.length === 0 && ndisGoals.length === 0;

  useEffect(() => {
    onFormLoading(achievementsLoading);
  }, [achievementsLoading]);

  useEffect(() => {
    if (!isEmpty(achievementsData)) {
      const achievements = achievementsData!.reduce((result: { [key in string]: string }, { goal, content }) => {
        // eslint-disable-next-line no-param-reassign
        result[goal.id] = content;

        return result;
      }, {});

      reset({ achievements });
    }
  }, [achievementsData, reset]);

  const onSubmit = async (values: AchievementsFormInputs) => {
    try {
      if (isDirty) {
        onFormSubmitting(true);

        await updateSessionReportAchievements({
          sessionReportId,
          achievements: achievementsData!.map(({ id, goal }) => ({
            id,
            content: values.achievements[goal.id] ?? "",
          })),
        });

        await refetchAchievements();
      }

      onSuccess(isDirty);
    } catch (error) {
      Sentry.captureException(error);
      onError(error);
    } finally {
      onFormSubmitting(false);
    }
  };

  if (achievementsLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (achievementsError) {
    return <ErrorAlert message="Session report details failed to load" />;
  }

  return (
    <FormProvider {...formMethods}>
      <form id="session-report-detail-form" onSubmit={handleSubmit(onSubmit)}>
        <ColumnBox>
          <SessionReportNoGoalsAlert show={hasNoGoals} />

          <Typography variant="h2">Achievements</Typography>

          <Typography variant="h3" mt={2} sx={{ fontSize: "18px", lineHeight: "21px" }}>
            What you did in this session to focus on each fitness goal.
          </Typography>

          {!fitnessGoals.length && <Typography mt={2}>No fitness goals.</Typography>}

          {!!fitnessGoals.length && (
            <ColumnBox mt={3} gap={3}>
              {fitnessGoals.map((goal, index) => (
                <ColumnBox key={goal.id} gap={2}>
                  <Typography variant="h3">{`Fitness Goal ${index + 1}: ${goal.description}`}</Typography>
                  <FormTextField
                    name={`achievements.${goal.id}`}
                    placeholder="What you did in this session."
                    multiline
                    minRows={4}
                    InputProps={{ sx: { bgcolor: "white" } }}
                    readOnly={!isEditMode}
                  />
                </ColumnBox>
              ))}
            </ColumnBox>
          )}

          <Typography variant="h3" mt={4} sx={{ fontSize: "18px", lineHeight: "21px" }}>
            What you did in this session to focus on each NDIS goal.
          </Typography>

          {!ndisGoals.length && <Typography mt={2}>No NDIS goals.</Typography>}

          {!!ndisGoals.length && (
            <ColumnBox mt={3} gap={3}>
              {ndisGoals.map((goal, index) => (
                <ColumnBox key={goal.id} gap={2}>
                  <Typography variant="h3">{`NDIS Goal ${index + 1}: ${goal.description}`}</Typography>
                  <FormTextField
                    name={`achievements.${goal.id}`}
                    placeholder="What you did in this session."
                    multiline
                    minRows={4}
                    InputProps={{ sx: { bgcolor: "white" } }}
                    readOnly={!isEditMode}
                  />
                </ColumnBox>
              ))}
            </ColumnBox>
          )}
        </ColumnBox>
      </form>
    </FormProvider>
  );
}
