import React from "react";
import { Typography } from "@mui/material";

export default function PWADServicesConsentContent(): JSX.Element {
  return (
    <>
      <Typography variant="body1">
        I consent to the provision of services by WeFlex Pty Ltd (ABN: 44 644 941 873).
      </Typography>

      <Typography variant="body1" mt={1}>
        These services include the provision of supports through Fee for Service or NDIS Funded Plans, such as Health &
        Wellbeing, Social & Community Participation, Skill Development, and/or Therapeutic Services.
      </Typography>

      <Typography variant="body1" mt={1}>
        I have been advised about support options available and have had choice and control in selecting my supports.
      </Typography>

      <Typography variant="body1" mt={1}>
        I consent to WeFlex collecting information about me for the purposes of providing supports. I am aware of the
        type of information recorded and that I have the right to access this information at a time suitable to both
        myself and WeFlex.
      </Typography>

      <Typography variant="body1" mt={1}>
        I understand that any information I give will be treated by WeFlex (and its representatives) as confidential and
        my information will only be shared with those who require the information to carry out their duties in my
        supports.
      </Typography>

      <Typography variant="body1" mt={1}>
        I consent to WeFlex exchanging and releasing relevant information to other agencies, individual practitioners,
        and Disability Support Services, only in response to my needs. Where possible this will be discussed with me
        prior to the information exchange occurring.
      </Typography>

      <Typography variant="body1" mt={1}>
        I understand that a record of my information may be kept with WeFlex (and its representatives). Files/Data will
        be stored on a password protected system and/or locked location.
      </Typography>

      <Typography variant="body1" mt={1}>
        I understand that independent auditors may view my information as part of WeFlex adherence to National
        Government Standards.
      </Typography>

      <Typography variant="body1" mt={1}>
        I understand and consent to WeFlex seeking medical/dental treatment for me in the event of an emergency.
      </Typography>

      <Typography variant="body1" mt={1}>
        I understand that I may limit the information recorded about me and who this information is provided to.
      </Typography>
    </>
  );
}
