import { ActualFileObject } from "filepond";
import heic2any from "heic2any";
import { endsWith, replace, toLower } from "lodash";
import {
  EXCEL_DOCUMENT_MIME_TYPES,
  IMAGE_MIME_TYPES,
  PDF_MIME_TYPES,
  WORD_DOCUMENT_MIME_TYPES,
} from "../constants/mimeTypes";

export const DEFAULT_ALLOWED_MIME_TYPES = [
  ...IMAGE_MIME_TYPES,
  ...WORD_DOCUMENT_MIME_TYPES,
  ...EXCEL_DOCUMENT_MIME_TYPES,
  ...PDF_MIME_TYPES,
];

export const DEFAULT_ALLOWED_MIME_TYPES_MESSAGE = "Allowed file types: images, documents, spreadsheets, and PDFs";

export const MAX_FILE_SIZE_MEGABYTES = 20;

export interface InitiateFileUploadParameters {
  file_name: string;
  content_type: string;
  person: string;
}

export interface InitiateFileUploadResponse {
  url: string;
  key: string;
  policy: string;
  signature: string;
  aws_access_key_id: string;
}

export function isFileHeicFromExtension(fileName: string) {
  return endsWith(toLower(fileName), ".heic");
}

export function handleValidateFileType(file: File, type: string) {
  return new Promise<string>((resolve, reject) => {
    if (isFileHeicFromExtension(file.name)) {
      resolve("image/heic");
    } else {
      resolve(type);
    }
  });
}

export function handleRenameFile(options: { basename: string; extension: string; name: string }) {
  // Always convert extension to be lowercase.
  let extensionParsed = toLower(options.extension);

  if (extensionParsed === ".jpeg") {
    // Replace ".jpeg" extension with ".jpg" for consistency.
    extensionParsed = ".jpg";
  }

  return `${options.basename}${extensionParsed}`;
}

export function getFileToUploadFromFilePondFile(filePondFile: ActualFileObject) {
  return new Promise<File | ActualFileObject>((resolve, reject) => {
    if (isFileHeicFromExtension(filePondFile.name)) {
      // If the source image is an HEIC image, we automatically convert it to a JPEG image.
      heic2any({
        blob: filePondFile.slice(0, filePondFile.size, "image/heic"),
        toType: "image/jpeg",
        // The JPEG quality value is intended to result in the converted JPEG image being a slightly smaller
        // file size than the HEIC source image.
        quality: 0.8,
      })
        .then((imageBlobConvertedFromHeic) => {
          const imageFileConvertedFromHeic = new File(
            [imageBlobConvertedFromHeic as Blob],
            replace(filePondFile.name, ".heic", ".jpg"),
            {
              type: "image/jpg",
              lastModified: filePondFile.lastModified,
            },
          );

          if (imageFileConvertedFromHeic.size > MAX_FILE_SIZE_MEGABYTES * 1000000) {
            console.log(`New image converted from HEIC source image is ${imageFileConvertedFromHeic.size} bytes.`);

            reject(
              new Error("New image converted from HEIC source image is larger than the maximum allowed file size."),
            );
          }

          resolve(imageFileConvertedFromHeic);
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      resolve(filePondFile);
    }
  });
}
