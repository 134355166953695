export type ServiceModel = {
  id: string;
  name: string;
  description: string;
};

export type SessionLocationModel = {
  id: string;
  place: string;
  location_type: SessionLocationTypeEnum;
  mobile_location: string;
  services: ServiceModel[];
};

export enum SessionLocationTypeEnum {
  FIXED = "FIXED",
  MOBILE = "MOBILE",
  VIRTUAL = "VIRTUAL",
}

export type SessionModel = {
  id: string;
  start_time: string;
  end_time: string;
};

export interface SpecialtyBaseModel {
  id: string;
  name: string;
  description: string;
}

export interface SpecialtyModel extends SpecialtyBaseModel {
  service: ServiceModel;
}

export interface AvailableSessionsModel {
  services: {
    id: string;
    name: string;
    locations: {
      id: string;
      name: string;
      place: string;
      location_type: SessionLocationTypeEnum;
      mobile_location: string;
      session: SessionModel[];
    }[];
    specialties: SpecialtyBaseModel[];
  }[];
}

export const SessionRepeatFrequencyEnum = [
  {
    value: "WEEKLY",
    label: "Weekly",
  },
  // {
  //   value: "FORNIGHTLY",
  //   label: "Fortnightly",
  // },
];
