import React from "react";
import { Box, CircularProgress, Container, Divider, Paper, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import { useQuery } from "react-query";
import InvoiceRow from "./InvoiceRow";
import { ErrorResponse } from "../../../api/Generic";
import { InvoiceListModel } from "../../../models/Invoice";
import ErrorAlert from "../../common/ErrorAlert";

function InvoiceList(): JSX.Element {
  const { data: invoicesData, isLoading, error } = useQuery<InvoiceListModel[], ErrorResponse>(`/invoice/list/`);

  if (isLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <ErrorAlert message="Invoices failed to load" />;
  }

  if (isEmpty(invoicesData)) {
    return (
      <Paper sx={{ p: 4, display: "flex", justifyContent: "center", mt: 2 }} elevation={0}>
        <Typography>You currently do not have any generated invoices.</Typography>
      </Paper>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box mt={4}>
        <Typography variant="h1">
          My{" "}
          <Typography display="inline" variant="h1" component="span" color="link.main">
            Invoices.
          </Typography>
        </Typography>

        <Typography variant="h3" sx={{ my: 3 }}>
          View the generated invoices WeFlex has created for you.
        </Typography>
      </Box>

      <Box mt={2}>
        {invoicesData?.map((invoice, index, array) => (
          <React.Fragment key={invoice.invoice_number}>
            <InvoiceRow invoice={invoice} />
            {index < array.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </Box>
    </Container>
  );
}

export default InvoiceList;
