import { useMutation } from "react-query";
import { FPProfileAboutMeEditModel } from "../../models/FPProfile";
import { PersonGenderEnum, PersonModel } from "../../models/Person";
import Axios from "../Axios";
import { ErrorResponse, ServerResponse } from "../Generic";

type InsertFPProfileParameters = {
  person: PersonModel;
  password: string;
};

type EditFPProfileAboutMeParameters = {
  person: {
    phone: string;
    dob: string;
    gender: PersonGenderEnum | "";
    nationality?: string;
    spoken_languages?: string[];
    avatar: string;
  };
  fp_profile: FPProfileAboutMeEditModel;
};

type EditFPProfileMyDocumentsParameters = {
  fp_profile: {
    primary_qualification_file: string;
    additional_qualification_file1: string;
    additional_qualification_file2: string;
    fitness_body_membership_file: string;
    professional_and_liability_insurance_file: string;
    first_aid_cpr_file: string;
    cpr_file: string;
    covid_vaccination_file: string;
    working_with_children_file: string;
  };
};

type EditFPProfileMyBusinessParameters = {
  fp_profile: {
    business_type: string;
    business_name: string;
    business_abn: string;
    business_registered_gst: boolean;
  };
};

type EditFPProfileMyServicesParameters = {
  fp_profile: {
    services: string[];
    specialties: string[];
    availability_guide: string;
  };
};

type EditFPProfilePayoutDetailsParameters = {
  fp_profile: {
    payout_details_account_holder: string;
    payout_details_bsb: string;
    payout_details_account_number: string;
  };
};

type EditFPProfileTermsParameters = {
  fp_profile: {
    waivers_consent_commitment_waiver: boolean;
    waivers_consent_media_marketing_waiver: boolean;
  };
};

type EditFPProfileTrainingParameters = {
  fp_profile: {
    ndis_orientation_module_file: string;
    ndis_worker_screen_number: string;
  };
};

export default function useInsertFPProfile() {
  return useMutation<ServerResponse, ErrorResponse, InsertFPProfileParameters>(
    ({ person, password }: InsertFPProfileParameters) =>
      Axios.post<ServerResponse, any>("/fpprofile/insert/", { input: { person, password } }),
  );
}

export function useSubmitFPProfileStatus(fp_profile_id: string) {
  return useMutation<ServerResponse, ErrorResponse>(() =>
    Axios.post<ServerResponse, any>(`/fpprofile/edit/${fp_profile_id}/submit/`, {}),
  );
}

export function useEditFPProfileAboutMe(fp_profile_id: string) {
  return useMutation<ServerResponse, ErrorResponse, EditFPProfileAboutMeParameters>(
    ({ person, fp_profile }: EditFPProfileAboutMeParameters) =>
      Axios.post<ServerResponse, any>(`/fpprofile/edit/${fp_profile_id}/about-me/`, {
        input: { person_data: person, fp_profile_data: fp_profile },
      }),
  );
}

export function useEditFPProfileMyDocuments(fp_profile_id: string) {
  return useMutation<ServerResponse, ErrorResponse, EditFPProfileMyDocumentsParameters>(
    ({ fp_profile }: EditFPProfileMyDocumentsParameters) =>
      Axios.post<ServerResponse, any>(`/fpprofile/edit/${fp_profile_id}/my-documents/`, {
        input: { fp_profile_data: fp_profile },
      }),
  );
}

export function useEditFPProfileMyBusiness(fp_profile_id: string) {
  return useMutation<ServerResponse, ErrorResponse, EditFPProfileMyBusinessParameters>(
    ({ fp_profile }: EditFPProfileMyBusinessParameters) =>
      Axios.post<ServerResponse, any>(`/fpprofile/edit/${fp_profile_id}/my-business/`, {
        input: { fp_profile_data: fp_profile },
      }),
  );
}

export function useEditFPProfileMyServices(fp_profile_id: string) {
  return useMutation<ServerResponse, ErrorResponse, EditFPProfileMyServicesParameters>(
    ({ fp_profile }: EditFPProfileMyServicesParameters) =>
      Axios.post<ServerResponse, any>(`/fpprofile/edit/${fp_profile_id}/my-services/`, {
        input: { fp_profile_data: fp_profile },
      }),
  );
}

export function useEditFPProfilePayoutDetails(fp_profile_id: string) {
  return useMutation<ServerResponse, ErrorResponse, EditFPProfilePayoutDetailsParameters>(
    ({ fp_profile }: EditFPProfilePayoutDetailsParameters) =>
      Axios.post<ServerResponse, any>(`/fpprofile/edit/${fp_profile_id}/payout-details/`, {
        input: { fp_profile_data: fp_profile },
      }),
  );
}

export function useEditFPProfileTerms(fp_profile_id: string) {
  return useMutation<ServerResponse, ErrorResponse, EditFPProfileTermsParameters>(
    ({ fp_profile }: EditFPProfileTermsParameters) =>
      Axios.post<ServerResponse, any>(`/fpprofile/edit/${fp_profile_id}/terms/`, {
        input: { fp_profile_data: fp_profile },
      }),
  );
}

export function useEditFPProfileTraining(fp_profile_id: string) {
  return useMutation<ServerResponse, ErrorResponse, EditFPProfileTrainingParameters>(
    ({ fp_profile }: EditFPProfileTrainingParameters) =>
      Axios.post<ServerResponse, any>(`/fpprofile/edit/${fp_profile_id}/training/`, {
        input: { fp_profile_data: fp_profile },
      }),
  );
}
