import { isArray } from "lodash";
import { GENERIC_REQUEST_ERROR_MESSAGE } from "../constants/validation";

export default function getErrorMessages(serializedMessage?: string): string[] {
  if (!serializedMessage) {
    return [GENERIC_REQUEST_ERROR_MESSAGE];
  }

  const errorsObject = JSON.parse(serializedMessage);

  if (!isArray(errorsObject?.errors)) {
    return [GENERIC_REQUEST_ERROR_MESSAGE];
  }

  return errorsObject.errors.map(({ message }: any) => message);
}
