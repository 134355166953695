import { useMemo, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { DesktopDatePicker, LoadingButton, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Box, Button, Container, Divider, FormHelperText, Grid, TextField, Typography } from "@mui/material";
import * as Sentry from "@sentry/browser";
import { format } from "date-fns";
import { isEmpty, isNil, map } from "lodash";
import { FormProvider, useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import * as yup from "yup";
import FPCard from "./FPCard";
import RequestSessionCancelDialog from "./RequestSessionCancelDialog";
import ConfirmationModal from "./RequestSessionConfirmationDialog";
import RequestSessionForm from "./RequestSessionForm";
import { isErrorResponse } from "../../../api/Generic";
import useRequestSession from "../../../api/mutations/SessionRequest";
import * as ROUTES from "../../../constants/routes";
import { GENERIC_REQUEST_ERROR_MESSAGE } from "../../../constants/validation";
import { SessionRepeatFrequencyEnum } from "../../../models/Session";
import { formatISODate } from "../../../utils/formatDateTime";
import getErrorMessages from "../../../utils/getErrorMessages";
import PickersDay from "../../calendar/PickersDay";
import ErrorDialog from "../../common/ErrorDialog";

interface RequestSessionFormInputs {
  service: string;
  specialty: string;
  session: string;
  isRepeated: boolean;
  repeatFrequency: string;
  repeatCount: number;
  tandc: boolean;
}

interface YearMonthModel {
  year: string;
  month: string;
}

const RequestSessionFormSchema = yup.object({
  tandc: yup.bool().oneOf([true], "You must agree the Terms and Conditions."),
  isRepeated: yup.bool(),
  repeatFrequency: yup
    .string()
    .oneOf(map(SessionRepeatFrequencyEnum, "value") as string[], "You must select a frequency."),
  repeatCount: yup.number().min(1).max(12).required("You must select a number."),
});

/**
 * TODO: Update component to use LocalizationProvider and AdapterDateFns from `@mui/x-date-pickers`.
 */
export default function RequestSession() {
  const { fpId } = useParams<{ fpId: string }>();
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [submitErrors, setSubmitErrors] = useState<string[] | null>(null);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openCancelWarning, setOpenCancelWarning] = useState(false);
  const [openCalendar, setOpenCalendar] = useState(false);

  const { mutate: requestSession, isLoading: requestSessionLoading } = useRequestSession();
  const history = useHistory();
  const now = Date.now();

  const [currentYearMonth, setCurrentYearMonth] = useState<YearMonthModel>({
    year: format(now, "yyyy"),
    month: format(now, "MM"),
  });

  const { data: availableSessions, isLoading: isAvailableSessionsLoading } = useQuery<{
    sessions_available: { day: string; count: number }[];
  }>(
    `/fpprofile/get-sessions-available-by-month/?fpprofile=${fpId}&month=${currentYearMonth.month}&year=${currentYearMonth.year}`,
  );

  const GO_BACK_URL = ROUTES.PWAD_MAKE_A_BOOKING;

  const methods = useForm<RequestSessionFormInputs>({
    mode: "onTouched",
    resolver: yupResolver(RequestSessionFormSchema),
    criteriaMode: "firstError",
    defaultValues: {
      service: "",
      specialty: "",
      session: "",
      isRepeated: false,
      repeatFrequency: SessionRepeatFrequencyEnum[0].value as string,
      repeatCount: 1,
      tandc: false,
    },
  });

  const tandc = methods.watch("tandc");

  const availableDates = useMemo(
    () => availableSessions?.sessions_available?.map(({ day }) => day) ?? [],
    [availableSessions],
  );

  const isValid = tandc; // is true

  const setNewCurrentYearMonth = (newValue: YearMonthModel) => {
    setCurrentYearMonth((prevState) => {
      if (prevState.year === newValue.year && prevState.month === newValue.month) {
        return prevState;
      }
      return newValue;
    });
  };

  const handleChange = (newValue: any) => {
    setSelectedDate(newValue);
  };

  const onSubmit = ({ specialty, session, isRepeated, repeatFrequency, repeatCount }: any): void => {
    requestSession(
      { specialty, session, isRepeated, repeatFrequency, repeatCount },
      {
        onError: (error) => {
          Sentry.captureException(error);

          if (isErrorResponse(error)) {
            setSubmitErrors(getErrorMessages(error.response.data?.message));
          } else {
            setSubmitErrors([GENERIC_REQUEST_ERROR_MESSAGE]);
          }
        },
        onSuccess: () => {
          setOpenConfirmation(true);
        },
      },
    );
  };

  const handleConfirmationClose = () => {
    setOpenConfirmation(false);
    history.push(GO_BACK_URL);
  };

  const handleCancelOk = () => {
    setOpenCancelWarning(false);
    history.push(GO_BACK_URL);
  };

  const handleCancelClose = () => {
    setOpenCancelWarning(false);
  };

  const handleCancel = () => {
    if (isValid) {
      setOpenCancelWarning(true);
    } else {
      history.push(GO_BACK_URL);
    }
  };

  const handleCalendarOpen = () => {
    if (!isNil(selectedDate)) {
      setNewCurrentYearMonth({ year: format(selectedDate, "yyyy"), month: format(selectedDate, "MM") });
    } else {
      setNewCurrentYearMonth({ year: format(now, "yyyy"), month: format(now, "MM") });
    }

    setOpenCalendar(true);
  };

  const handleCalendarClose = () => {
    setOpenCalendar(false);
  };

  const handleMonthChange = (date: any) => {
    setNewCurrentYearMonth({ year: format(date, "yyyy"), month: format(date, "MM") });
  };

  const handleErrorClose = () => {
    setSubmitErrors(null);
  };

  return (
    <Container maxWidth="lg">
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid container spacing={{ lg: 2 }} justifyContent="space-between" pt={4}>
          <Grid item lg={6} xs={12}>
            <Typography variant="h1">
              Request a{" "}
              <Typography display="inline" variant="h1" component="span" color="link.main">
                Booking.
              </Typography>
            </Typography>

            <Box mt={5}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Typography variant="body1" fontWeight="bold" mb={2}>
                  Choose a date
                </Typography>

                <DesktopDatePicker
                  open={openCalendar}
                  onClose={handleCalendarClose}
                  loading={isAvailableSessionsLoading}
                  mask=""
                  value={selectedDate}
                  disablePast
                  inputFormat="eeee MMMM d"
                  onChange={handleChange}
                  onMonthChange={handleMonthChange}
                  renderDay={(day, _selectedDates, pickersDayProps) => (
                    <PickersDay
                      {...pickersDayProps}
                      sx={{
                        "&.Mui-disabled": {
                          textDecoration: "line-through",
                          backgroundColor: "transparent !important",
                        },
                        "&.MuiPickersDay-root": {
                          backgroundColor: "#D2EBD3",
                        },
                      }}
                      disabled={!availableDates.includes(formatISODate(day as Date))}
                    />
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Date"
                      inputProps={{
                        ...params.inputProps,
                        readOnly: true,
                      }}
                      required
                      onClick={handleCalendarOpen}
                    />
                  )}
                />

                <FormHelperText>Only days with available sessions can be selected</FormHelperText>

                <FormProvider {...methods}>
                  {!isNil(selectedDate) ? (
                    <RequestSessionForm fpId={fpId} date={format(selectedDate!, "yyyy-MM-dd")} />
                  ) : null}
                </FormProvider>
              </LocalizationProvider>
            </Box>
          </Grid>

          <Grid item lg={6} xs={12} sx={{ display: { xs: "none", lg: "block" } }}>
            <FPCard fpId={fpId} />
          </Grid>
        </Grid>

        <Box my={5}>
          <Divider />
        </Box>

        <Box display="flex" justifyContent={{ xs: "center", lg: "flex-end" }} gap={2} my={{ xs: 12, lg: 3 }}>
          <Button size="large" variant="outlined" color="primary" sx={{ minWidth: 150 }} onClick={handleCancel}>
            Cancel
          </Button>

          <LoadingButton
            type="submit"
            size="large"
            variant="contained"
            loading={requestSessionLoading}
            disabled={!isValid}
            sx={{ minWidth: 150 }}
          >
            Request
          </LoadingButton>
        </Box>
      </form>
      <ConfirmationModal open={openConfirmation} onClose={handleConfirmationClose} />
      <RequestSessionCancelDialog open={openCancelWarning} onOk={handleCancelOk} onClose={handleCancelClose} />
      {!isEmpty(submitErrors) ? (
        <ErrorDialog title="Failed to request the booking" messages={submitErrors!} onClose={handleErrorClose} />
      ) : null}
    </Container>
  );
}
