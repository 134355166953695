import { Box, Container, Typography } from "@mui/material";
import { Redirect, Route, Switch } from "react-router-dom";
import SessionList from "./SessionList";
import * as ROUTES from "../../../constants/routes";
import NavTabs from "../../common/NavTabs";

const navTabItems = [
  {
    label: "To Do",
    path: ROUTES.FP_MY_SESSION_REPORTS_TO_DO,
  },
  {
    label: "Completed",
    path: ROUTES.FP_MY_SESSION_REPORTS_COMPLETED,
  },
];

export default function MySessionReports() {
  return (
    <Container maxWidth="lg">
      <Box mt={4}>
        <Typography variant="h1">
          My Session{" "}
          <Typography display="inline" variant="h1" component="span" color="link.main">
            Reports.
          </Typography>
        </Typography>

        <Typography variant="h3" sx={{ my: 3 }}>
          Complete and view your WeFlex client session reports here. You will have access to these reports for 12
          months.
        </Typography>
      </Box>

      <Box mt={5}>
        <NavTabs items={navTabItems} />

        <Switch>
          <Route
            exact
            path={ROUTES.FP_MY_SESSION_REPORTS_TO_DO}
            render={() => <SessionList showCompletedReports={false} />}
          />
          <Route
            exact
            path={ROUTES.FP_MY_SESSION_REPORTS_COMPLETED}
            render={() => <SessionList showCompletedReports />}
          />

          <Redirect to={ROUTES.FP_MY_SESSION_REPORTS_TO_DO} />
        </Switch>
      </Box>
    </Container>
  );
}
