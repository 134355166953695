import { useMemo } from "react";
import SchoolIcon from "@mui/icons-material/School";
import { sortBy } from "lodash";
import { CertificationModel, FPProfileModel } from "../../models/FPProfile";
import CheckIconList from "../common/CheckIconList";
import ProfileCard from "../common/ProfileCard";

interface Props {
  myQualificationsData?: FPProfileModel;
}

export default function FPCertifications({ myQualificationsData }: Props) {
  const certifications = useMemo<CertificationModel[]>(
    () => sortBy(myQualificationsData?.certifications, "name"),
    [myQualificationsData],
  );

  return (
    <ProfileCard title="Certifications" Icon={SchoolIcon}>
      <CheckIconList showDivider list={certifications} />
    </ProfileCard>
  );
}
