import React, { ReactNode } from "react";
import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ColumnBox from "./ColumnBox";
import ProfileCard from "./ProfileCard";
import { BookingOutcomeLabel, BookingStatusLabel } from "../../models/Booking";
import { BookingStatisticsValuesModel } from "../../models/Statistics";

interface Props {
  bookingStatisticsValues: BookingStatisticsValuesModel;
  title: string | ReactNode;
  Icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.text.secondary,
    color: theme.palette.common.white,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // Hide the last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function StatisticsProfileCard({ bookingStatisticsValues, title, Icon }: Props): JSX.Element | null {
  const renderTable = (statistic: string, rows: Array<{ name: string; count: number }>) => {
    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>{statistic}</StyledTableCell>
              <StyledTableCell align="right">Count</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <StyledTableRow key={row.name}>
                <StyledTableCell component="th" scope="row">
                  {row.name}
                </StyledTableCell>
                <StyledTableCell align="right">{row.count}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const totalRows = [
    {
      name: "Bookings",
      count: bookingStatisticsValues.total_bookings_count,
    },
    {
      name: "Session Reports",
      count: bookingStatisticsValues.total_session_reports_count,
    },
  ];

  const statusRows = [
    {
      name: BookingStatusLabel.PENDING,
      count: bookingStatisticsValues.status_pending_count,
    },
    {
      name: BookingStatusLabel.ACCEPTED,
      count: bookingStatisticsValues.status_accepted_count,
    },
    {
      name: BookingStatusLabel.DECLINED,
      count: bookingStatisticsValues.status_declined_count,
    },
    {
      name: BookingStatusLabel.CANCELED,
      count: bookingStatisticsValues.status_cancelled_count,
    },
  ];

  const outcomeRows = [
    {
      name: "Not Set",
      count: bookingStatisticsValues.outcome_not_set_count,
    },
    {
      name: BookingOutcomeLabel.COMPLETED,
      count: bookingStatisticsValues.outcome_completed_count,
    },
    {
      name: BookingOutcomeLabel.RESCHEDULED,
      count: bookingStatisticsValues.outcome_rescheduled_count,
    },
    {
      name: BookingOutcomeLabel.CANCELLED_WITH_NOTICE,
      count: bookingStatisticsValues.outcome_cancelled_with_notice_count,
    },
    {
      name: BookingOutcomeLabel.CANCELLED_SHORT_NOTICE,
      count: bookingStatisticsValues.outcome_cancelled_short_notice_count,
    },
  ];

  return (
    <ProfileCard title={title} Icon={Icon}>
      <ColumnBox gap={3}>
        {renderTable("Total", totalRows)}

        {renderTable("Status", statusRows)}

        {renderTable("Outcome", outcomeRows)}
      </ColumnBox>
    </ProfileCard>
  );
}
