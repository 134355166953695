import { PersonModel } from "./Person";
import { PWADGoalModel } from "./PWADProfile";
import { SessionLocationTypeEnum } from "./Session";

export enum BookingStatusEnum {
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  DECLINED = "DECLINED",
  CANCELED = "CANCELED",
}

export const BookingStatusLabel: { [key in BookingStatusEnum]: string } = {
  PENDING: "Pending",
  ACCEPTED: "Accepted",
  DECLINED: "Declined",
  CANCELED: "Cancelled",
};

export enum BookingOutcomeEnum {
  COMPLETED = "COMPLETED",
  RESCHEDULED = "RESCHEDULED",
  CANCELLED_WITH_NOTICE = "CANCELLED_WITH_NOTICE",
  CANCELLED_SHORT_NOTICE = "CANCELLED_SHORT_NOTICE",
  CANCELLED_BY_FP = "CANCELLED_BY_FP",
}

export const BookingOutcomeLabel: { [key in BookingOutcomeEnum]: string } = {
  COMPLETED: "Completed",
  RESCHEDULED: "Rescheduled",
  CANCELLED_WITH_NOTICE: "Cancelled - With Notice",
  CANCELLED_SHORT_NOTICE: "Cancelled - Short Notice",
  CANCELLED_BY_FP: "Cancelled - By Fitness Professional",
};

export interface BookingModel {
  id: string;
  status: BookingStatusEnum;
  outcome: BookingOutcomeEnum | "";
  pwad_profile: {
    id: string;
    person: PersonModel;
  };
  session: {
    date: string;
    start_time: string;
    fp_profile: {
      id: string;
      person: PersonModel;
    };
    session_location: {
      place: string;
      location_type: SessionLocationTypeEnum;
      mobile_location: string;
    };
  };
}

export enum SessionReportStatusEnum {
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
}

export const SessionReportStatusLabel: { [key in SessionReportStatusEnum]: string } = {
  IN_PROGRESS: "Started",
  COMPLETED: "Completed",
};

interface SessionReportBaseModel {
  id: string;
  status: SessionReportStatusEnum;
}

export interface BookingWithSessionReportModel {
  id: string;
  status: BookingStatusEnum;
  outcome: BookingOutcomeEnum | "";
  pwad_profile: {
    id: string;
    person: PersonModel;
  };
  session: {
    date: string;
    start_time: string;
    fp_profile: {
      id: string;
      person?: PersonModel;
    };
    session_location: {
      place: string;
      location_type: SessionLocationTypeEnum;
      mobile_location: string;
    };
  };
  session_report: SessionReportBaseModel | null;
}

export interface SessionReportAchievementModel {
  id: string;
  content: string;
  goal: PWADGoalModel;
}

export interface SessionReportModel extends SessionReportBaseModel {
  additional_notes: string;
  did_incident_occur: boolean | null;
  incident_report_private_file: string | null;
}
