import { useMutation } from "react-query";
import { BookingOutcomeEnum } from "../../models/Booking";
import Axios from "../Axios";
import { ErrorResponse, ServerResponse } from "../Generic";

type BookingActionParameters = {
  booking: string;
};

type BookingSetOutcomeParameters = {
  booking: string;
  outcome: BookingOutcomeEnum;
};

type BulkBookingActionParameters = {
  updates: Record<string, string>[];
};

export function useAcceptBooking() {
  return useMutation<ServerResponse, ErrorResponse, BookingActionParameters>((input: BookingActionParameters) =>
    Axios.post<ServerResponse, any>("booking/accept/", input),
  );
}

export function useDeclineBooking() {
  return useMutation<ServerResponse, ErrorResponse, BookingActionParameters>((input: BookingActionParameters) =>
    Axios.post<ServerResponse, any>("booking/decline/", input),
  );
}

export function useCancelBooking() {
  return useMutation<ServerResponse, ErrorResponse, BookingActionParameters>((input: BookingActionParameters) =>
    Axios.post<ServerResponse, any>("booking/cancel/", input),
  );
}

export function useSetBookingOutcome() {
  return useMutation<ServerResponse, ErrorResponse, BookingSetOutcomeParameters>((input: BookingSetOutcomeParameters) =>
    Axios.post<ServerResponse, any>("booking/set-outcome/", input),
  );
}

export function useUpdateBookings() {
  return useMutation<ServerResponse, ErrorResponse, BulkBookingActionParameters>((input: BulkBookingActionParameters) =>
    Axios.post<ServerResponse, any>("booking/bulk-update/", input),
  );
}
