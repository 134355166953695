import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import { PWADProfileAboutMeModel } from "../../models/PWADProfile";
import BooleanValueDisplayBox from "../common/BooleanValueDisplayBox";
import ProfileCard from "../common/ProfileCard";
import ProfileCardDivider from "../common/ProfileCardDivider";
import ProfileCardTitleWithContent from "../common/ProfileCardTitleWithContent";

interface Props {
  aboutMeData: PWADProfileAboutMeModel;
}

export default function PWADGymExperience({ aboutMeData }: Props) {
  return (
    <ProfileCard title="Exercise Experience" Icon={FitnessCenterIcon}>
      <ProfileCardTitleWithContent
        title="Have you been to the gym before?"
        content={
          <BooleanValueDisplayBox
            value={aboutMeData.gym_experience_have_you_been_before}
            trueLabel="Yes, I have been to the gym before"
            falseLabel="No, I have not been to the gym before"
            nullLabel="I have not yet indicated whether I have been to the gym before"
            mb={4}
          />
        }
      />

      <ProfileCardTitleWithContent
        title="My experience with exercise"
        content={aboutMeData.gym_experience_with_exercise}
      />

      <ProfileCardDivider />

      <ProfileCardTitleWithContent
        title="How I feel about starting with WeFlex"
        content={aboutMeData.gym_experience_how_i_feel_about_starting_with_weflex}
      />

      <ProfileCardDivider />

      <ProfileCardTitleWithContent
        title="Other notes about my exercise experience"
        content={aboutMeData.gym_experience_what_else_you_should_know}
      />
    </ProfileCard>
  );
}
