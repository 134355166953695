import { Avatar, Box, Button, Card, CardContent, Divider, Grid, Link, Typography, useMediaQuery } from "@mui/material";
import { generatePath, Link as RouterLink, useHistory } from "react-router-dom";
import FPSessionLocations from "./FPSessionLocations";
import * as ROUTES from "../../../constants/routes";
import { FPNetworkProfileModel } from "../../../models/FPProfile";
import theme from "../../../styles/_theme";
import getAvatarUrl from "../../../utils/getAvatarUrl";
import { getFullName, useCurrentUserProfileIsLive } from "../../../utils/person";
import CheckIconList from "../../common/CheckIconList";
import ColumnBox from "../../common/ColumnBox";
import OptionalValue from "../../common/OptionalValue";

interface Props {
  fpProfile: FPNetworkProfileModel;
}

function MyNetworkFPProfile({ fpProfile }: Props) {
  const mobileMode = useMediaQuery(theme.breakpoints.down("lg"));
  const history = useHistory();
  const isProfileLive = useCurrentUserProfileIsLive();

  const renderFPName = () => {
    if (isProfileLive) {
      return (
        <Typography>
          <Link
            component={RouterLink}
            to={generatePath(ROUTES.PWAD_VIEW_FP_PROFILE, { fpId: fpProfile.id })}
            variant="link1"
            color="link.main"
          >
            {getFullName(fpProfile.person)}
          </Link>
        </Typography>
      );
    }

    return <Typography sx={{ fontWeight: "bold" }}>{getFullName(fpProfile.person)}</Typography>;
  };

  const renderFPViewProfile = () => {
    if (!isProfileLive) {
      return null;
    }

    return (
      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          fullWidth={mobileMode}
          onClick={() => {
            history.push(generatePath(ROUTES.PWAD_VIEW_FP_PROFILE, { fpId: fpProfile.id }));
          }}
        >
          View Profile
        </Button>
      </Box>
    );
  };

  const hasSessionLocations = !!fpProfile.session_locations.length;

  return (
    <Card sx={{ display: "flex", p: 2, my: 3, flexDirection: "column" }}>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Box sx={{ display: { lg: "flex", xs: "none" }, flexDirection: "column" }}>
          <Avatar src={getAvatarUrl(fpProfile.person.avatar)} sx={{ width: 200, height: 200 }} />
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
          <CardContent sx={{ py: { xs: 0, lg: 1 }, px: 2 }}>
            <Box display="flex" flexDirection="row" gap={2}>
              <Box display={{ xs: "flex", lg: "none" }} flexDirection="column">
                <Avatar src={getAvatarUrl(fpProfile.person.avatar)} sx={{ width: 60, height: 60 }} />
              </Box>

              <ColumnBox>
                <Box sx={{ mb: 2 }}>{renderFPName()}</Box>
                <Box sx={{ mb: 2 }}>
                  <Typography>
                    <OptionalValue>{fpProfile.about_me}</OptionalValue>
                  </Typography>
                </Box>
              </ColumnBox>
            </Box>

            <Divider />

            <Box sx={{ my: 2 }}>
              <Grid container rowSpacing={2} columnSpacing={1} alignItems="flex-start">
                <Grid item lg={1} xs={12}>
                  <Typography variant="h3" sx={{ lineHeight: theme.typography.body1.lineHeight }}>
                    Services
                  </Typography>
                </Grid>

                <Grid item lg={11} xs={12}>
                  <CheckIconList
                    list={fpProfile.services
                      .sort((s1, s2) => s1.name.localeCompare(s2.name))
                      .map((service) => ({
                        id: service.id,
                        name: service.name,
                      }))}
                  />
                </Grid>
              </Grid>
            </Box>

            <Divider />

            <Box sx={{ mt: 2 }}>
              <Grid container rowSpacing={2} columnSpacing={1} alignItems="flex-start">
                <Grid item lg={1} xs={12}>
                  <Typography variant="h3" sx={{ lineHeight: theme.typography.body1.lineHeight }}>
                    Locations
                  </Typography>
                </Grid>

                <Grid item lg={11} xs={12}>
                  {hasSessionLocations && <FPSessionLocations locations={fpProfile.session_locations} />}

                  {!hasSessionLocations && <Typography>No locations currently available</Typography>}
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Box>
      </Box>
      {renderFPViewProfile()}
    </Card>
  );
}

export default MyNetworkFPProfile;
