import React, { ReactNode } from "react";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Divider } from "@mui/material";
import { generatePath, useParams } from "react-router-dom";
import ENV from "../../../../constants/envConstants";
import * as ROUTES from "../../../../constants/routes";
import { BookingModel, BookingStatusEnum } from "../../../../models/Booking";
import BookingItemOutcome from "../../../common/BookingItemOutcome";
import Dot from "../../../common/Dot";
import SessionRow from "../../../common/SessionRow";

interface Props {
  groupedBookings: Record<BookingStatusEnum, BookingModel[]>;
  onAcceptBooking(sessionId: string): void;
  onDeclineBooking(sessionId: string): void;
  loading: { id: string; action: "accept" | "decline" } | null;
}

function renderStatus(status: BookingStatusEnum) {
  if (status === BookingStatusEnum.PENDING) {
    return <Dot color="warning" sx={{ mt: 1 }} />;
  }

  if (status === BookingStatusEnum.ACCEPTED) {
    return <Dot color="success" sx={{ mt: 1 }} />;
  }

  return null;
}

const BookingList = ({ groupedBookings, onAcceptBooking, onDeclineBooking, loading }: Props): JSX.Element => {
  const { status } = useParams<{ status: BookingStatusEnum }>();
  const bookings = groupedBookings[status];

  function renderActions(booking: BookingModel): ReactNode {
    switch (booking.status) {
      case BookingStatusEnum.PENDING:
        return (
          <Box
            display="flex"
            justifyContent={{ lg: "flex-start", xs: "center" }}
            width="265px"
            alignItems="center"
            gap={3}
          >
            <LoadingButton
              loading={loading?.id === booking.id && loading?.action === "decline"}
              disabled={loading?.id === booking.id}
              variant="outlined"
              onClick={() => {
                onDeclineBooking(booking.id);
              }}
            >
              Decline
            </LoadingButton>

            <LoadingButton
              loading={loading?.id === booking.id && loading?.action === "accept"}
              disabled={loading?.id === booking.id}
              variant="contained"
              onClick={() => {
                onAcceptBooking(booking.id);
              }}
            >
              Accept
            </LoadingButton>
          </Box>
        );
      default:
        return (
          <Box display="flex" flex={{ lg: 0.8, xs: 1 }} justifyContent="space-between" alignItems="center">
            {!!booking.outcome && <BookingItemOutcome outcome={booking.outcome} />}

            {!booking.outcome && <div />}

            <Button
              variant="contained"
              href={`mailto:${encodeURIComponent(booking.pwad_profile.person.email)}?cc=${encodeURIComponent(
                ENV.CC_EMAIL_ADDRESS as string,
              )}`}
              target="_blank"
            >
              Email
            </Button>
          </Box>
        );
    }
  }

  return (
    <Box mt={2}>
      {bookings.map((booking, index, array) => (
        <React.Fragment key={booking.id}>
          <SessionRow
            person={booking.pwad_profile.person}
            date={booking.session.date}
            startTime={booking.session.start_time}
            location={booking.session.session_location}
            profileLinkUrl={generatePath(ROUTES.FP_VIEW_PWAD_PROFILE, { pwadProfileId: booking.pwad_profile.id })}
            renderDateStatus={() => renderStatus(status)}
            renderActions={() => renderActions(booking)}
          />
          {index < array.length - 1 && <Divider />}
        </React.Fragment>
      ))}
    </Box>
  );
};

export default BookingList;
